.container {
    background: #f4f4f4;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.8rem;
    position: relative;
    border: 1px solid transparent;
}

.delete {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;
}

.title {
    font-size: 0.875rem;
}

.text {
    color: #707070;
}

.approve {
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
    background-color: #4fb1eb;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 2rem;
    width: 3rem;
    border-radius: 1.25rem;
}

.approved {
    background: #e2fafa;
    border: 1px solid #40cbcb;

    & .approve {
        background: #40cbcb;
    }
}
