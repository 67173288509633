.table {
  position: relative;
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}

.marginTop {
  margin-top: 1.5rem;
}

.spinner {
  margin-top: 1rem;
}

.bordered {
  border-bottom: 2px solid #4fb1eb;
  padding-bottom: 1rem;
}

.minHeight {
  min-height: calc((100vh - 528.812px) - 2.2rem);
}

.wrapper {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.subtitle {
  margin-top: 1rem;
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
  height: 32px;
}

.header {
  border-top: 1px solid #d8d8d8;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: red;
}

.addButton {
  display: inline-flex;
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.title {
  font-weight: 500;
}

.expandableHeadline {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.subTitleButton {
  margin-left: auto;
  font: normal normal 400 1rem var(--main-font);
  color: #4fb1eb;
  cursor: pointer;

  &.disabled {
    color: #c3c3c3;
  }
}

.header {
  display: grid;
  width: 100%;
  align-items: center;
  padding: 0.375rem 0.75rem 0.375rem 1rem;
  grid-template-rows: 1fr;
  grid-template-columns: 7% 10% 47% 20% 10%;
  border-bottom: 1px solid #4fb1eb;
  font-weight: 500;

  & > div {
    display: flex;
    align-items: center;
  }
}

.centered {
  text-align: center;
  justify-content: center;
}

.row {
  display: grid;
  width: 100%;
  align-items: center;
  padding: 0 0.75rem 0 1rem;
  grid-template-rows: 1fr;
  grid-template-columns: 7% 8% 8% 47% 20% 10%;
  font-weight: 400;
  cursor: pointer;
  height: 3rem;

  &:nth-child(2n + 2) {
    background: #eff7fc;
  }

  &:hover {
    background-color: #4fb1eb;
    color: #fff;

    & :global(.checkbox) {
      border-color: #fff;
    }

    & path {
      stroke: #fff;
    }

    & .delete {
      display: block;
    }
  }
}

.selected {
  background-color: #4fb1eb !important;
  color: #fff;

  & :global(.checkbox) {
    border-color: #fff;
  }

  & path {
    stroke: #fff;
  }

  & .delete {
    display: block;
  }
}

.materialRow {
  grid-template-columns: 7% 51% 18% 24%;
}

.planMaterialRow {
  grid-template-columns: 7% 51% 18% 24%;
}

.expenditureRow {
  grid-template-columns: 7% 51% 18% 24%;
  cursor: default;
}

.selected {
  background-color: #4fb1eb !important;
  color: #fff;

  & :global(.checkbox) {
    border-color: #fff;
  }

  & path {
    stroke: #fff;
  }
}

.input {
  width: 4.5rem;
  font-size: 13px;
  background-color: #fff;
  padding: 0.5rem;
  height: var(--input-height);
}

.name {
  padding-top: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.063rem;
}

.delete {
  display: none;
}

.deleteMaterial {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  & > div {
    position: relative;
  }

  &:before {
    content: "";
    background-color: #4fb1eb;
    height: 7rem;
    width: 15rem;
    position: absolute;
    left: -4rem;
    top: -2rem;
  }
}

.measure {
  text-align: center;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number {
  padding-top: 1px;
}

.errorInput {
  border-color: red !important;
}

.approver {
  display: flex;
  align-items: center;
  height: 2.5rem;
  gap: 0.5rem;
  border-radius: 8px;
  background-color: #eee;
  margin-bottom: 0.5rem;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
}

.marker {
  background-color: #4fb1eb;
  height: 100%;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0 0 8px;

  &.approved {
    background-color: #a0e84d;
  }

  svg {
    width: 1rem;
  }
}

.date {
  margin-left: auto;
  padding-right: 0.5rem;
  opacity: 0.5;
}

.apprList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}

.addBtn {
  padding-left: 0;
}
