.container {
  width: 4.19rem;
  background-color: #F5F5F5;
  // padding: 0.1rem;
  padding-top: 5.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 4rem);
  row-gap: 1.8rem;

  @media (max-height: 1020px) {
    row-gap: 1.4rem;
  }
  @media (max-height: 900px) {
    row-gap: 1rem;
  }
  @media (max-height: 820px) {
    row-gap: 0.5rem;
  }
  @media (max-height: 740px) {
    row-gap: 0.2rem;
  }

  @media (max-height: 1000px) {
    padding-top: 2rem;
  }

  .bottom {
    margin-top: auto;
  }

  .menuIconContainer {
    width: 3.3rem;
    height: 3rem;
    position: relative;
    margin: 0.2rem;
    margin-bottom: 5rem;

    .menuIcon {
      background-color: #E8E8E8;
      border-radius: 7px;
      padding: 0.5rem 0.6rem;
      width: min-content;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;

      &:hover {
        background-color: #4FB2ED;

        circle {
          fill: #fff;
        }
      }

      &.active {
        top: 0.2rem;
        left: 0.2rem;
        padding: 0.3rem 0.6rem;
      }
    }

    .moduleMenuPopup {
      position: absolute;
      background-color: #fff;
      width: 21rem;
      height: 14rem;
      border-radius: 5px;
      display: none;
      top: 0;
      left: 0;
      z-index: 98;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
      flex-wrap: wrap;
      padding: 3rem 0.5rem 1rem 0.5rem;

      &.active {
        display: flex;
      }
    }
  }
}