.slider {
  width: 58rem;
}
.container {
  height: 100vh;

  .header {
    padding: 1.3rem 0 1.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-devider-field1);
  }
}

.tab {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
