.contractors {
  padding-top: 1.8rem !important;
  margin-bottom: -1rem !important;
  min-height: 3.875rem;
}

.row {
  display: grid;
  grid-template-columns: 50% 50%;
}

.right {
  text-align: right;
  display: flex;
  justify-content: end;
}

.toAcceptance {
  opacity: 0.65;
}

.count {
  font-weight: 600;
  margin-right: 0.5rem;
}

.name {
  font-weight: 500;
}
