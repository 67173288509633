.tableWrapper {
  & thead {
    display: none;
  }

  & tr[data-row-key="0_parent"] {
    & td {
      border-top: 1px solid #c3c3c3;
    }
  }
}

.hideScroll {
  & :global(.ant-table-content) {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.negative {
  color: red;
}

.totalString {
  position: sticky;
  overflow-x: auto;
  bottom: 3.25rem;
  font-weight: 600;
  border-bottom: 1px solid #c3c3c3;
  border-top: 1px solid #c3c3c3;
}
