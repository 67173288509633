.placeholder {
    animation: fade 1s linear;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
