.checker {
  display: flex;
  align-items: center;
}

.checkbox, .checker {
  font: normal normal 1rem/1.25rem var(--title-font);
}

.checkbox {
  margin-right: 3rem;
}