.header {
  padding: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;

  h2 {
    font-size: 22px;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
  }
}

.btn {
  width: 180px;
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  svg {
    flex-shrink: 0;
  }

  span {
    font-size: 14px;
    text-align: left;
    line-height: 1.05;
  }
}

.search {
  width: 21rem;
  margin-left: auto;
  & :global(.inputBaseIcon) {
    right: 2px;
  }
}

.searchInput {
  border-radius: calc(var(--input-height) / 2);
  border-color: #4fb1eb;
  padding-right: 7rem;
}

.searchBtn {
  background-color: #4fb1eb;
  color: #fff;
  height: calc(var(--input-height) - 4px);
  border-radius: calc(var(--input-height) / 2);
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  gap: 0.5rem;
  cursor: pointer;

  svg {
    display: block;
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}
