.header {
  height: 2.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  & h1 {
    margin-left: 2rem;
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 0;
    margin-right: auto;
    width: 68%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.btns {
  display: flex;
  align-items: center;

  & button {
    margin-left: 1rem;
  }
}

.projectName {
  font-size: 1.125rem;
}

.sum {
  font-size: 1.125rem;
  font-weight: 500;
}

.divider {
  margin: 0 1rem;
  height: 2.5rem;
  width: 2px;
  background-color: #c7c7c7;
  border-radius: 2px;
}
