.container {
  display: flex;
  align-items: center;

  & input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #4fb1eb;
    padding: 0;
    height: 1.3rem;
    width: 1.5rem;
    text-align: center;
    color: #4fb1eb;

    &:disabled {
      border-bottom: none;
      color: #c4c4c4;
    }
  }
}
