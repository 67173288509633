.addSectionButton {
  display: flex;
  transition: none;
  align-items: center;
  height: auto;
  margin-bottom: 1.5rem;
  position: absolute;
  top: 3.25rem;
  z-index: 9999;

  .text {
    margin-right: 1rem;
  }
}

.withBorderBottom {
  border-bottom: 1px solid var(--color-border-primary);
  margin: 1rem 0;
}
