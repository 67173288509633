.footer {
  & > div {
    width: 100%;
  }
}

.bottomControlsWrapper {
  padding: 0;
}

.bottomControls {
  padding: 1rem 0;
  position: static;
  background-color: #fff;
}

.button {
  width: auto;
  margin-left: 1rem;
}

.cancel {
  cursor: pointer !important;
}

.create {
  margin-left: 1rem;
}