.green {
    & :global(.handlerCountSvg) {
        fill: #ADEB65;
    }
}

.container {
    padding: 1rem 0.75rem;
    position: relative;
}

.bg {
    position: absolute;
    z-index: 0;
    pointer-events: none;
    top: 0;
    left: 0;
}

.count {
    padding-top: 0.5rem;
    position: relative;
    display: flex;
    align-items: center;
    height: 2rem;
    font-size: 1.2rem;
    font-weight: 400;

    & svg {
        height: 100%;
    }

    & span {
        position: relative;
    }
}

.row {
    height: 3.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.amount {
    font-size: 1.2rem;
    font-weight: 400;
    text-align: right;
    white-space: nowrap;
}

.plus {
    font-weight: 500;
    font-size: 1rem;
}

.title {
    font-weight: 400;
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
}