.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999999999;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.head {
  display: flex;
  width: calc(100vw - 120px);
  position: absolute;
  left: 60px;
  top: 40px;
  align-items: center;
}

.back {
  cursor: pointer;
  margin-right: 24px;
}

.name {
  margin-left: 10px;
  margin-right: auto;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.125rem;
  color: #fff;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.4);

  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  &:active {
    background: rgba(0, 0, 0, 1);
  }
}

.nav {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  cursor: pointer;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  &:active {
    background: rgba(0, 0, 0, 1);
  }
}

.next {
  left: auto;
  right: 60px;
  transform: translateY(-50%) scaleX(-1);
}

.spinner {
  pointer-events: none;
}
