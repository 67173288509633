.downloadIcon {
  width: 28px;
  height: 23px;
}

.root {
  position: relative;
}

.options {
  position: absolute;
  background-color: #fff;
  width: 100%;
  border: 1px solid var(--color-background-primary);
  border-radius: 0.25rem;
  transform: translateY(0.25rem);
}

.option {
  border-bottom: 1px solid var(--color-background-primary);
  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-primary);
    color: #fff;
  }

  &:last-child {
    border-bottom: none;
  }
}
