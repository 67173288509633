.pill {
  cursor: pointer;
  background-color: white;
  border-radius: 0.75rem;
  outline-width: 1px;
  outline-style: solid;
  padding: 0.153rem 0.413rem 0.153rem 0.134rem;
  width: max-content;
  height: 1.52rem;
  display: flex;
  align-items: center;
  min-width: 4rem;

  & img {
    margin-right: 0.23rem;
  }
}

.success {
  outline-color: #00dec5;
}

.error {
  outline-color: #eb70b7;
}

.label {
  font-family: var(--title-font);
  font-style: normal;
  font-weight: 500;
  font-size: 0.6875rem;
  line-height: 0.8125rem;
  margin: 0 auto;
  text-align: center;
}

.expandArrow {
  height: 0.375rem;
  width: 0.75rem;
  transition: 0.1s ease-in-out;
  margin-left: 0.23rem;
}
