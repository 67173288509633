.table {
    margin-right: 0 !important;
    width: 100%;
}

.orgRow {
    cursor: pointer;
    background-color: #f4f4f4;
    color: #000;
    border-radius: 10px;
    font-size: 1.125rem;
    height: 3.875rem;
    font-family: var(--title-font);
    font-weight: 500;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 105fr 24fr 20fr 10fr;
    padding-left: 23px;
    align-items: center;
    margin-bottom: 8px;
    padding-right: 1rem;

    & div {
        display: flex;
        height: 100%;
        align-items: center;
    }

    & svg {
        margin-left: auto;
    }

    & > div:nth-child(4) {
        opacity: 0;
    }
    &:hover {
        background-color: #4FB2ED;
        color: #fff;

        .boxBtn {
            display: flex;
        }
        & svg > path {
            fill: #fff;
        }
        & > div:nth-child(4) {
            opacity: 1;
        }
    }
}
.orgName {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.orgInn {
    width: 70%;
    min-width: 20rem;
}
.orgKpp {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 15rem;
    max-width: 17rem;
    margin: 0 2rem;
    justify-content: space-between;
}

.btns {
    display: flex;
    align-items: center;
    width: 80px;
    height: 40px !important;
    border-radius: 20px;
    padding: 0 16px;
    justify-content: space-between;
    background-color: #fff;

    & svg {
        margin-left: 0 !important;

        & path {
            fill: #606060 !important;
        }
    }
}
