.imgWrapper {
  width: 11rem;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  aspect-ratio: 14/9;

  img {
    display: block;
    width: 100%;
  }

  &:hover .controls {
    display: flex;

    position: absolute;
    right: 0;
    top: 0;
  }
}

.spinner {
  width: 11rem;
  aspect-ratio: 14/9;
}

.hidden {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}

.controls {
  display: none;
}

.modal {
  width: 580px;
}

.content {
  padding: 1rem;
  height: calc(100% - 4rem);
}

.hidden {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}

.uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  border: 1px dashed #4fb2ed;
  align-self: stretch;
  border-radius: 0.5rem;
  cursor: pointer;
  div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    height: 150px;
    width: 150px;
    border-radius: 0.5rem;

    svg {
      height: 50px;
      width: 30px;
    }
  }
}

.picWrapper {
  height: 300px;
  width: 100%;
  overflow: hidden;
}

.img {
  display: block;
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}

.previewCanvas {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}

.cropperWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f4f4f4;
  border: 1px dashed #4fb2ed;
  border-radius: 0.5rem;
}

.cropperRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
