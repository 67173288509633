.root {
  cursor: pointer;
}

.portal {
  z-index: 101;
}

.popup {
  width: 17.125rem;
}

.head {
  border-bottom: 1px solid #d2d2d2;
  padding: 0.5rem 1rem 0.5rem;
  color: #4fb1eb;
}

.content {
  padding: 0.5rem 1rem;
}

.subtitle {
  margin: 0 0 0.5rem 0;
  span {
    font-weight: 600;
    display: inline-block;
    margin-left: 0.25rem;
  }
}

.entry {
  display: grid;
  width: 100%;
  align-items: center;
  gap: 0.75rem;
  grid-template-columns: auto 1fr auto;
  margin-bottom: 0.5rem;

  svg {
    margin-left: auto;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.name {
  font-size: 0.875rem;
}

.controls {
  border-top: 1px solid #d2d2d2;
  padding: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: center;

  button {
    font-size: 0.875rem;
    height: 2.25rem;
    border-radius: 6px;
  }
}

.status {
  font-size: 0.875rem;
  font-weight: 700;
  color: #fff;
  background-color: #4fd8eb;
  height: 2.25rem;
  display: flex;
  align-items: center;
  border-radius: 1.125rem;
  padding-left: 0.5rem;
  padding-right: 0.75rem;
  gap: 0.25rem;

  svg {
    width: 1.375rem;
  }

  circle {
    stroke: #fff;
  }

  .count {
    background-color: #0000001c;
    height: 1.75rem;
    border-radius: 0.875rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    margin-left: 2px;
  }
}

.statusApproved {
  background-color: #4febd1;

  circle {
    stroke: #fff;
    opacity: 0.2;
    fill: #000;
  }
}

.date {
  background-color: #e6f6ff;
  margin: 0.5rem 1rem;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  height: 2rem;
  width: fit-content;
  font-size: 0.875rem;

  svg {
    margin-left: 0.5rem;
    height: 1.375rem;
  }
}

.divider {
  border-radius: 1.5px;
  opacity: 0.5;
  background: #4fb1eb;
  margin: 0 1rem;
  width: 2px;
  height: 1.375rem;
}
