.monthSlider {
  position: relative;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  height: 100%;
}

.draggable {
  position: absolute;
  height: 100%;
  border: 2px solid #5bb6ec;
  background-color: rgba(255, 255, 255, 0.48);
  border-radius: 3px;
  transition: width 0.5s;
  &:not([aria-disabled=true]){
    cursor: move;
  }
}

.left {
  position: absolute;
  height: calc(100% + 4px);
  width: 10px;
  background: #5bb6ec;
  top: -2px;
  left: -9px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.right {
  position: absolute;
  height: calc(100% + 4px);
  width: 10px;
  background: #5bb6ec;
  top: -2px;
  right: -10px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.stick {
  position: absolute;
  height: 16px;
  top: 50%;
  left: 50%;
  width: 6px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
}

.month {
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  user-select: none;
}

.hidden {
  display: none;
}

.withBorder{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    border-right: .25rem dashed #5bb6ec;
  }
}