@media (max-width: 6000px) {
  html {
    font-size: 21px;
  }
}
@media (max-width: 5000px) {
  html {
    font-size: 20px;
  }
}
@media (max-width: 4000px) {
  html {
    font-size: 19px;
  }
}
@media (max-width: 3200px) {
  html {
    font-size: 18px;
  }
}
@media (max-width: 2800px) {
  html {
    font-size: 17px;
  }
}
@media (max-width: 2400px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 1700px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 1500px) {
  html {
    font-size: 14px;
  }
}
