.title {
  color: #696969;
  margin-bottom: 0.35rem;
  font-weight: 600;
}

.defaultHeight {
  height: 2.64rem;
  display: flex;
  align-items: center;
}

.container {
  width: 100%;
}

.content {
  width: 100%;
}
