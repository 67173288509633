.elem {
  width: 100%;
  cursor: pointer;
}

.empty {
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.info {
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: flex-start;
  align-self: flex-start;
}
