.root {
  height: 100vh;
  overflow-y: auto;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
  padding: 1.5rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - 3rem);
    height: 1px;
    background-color: #d6d6d6;
  }

  h2 {
    font-style: 1.375rem;
    font-weight: 700;
    margin-bottom: 0;
  }
}

.status {
  font-weight: 600;
  color: #fff;
  background-color: #4fd8eb;
  padding: 0.75rem 1rem;
  border-radius: 1.5rem;
}

.content {
  padding: 1.5rem;
  padding-bottom: 2rem;
}

.descr {
  border: 1px solid #4fb1eb;
  border-radius: 1rem;
  padding: 0.75rem;
}

.title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.more {
  display: inline-block;
  color: var(--color-background-primary);
  cursor: pointer;
  font-weight: 500;
}

.videoWrapper {
  margin-top: 1.5rem;
  border: 2px solid #4fb1eb;
  border-radius: 1rem;
  overflow: hidden;
  width: 32.5rem;
  margin-left: auto;
  margin-right: auto;
}

.video {
  display: block;
  width: 100%;
}

.controls {
  position: fixed;
  bottom: 0;
  width: calc(70.5rem - 19.125rem);
  background-color: #fff;
  border-top: 1px solid #4fb1eb;
  padding: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;

  button {
    font-size: 0.875rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
  }
}

.separBtn {
  margin-top: 1.25rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}
