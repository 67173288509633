.approving {
  display: flex;
  align-items: center;
  column-gap: .25rem;
}

.userApproveButton {
  cursor: pointer;
}

.countContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  position: relative;
  top: -.5rem
}

.count {
  background: var(--color-background-primary);
  color: #fff;
  font: normal normal 400 0.875rem var(--title-font);
  min-width: 2.188rem;
  padding: 0.05rem 0;
  text-align: center;
  border-radius: 1rem;
  align-self: start;
}

.popup {
  width: 19rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
}

.popupTitle {
  font-size: 0.75rem;
  color: var(--color-font-primary);
  margin: 0;
}

.popupYourApprove {
  margin-top: 0.84375rem;
}

.approver {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    justify-content: flex-end;
    column-gap: 1.25rem;
    align-items: center;
    flex-wrap: nowrap;
  }
}

.approvalsTitle {
  display: block;
  font-size: 0.75rem;
}

.approvalsCount {
  font-weight: bold;
}

.popupHeader {
  border-bottom: 1px solid #D5D5D5;
  padding: .5rem 1rem;
}

.popupBody {
  padding: 0 1rem 1.2rem;
  display: flex;
  flex-direction: column;
  row-gap: .83125rem;
}

.popupYourApprove {
  display: flex;
  justify-content: space-between;
}

.approversList {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  margin-top: 0.844rem;
}