.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    & .popup {
      visibility: visible;
      pointer-events: all;
      opacity: 1;
      z-index: 21;
    }
  }
}

.popup {
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  min-width: 15.5rem;
  background-color: #fff;
  padding: 0.5rem 0.5rem 0;
  border-radius: 10px;
  position: absolute;
  border: 1px solid #4fb2ed;
  margin-right: 2rem;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 2rem;
    left: -2rem;
    top: 0;
  }

  &.last {
    bottom: auto;
    top: -1000%;
    right: 50%;
    transform: translateY(100%);
  }

  &.right {
    left: auto;
    right: -2rem;
    transform: translateX(100%);
  }
}

.title {
  color: #727272;
  margin-bottom: 0.5rem;
  text-align: center;
}

.flex {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.8rem;
  font-size: 0.875rem;

  & p {
    margin-right: auto;
    margin-bottom: 0;
  }
}

.chart {
  width: 120px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.legend {
  width: 2px;
  height: 1rem;
  margin: 0 auto;
  grid-area: color;
}

.pair {
  display: flex;
}

.item {
  display: grid;
  flex-basis: 50%;
  grid-template-columns: 1rem 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: "color sum" "empty title";
  margin-bottom: 0.5rem;
  text-align: left;

  & p {
    margin-bottom: 0;
  }
}

.fieldName {
  grid-area: title;
  font-size: 0.8rem;
  color: #000;
}

.sum {
  grid-area: sum;
  font: normal normal 300 0.8rem/1rem var(--title-font);
  white-space: nowrap;

  & span {
    color: #000 !important;
  }
}

.final {
  &,
  .sum {
    font-weight: 500;
  }
}

.info,
.currency {
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.info {
  background: #d6ebf7;
}

.currency {
  background: #ededed;
}
