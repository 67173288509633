.labelBox {
  display: flex;
  margin: 0.5rem 0;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 0.375rem;

  .blueLabelWrapper {
    width: auto;
    max-width: 11.125rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  .blueLabel {
    color: #4fb2ed;
    background-color: #e6f4fc;
    border-radius: 50px;
    padding: 0.2rem 0.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    max-width: 14rem;
    display: flex;
    align-items: center;
    &:empty {
      display: none;
    }
  }

  .greyLabel {
    color: #808080;
    background-color: #ededed;
    border-radius: 50px;
    padding: 0.2rem 0.6rem;
    white-space: nowrap;
    height: min-content;
    cursor: pointer;

    svg {
      margin-right: 0.2rem;
    }
  }
}

.cursorPointer {
  cursor: pointer;
}

.container {
  display: block;
  max-width: 100%;
}

@media screen and (max-width: 1441px) {
  .labelBox {
    flex-wrap: nowrap !important;
  }
}

.title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.ungroupIcon {
  cursor: pointer;
}

.segments {
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
  opacity: 0.5;

  & > div {
    font-size: 11px !important;
  }
}
