.leftSide {
  grid-template-columns: 0.55fr 1.85fr 1.2fr;
}

.field2 {
  padding-left: 2rem;
}

.fieldGroup {
  width: 8rem;

  &:before {
    width: calc(100% - 4px - 0.7rem - 2.9rem) !important;
  }
}
