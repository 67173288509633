.button {
  border-radius: 0.63rem;
  background-color: var(--color-background-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--main-font);
  color: #fff;
  width: 2.375rem;
  height: 2.375rem;
  justify-content: center;
  padding-right: 0.1rem;
  flex-shrink: 0;

  .arrowLeft {
    width: 0.7rem;
  }
}
