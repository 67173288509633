@use "src/scss/mixins/_ui-indicators.scss" as ui_indicators;

.sectionRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 2.3125rem;
  margin-bottom: 0.625rem;
  height: 3.625rem;
  position: relative;
  cursor: pointer;
}

.withArrowPadding {
  padding-right: 3rem;
}

.checkDouble {
  width: 1.6rem;
  min-width: 1.6rem;
  max-width: 1.6rem;
}

.primary {
  background-color: var(--color-background-field6);

  .addBtn {
    display: none;

    :global(.addBtnBg) {
      fill: #fff;
    }

    :global(.addBtnCross) {
      fill: #000;
    }
  }

  &:hover {
    background-color: var(--color-background-primary);

    .checkDouble > path {
      fill: white;
    }

    .addBtn {
      display: block;
    }

    .checkbox {
      border-color: #fff;

      path {
        stroke: var(--color-font-hover);
      }
    }

    .index {
      background-color: #fff;
      color: #000;
    }

    .actionBlock {
      display: flex;
    }

    &,
    .budgetTitle,
    .budgetTitle *,
    .input,
    .input:hover,
    .input::placeholder,
    .progressBar {
      color: var(--color-font-hover);
    }

    .input,
    .input:hover {
      border: 1px solid var(--color-font-hover);
    }

    .mark,
    .info,
    .progressBarFiller {
      background-color: var(--color-font-hover);
    }

    .info path {
      fill: #000;
    }

    .arrowIcon path {
      fill: var(--color-font-hover);
    }
  }

  &:hover .input {
    background-color: transparent;
  }
}

.checkboxContainer {
  margin-right: 0.75rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
}

.inputContainer {
  width: 100%;
}

.input {
  color: #000;
  border: 1px solid var(--color-border-primary);
  transition: none;
}

.bigBtn {
  border-radius: 0.5rem;
  border-color: #ffffff;
  background-color: var(--color-background-main);
  width: 16.7rem;
  height: 3rem;
  padding: 0 0.8rem 0 1.35rem;
  font: normal normal 500 1.12rem var(--title-font);
  color: var(--color-font-secondary4);
  cursor: pointer;

  .buttonContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  width: 35%;
}

.confirmSelect {
  margin-left: 1rem;
}

.actions {
  display: flex;
}

.actionsMargin {
  margin-right: 1.625rem;
}

.actionBlock {
  display: none;
}

.budgetCol {
  display: flex;
  justify-content: flex-end;
}

.divider {
  height: 2.25rem;
  width: 1px;
  background-color: #c9c9c9;
  margin: 0 1.34375rem;
  flex-shrink: 0;
}

.withArrowPadding .divider {
  margin: 0 1rem;
}

.infoContainer {
  margin: 0 1.625rem;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4fb2ed;
  border-radius: 50%;
  width: 1.625rem;
  height: 1.625rem;
  cursor: pointer;

  & path {
    fill: #fff;
  }
}

.arrowIcon {
  position: absolute;
  right: 1.125rem;
  width: 1rem;
  height: 0.5rem;

  path {
    fill: #969696;
  }
}

.leftSide {
  display: flex;
  align-items: center;
  width: auto;
  justify-content: flex-end;
}

.mark {
  background-color: #c1e7ff;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  color: #000;
  font-weight: 500;
  margin-left: 1.625rem;
}

.budgetTitle {
  width: 10.6rem;
}

.checkboxPlaceholder {
  visibility: hidden;
}

.discount {
  margin-left: 1.25rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.withoutDiscountIcon {
  opacity: 0.5;
}

$indicatorSize: 8px;

.withIndicator:before {
  @include ui_indicators.getIndicatorAsContent($indicatorSize);
  position: absolute;
  left: -1rem;
  top: 50%;
  transform: translateY(-50%);
}

.sectionPlanButton {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  flex-shrink: 0;
}
