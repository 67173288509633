.root {
  border-radius: 0.75rem;
  border: 1px solid var(--color-background-primary);
  font-weight: 500;
  font-size: 1.125rem;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-primary);
    color: #fff;

    & path {
      stroke: #fff;
    }
  }
}
