.wrapper {
  padding: 0.875rem 1.25rem 1.5rem;
}

.pickers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.875rem;
}

.button {
  background-color: #e5e5e5;
  border-radius: 5px;
  padding: 0.3rem 1rem;
}

.dateValue {
  border: 1px solid #808080;
  padding: 0.3rem 1rem;
  border-radius: 5px;
}

.marginBottom {
  margin-bottom: 14px;
}

.actionBlock {
  position: absolute;
  bottom: 19px;
  left: 50%;
  transform: translateX(-50%);
}

.select {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--input-height);
  border: 1px solid var(--color-border-field18);
  border-radius: 0.5rem;
  padding: 0.6875rem 1.125rem;
  font-family: "Montserrat", sans-serif;
  position: relative;
  background: #fff;

  &:not([aria-disabled="true"]) {
    cursor: pointer;
  }
}

.calendarIcon {
  margin-left: auto;
}

.title {
  margin-right: 1rem;
  font-weight: 400;
}

.optionsBlock {
  border-radius: 12px;
  background: #fff;
  z-index: 9999;
  transition: all 0.3s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: none;
  height: 0;
  cursor: auto;

  &.isOpen {
    display: block;
    height: auto;
  }
}

.option {
  border-radius: 0.5rem;
  padding: 0.6rem 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background: #4fb2ed;
    color: #fff;
  }
}

.estimateItem {
  font: 0.75rem var(--main-font);
  padding: 0.3rem 0.2rem;
  margin: 0.3rem 0;
}

.flexClass {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label {
  color: #666666;
  margin-bottom: 0.35rem;
}

.hidden {
  display: none;
}

.error {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
  position: absolute;
  bottom: -1.25rem;
}

.display {
  background-color: transparent;
  border-color: transparent;
}

.placeholder {
  opacity: 0.5;
}

.optionsPortal {
  z-index: 999999999;
}

.popoverWrapper {
  width: 100%;
}

.container {
  position: relative;
}
