.chartLegend {
  padding: 0;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 2.5rem;
  grid-row-gap: .5rem;
  width: 100%;
  margin: auto;

  & li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    column-gap: .5rem;
    font-weight: lighter;

    &.centered {
      justify-content: center;
    }
  }
}


.chartLegendMark {
  position: relative;
  width: 1.625rem;
  height: .75rem;
  border-radius: .75rem;
}
