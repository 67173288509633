.viewContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  a {
    color: var(--color-background-primary);
  }
}

.bubbles {
  display: flex;
  flex-wrap: wrap;
  gap: 0.53rem;
  margin-bottom: 0.35rem;
}

.bubble {
  border-radius: 0.8rem;
  color: white;
  background-color: var(--color-background-primary);
  padding: 0.5rem 0.66rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.4rem;
}

.date {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  gap: 0.5rem;
}

.back {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 1rem;
}
