.container {
  background-color: var(--color-background-field9);
  padding: 1rem 2rem;
  min-height: 100%;
}

.content {
  position: relative;
  padding: 1rem 1rem 0 1.875rem;
}
