.new {
  padding: 1.875rem 1.3125rem 0.9375rem;
  border-bottom: 1px solid #d6d6d6;

  .title {
    font-weight: 700;
    font-size: 1.375rem;
    margin-bottom: 1rem;
  }
}

.tabs {
  & > * {
    width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.tabsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.display {
  border-bottom: 3px solid #bae5ff;
  background-color: #e4f6ff;
  padding: 1.5rem 1.3125rem 0.8125rem;

  .head {
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns: 1fr auto;
    gap: 0.5rem;
    padding-right: 1rem;
  }

  .title {
    font-weight: 600;
    font-size: 1.125rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.labeledItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  .label {
    opacity: 0.7;
  }
}

.bubble {
  padding: 0 0.5rem;
  border-radius: 1rem;
  background: #fff;
  height: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.creator {
  white-space: nowrap;
  max-width: 11.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  grid-template-columns: 1.5rem 1fr;

  .avatar {
    height: 1.5rem;
    width: 1.5rem;
    flex-shrink: 0;

    div {
      font-size: 0.625rem;
      font-weight: 500;
    }
  }
}

.date {
  max-width: 13.125rem;

  svg {
    height: 1.375rem;
  }
}

.divider {
  border-radius: 1.5px;
  opacity: 0.5;
  background: #4fb1eb;
  width: 2px;
  height: 1.375rem;
}

.row2 {
  padding-top: 0.875rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.repeatPortal {
  z-index: 1010;
}

.repeatPopup {
  width: 276px;

  header {
    border-bottom: 1px solid #d2d2d2;
    padding: 0.5rem 1rem 0.5rem;
    color: #4fb1eb;
  }

  main {
    background-color: #e8f7ff;
    margin: 0.5rem 1rem;
    display: flex;
    align-items: center;
    height: 2.625rem;
    border-radius: 0.5rem;
    justify-content: space-around;
  }
}

.building {
  max-width: 28.125rem;
}

.section {
  max-width: 35rem;
}
