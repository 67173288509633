.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--input-height);
  padding: 0 0.5rem;
  position: relative;
  border: none;
  font-size: 1rem;
  color: #000;
  font-weight: 400;
  flex-shrink: 0;
}

.triangle {
  position: absolute;
  top: -0.75rem;
  right: 46%;
  width: 1.375rem;
  height: 1.375rem;
  transform: rotate(45deg);
  background-color: #fff;
  border-top: 1px solid #4fb2ed;
  border-left: 1px solid #4fb2ed;
}

.calendatIconView {
  width: 1.0625rem;
  height: 1.0625rem;
  margin: 0 0.5rem 5px;
  flex-shrink: 0;
  flex-grow: 0;
  position: absolute;
  right: 0;
  img {
    width: 100%;
    height: 100%;
  }
}

.container {
  width: 100%;
  font-family: var(--main-font);
  position: relative;
  height: auto;
  padding-bottom: 1.5rem;

  .titleBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;

    .button {
      border: 1px solid #4fb2ed;
      border-radius: 5px;
      padding: 0.3rem 1rem;
    }

    .dateBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 23.75rem;
      border-bottom: 1px solid #e3e3e3;
      padding: 0.5rem;

      .textPeriod {
        color: #707070;
        font-size: 1rem;
      }

      .textDate {
        font-size: 1rem;
        color: #000;
        font-weight: 400;
        font-family: var(--main-font);
      }

      .dateValue {
        border: none;
        border-radius: 5px;
        background-color: #f2f2f2;
        display: flex;
        flex-basis: 48%;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0.5625rem 0.625rem 0.8125rem;
        cursor: pointer;

        &.activeDate {
          background-color: #4fb2ed;
          color: #fff;

          .textPeriod {
            color: #fff;
          }

          .textDate {
            color: #fff;
          }

          .iconCal {
            color: #fff;
          }
        }
      }
    }

    .monthPickerWrapper {
      margin: 0 1rem;
    }

    .closeButton {
      margin-right: 1rem;
    }

    .actionBlock {
      width: min-content;
      text-align: center;
    }
  }

  .marginBottom {
    justify-content: center;
    margin-bottom: 1rem;
  }

  .actionBlock {
    text-align: center;
    padding: 1rem;
  }
}

.select {
  border: 1px solid var(--color-border-field18);
  border-radius: 0.5rem;
  font: normal normal 400 1rem var(--main-font);
  position: relative;
  height: var(--input-height);

  display: flex;
  justify-content: space-between;
  align-items: center;

  .calendarIcon {
    position: absolute;
    right: 0.5rem;
    top: 0.7rem;
  }

  .optionsBlock {
    top: -0.0625rem;
    right: 50%;
    transform: translateX(50%);
    border-radius: 10px;
    position: absolute;
    background: #fff;
    z-index: 5;
    transition: all 0.3s ease;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    display: none;
    // height: 0;

    &.isOpen {
      display: block;
      margin-top: 4.0625rem;
      height: auto;
      border-right: 1px solid #4fb2ed;
      border-left: 1px solid #4fb2ed;
      border-bottom: 1px solid #4fb2ed;
      border-top: 1px solid #4fb2ed;
    }

    &.isTooRight {
      right: 100%;
    }

    &.isTooLeft {
      right: 10%;
    }

    .option {
      border-radius: 0.5rem;
      padding: 0.6rem 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        background: #4fb2ed;
        color: #fff;
      }
    }
  }
}

.estimateItem {
  font: 12px var(--main-font);
  padding: 0.3rem 0.2rem;
  margin: 0.3rem 0;
}

.wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 0.875rem;
}

.error {
  color: #ff0558;
  position: absolute;
  font-size: 0.687rem;
  top: calc(100% + 0.1rem);
  left: 0.1rem;
}

.submit {
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}
