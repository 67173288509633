.island {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  border-radius: 1rem;
  padding: 1rem;
  background-color: white;
  position: relative;
  border: 1px solid #E9E9E9;
  margin: 0 auto;
  //
  //&:active {
  //  cursor: grabbing;
  //  z-index: 2;
  //}
}

.island:before {
  content: '';
  width: 5.2rem;
  height: .375rem;
  border-radius: .375rem;
  background-color: #E9E9E9;
  position: absolute;
  top: .5rem;
  left: 50%;
  transform: translateX(-50%);
  //cursor: grab;
  transition: all .2s ease-in-out;
}

.islandFullScreen {
  grid-column: 1/4 !important;
  grid-row: 1/9 !important;
  z-index: 3;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .875rem 0 0 0;
}

.content {
  position: relative;
  max-height: 100%;
  height: 100%;
}

.emptyPlaceholder {
  min-height: auto;
}