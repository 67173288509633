.header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 1.4rem 1.76rem .8rem;
  font-weight: 400;
  background-color: white;
  z-index: 70;
}

.fileBlock {
  width: max-content;
  padding: 0.5rem 0.75rem;
  max-width: 30rem;
  min-width: 15rem;
}