.form {
  border-radius: 0.75rem;
  border: 1px solid #ebebeb;
  background: #fafafa;
  margin-top: 1rem;
}

.first {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.75rem;
  border-bottom: 1px solid #ebebeb;
}

.second {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.75rem;
}

.row {
  display: grid;
  grid-template-columns: 45% 45% 1fr;
  width: 100%;
  gap: 0.5rem;
  justify-content: space-between;

  & > label {
    margin-bottom: 0;
  }
}

.control {
  flex-shrink: 0;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete {
  & > div {
    background-color: transparent;
  }
}

.select {
  max-width: calc(34rem - 1.5rem - 3.6rem);
}

.expandableTitle {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 1rem;
  min-height: calc(2rem + 2px);
}
