.row {
  display: flex;
  width: 100%;
  column-gap: 0.675rem;
  margin-bottom: 1rem;
}

.controls {
  margin-bottom: 1rem;
}

.w100p {
  flex-basis: 100%;
  width: 100%;
}

.w45p {
  flex-basis: 45%;
}

.w30p {
  flex-basis: 30%;
}

.w25p {
  flex-basis: 25%;
}

.filesInput {
  visibility: hidden;
  position: absolute;
}

.filesBtn {
  margin-bottom: 0.4rem;
  margin-left: 1rem;
}

.fileRow {
  width: fit-content;
  white-space: nowrap;

  & svg {
    margin-left: 1.5rem;
  }
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.error {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
  position: absolute;
  bottom: -1.25rem;
  white-space: nowrap;
}

.add {
  margin-bottom: 1rem;
}
