body.osla {
  h1 {
    font-family: 'Gotham Pro';
    font-weight: 700;
    font-size: 74px;
    line-height: 80px;
    color: #FFFFFF;
  }

  h2 {
    font-family: 'Gotham Pro';
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    color: #424345;
  }

  .btn, .button {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    transition: 0.3s;
    white-space: nowrap;
    font-family: 'Gotham Pro';
  }

  .btn {
    padding: 12px 20px;
    max-width: 260px;
    width: 100%;
    height: 48px;
    background: #5EA2F4;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .btn:hover {
    background: #4F94E7;
  }

  .button {
    padding: 15px 30px;
    max-width: 356px;
    width: 100%;
    height: 56px;
    background: #FFFFFF;
    font-size: 22px;
    line-height: 26px;
    color: #5EA2F4;
  }

  .button:hover {
    color: #4F94E7;
    background: rgba(255, 255, 255, 0.8);
  }


  .improve__main {
    background: linear-gradient(0deg, rgba(48, 123, 211, 0.5) 0%, rgba(95, 163, 245, 0) 100%), #5EA2F4;
    padding: 236px 0 154px;
  }

  .improve__kran {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .improve__kran svg {
    width: 100%;
    height: 100%;
  }

  .improve__star {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .improve__star svg {
    width: 100%;
    height: 100%;
  }

  .improve__info {
    max-width: 1068px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: -5px;
    z-index: 1;
  }

  .improve__info h1 {
    text-align: center;
  }

  .improve__info span {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    display: block;
    margin-top: 29px;
  }

  .improve__info .button {
    margin: 100px auto 0;
  }

  .improve__shared {
    position: relative;
    margin-top: 170px;
  }

  .improve__shared strong {
    font-family: 'Gotham Pro';
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    display: block;
    color: rgba(255, 255, 255, 0.6);
  }

  .mouser-dun {
    position: absolute;
    right: 49%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 40px;
    height: 50px;
    top: 56px;
    -webkit-animation-name: example;
    animation-name: example;
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    z-index: 99;
  }

  .scroller {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mouser-dun-arrow {
    background-image: url("../img/arrow-doun.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: -20px;
    -webkit-animation: sdb07 2.5s infinite;
    animation: sdb07 2.5s infinite;
    opacity: 0.2;
    width: 28px;
    height: 13px;
  }

  .mouser-dun-arrow:nth-child(1) {
    -webkit-animation-delay: .0s;
    animation-delay: .0s;
  }

  .mouser-dun-arrow:nth-child(2) {
    margin-bottom: -20px;
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
  }

  .mouser-dun-arrow:nth-child(3) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  @-webkit-keyframes example {
    0% {
      -webkit-transform: translateY(-20%);
      transform: translateY(-20%)
    }
    50% {
      -webkit-transform: translateY(20%);
      transform: translateY(20%)
    }
    100% {
      -webkit-transform: translateY(-20%);
      transform: translateY(-20%)
    }
  }
  @-webkit-keyframes sdb07 {
    0% {
      opacity: 0.2;

    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;

    }
  }


  .anim-cran {
    position: relative;
    animation: anim-cran 2s infinite alternate;
  }

  @keyframes anim-cran {
    from {
      -webkit-transform: translateY(-1.5em);
      transition: all 2s;
    }
    to {
      -webkit-transform: translateY(0em);

      transition: all 2s;
    }
  }

  .anim-star {
    animation: name-of-animation 3s infinite linear;
  }

  @keyframes name-of-animation {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }

  .environment__main {
    background: #DFECFF;
    padding-top: 116px;
  }

  .environment__photo {
    margin: -10px auto 0;
    width: 100%;
    max-width: 1920px;
    display: flex;
  }

  .environment__planshet {
    display: none;
  }

  .environment__mob {
    display: none;
  }

  .environment__title p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #424345;
    margin-top: 16px;
  }

  .environment__info {
    display: flex;
    justify-content: center;
    padding: 0 15px;
    position: absolute;
    bottom: 37px;
    width: 100%;
  }

  .title-bot {
    font-family: 'Gotham Pro';
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #5EA2F4;
    display: block;
  }

  .production__main {
    margin-top: 128px;
    padding-bottom: 60px;
  }

  .production__flex {
    display: flex;

    justify-content: space-between;
  }

  .production__info {
    max-width: 550px;
    width: 100%;
    margin-top: 25px;
  }

  .production__info p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #424345;
    margin-top: 27px;
  }

  .production__info ul {
    margin-bottom: 81px;
    margin-top: 22px;
    list-style: unset;
    margin-left: 32px;
  }

  .production__info ul li {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #424345;
  }

  .production__info .btn {
    font-size: 22px;
    line-height: 26px;
    max-width: 342px;
    height: 52px;
  }

  .production__photo {
    margin-top: 14px;
    display: flex;
  }

  .production__mob {
    display: none;
  }

  .production__costs {
    display: flex;
    justify-content: center;
    margin-top: 130px;
  }

  .business__main {
    background: #DFECFF;
    padding-top: 160px;
  }

  .business__cnt {
    position: relative;
  }

  .business__flex, .second-part {
    position: relative;
    z-index: 1;
  }

  .business__flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .business__info {
    max-width: 737px;
    width: 100%;
  }

  .business__info > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #424345;
    margin-top: 16px;
  }

  .business__bash {
    display: flex;
  }

  .business__bash svg {
    width: 100%;
    height: 100%;
  }

  .business__itme {
    max-width: 484px;
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    position: relative;
  }

  .business__itme:before {
    content: "";
    position: absolute;
    top: 76px;
    left: 17px;
    background-image: url("../img/liners-left.png");
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 399px;
    width: 100%;
    height: 496px;
    z-index: -1;
  }

  .second-part__info .business__itme:before {
    background-image: url("../img/liners-right.png");
    max-width: 191px;
    height: 383px;
    top: auto;
    bottom: 27px;
    left: -169px;
  }

  .business__block {
    margin-top: 43px;
    display: flex;
    align-items: flex-start;
  }

  .business__number {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5EA2F4;
    width: 36px;
    height: 36px;
    background: #C8E5FF;
    border-radius: 30px;
    margin-right: 20px;
  }

  .business__name strong {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #424345;
  }

  .business__name p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #424345;
  }

  .business-mob {
    display: none;
  }

  .animate-site {
    transform: translateZ(0);
  }

  .animate-site .anim-busin-1, .animate-site .anim-busin-2, .animate-site .anim-busin-3, .animate-site .anim-busin-4, .animate-site .anim-busin-5, .animate-site .anim-busin-6, .animate-site .anim-busin-7 {
    opacity: 0;
    transform: translateY(100px);
    transition-duration: .8s;
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(.33, 1, .68, 1);
    will-change: transform, opacity;
  }

  .animate-site .anim-busin-1 {
    transition-delay: .2s;
  }

  .animate-site .anim-busin-2 {
    transition-delay: .3s;
  }

  .animate-site .anim-busin-3 {
    transition-delay: .4s;
  }

  .animate-site .anim-busin-4 {
    transition-delay: .5s;
  }

  .animate-site .anim-busin-5 {
    transition-delay: .8s;
  }

  .animate-site .anim-busin-6 {
    transition-delay: .9s;
  }

  .animate-site .anim-busin-7 {
    transition-delay: 1.0s;
  }

  .animate-site.in-viewport .anim-busin-1, .animate-site.in-viewport .anim-busin-2, .animate-site.in-viewport .anim-busin-3, .animate-site.in-viewport .anim-busin-4, .animate-site.in-viewport .anim-busin-5, .animate-site.in-viewport .anim-busin-6, .animate-site.in-viewport .anim-busin-7 {
    opacity: 1;
    transform: translateY(0);
  }

  /*============-anime-bash-============*/
  .animate-site .anim-bash-1, .animate-site .anim-bash-2, .animate-site .anim-bash-3, .animate-site .anim-bash-4, .animate-site .anim-bash-5, .animate-site .anim-bash-6, .animate-site .anim-bash-7 {
    opacity: 0;
    transform: translateY(100px);
    transition-duration: .8s;
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(.33, 1, .68, 1);
    will-change: transform, opacity;
  }


  .animate-site .anim-bash-1 {
    transition-delay: .2s;
  }

  .animate-site .anim-bash-2 {
    transition-delay: .3s;
  }

  .animate-site .anim-bash-3 {
    transition-delay: .4s;
  }

  .animate-site .anim-bash-4 {
    transition-delay: .5s;
  }

  .animate-site .anim-bash-5 {
    transition-delay: .8s;
  }

  .animate-site .anim-bash-6 {
    transition-delay: .9s;
  }

  .animate-site .anim-bash-7 {
    transition-delay: 1.0s;
  }

  .animate-site.in-viewport .anim-bash-1, .animate-site.in-viewport .anim-bash-2, .animate-site.in-viewport .anim-bash-3, .animate-site.in-viewport .anim-bash-4, .animate-site.in-viewport .anim-bash-5, .animate-site.in-viewport .anim-bash-6, .animate-site.in-viewport .anim-bash-7 {
    opacity: 1;
    transform: translateY(0);
  }


  .second-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -387px;
  }

  .second-part__info {
    max-width: 761px;
    width: 100%;
    margin-top: 80px;
  }

  .second-part__bash {
    margin-left: -8px;
    display: flex;
  }

  .second-part__bash svg {
    width: 100%;
    height: 100%;
  }

  .implem__main {
    margin: 106px 0;
  }

  .implem__cnt h2 {
    text-align: center;
  }

  .implem__flex {
    display: flex;
    align-items: flex-start;
    max-width: 888px;
    width: 100%;
    justify-content: space-between;
    margin: 101px auto 100px;
  }

  .implem-mob__photo {
    display: none;
  }

  .implem__box {
    max-width: 226px;
    width: 100%;
  }

  .implem__number {
    display: flex;
    align-items: flex-end;
  }

  .implem__number small {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #8A8A8A;
  }

  .implem__number strong {
    font-family: 'Gotham Pro';
    font-weight: 700;
    font-size: 90px;
    line-height: 80px;
    color: #5EA2F4;
    display: block;
    margin-left: 8px;
  }

  .implem__box p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-top: 10px;
    color: #8A8A8A;

  }

  .request__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #DFECFF;
    padding-top: 140px;
    padding-bottom: 100px;
  }

  .request-img__left, .request-img__right {
    display: flex;
    position: absolute;
    z-index: 1;
  }

  .request-img__left {
    left: 0;
    top: -180px;
  }

  .request-img__right {
    right: 0;
    top: -86px;
  }

  .request-img__plansh {
    display: none;
  }

  .request__cnt {
    max-width: 812px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  .request__cnt h2 {
    text-align: center;
  }

  .request__cnt > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin-top: 20px;
    color: #424345;
  }

  .request__form {
    margin-top: 72px;
  }

  .request__wrap {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .request__inp {
    width: 32%;
  }

  .request__inp label {
    display: flex;
    width: 100%;
    margin-bottom: 0;
  }

  .request__inp label input {
    width: 100%;
    height: 48px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(94, 162, 244, 0.2);
    border-radius: 8px;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #5EA2F4;
    transition: 0.3s;
    font-family: Arial;
  }

  .request__inp label input::placeholder {
    color: #5EA2F4;

  }

  .request__inp label input:hover {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #5EA2F4;
  }

  .request__inp label input:active {
    background: #FFFFFF;
    border: 1px solid #5EA2F4;
  }

  .request__form .btn {
    margin: 24px auto 16px;
    max-width: 260px;
  }

  .request__form p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    max-width: 499px;
    width: 100%;
    margin: 0 auto;
  }

  .request__form p a {
    display: inline;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    text-decoration: underline;
  }

  .request__form p a:hover {
    text-decoration: unset;
  }

  footer {
    background: #DFECFF;
  }

  .footer__main {
    padding-bottom: 50px;
    position: relative;
    z-index: 11;
  }

  .footer__logo {
    display: flex;
    align-items: center;
  }

  .footer__logo a {
    display: flex;
  }

  .footer__logo span {
    margin-left: 24px;
    padding-left: 24px;
    border-left: 2px solid rgba(66, 67, 69, 0.4);
    font-family: 'Gotham Pro';
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #424345;
    max-width: 145px;
    width: 100%;
  }

  .footer__flex {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;

    width: 100%;
  }

  .footer__info p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #424345;
  }

  .footer__info p + p {
    margin-top: 11px;
  }

  .footer__contact {
    margin-left: 153px;

  }

  .footer__contact a {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #424345;
  }

  .footer__contact a + a {
    margin-top: 11px;
  }

  .footer__data {
    max-width: 287px;
    width: 100%;
    margin-left: 103px;
  }

  .footer__data p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #424345;
  }

  .footer__politic {
    max-width: 177px;
    width: 100%;
    margin-left: 97px;
  }

  .footer__politic a {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #424345;
  }

  .footer__politic a:hover {
    text-decoration: unset;
  }


  @media (max-width: 1610px) {
    .improve__kran {
      max-width: 300px;
    }
    .improve__star {
      max-width: 350px;
    }
    h1 {
      font-size: 64px;
      line-height: 70px;
    }
    .improve__main {
      padding: 130px 0 94px;
    }

    .improve__shared {
      margin-top: 100px;
    }
    .improve__info span {
      font-size: 20px;
      line-height: 28px;
      margin-top: 22px;
    }
    .improve__shared strong {
      font-size: 24px;
      line-height: 28px;
    }
    h2 {
      font-size: 48px;
    }
    .environment__info {
      bottom: 25px;
    }
    .title-bot {
      font-size: 24px;
    }
    .production__info {
      max-width: 520px;
    }
    .production__photo {
      margin-top: 0;
      max-width: 820px;
    }
    .production__costs {
      margin-top: 70px;
    }
    .production__main {
      margin-top: 80px;
      padding-bottom: 40px;
    }
    .business__main {
      padding-top: 90px;
    }
    .request__main {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    .request-img__left {
      top: -39px;
      max-width: 280px;
    }
    .request-img__right {
      top: -38px;
      max-width: 330px;
    }
    .second-part {
      margin-top: -270px;
    }
  }
  @media (max-width: 1500px) {
    h1 {
      font-size: 54px;
      line-height: 60px;
    }
    .improve__main {
      padding: 146px 0 95px;
    }
    .improve__info span {
      font-size: 18px;
      line-height: 24px;
    }
    h2 {
      font-size: 44px;
      line-height: 46px;
    }
    .production__photo {
      max-width: 627px;
    }
    .production__info {
      max-width: 480px;
    }
    .second-part {
      margin-top: -200px;
    }
    .business__info {
      max-width: 652px;
    }
    .second-part__info {
      max-width: 520px;
      margin-top: 80px;
      margin-left: 30px;
    }
    .footer__flex {
      justify-content: space-between;
    }
    .footer__contact {
      margin-left: 40px;
    }
    .footer__data {
      margin-left: 50px;
    }
    .footer__politic {
      margin-left: 50px;
    }
  }
  @media (max-width: 1400px) {
    h1 {
      font-size: 44px;
      line-height: 50px;
    }
    h2 {
      font-size: 38px;
      line-height: 40px;
    }
    .production__info ul {
      margin-bottom: 60px;
      margin-top: 14px;
      margin-left: 25px;
    }
    .business__info {
      max-width: 580px;
      margin-right: -60px;
    }
    .second-part__info {
      max-width: 460px;
      margin-top: 0;
    }
    .second-part {
      margin-top: -238px;
    }
    .second-part__bash {
      margin-left: 0;
      max-width: 551px;
      width: 100%;
    }
    .implem__flex {
      margin: 70px auto 70px;
    }
    .implem__main {
      margin: 80px 0;
    }
    .request__cnt {
      max-width: 730px;
    }
    .business__bash {
      max-width: 550px;
      width: 100%;
    }
    .business__itme:before {
      background-size: contain;
      max-width: 395px;
      height: 363px;
    }
    .request__main {
      padding-top: 60px;
      padding-bottom: 30px;
    }
    .request-img__left {
      top: -20px;
      max-width: 269px;
    }
    .request-img__right {
      top: 18px;
      max-width: 339px;
    }
    .footer__contact {
      margin-left: 100px;
    }
    .footer__data {
      margin-left: 70px;
    }
    .footer__politic {
      margin-left: 50px;
    }
    .improve__info {
      max-width: 680px;
      top: 0;
    }
    .environment__main {
      padding-top: 80px;
    }
    .environment__title p {
      font-size: 18px;
      line-height: 22px;
    }
    .environment__photo {
      margin: 20px auto 0;
    }
    .title-bot {
      font-size: 20px;
      line-height: 24px;
    }
    .production__photo {
      max-width: 550px;
      margin-top: 80px;
    }
    .production__info p {
      font-size: 18px;
      line-height: 22px;
      margin-top: 14px;
      max-width: 520px;
      width: 100%;
    }
    .production__info ul li {
      font-size: 18px;
      line-height: 22px;
      max-width: 520px;
      width: 100%;
    }
    .production__info {
      margin-right: -90px;
      max-width: 660px;
    }
    .business__itme {
      max-width: 460px;
      margin: 0;
    }
    .request__wrap {
      margin: 0 auto;
      max-width: 362px;
      flex-direction: column;
    }
    .request__inp {
      width: 100%;
      margin-top: 8px;
    }
    .request__form {
      margin-top: 40px;
    }
    .request__form .btn {
      max-width: 362px;
    }
  }
  @media (max-width: 1300px) {

    .improve__info {
      max-width: 900px;
      top: 0;
    }
    .improve__info span {
      font-size: 18px;
      line-height: 24px;
      margin-top: 18px;
    }
    .improve__kran {
      max-width: 280px;
    }
    .improve__star {
      max-width: 320px;
    }
    .improve__shared {
      margin-top: 70px;
    }
    h2 {
      font-size: 36px;
    }
    .environment__title p {
      font-size: 18px;
      line-height: 24px;
      margin-top: 10px;
    }
    .environment__main {
      padding-top: 50px;
    }
    .title-bot {
      font-size: 22px;
      line-height: 26px;
    }
    .environment__info {
      bottom: 19px;
    }
    .production__info p {
      font-size: 18px;
      line-height: 26px;
      margin-top: 20px;
    }
    .production__info ul li {
      font-size: 18px;
      line-height: 26px;
    }
    .production__info .btn {
      font-size: 20px;
    }
    .production__info {
      max-width: 470px;
      margin-top: 0;
    }
    .production__costs {
      margin-top: 50px;
    }
    .business__info > p {
      font-size: 18px;
      line-height: 26px;
      margin-top: 10px;
    }
    .business__main {
      padding-top: 60px;
    }
    .business__itme {
      max-width: 420px;
      padding-top: 0;
    }
    .business__info {
      max-width: 550px;
    }
    .business__bash {
      max-width: 590px;
      width: 100%;
    }
    .second-part__info {
      max-width: 500px;
      margin-top: 0;
    }
    .second-part__bash {
      margin-left: 0;
      max-width: 580px;
      width: 100%;
    }
    .second-part {
      margin-top: -260px;
    }
    .request-img__left {
      top: -35px;
      max-width: 220px;
    }
    .request-img__right {
      top: 10px;
      max-width: 260px;
    }
    .request__form {
      margin-top: 60px;
    }
    .business__itme:before {
      top: 22px;
    }
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 40px;
      line-height: 44px;
    }
    h2 {
      font-size: 32px;
      line-height: 42px;
    }
    .improve__info {
      max-width: 745px;
    }
    .improve__main {
      padding: 110px 0 90px;
    }
    .improve__shared strong {
      font-size: 20px;
      line-height: 26px;
    }
    .improve__kran {
      max-width: 240px;
    }
    .improve__star {
      max-width: 270px;
    }
    .improve__info span {
      font-size: 16px;
    }
    .title-bot {
      font-size: 20px;
      line-height: 26px;
    }
    .environment__title p {
      font-size: 16px;
    }
    .production__info ul li {
      font-size: 16px;
      line-height: 24px;
    }
    .production__info p {
      font-size: 16px;
      line-height: 24px;
      margin-top: 15px;
    }
    .production__info ul {
      margin-bottom: 30px;
      margin-top: 15px;
      margin-left: 25px;
    }
    .production__info {
      max-width: 400px;
      margin-right: 0;
    }
    .second-part {
      margin-top: -120px;
    }
    .implem__number strong {
      font-size: 80px;
    }
    .footer__flex {
      justify-content: space-between;
    }
    .footer__contact {
      margin-left: 25px;
    }
    .footer__data {
      margin-left: 20px;
    }
    .footer__politic {
      margin-left: 10px;
    }
    .request__cnt > p {
      font-size: 18px;
      line-height: 24px;
    }
    .request__cnt {
      max-width: 500px;
    }
    .request__main {
      padding-top: 40px;
      padding-bottom: 30px;
    }
    .request-img__left {
      top: 65px;
      max-width: 220px;
    }
    .request-img__right {
      top: 110px;
    }
  }
  @media (max-width: 991px) {
    .button {
      height: 50px;
      font-size: 18px;
      line-height: 24px;
    }
    .improve__info {
      max-width: 600px;
    }
    .improve__main {
      padding: 105px 0 85px;
    }
    h1 {
      font-size: 36px;
      line-height: 42px;
    }
    .improve__info .button {
      margin: 60px auto 0;
      max-width: 291px;
      height: 44px;
    }
    .improve__kran {
      max-width: 180px;
    }
    .improve__star {
      max-width: 200px;
    }
    .improve__shared strong {
      font-size: 18px;
      line-height: 24px;
    }
    h2 {
      font-size: 28px;
      line-height: 34px;
    }

    .title-bot {
      font-size: 18px;
      line-height: 24px;
    }
    .environment__info {
      bottom: 0;
      position: relative;
      padding: 24px 16px;
      background: linear-gradient(180deg, rgba(213, 230, 249, 0) -112.5%, #D5E6F9 100%);
    }
    .environment__dec {
      display: none;
    }
    .environment__planshet {
      display: block;
    }
    .production__flex {
      flex-direction: column;
    }

    .production__photo {
      margin-top: 30px;
      max-width: 820px;
      justify-content: center;

    }
    .production__info .btn {
      font-size: 18px;
      position: absolute;
      bottom: 30px;
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);
      background: #424345;
      color: #FFFFFF;
    }
    .production__info .btn:hover {
      background: #4F94E7;
    }
    .production__main {
      margin-top: 60px;
      padding-bottom: 110px;
    }
    .production__info {
      max-width: 100%;
    }
    .business__name p {
      font-size: 16px;
      line-height: 24px;
    }
    .production__info p {
      max-width: 100%;
    }
    .production__info ul {
      margin-left: 20px;
    }
    .production__info ul li {
      max-width: 100%;
    }
    .business__name strong {
      font-size: 16px;
      line-height: 24px;
    }
    .business__block {
      margin-top: 20px;
    }
    .business__number {
      font-size: 16px;
      line-height: 24px;
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }
    .business__itme:before {
      display: none;
    }
    .business__info > p {
      font-size: 16px;
      line-height: 24px;
      margin-top: 10px;
    }
    .second-part {
      margin-top: -60px;
    }
    .second-part__info {
      max-width: 300px;
      margin-top: 0;
      margin-left: 20px;
    }
    .implem__box p {
      font-size: 18px;
      line-height: 22px;
      margin-top: 6px;
    }
    .implem__number small {
      font-size: 18px;
      line-height: 24px;
    }
    .implem__flex {
      margin: 60px auto 60px;
    }
    .implem__main {
      margin: 100px 0 40px;
    }
    .request__main {
      padding-top: 40px;
      padding-bottom: 71px;
    }
    .request__cnt {
      max-width: 580px;
    }
    .footer__flex {
      flex-wrap: wrap;
    }
    .footer__politic {
      margin-left: 0;
      margin-top: 20px;
    }
    .footer__data {
      margin-left: 10px;
      max-width: 238px;
    }
    .request__main {
      padding-top: 56px;
      padding-bottom: 56px;
    }
    .request__form {
      margin-top: 40px;
    }
    .environment__photo {
      margin: 20px auto 0;
    }
    .footer__politic {
      max-width: 377px;
    }
    .improve__shared {
      margin-top: 100px;
    }
    .business__bash {
      display: none;
    }
    .business__flex {
      flex-direction: column;
    }
    .business__info .business__itme {
      display: none;
    }
    .business-mob__photo {
      display: flex;
      justify-content: flex-end;
    }
    .business-mob {
      display: block;
      margin-top: 50px;
    }
    .second-part {
      display: none;
    }
    .bush-photo-moblie {
      display: none;
    }
    .business__info {
      max-width: 100%;
    }
    .business-mob__numb {
      max-width: 332px;
      width: 100%;
    }
    .business-mob__cnt:nth-child(1) .business-mob__photo {
      margin-top: -130px;
    }
    .business-mob__cnt:nth-child(2) {
      margin-top: -240px;
    }
    .business-mob__cnt:nth-child(2) .business-mob__photo {
      margin-top: -135px;
    }
    .business-mob__cnt:nth-child(3) {
      margin-top: -115px;
    }
    .business-mob__cnt:nth-child(3) .business-mob__photo {
      margin-top: -200px;
    }
    .request-img__left {
      top: 80px;
      max-width: 179px;
    }
    .request__wrap {
      max-width: 336px;
    }
    .request-img__dec {
      display: none;
    }
    .request-img__plansh {
      display: block;
    }
    .footer__info p {
      font-size: 14px;
    }
    .footer__contact a {
      font-size: 14px;
    }
    .footer__data p {
      font-size: 14px;
    }
    .footer__politic a {
      font-size: 14px;
    }
    .request__form .btn {
      max-width: 336px;
    }
  }
  @media (max-width: 760px) {
    h1 {
      font-size: 36px;
      line-height: 44px;
    }
    .improve__info .button {
      margin: 30px auto 0;
    }
    .improve__main {
      padding: 50px 0 50px;
    }
    .environment__title {
      max-width: 360px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
    .production__info h2 {
      text-align: center;
    }
    .mouser-dun {
      display: none;
    }
    .improve__shared {
      max-width: 400px;
      margin: 70px auto 0;
    }
    .improve__kran {
      max-width: 160px;
    }
    .improve__star {
      max-width: 160px;
    }
    h2 {
      font-size: 26px;
      line-height: 32px;
    }
    .environment__dec {
      display: none;
    }
    .environment__planshet {
      display: none;
    }
    .environment__mob {
      display: block;
    }
    .environment__photo {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
    .production__dec {
      display: none;
    }
    .production__mob {
      display: block;
    }
    .production__costs {
      margin-top: 30px;
    }
    .second-part {
      margin-top: 0;
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    .second-part__bash {
      max-width: 358px;
      width: 100%;
      margin: 30px auto 0;
    }
    .second-part__info {
      max-width: 400px;
      margin-top: 0;
      margin-left: 0;
    }
    .implem__flex {
      flex-direction: column;
      align-items: center;
      margin: 20px auto 60px;
    }
    .implem__box {
      margin-top: 40px;
    }
    .request__inp {
      width: 100%;
    }
    .request__cnt {
      max-width: 500px;
      padding: 0 16px;
    }
    .footer__flex {
      flex-direction: column;
      align-items: flex-start;
    }
    .footer__info {
      order: 4;
      margin-top: 24px;
    }
    .footer__contact {
      margin-left: 0;
    }
    .footer__data {
      margin-left: 0;
      margin-top: 12px;
    }
    .footer__politic {
      margin-top: 24px;
      max-width: 377px;
    }
    .footer__main {
      padding-bottom: 40px;
    }
    .request__wrap {
      grid-gap: 0;
    }

    .request__form .btn {
      margin: 20px auto 20px;
    }
    .request__form {
      margin-top: 30px;
    }
    .request__form p {
      text-align: left;
      max-width: 356px;
    }
    .business-mob__cnt:nth-child(1) .business-mob__photo {
      margin-top: -80px;
    }
    .business-mob__cnt:nth-child(2) {
      margin-top: -200px;
    }
    .business-mob__cnt:nth-child(2) .business-mob__photo {
      margin-top: -40px;
    }
    .business-mob__numb {
      max-width: 360px;
    }
    .business-mob__cnt:nth-child(3) {
      margin-top: -90px;
    }
    .business-mob__cnt:nth-child(3) .business-mob__photo {
      margin-top: -80px;
    }
    .request-img__left {
      display: none;
    }
    .request-img__right {
      display: none;
    }
  }
  @media (max-width: 570px) {
    .implem-mob__photo {
      display: flex;
    }
    h1 {
      line-height: 38px;
    }
    .improve__info {
      padding: 0 16px;
      position: unset;
    }
    .improve__kran {
      display: none;
    }
    .improve__star {
      display: none;
    }
    .improve__main {
      padding: 30px 0 0;
    }
    .improve__info .button {
      position: relative;
    }
    .improve__info span {
      font-size: 16px;
      margin-top: 12px;
      line-height: 20px;
    }
    .improve__shared {
      max-width: 275px;
      width: 100%;
      margin: 0;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
    .implem-mob__photo {
      display: flex;
      width: 100%;
      margin-top: -40px;
    }
    .implem-mob__photo img {
      width: 100%;
    }
    .button {
      height: 48px;
    }
    .environment__main {
      padding-top: 48px;
    }
    .environment__title p {
      max-width: 400px;
      width: 100%;
      margin: 10px auto 0;
    }
    .production__info h2 {
      text-align: center;
      max-width: 350px;
      width: 100%;
      margin: 0 auto;
    }
    .production__photo {
      margin-top: 15px;
    }
    .title-bot {
      max-width: 350px;
      width: 100%;
      margin: 0 auto;
    }
    .production__main {
      padding-bottom: 96px;
    }
    .business-mob__cnt {
      margin-top: 30px;
    }
    .bush-photo-planshet {
      display: none;
    }
    .bush-photo-moblie {
      display: block;
    }
    .business-mob {
      margin-top: 10px;
    }
    .business-mob__cnt:nth-child(1) .business-mob__photo {
      margin-top: 20px;
    }
    .business-mob__cnt:nth-child(2) {
      margin-top: -90px;
    }
    .business-mob__cnt:nth-child(2) .business-mob__photo {
      margin-top: 15px;
    }
    .business-mob__cnt:nth-child(3) {
      margin-top: -10px;
    }
    .business-mob__cnt:nth-child(3) .business-mob__photo {
      margin-top: 20px;
    }
    .implem__main {
      margin: 60px 0;
    }
    .request__cnt > p {
      font-size: 16px;
      line-height: 22px;
    }
    .footer__data {
      max-width: 315px;
    }

  }
}
