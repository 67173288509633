.backNavigationBar {
  display: flex;
  align-items: center;
  margin-bottom: 0.65rem;
  justify-content: space-between;

  .leftSide,
  .rightSide {
    display: flex;
    align-items: center;
  }

  .text {
    font: normal normal 400 1.125rem/1.375rem var(--title-font);
    margin: 0;
  }

  .rightSide .text {
    color: var(--color-font-secondary1);
  }

  .button {
    margin-right: 1rem;
  }
}

.nested {
  padding-top: 1rem;
  margin-bottom: 0 !important;
}

.slashText {
  margin: 0 0 0 0.5rem;
  font-family: var(--title-font);
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #000000;
}

.slashed .text, .bold .text {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  font-style: normal;
  color: #000000;
}
