.subtitle {
  margin-top: 0.6rem;
}

.headline {
  padding-left: 2rem;
}

.field {
  margin-bottom: 1rem;
}

.plannedCompletion {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.executorsTable {
  margin-left: -2rem;
  width: calc(100% + 4rem);
}

.calendarInput {
  width: 13.5rem;
}

.budgetPseudoInput {
  border: none !important;
}

.doubleInput {
  flex-shrink: 0;
  border: 1px solid var(--color-border-field18);
  outline: none;
  height: var(--input-height) !important;
}

.closingDatesRow {
  margin-bottom: 1rem;
  button {
    padding-left: 0;
  }
}
