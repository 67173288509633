.form {
  height: 4.75rem;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ebebeb;
  padding: 0 1rem;
  display: grid;
  align-items: center;
  grid-template-columns: 40% 15% 10% 25% 10%;

  & > div {
    padding-right: 0.5rem;

    &:last-child {
      padding-right: 0;
    }
  }
}

.checks {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.actions {
  display: flex;
  justify-content: end;
}
