.notyWrapper {
  height: 3.38rem;
  width: 2.94rem;
  display: flex;
  align-items: center;
  position: relative;
}

.notyCounterBlock {
  cursor: pointer;
  @media (max-width: 1440px) {
    top: 0.125rem;
  }
}

.notyCounter {
  padding: 0.125rem 0.375rem;
  background-color: var(--color-indicator-frostbite);
  border-radius: 0.75rem;
  right: -16%;
  font-size: 0.688rem;
  font-weight: 500;
  color: #fff;
}

.innerModal {
  min-height: 10rem;
  .notyQuantity {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .notyList {
    padding: 0 5px 13px 5px;
    margin-top: 20px;
    border-bottom: 1px solid #bcbcbc;
  }
}

.marginNoty {
  margin: 31px 0 0 38px;

  .notyTextTitle {
    color: #000;
    font-size: 22px;
    font-weight: 400;
  }
}

.bottomActions {
  padding: .6875rem 1.125rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settingsIcon{
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.innerLargeModal {
  height: 100%;
  overflow: auto;

  .notyList {
    padding: 0 5px 13px 5px;
    margin-top: 20px;
    border-bottom: 1px solid #bcbcbc;
  }
}

.emptyPlaceholder{
  height: auto !important;
  margin-top: 2.5rem;
}