.boxButtonPaginator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.isLoading {
  opacity: 0.75;
}

.buttonPaginator {
  background-color: #ffffff;
  border: 1px solid #4fb2ed;
  color: #000;
  width: 17.5rem;
  height: 2.25rem;
  border-radius: 20px;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1.05;
  justify-content: center;

  &:not(.isLoading):hover {
    box-shadow: 0 0 10px #cfcfcf;
  }
}
