.tableSide {
  height: auto;
  min-height: calc(100vh - 16.5rem);
  & tr {
    transition: 0s !important;

    & td {
      &:nth-child(3n + 3) {
        border-right: 1px solid var(--color-border-field7);
      }
    }

    &:global(.hover),
    &:hover {
      & td {
        transition: 0s !important;
        background-color: #d8f1ff !important;
      }
    }
  }
}
