.headline {
  padding: 1.5rem 1.5rem 1rem;
  border-bottom: 1px solid #bfbfbf;
}

.title {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0;
}

.subtitle {
  color: var(--color-font-secondary1);
  font-weight: 400;
  margin-top: 0.625rem;
  margin-bottom: 0;
}

.text {
  font-weight: 400;
  color: var(--color-font-secondary1);
}

.medium {
  font-weight: 500;
}

.calendar {
  margin: 0 0.75rem;

  @media screen and (max-width: 1680px) {
    margin: 0 0.438rem;
  }
}

.info {
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
}

.content {
  padding: 1.563rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
}

.files{
  grid-column: 1/3;
}

.filesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;

  & span {
    font-size: 1rem;
    font-weight: 600;
  }

  & button {
    color: #4fb2ed;
    font-size: 1rem;
    cursor: pointer;
  }
}