.input {
  border: none !important;
  padding-left: 0;
  background-color: transparent !important;
}

.loading {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty {
  padding: 0.6rem 1rem;
  white-space: nowrap;
}

.option {
  border-radius: 0.5rem;
  padding: 0.6rem 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover,
  &.selected {
    background: #4fb2ed;
    color: #fff;
  }
}
