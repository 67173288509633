.budgetIsland {
  width: 100%;
  grid-column: 2;
  grid-row: 1 / 5;
  user-select: none;
}

.heading {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1.5rem;

  & > *:first-child {
    margin-right: auto;
  }
}