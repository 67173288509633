.container {
  margin: 0 auto;
  width: 100%;
  position: relative;

  @media (max-width: 2501px) {
    max-width: 1550px !important;
  }

  @media (max-width: 1920px) {
    max-width: 1200px !important;
  }

  @media (max-width: 1599px) {
    max-width: 1000px !important;
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5625rem;
  min-height: 2.5rem;
}

.fullName {
  font-size: 1.375rem;
  font-weight: 500;
  margin-bottom: 0;
}

.title {
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--color-font-secondary1);
  margin-bottom: 0;
}

.subtitle {
  font-size: 1.125rem;
  font-weight: 500;
  margin: 2rem 0;
}

.columnContainer {
  display: flex;
  justify-content: space-between;
}

.column {
  flex-basis: 45%;
}

.field {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.8rem;
}

.inputContainer {
  position: relative;

  & input {
    height: 2.5rem;
    padding: 0.7rem 1rem;
    border-color: var(--color-font);

    &:focus {
      border-color: var(--color-font-primary);
    }
  }

  & svg {
    position: absolute;
    right: 2.5%;
    top: 25%;
    width: 1.25rem;
    height: 1.25rem;
  }
}

.mbNone {
  margin-bottom: 0;
}

.label {
  margin-bottom: 0.35rem;
}

.errorMessage {
  position: absolute;
  margin-top: 0.313rem;
  color: var(--color-status-alarm);
  font-size: 0.875rem;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 4rem;
}

.checkboxLabel {
  font-family: "Montserrat", sans-serif !important;
  font-size: 1.125rem !important;
  margin-left: 0.969rem;
  color: var(--color-font4);
  margin-bottom: 0;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
