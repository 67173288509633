.tableWrapper {
  & thead {

    & th {
      border-bottom: 1px solid var(--color-background-primary);
      padding-bottom: 0.5rem !important;
    }
    
  }
}

.tableRowClass {
  background-color: #FFFFFF;
  color: #000;
  font: normal normal 400 1rem var(--title-font);
  height: 3.875rem;

  & td:nth-child(1) {
    text-align: center;
  }

  & td {
    border-top: none !important;
    border-bottom: 1px solid #E1E8EE;
  }
}

.tableHeaderClass {
  font: normal normal 500 1rem var(--main-font);
}

.labelBox {
  display: flex;
  margin: 0.5rem 0;
  flex-wrap: wrap;
  align-items: flex-end;

  .greyLabel {
    color: #808080;
    background-color: #EDEDED;
    border-radius: 50px;
    padding: 0.2rem 0.6rem;
    white-space: nowrap;
    height: min-content;
    cursor: pointer;

    svg {
      margin-right: 0.2rem;
    }
  }
}

.blueLabel {
  color: #4FB2ED;
  background-color: #E6F4FC;
  border-radius: 50px;
  padding: 0.2rem 0.6rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  white-space: nowrap;
  cursor: pointer;
  width: max-content;
}

.quontityCell {
  // margin-bottom: 0.5rem;
}

.contentBox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .box1 {
    width: 80%;
  }

  .box2 {
    cursor: pointer;
  }

}

.inputBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
}

.input {
  height: 2.18rem;
  width: 7.8rem;
  border: none;
  background-color: #EFEFEF;
  border-radius: 0.5rem !important;
  color: var(--color-font-main);
  padding-left: 1rem;
}

.checkFile {
  cursor: pointer;
}

.tableTextBold {
  font-size: 1rem;
  color: #000;
  font-weight: 500;
}