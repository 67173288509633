.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 2.3125rem;
  margin-bottom: 0.625rem;
  height: 3.625rem;
  position: relative;
  cursor: pointer;
  background: var(--color-background-field6);

  &:hover {
    background: var(--color-background-primary);;
  }
}

.input {
  margin-left: 3.25rem;
  width: 50%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #f4f4f4;
  border-radius: 1rem;
  padding: 0.5rem 0.75rem;
}

.spinnerContainer {
  width: 1.1rem;
  height: 1.1rem;

  .spinner {
    border-width: 0.2rem;
    width: 100%;
    height: 100%;
  }
}