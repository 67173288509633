.form {
  background-color: #f4f4f4;
  border-bottom: 1px solid #ebebeb;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}

.checks {
  padding: 0 1rem;
}

.checks,
.clarificationContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.25rem;
}

.clarificationContainer {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.clarification {
  height: min-content;
  white-space: nowrap;
}

.clarificationOptions {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  max-height: 8rem;
}

.clarification:nth-child(2) .clarificationOptions {
  max-height: 6rem;
}

.actions {
  display: flex;
  justify-content: end;
  align-self: center;
  grid-column: -2 / -1;
}

.row {
  display: grid;
  grid-template-columns: 8fr 3fr 3fr 2fr 3fr 3fr 1fr;
  column-gap: 1rem;
  width: 100%;
}
