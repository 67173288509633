.container {
  height: 2px;
  width: calc(100% + 4rem);
  position: relative;
  margin: 1.5rem 0;
  margin-left: -2rem;
  margin-right: -2rem;
}

.status {
  position: absolute;
  left: 0.625rem;
  top: 0;
  transform: translateY(-50%);
  //min-width: 5.875rem;
  font-weight: 800;
  color: #fff;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  padding-left: 0.25rem;
  padding-right: 1rem;
  height: 1.4rem;
  border-radius: 0.7rem;
  margin-left: 0.5rem;
}

.icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  background-image: url("./check.svg");
  background-size: 0.9rem;
  background-position: center;
  background-repeat: no-repeat;
  flex-grow: 0;
  flex-shrink: 0;
}

.arrow {
  background-image: url("./arrow.svg");
}

.check {
  background-image: url("./check.svg");
}

.mt0 {
  margin-top: 0;
}
