.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.title {
  font-weight: 500;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.empty {
  opacity: 0.6;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}
