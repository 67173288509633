.row {
  display: grid;
  grid-template-columns: 6% 43% 12% 12% 17% 10%;
  padding: 0 .4rem !important;

  & > *:first-child {
    padding-left: .4rem;
  }

  & > *:last-child > div {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  & > * {
    font-size: 1rem !important;
    font-family: var(--main-font) !important;
  }

  & > .selectCell > div {
    overflow: visible;
  }
}

.input input {
  background-color: white;
}

.selectCell {
}