.container {
  background-color: var(--color-background-field6);
  border-radius: 1rem;
  margin-bottom: 0.75rem;
  height: 3.875rem;
  font: normal normal 400 1.12rem var(--title-font);
  position: relative;
  border: 1px solid var(--color-background-field6);
  transition: all 1s ease;

  &.active {
    background-color: var(--color-background-main);
    border: 1px solid var(--color-background-primary);
    height: auto;
  }

  .header {
    display: grid;
    grid-template-columns: 55% 15% 15% 15%;
    align-items: center;
    height: 3.875rem;
    padding: 0 0.5rem;
    padding-right: 1.5rem;
    cursor: pointer;

    .field1 {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 1rem;
      padding-left: 1.5rem;
    }

    .secondaryField {
      width: 8%;
      color: var(--color-font-secondary2);

      .value {
        font: normal normal 300 1.18rem var(--title-font);
      }

      .name {
        font: normal normal 300 0.875rem var(--title-font);
        color: #646464;
      }
    }

    .field3 {
      width: 10%;
      color: var(--color-font-secondary3);
      white-space: nowrap;
    }

    .field4 {
      width: 20%;
      text-align: right;
      padding: 0 3.87rem;
      font-weight: 500;
      white-space: nowrap;
    }

    .devider {
      height: 1.8rem;
      width: 1px;
      background-color: var(--color-devider-main);
      margin: 0 auto;
    }
  }

  .content {
    padding-bottom: 0.5rem;
    font-size: 1rem;

    .contentHeader {
      height: 4.23rem;
      background-color: var(--color-background-field10);
      border-radius: 0.875rem;
      font-weight: 500;
      display: flex;
      position: sticky;
      top: 2.8rem;
      z-index: 5;

      @media (max-width: 1441px) {
        height: 4.5rem;
      }

      .fieldTitle {
        text-align: center;
        width: 100%;
      }

      .leftSide {
        display: grid;
        grid-template-columns: 1.2fr 1.2fr 1.2fr;
        align-items: flex-end;
        padding-bottom: 0.625rem;
        width: 40%;
        border-right: 1px solid var(--color-border-field7);

        .field1 {
          width: 7rem;
          padding-left: 1.5rem;
        }

        .secondaryField {
          flex: 1;
          text-align: center;
        }

        .field3 {
          // width: 9rem;
          text-align: center;
        }
      }

      .rightSide {
        width: 60%;

        &.headingWithExpandedDifference > .top {
          .fieldP,
          .fieldF,
          .fieldD {
            width: 33%;
          }

          .fieldD {
            justify-content: center;
          }
        }

        .top {
          display: flex;
          border-bottom: 1px solid var(--color-border-field7);

          & div {
            text-align: center;
          }

          .fieldP {
            width: 44%;
            text-align: center;
            padding: 0.31rem 0 0.28rem 0;
          }

          .fieldF {
            @extend .fieldP;
          }

          .fieldD {
            @extend .fieldP;
            width: 12%;
            display: flex;
            align-items: center;
            column-gap: 0.625rem;
            cursor: pointer;
            user-select: none;

            & img {
              height: 67%;
            }
          }
        }
      }
    }
  }
}

.headerField {
  font-weight: 300;
  min-width: 10%;
  text-align: right;

  .headerPrice {
    color: #000;
    font-size: 1.1875rem;
  }

  .headerPrice1 {
    color: #000;
    font-size: 1.1875rem;
  }

  .headerPrice3 {
    color: #000;
    font-size: 1.1875rem;
  }

  .headerText {
    font-size: 0.875rem;
    color: #646464;
  }
}

.bottomFields {
  font-size: 1rem;
  color: #000;
  font-weight: 500;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.25fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  height: calc(2.4rem - 1px);
  position: relative;

  & div,
  .titleField6,
  .titleField7 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &.withExpandedDifference {
    grid-template-columns: 1fr 1fr 1.25fr 1fr 1fr 1fr;

    & div:not(.titleField6):not(.titleField7) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 0.75rem;
      width: 100%;
      height: 100%;
    }

    & div:nth-child(-n + 1) {
      justify-content: center;
      padding-right: 0;
    }
  }

  .borderMiddle {
    position: absolute;
    top: 0;
    left: 52.5%;
    height: 2.4rem;
    width: 1px;
    background-color: #c3c3c3;
  }

  .bottomFieldsLeft {
    display: grid;
    grid-template-columns: 0.8fr 1fr 1fr;
    padding-bottom: 0.625rem;
  }

  .bottomFieldsRight {
    display: grid;
    grid-template-columns: 0.7fr 1fr 1fr;
    padding-bottom: 0.625rem;
  }
}

@media (max-width: 1500px) {
  div.borderMiddle {
    height: 2.3rem !important;
  }
  div.titleField3 {
    margin: 0 1rem;
  }
}

.withRightBorder {
  border-right: 1px solid var(--color-border-field7);
}

.leftTriangle {
  transform: rotate(180deg);
}
