.packingListContainer {
  padding: 1rem 2rem;
  height: 100%;
  background-color: var(--color-background-field9);

  .packingList {
    padding: 1.25rem 0 0.75rem;
  }

  .templateContent {
    padding: 0 !important;
  }
}