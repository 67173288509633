.container {
  overflow: auto;
  z-index: 2;
}

.wrapper {
  position: relative;
}

.namesOfDays {
  background-color: #fff;
  position: sticky;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 2px;
  font: normal normal 500 1.125rem var(--main-font);
  top: 0;
  z-index: 4;

  & > div {
    box-sizing: border-box;
    margin: 0.5rem;
    text-align: center;
    background-color: #f7f7f7;
  }
}

.calendar {
  background-color: white;
  width: 100%;
  flex-grow: 2;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  box-sizing: border-box;
  grid-gap: 1px;

  @media (min-width: 1900px) and (min-height: 1079px) {
    max-height: 53vh;
  }

  @media (min-width: 1900px) and (min-height: 1199px) {
    max-height: 48vh;
  }

  @media (min-width: 1900px) and (min-height: 1299px) {
    max-height: 44vh;
  }

  @media (max-width: 1440px) {
    max-height: 100vh;
  }
}

.dayOfWeek {
  padding: 0 0.25rem 0.1rem 0.25rem;
  border: #d7d7d7 1px solid;
  border-radius: 5px;
  margin-left: 0.25rem;
}

.day {
  padding: 3px 0.5rem 6px;
  box-sizing: border-box;
  margin: 0.3rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 6.5rem;

  .dayNumber1 {
    font: normal normal 400 1.25rem var(--title-font);
    color: #707070;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dayNumber2 {
    font: normal normal 400 1.5rem var(--title-font);
    color: #707070;
  }

  & > .dayEvents {
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > .dayEvent {
      padding: 0.17rem;
      border: 1px solid #a866ee;
      border-radius: 0.5rem;
    }
  }
}

.boxValue {
  font: normal normal 400 1rem var(--title-font);

  span {
    color: #00b4f2;
  }
}

.disabledDay {
  @extend .day;

  & > * {
    opacity: 0.4;
  }
}

.containerPlate {
  @media (min-width: 2100px) {
    display: flex;
  }
}

.eventsGrid {
  &_3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "first first" "second third";
    column-gap: 3px;

    & > div {
      &:first-child {
        grid-area: first;
      }
    }
  }
}

.legend {
  opacity: 1;
  display: none;
  position: fixed;
  left: 50%;
  bottom: 10rem;
  transform: translateX(-50%);
}

@media screen and (max-width: 1900px) {
  .eventsGrid {
    &_3 {
      display: flex;
      align-items: center;
      column-gap: 3px;
      position: relative;
    }
  }

  .legend {
    display: flex;
  }
}