.container {
  position: relative;
  display: flex;
  height: 50px;
}

.container, .handler {
  width: 57px;
}

.handler {
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0);
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.items {
  background-color: #fff;
  border-radius: 0 0 0.5rem 0.5rem;
  position: absolute;
  top: 50px;
  border: 1px solid var(--color-border-primary);
  z-index: 1;
  right: 0;
}

.item {
  display: flex;
  align-items: center;
  padding: 0.625rem;
  cursor: pointer;

  .icon {
    margin-right: 0.625rem;
    width: 1rem;
    height: 1rem;
    display: flex;
  }

  .name {
    font: normal normal 0.813rem/1rem var(--title-font);
  }

  &:not(:last-child) {
    border-bottom: 1px solid #E3E3E3;
  }
}

.open {
  .handler {
    border-color: var(--color-border-primary);
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: #fff;
    z-index: 2;
    position: relative;
  }
}

.hider {
  position: absolute;
  width: 55px;
  height: 10px;
  background: #fff;
  top: 48px;
  z-index: 3;
  right: 1px;
}