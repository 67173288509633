.deletedText {
  font-size: 0.875rem;
  color: #000;
  font-weight: 400;

  span {
    border-bottom: 1px solid #4fb2ed;
    cursor: pointer;
  }
}
