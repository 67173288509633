.row {
  height: 3rem;
  padding-top: 0;
  display: grid;
  grid-template-columns: 2.5% 25.5% 3% 5.5% repeat(9, 7%);
}

.row5days {
  grid-template-columns: 2.5% 25.5% 3% 5.5% repeat(7, 9%);
}

.date {
  border-left: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total {
  font-size: 0.75rem;
  margin-top: 0.3rem;
  color: #707070;
}

.blue {
  color: #4fb1eb;
}

.dateNum {
  color: #a0a0a0;
}
