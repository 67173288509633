.container {
  display: flex;

  & button {
    margin-right: 2rem;
    display: flex;
    align-items: center;
  }

  & svg {
    margin-right: 1rem;
    height: 2rem;
    width: 2rem;
  }
}
