.subtext {
  color: #707070;
  margin-bottom: 0.75rem;
}

.row {
  display: flex;
  width: 100%;
  column-gap: 0.675rem;
  margin-bottom: 0.675rem;
  margin-top: 1rem;
}

.filesInput {
  visibility: hidden;
  position: absolute;
}

.filesBtn {
  margin-bottom: 0.4rem;
  margin-left: 1rem;
  width: 30% !important;
}

.fileRow {
  width: fit-content;
  white-space: nowrap;

  & svg {
    margin-left: 1.5rem;
  }
}

.select {
  width: 70%;
}

.inputWrapper {
  padding-top: 2rem;
}
