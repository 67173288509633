.sectionsHeader {
  grid-template-columns: 1fr 1fr;
}

.ticketsHeader {
  grid-template-columns: 41.5% 13% 12% 10% 18%;

  &:before {
    height: calc(100% + 4rem) !important;
    top: -4rem !important;
  }

  @media screen and (max-width: 1680px) {
    grid-template-columns: 41.5% 13% 12% 10% 17.5%;
  }

  @media screen and (max-width: 1440px) {
    grid-template-columns: 41.5% 13% 12% 10% 16.5%;
  }

  &.withoutCreateRemark {
    grid-template-columns: 41.5% 13% 12% 14% 19.5%;
  }

  &.withoutLastColumn {
    grid-template-columns: 50.5% 13% 12% 14% 10.5%;
  }
}
