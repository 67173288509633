.root {
  position: relative;
  background-color: #fff;
  border-radius: 0.75rem;
  padding: 1rem;
  height: 5rem;
  display: flex;
  align-items: center;
  cursor: default;
}

.indicator {
  position: absolute;
  white-space: nowrap;
  background-color: #fff;
  font-size: 0.85rem;
  height: 1.4375rem;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0.7rem;
  top: 0;
  left: 0;
  transform: translateY(-50%) translateX(-0.75rem);
  /* синхронизировано с основным фоновым цветом */
  outline: 1px solid #f4f4f4;
}
