.headline {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;

  & h1 {
    margin-left: 33px;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
  }
}

.save {
  margin-right: 14px;
  margin-left: auto;
}

.nameInput {
  width: 420px;
}

.row {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 635fr 175fr 175fr 170fr 450fr;
  gap: 16px;
  margin-top: 20px;

  &.kz {
    grid-template-columns: 965fr 225fr 450fr;
  }
}

.cellInRow1 {
  display: grid;
  grid-template-columns: 41fr 21fr;
  gap: 16px;
}

.select {
  & label {
    font-size: 1rem;
    color: #666;
  }
}

.add {
  display: flex;
  align-items: center;
  margin-top: 30px;

  & button {
    margin-left: 32px;
  }
}

.colorInput {
  background: #efefef;
}
