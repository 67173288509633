@import "src/scss/dimensions";

.block {
  width: 50%;
  padding-right: 1rem;
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.main {
  display: flex;
  flex-wrap: wrap;
  gap: 1.563rem 0;
}

.title {
  font: normal normal 500 1rem/1.125rem var(--title-font);
}

.header {
  margin-bottom: 1rem;
}

.blueValue {
  background-color: #D9F1FF;
  border-radius: 0.5rem;
  padding: 0.313rem;

  &, &:hover {
    color: #000;
  }
}