.handler {
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.withOpenIndicators {
  .header {
    flex-direction: column;
  }

  .changeStateContainer {
    top: 17rem;
  }
}

.header {
  display: flex;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 19;
  padding: 1.5rem 2.5rem 0;
}

.changeStateContainer {
  position: sticky;
  background: #fff;
  top: 7rem;
  z-index: 18;
  padding: 1rem 2.5rem 0.5rem;
}

.headerContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.headerBottomRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.uploadFile {
  margin-left: auto;
  position: absolute;
  top: 3.5rem;
  right: 0;
}

.importButton {
  margin-top: 2.25rem;

  .button svg {
    margin-left: 0.5rem;
  }
}

.body {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.estimateState {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.estimateStateDescription {
  font: normal normal 1rem/1.125rem var(--title-font);
  color: var(--color-font-primary);
  margin-left: 2rem;
}
