.container {
  padding-top: 1.5rem;
}

.lsrRow {
  display: grid;
  grid-template-columns: 64% 12% 12% 12%;
}

.lsrName {
  word-break: break-all;
}
