.wrapper {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;

  border-radius: 0.75rem;
  border: 1px solid var(--blue-blue-accent, #4FB1EB);

  margin-bottom: 0.75rem;
}

.title {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.infoItem {

}

.infoTitle {
  font-weight: 500;
}

.infoValue {
  margin-left: 0.69rem;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.manuallyText {
  font-weight: 400;
  color: #4FB1EB;
}

.button {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;

  .editPart {
    display: flex;
    padding: 0.25rem 0.5rem 0.25rem 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;

    border-radius: 1rem 0 0 1rem;
    background: var(--blue-blue-accent, #4FB1EB);
    cursor: pointer;
  }

  .deletePart {
    display: flex;
    padding: 0.25rem 0.625rem 0.25rem 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;

    border-radius: 0 0.875rem 0.875rem 0;
    border-top: 1px solid #4FB1EB;
    border-right: 1px solid #4FB1EB;
    border-bottom: 1px solid #4FB1EB;
    background: #FFFFFF;
    cursor: pointer;
  }
}

