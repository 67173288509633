.modal {
  width: 40rem;
}

.header {
  padding: 1.5rem 1rem 1rem 2rem;
  border-bottom: 1px solid #d8d8d8;
  position: sticky;
  z-index: 2;
  background-color: #fff;
  top: 0;

  & h2 {
    font-size: 1.375rem;
    margin-bottom: 0.625rem;
  }
}

.subtitle {
  color: #707070;
  margin-bottom: 0.625rem;
}

.content {
  padding: 0.625rem 1rem 1rem 2rem;
}

.row {
  display: grid;
  align-items: center;
  margin-bottom: 0.625rem;
  column-gap: 0.625rem;
}

.row1 {
  grid-template-columns: 8fr 3fr;
}

.row2 {
  grid-template-columns: 3fr 4.5fr 2fr;
}

.content2 {
  padding: 0.625rem 1rem 0rem 2rem;
}

.docsContainer {
  padding: 0rem 1rem 0rem 2rem;

  & h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;
  }
}

.addButton {
  padding-left: 0;
}

.heightHolder {
  height: 4rem;
}

.error {
  font-size: 0.687rem;
  color: #ff0558;
}

.materials {
  padding: 0 2rem;
}

.select {
  max-width: 36.5rem;
}

.hr {
  width: calc(100% + 3rem);
  height: 1px;
  background-color: #d8d8d8;
  margin-bottom: 1rem;
  margin-left: -2rem;
  margin-right: -1rem;
}
