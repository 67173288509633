.attr2 {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  label {
    margin-bottom: 0;
  }
}

.list {
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.row {
  padding: 0.25rem 1rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.5rem;

  &:nth-child(odd) {
    background-color: rgb(196, 236, 254);
  }
}
