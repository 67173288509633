.head {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
}

/* .root {
  &:nth-child(even) {
    background-color: red;
  }
} */
