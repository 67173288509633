.container {
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
  margin-left: 1rem;

  height: var(--input-height);
}

.slider {
  height: var(--input-height);
}

.gear {
  cursor: pointer;
}

.periodOptions {
  padding: .375rem 0 1.0625rem;
}

.periodPickers {
  padding: 0 .8rem 1.0625rem .625rem;
  display: flex;
  flex-direction: column;
  row-gap: .75rem;
  border-bottom: 1px solid var(--color-border-field16);
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: .6rem;
}

.periodSelect{
  @extend .row;
  padding: 1.0625rem .875rem 0 .8125rem;

}