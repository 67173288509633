.btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4fb1eb;
  text-transform: uppercase;

  &:disabled {
    cursor: auto;
    color: #a5a5a5;

    & path {
      fill: #a5a5a5;
    }
  }
}

.define {
  color: #ef9d1c;
  cursor: auto;
}
