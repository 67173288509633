@import "src/scss/mixins/hideScroll";

.container {
  margin: 0 auto;
  background: #fff;
  box-shadow: var(--modal-shadow);
  z-index: 99999999999;
  border: none;
  border-radius: 15px;

  &::-webkit-scrollbar {
    display: none;
  }

  .header {
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: space-between;
    align-items: center;
    height: max-content;

    .title {
      font: normal normal 500 1.375rem/1.929rem var(--title-font);
    }

    .crossIcon {
      cursor: pointer;
      width: 1rem;
      height: 1rem;
      opacity: 1;
      align-self: flex-start;
    }
  }
}

.shadow {
  background-color: rgba(0, 0, 0, 0.06);
  overflow: auto;
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @include hideScroll();
}

.shadowDarker {
  background-color: rgba(0, 0, 0, 0.1);
}
