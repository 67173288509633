.link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notyCounterBlock {
  position: relative;
  top: 2px;
  left: 0;
  cursor: pointer;
  & svg {
    top: 3px;
    position: relative;
  }
}

.notyCounter {
  padding: 0.125rem 0.375rem;
  background-color: var(--color-indicator-frostbite);
  border-radius: 0.75rem;
  right: 4%;
  font-size: 0.688rem;
  font-weight: 500;
  color: #fff;
  z-index: 1;
}

.container {
  position: relative;
  height: 3.38rem;
  width: 3.94rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-top: solid 6px transparent;
  border-bottom: solid 6px transparent;

  .icon {
    width: 100%;
    height: 100%;
  }
}

.active .container {
  background-color: #e8e8e8;
  border-bottom: solid 6px #4fb2ed;
}