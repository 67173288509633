.thead {
    border-radius: 0.5rem;
    padding: 0 1.5rem;
    display: grid;
    align-items: center;
    grid-template-columns: 5% 50% 10% 12%;
    background: #f2f2f2;
    font-weight: 500;
    height: 3rem;
    margin-bottom: 3px;
    position: sticky;
    z-index: 2;
    top: 10rem;
}

.section {
    margin-top: -1px;
    background: #f2f2f2;
}

.sectionRow {
    display: grid;
    min-height: 2.875rem;
    grid-template-columns: 42% 4% 12% 5% 5% 16% 6% 6% 6%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.opacity {
    opacity: 0.7;
}

.right {
    text-align: right;
    justify-content: end;
}

.row {
    min-height: 3.5rem;
    padding: 0 1.5rem;
    display: grid;
    align-items: center;
    grid-template-columns: 5% 50% 10% 12% 7% 6% 6%;
}

.expenditure {
    border-bottom: 1px solid #d6d6d6;

    &:last-of-type {
        border-bottom: none;
    }
}

.filesRow {
    display: flex;
    align-items: center;
    padding: 0.8rem 1.5rem 0;
    column-gap: 0.5rem;
    flex-wrap: wrap;

    & > * {
        margin-bottom: 0.8rem;
    }
}
