@import "src/scss/mixins/sliceTextByLines";

.container {
  height: 6.2rem;
}

.wrapper {
  overflow: hidden;
}

.row {
  height: 6.2rem;
  display: grid;
  grid-template-columns: 2.5% 25.5% 3% 5.5% repeat(9, 7%);
  padding: 0 1.5rem;
  border-bottom: 1px solid #ebebeb;

  &.nestedRow {
    grid-template-columns: 2.5% 2.5% 23% 3% 5.5% repeat(9, 7%);
  }
}

.row5days {
  grid-template-columns: 2.5% 25.5% 3% 5.5% repeat(7, 9%);

  &.nestedRow {
    grid-template-columns: 2.5% 2.5% 23% 3% 5.5% repeat(7, 9%);
  }
}

.name {
  @include slice-text-by-lines(2);
  min-height: 2rem;
}

.bottom {
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
}

.justification {
  background-color: #fff;
  margin-right: 0.5rem;
  cursor: pointer;
}

.open .justification,
.justificationOpened {
  background: #f4f4f4;
}

.mainCell {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 0.5rem;
}

.bordered {
  border-left: 1px solid #cecece;
  margin: 0.5rem 0;
  height: calc(100% - 1rem);
}

.blue {
  color: #4fb1eb;
}

.date {
  padding: 0 0.5rem;
  flex-grow: 2;
  display: flex;
  justify-content: center;
  text-align: center;
}

.dayOneLine {
  justify-content: space-between;
  padding: 0 1rem;
}

.dayTwoLines {
  flex-direction: column;
  align-items: center;
}
