@use 'src/scss/mixins/_ui-indicators.scss' as ui_indicators;

.container {
  cursor: pointer;
  padding: 0.5rem 0.6rem;
  text-align: center;
  position: relative;
  height: 3.38rem;
  width: 3.94rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    // width: 1.6rem;
  }
}

.active .container {
  background-color: #e8e8e8;
  border-radius: 4px;
}

.active .bottom:not(.withoutUnderline) {
  border-bottom: solid 6px #4FB2ED;
}

.active .left:not(.withoutUnderline) {
  border-left: solid 6px #4FB2ED;
}

.bottom {
  border-bottom: solid 6px transparent;
}

.left {
  border-left: solid 6px transparent;
}

$tooltipColor: #4FB2ED;

.tooltip {
  padding: 5.3px 2rem;
  z-index: 9999;
  color: #fff;
  white-space: nowrap;
  border-left: none;
  font: 0.9em var(--title-font);
}

.tooltipPortal {
  cursor: pointer;

  & :global(.bp4-popover2 .bp4-popover2-arrow-fill) {
    fill: $tooltipColor;
  }

  & :global(.bp4-popover2), & :global(.bp4-popover2-content) {
    background-color: $tooltipColor !important;
  }

  & :global(.bp4-popover2) {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  & :global(.bp4-popover2-arrow-border) {
    display: none;
  }

  & :global(.bp4-popover2-arrow-fill) {
    transform: translateX(1px);
  }
}

.isRightPortal {
  & :global(.bp4-overlay-content) {
    transform: translateX(-.25rem);
  }

  & :global(.bp4-popover2-arrow) {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
}

.isBottomPortal {
  & :global(.bp4-overlay-content) {
    transform: translateY(-.25rem);
  }
}

$indicatorSize: 8px;

.withIndicator:after {
  @include ui_indicators.getIndicatorAsContent($indicatorSize);
  position: absolute;
  right: calc($indicatorSize / 2);
  top: calc($indicatorSize / 2);
}