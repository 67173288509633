.wrapper {
  display: flex;
  padding: 1rem 1rem 1.5rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;

  border-radius: 0.75rem;
  border: 1px solid var(--blue-blue-accent, #4FB1EB);
  background-color: #FFF;

  position: relative;

  margin-bottom: 2rem;
}

.longItem {
  width: 100%;
}

.longInput {
  width: 100%;
}

.itemTitle {
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
}

.itemsGroup {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.selectWrapper {
  margin-top: 0.25rem;
}

.select {
  height: 2.25rem;

  display: flex;
  padding: 0.5rem 0.625rem 0.5rem 0.75rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 0.375rem;
  border: 1px solid var(--gray-gray-4, #C3C3C3);
  background: var(--base-white, #FFF);

  color: var(--base-text-primary, #000);
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: -0.02625rem;
}

.selectOption {
  color: var(--base-text-primary, #000);
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: -0.02625rem;
}

.inputWrapper {
  margin-top: 0.25rem;
}

.input {
  display: flex;
  padding: 0.5rem 0.625rem 0.5rem 0.75rem;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 0.375rem;
  border: 1px solid var(--gray-gray-4, #C3C3C3);
  background: var(--base-white, #FFF);
  height: 2.25rem;

  color: var(--base-text-secondary, #696969);
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: -0.02625rem;

  &:focus {
    border-color: #4fb2ed;
  }
}

.selectType {
  min-width: 11.4rem;
}

.measureSelect {
  min-width: 7.38rem;
}

.topItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.save {
  position: absolute;
  bottom: -0.75rem;
  right: 1.5rem;

  display: flex;
  padding: 0 0.75rem 0 0.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;

  border-radius: 0.875rem;
  background: var(--blue-blue-accent, #4FB1EB);

  color: #FFF;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 171.429% */

  cursor: pointer;
}

.cancel {
  position: absolute;
  top: -0.8125rem;
  right: 1.5rem;

  display: flex;
  padding: 0.3125rem;
  width: 1.625rem;
  height: 1.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;

  border-radius: 0.875rem;
  background: var(--blue-blue-accent, #4FB1EB);

  cursor: pointer;

  svg {
    width: 0.83213rem;
    height: 0.83213rem;
    flex-shrink: 0;
  }
}


// использовать, если вдруг нужен будет второй варинат дизайна кнопок
//.buttonsBlock {
//  display: flex;
//  padding: 0 1.5rem;
//  height: 1.5rem;
//  align-items: center;
//
//  position: absolute;
//  bottom: -0.75rem;
//  right: 0;
//
//  .save {
//    display: flex;
//    padding: 0 0.5rem 0 0.25rem;
//    justify-content: center;
//    align-items: center;
//    gap: 0.375rem;
//
//    border-radius: 1rem 0 0 1rem;
//    border-top: 1px solid #47D6D1;
//    border-left: 1px solid #47D6D1;
//    border-bottom: 1px solid #47D6D1;
//    background: var(--color-status-green-approved, #47D6D1);
//
//    color: #FFF;
//    font-family: "Montserrat", sans-serif;
//    font-size: 0.875rem;
//    font-style: normal;
//    font-weight: 600;
//    line-height: 1.5rem;
//    cursor: pointer;
//  }
//
//  .cancel {
//    display: flex;
//    padding: 0 0.75rem 0 0.5rem;
//    justify-content: center;
//    align-items: center;
//    gap: 0.375rem;
//
//    border-radius: 0 0.875rem 0.875rem 0;
//    border-top: 1px solid #47D6D1;
//    border-right: 1px solid #47D6D1;
//    border-bottom: 1px solid #47D6D1;
//    background: #FFF;
//
//    color: #696969;
//    font-family: "Montserrat", sans-serif;
//    font-size: 0.875rem;
//    font-style: normal;
//    font-weight: 600;
//    line-height: 1.5rem;
//    cursor: pointer;
//  }
//}
