.modal {
  position: fixed;
  bottom: 1rem;

  width: 43rem;
  left: 1rem;
  border-radius: 1.125rem;
  padding: 2.8125rem 3rem 0;
  border: 1px solid #d7d7d7;
  background: rgba(242, 242, 242, 0.8);
  backdrop-filter: blur(15px);
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;

  svg {
    width: 1rem;

    path {
      fill: #525252;
    }
  }
}

.select {
  margin-bottom: 3.125rem;
}

.selectOpened {
  margin-bottom: 12rem;
}

.options {
  max-height: 10.875rem;
}

.bottom {
  border-top: 1px solid #d3d3d3;
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: calc(100% + 6rem);
  margin-left: -3rem;
  margin-right: -3rem;
  padding: 0.75rem 0;

  button {
    min-width: 12.5rem;
    justify-content: center;
    text-align: center;
  }
}

.banner {
  height: 25rem;
}

.success {
  width: 100%;
  display: flex;
  justify-content: center;
}

.option {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.75rem;
  padding-left: 0.75rem;
  height: 2rem;
  display: grid;
  grid-template-columns: auto 2fr 1fr;
  margin-bottom: 0.5rem;
}

/* .avatar {
  width: 1.5rem;
  height: 1.5rem;
} */

.position {
  text-align: right;
  padding-right: 0.5rem;
}
