.header {
  border-bottom: 1px solid var(--color-border-field10);
  padding: 1.25rem;
  margin-bottom: 1.25rem;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 1.25rem;
}

.remark {
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  color: #fff;
  height: 1.875rem;
  width: 9.625rem;
  border-radius: 1rem;

  &.created {
    background-color: var(--color-status-on-created);
  }
  
  &.review {
    background-color: var(--color-status-on-check);
  }
  
  &.accepted {
    background-color: var(--color-status-received);
  }
}

.secondary {
  color: var(--color-font-secondary1);
}

.name {
  font-weight: 400;
  color: #000;
  margin-top: 0.625rem;
  margin-bottom: 0;
}
