.freeTrigger {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 1rem;
  color: var(--color-background-primary);
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
