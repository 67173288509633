.root {
  cursor: pointer;

  &:hover {
    path {
      fill: #4fb1eb;
    }
  }
}

.top {
  .first {
    fill: #4fb1eb;
  }
}

.down {
  .second {
    fill: #4fb1eb;
  }
}
