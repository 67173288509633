.row {
  grid-template-columns: 27% 15% 9% 16% 12% 6% 15%;
}

.disabledFiles {
  cursor: inherit;
  background: transparent;
  color: #a5a5a5;
  & path {
    fill: #a5a5a5;
  }
}

.disabledBtn {
  background: #ebebeb;
  color: #a5a5a5;
}

.volumeHeader {
  width: 90%;
  display: grid;
  grid-template-columns: 39% 2% 59%;
  align-items: center;
}

.volumeSubheader {
  padding-left: 0.5rem;
}

.divider {
  background: #9ad9ff;
  width: 2px;
  height: 1.25rem;
  margin: 0 auto;
}
