input.switcher {
  position: relative;
  width: 34px;
  height: 19px;
  -webkit-appearance: none;
  background: #ebebeb;
  outline: none;
  border: 0;
  border-radius: 20px;
  transition: 0.5s;
  cursor: pointer;
  margin: 0;
}

input.switcher:before {
  content: "";
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 19px;
  top: 0;
  left: 0;
  background-color: #b2b2b2;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

input.switcher:checked:before {
  left: 19px;
  background-color: #4fb2ed;
}

.label {
  margin-right: 0.5rem;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;

  &.right {
    margin-right: unset;
    margin-left: 0.75rem;
  }
}

.container {
  display: flex;
  align-items: center;
}

input.switcher:disabled {
  cursor: default;
  opacity: 0.5;
  filter: grayscale(1);
}
