.setsRow {
  grid-template-columns: 52% 14% 20% 8% 6%;

  &.withActions {
    grid-template-columns: 47% 14% 20% 8% 6% 5%;
  }
}

.files {
  color: #000;
}
