.navbar {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  font-weight: 400;

  .button {
    display: flex;
    border-radius: 0.625rem;
    padding: 0.628rem 0.938rem 0.628rem 0.878rem;
    width: 2.375rem;
    height: 2.375rem;
    background-color: var(--color-background-field12);
    cursor: pointer;

    & img {
      width: 0.559rem;
      height: auto;
    }
  }
}