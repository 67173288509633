body.osla {
  .opportunities-pic {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .plat-ben {
    padding: 120px 0 60px;
    background: #DFECFF;
  }

  .plat-ben__block .swiper-pagination {
    display: none;
  }

  .plat-ben__block .swiper-grid-column > .swiper-wrapper {
    flex-direction: row;
  }

  .plat-ben__main p {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #424345;
    margin-top: 16px;
  }

  .plat-ben__block {
    margin: 24px 0 40px;
  }

  .plat-ben__box {
    padding: 24px 0 0;
    max-width: 496px;
    width: 100%;
    height: 326px;
    background: #5EA2F4;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16px;
  }

  .plat-ben__box span {
    text-align: center;
    display: block;
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFF;
    padding: 0 24px;
  }

  .plat-ben__box img {
    width: 100%;
  }

  .analysis-fact, .cost-forecast {
    padding: 120px 0 60px;
  }

  .analysis-fact {
    background: #EFF4FB;
  }

  .analysis-fact:after {
    content: "";
    position: absolute;
    top: 22px;
    right: 76px;
    background-image: url(../img/stars2.png);
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 192px;
    width: 100%;
    height: 180px;
  }

  .analysis-fact__flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 60px;
  }

  .analysis-fact__photo {
    display: flex;
    position: relative;
  }

  .analysis-fact__photo:before {
    content: "";
    background-image: url(../img/slaq.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -52px;
    left: -71px;
    max-width: 225px;
    width: 100%;
    height: 178px;
  }

  .analysis-fact__photo .fact-pic-mob {
    display: none;
  }

  .cost-forecast {
    background: #DFECFF;
  }

  .analysis-fact__info {
    max-width: 624px;
    width: 100%;
  }

  .analysis-fact__info h2 {
    margin-bottom: 40px;
  }

  .cost-forecast .analysis-fact__flex {
    flex-direction: row-reverse;
  }

  .cost-forecast .analysis-fact__photo:before {
    background-image: url("../img/slaq2.png");
    max-width: 225px;
    height: 178px;
    bottom: -66px;
    left: auto;
    right: -79px;
  }

  .analysis-fact__main {
    position: relative;
  }

  .cost-forecast .analysis-fact__main:after {
    content: "";
    position: absolute;
    bottom: 14px;
    left: -32px;
    background-image: url(../img/star4.png);
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 192px;
    width: 100%;
    height: 184px;
  }

  .safety__fon {
    background: linear-gradient(180deg, rgba(223, 236, 255, 0) 0%, #DFECFF 100%);
    padding: 120px 0 60px;
  }

  .safety__flex {
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
  }

  .safety__box {
    width: 24.3%;
    background: #5EA2F4;
    border-radius: 12px;
    padding-top: 24px;
    height: 410px;
    position: relative;
  }

  .safety__box strong {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    display: block;
    padding: 0 10px;
  }

  .safety__box ul {
    margin-top: 24px;
    padding: 0 16px;
  }

  .safety__box ul li {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.9);
    position: relative;
    margin-top: 10px;
    padding-left: 20px;
  }

  .safety__box ul li:before {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    width: 7.78px;
    height: 7.78px;
    background: #DDF0FC;
    opacity: 0.5;
    transform: rotate(45deg);
  }

  .safety__photo {
    display: flex;
    position: absolute;
    bottom: 0;
  }


  /*===========-style-politic-==========*/

  .privacy-policy {
    padding: 80px 0;
    background: #DFECFF;
  }

  .privacy-policy__info h1 {
    color: #5EA2F4;
    margin-bottom: 40px;
  }

  .privacy-policy__info p {
    margin-top: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #424345;

  }

  .privacy-policy__info p a {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #5EA2F4;
    text-decoration: underline;
    transition: 0.3s;
  }

  .privacy-policy__info p a:hover {
    text-decoration: unset;
  }

  .privacy-policy__info h3 {
    font-family: 'Gotham Pro';
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #424345;
    margin-top: 40px;
  }

  .privacy-policy__info ul {
    margin-top: 20px;
    margin-left: 25px;
    list-style: unset;
  }

  .privacy-policy__info ul li {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #424345;
  }


  @media (max-width: 1600px) {
    .opportunities-pic {
      max-width: 600px;
      width: 100%;
    }
    .plat-ben__box {
      max-width: 430px;
    }
    .analysis-fact__info {
      max-width: 570px;
    }
    .analysis-fact__photo {
      max-width: 700px;
    }
    .cost-forecast .analysis-fact__photo:before {
      max-width: 171px;
      height: 137px;
      bottom: -66px;
      right: -45px;
    }
    .analysis-fact__photo:before {
      bottom: -63px;
      left: -42px;
      max-width: 190px;
      height: 152px;
    }
  }
  @media (max-width: 1440px) {
    .plat-ben__box {
      max-width: 382px;
    }
    .analysis-fact__info {
      max-width: 530px;
    }
    .analysis-fact__photo {
      max-width: 630px;
    }
    .safety__photo {
      bottom: -1px;
    }
  }
  @media (max-width: 1300px) {
    .plat-ben__box {
      max-width: 355px;
    }
    .plat-ben {
      padding: 80px 0 40px;
    }
    .analysis-fact, .cost-forecast {
      padding: 80px 0 40px;
    }
    .analysis-fact:after {
      top: 27px;
      right: 13px;
      max-width: 147px;
      height: 143px;
    }
    .analysis-fact__info h2 {
      margin-bottom: 20px;
    }
    .analysis-fact__info {
      max-width: 510px;
    }
    .analysis-fact__photo {
      max-width: 560px;
    }
    .cost-forecast .analysis-fact__main:after {
      bottom: 11px;
      left: -20px;
      max-width: 150px;
      height: 142px;
    }
    .cost-forecast .analysis-fact__photo:before {
      max-width: 153px;
      height: 123px;
      bottom: -71px;
      right: -30px;
    }
    .safety__fon {
      padding: 80px 0 40px;
    }
    .safety__box {
      width: 32%;
      margin: 14px 7px 0;
    }
    .safety__flex {
      margin: 25px 0;
      justify-content: center;
      flex-wrap: wrap;
    }
    .safety__photo {
      bottom: 0;
    }

  }
  @media (max-width: 1200px) {
    .opportunities-pic {
      max-width: 460px;
    }
    .plat-ben__box {
      max-width: 300px;
      margin-top: 12px;
    }
    .analysis-fact:after {
      display: none;
    }
    .analysis-fact__photo:before {
      display: none;
    }
    .analysis-fact__info {
      max-width: 450px;
    }
    .analysis-fact__photo {
      max-width: 460px;
    }
    .cost-forecast .analysis-fact__main:after {
      display: none;
    }
    .safety__box {
      width: 45%;
      margin: 14px 7px 0;
    }
    .safety__fon h2 {
      text-align: center;
    }
    .opportunities-pic {
      max-width: 350px;
    }

    .privacy-policy {
      padding: 60px 0;
    }
    .privacy-policy__info h1 {
      margin-bottom: 20px;
    }
    .privacy-policy__info p {
      font-size: 18px;
      line-height: 26px;
    }
    .privacy-policy__info ul li {
      font-size: 18px;
      line-height: 26px;
    }
    .privacy-policy__info h3 {
      font-size: 26px;
      line-height: 32px;
      margin-top: 30px;
    }
    .privacy-policy__info p a {
      font-size: 18px;
      line-height: 26px;
    }
  }
  @media (max-width: 991px) {
    .plat-ben__main p {
      font-size: 18px;
      line-height: 24px;
      margin-top: 12px;
    }
    .plat-ben__box {
      max-width: 330px;
      margin-top: 0;
    }
    .plat-ben {
      padding: 60px 0 30px;
    }
    .plat-ben__box span {
      font-size: 18px;
      line-height: 22px;
    }
    .plat-ben__block .swiper-pagination {
      display: block;
      bottom: -20px;
    }
    .plat-ben__block {
      margin: 40px 0 40px;
      position: relative;
    }
    .plat-ben__block .swiper-pagination-bullet {
      border-radius: 0;
      width: 65px;
      height: 6px;
      background: rgba(94, 162, 244, 0.3);
      opacity: 1;
    }
    .plat-ben__block .swiper-pagination-bullet-active {
      background: #5EA2F4;
    }
    .analysis-fact__flex {
      width: 100%;
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 30px;
    }
    .analysis-fact__photo {
      max-width: 620px;
      margin-top: 24px;
    }
    .analysis-fact__info {
      max-width: 620px;
    }
    .analysis-fact__info h2 {
      text-align: center;
    }
    .analysis-fact, .cost-forecast {
      padding: 60px 0 30px;
    }
    .plat-ben__main p {
      font-size: 16px;
      line-height: 22px;
      margin-top: 10px;
    }
    .cost-forecast .analysis-fact__flex {
      flex-direction: column-reverse;
    }
    .safety__box {
      width: 46%;
    }
    .privacy-policy {
      padding: 40px 0;
    }
    .privacy-policy__info p {
      margin-top: 15px;
      font-size: 16px;
      line-height: 22px;
    }
    .privacy-policy__info p a {
      font-size: 16px;
      line-height: 22px;
    }
    .privacy-policy__info h3 {
      font-size: 24px;
      line-height: 30px;
    }
    .privacy-policy__info ul li {
      font-size: 16px;
      line-height: 22px;
    }
  }
  @media (max-width: 760px) {
    .opportunities-pic {
      display: none;
    }
    .plat-ben__box {
      max-width: 358px;
      height: 320px;
      padding: 16px 0 0;
      margin: 0 auto;
    }
    .plat-ben__box span {
      padding: 0 18px;
    }
    .safety__box {
      max-width: 358px;
      width: 100%;
      margin: 0 0 8px;
      height: 350px;
      padding-top: 18px;
    }
    .safety__fon {
      padding: 60px 0 30px;
    }
    .safety__box strong {
      font-size: 18px;
    }
    .safety__box ul {
      margin-top: 16px;
    }
    .safety__box ul li {
      font-size: 16px;
      line-height: 22px;
      margin-top: 6px;
      padding-left: 26px;
    }
    .safety__box ul li:before {
      content: url(../img/ptich.png);
      position: absolute;
      top: 0;
      left: 0;
      background: unset;
      opacity: 1;
      transform: unset;
    }
    .safety__flex {
      margin: 20px 0;
    }
    .safety__fon {
      padding: 60px 0 15px;
    }
  }
  @media (max-width: 570px) {
    .plat-ben__block .swiper-pagination-bullet {
      width: 50px;
    }
    .analysis-fact__photo .fact-pic-dec {
      display: none;
    }
    .analysis-fact__photo .fact-pic-mob {
      display: block;
    }
  }
  @media (max-width: 389px) {
    .plat-ben__block .swiper-pagination-bullet {
      width: 40px;
    }
  }
}