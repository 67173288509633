.popupContainer {
  position: relative;
}

.topPopup {
  top: -17.1rem;
  .popup:after{
    top: 100%;
    transform: scaleY(-1) translateX(50%);
  }
  .popup:before{
    top: calc(100% + .5px);
    transform: scaleY(-1) translateX(52%);
  }
}

.workInfo {
  cursor: default;
  width: 1.25rem;
  height: 1.25rem;
}

.justification {
  min-width: 10rem;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #4fb2ed;
  background: #e6f4fc;
  height: 1.875rem;
  border-radius: 0.9rem;
  width: fit-content;
  padding: 0 0.5rem;
}

.popupWrapper {
  padding-top: 1.1rem;
  width: max-content;
  height: auto;
  position: absolute;
  right: 0.625rem;
  transform: translateX(50%);
  z-index: 81;
}

.horizontalPopupWrapper{
  left: 200%;
  top: 0;
  transform: translateY(-50%);
  .popup:after{
    border: 0.8125rem solid transparent;
    border-right: 0.8125rem solid white;
    right: 100%;
    top: 50%;
    transform: none;
  }
  .popup:before{
    border: 0.82rem solid transparent;
    border-right: 0.82rem solid var(--color-background-primary) ;
    right: calc(100% + 1px);
    top: 50%;
    transform: none;
  }
}

.popup {
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 2.35rem;
  grid-template-columns: repeat(2, 7rem);
  grid-template-rows: repeat(3, 1fr);
  font-family: var(--title-font);
  font-weight: 400;
  font-size: 0.875rem;
  background-color: white;
  height: auto;
  border-radius: 0.375rem;
  padding: 0.5rem 0.625rem;
  outline: 1px solid var(--color-background-primary);
  &:after {
    content: "";
    display: block;
    border: 0.8125rem solid transparent;
    border-bottom: 0.8125rem solid white;
    position: absolute;
    top: -0.53rem;
    right: 50%;
    transform: translateX(50%);
  }
  &:before {
    content: "";
    display: block;
    border: 0.82rem solid transparent;
    border-bottom-color: var(--color-background-primary);
    position: absolute;
    top: -0.61rem;
    right: 50%;
    transform: translateX(51%);
  }
}

.popup > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.5rem;
}

.popup > div > span:nth-child(1) {
  color: #575757;
}

.popupJustification {
  grid-column: 1 / 3;
  grid-row: 1;
}

.popupMeasure,
.popupPrice {
  grid-row: 2;
}

.popupMeasure {
  grid-column: 1;
}

.popupPrice {
  grid-column: 2;
}

.popupEstimateCount,
.popupEstimatePrice {
  grid-row: 3;
}

.popupEstimateCount {
  grid-column: 1;
}

.popupEstimatePrice {
  grid-column: 2;
}
