.form {
  label {
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: black;
    font-family: var(--title-font) !important;
  }
}

.fieldInput {
  width: auto !important;
  padding-left: 0 !important;
}

.pair {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.calendarOptions {
  left: -20%;
}

textarea.fieldInput {
  height: auto;
}

.options {
  width: calc(100% + 2px) !important;
  top: 100% !important;
}

.fields {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1.2rem;
  width: 100%;
  padding: 1rem 3.5rem;
}

.isVisible {
  display: flex;
}

.range {
  flex: 1;
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
}

.isHidden {
  display: none;
}

.fieldsAdditional {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin: 1.5rem 0;
}

.root {
  padding: 1rem 3.5rem;
}

.tabs {
  margin-top: 1rem;
  width: fit-content;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: -0.375rem;

  & > * {
    width: fit-content !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
