.remarks {
  &.hidden {
    visibility: hidden;
    user-select: none;
    pointer-events: none;
  }

  justify-content: space-around;
  padding: 0 1rem;
}

.add {
  //margin-left: 2rem;

  & > span {
    display: block !important;
  }
}

.pendingRemarks {
  //margin-left: 2rem;

  & > span {
    display: block !important;
  }
}

.pendingRemarksCount {
  background-color: #ef8932 !important;
}

.divider {
  //margin-left: 2rem;
  width: 2px;
  height: 2.5rem;
  background-color: #cecece;
}

.popup {
  padding: 0.5rem 0.8rem;
}

.approve {
  flex-grow: 2;
  display: flex;
  justify-content: space-evenly;
}

.alone {
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 26px;
  }
}

.confirmModal {
  width: 600px;
}
