.container {
  padding: 1.25rem 1.563rem 7rem;
  margin-top: -4rem;
  border-top: 1px solid var(--color-border-field10);
}

.field {
  margin-bottom: 1.25rem;

  & label {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    color: #000;
    margin-bottom: 0.5rem;
  }
}
