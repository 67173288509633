.person {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.avatar {
  margin-right: 1rem;
  flex-shrink: 0;
}