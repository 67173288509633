.row {
  grid-template-columns: 2.8% 30% 3% 12% 9.9% 9.9% 10.6% 8% 4.3% 4.5% 5%;
  padding-right: 0.75rem;

  &:hover {
    & .actions {
      display: block;
    }

    & .commentCount {
      background-color: #fff;
      color: #000;
    }

    & .comment {
      & path {
        fill: transparent;
        stroke: #fff;
      }
    }

    & .files {
      color: var(--color-font-main);
    }
  }
}

.actions {
  display: none;
}

.comment {
  position: relative;
}

.commentCount {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  padding: 1px 7px 2px;
  background-color: #4fb1eb;
  color: #fff;
  border-radius: 0.75rem;
  font-weight: 500;
  font-size: 12px;
}
