.header {
  display: grid;
  width: 100%;
  align-items: center;
  padding: 0.375rem 0.75rem 0.375rem 1rem;
  grid-template-rows: 1fr;
  grid-template-columns: 7% 8% 8% 47% 20% 10%;
  border-bottom: 1px solid #4FB1EB;
  font-weight: 500;

  & > div {
    display: flex;
    align-items: center;
  }
}

.centered {
  text-align: center;
  justify-content: center;
}

.materialsHeader {
  grid-template-columns: 7% 51% 18% 24%;
}

.planHeader {
  grid-template-columns: 7% 51% 18% 24%;
}

.expendituresHeader {
  grid-template-columns: 7% 51% 18% 24%;
}