.modal {
  position: fixed;
  bottom: 1rem;

  width: 44rem;
  left: 1rem;
  border-radius: 1.125rem;
  padding: 1rem 1rem 0;
  border: 1px solid #d7d7d7;
  background: rgba(242, 242, 242, 0.8);
  backdrop-filter: blur(15px);
  z-index: 100;
}

.title {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;

  svg {
    width: 1rem;

    path {
      fill: #525252;
    }
  }
}

.select {
  margin-bottom: 3.125rem;
}

.selectOpened {
  margin-bottom: 12rem;
}

.options {
  max-height: 10.875rem;
}

.bottom {
  border-top: 1px solid #d3d3d3;
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0.75rem 0;
  margin-top: 0.5rem;

  button {
    min-width: 12.5rem;
    justify-content: center;
    text-align: center;
  }
}
//
.tabs {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.75rem;
}

.meta {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.875rem;
  margin-bottom: 1rem;

  b {
    font-weight: 600;
  }
}

.filesField {
  height: 14.25rem;
  overflow-y: auto;
}
