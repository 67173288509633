.title {
  font: normal normal 1rem var(--title-font);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 1rem;
  border: 1px solid rgba(0, 0, 0, 0);
}

.wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 3.625rem;
  position: relative;

  &:before {
    width: 3.25rem;
    height: 3.625rem;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    border-radius: 0.5rem 0 0 0.5rem;
  }

  &:hover {
    & .actions {
      visibility: visible;
    }
  }
}

.actions {
  visibility: hidden;
}
