.header {
  display: flex;
  flex-direction: column;
}

.tabs {
  justify-content: center;
  height: 1.8rem;
  & > * {
    height: inherit !important;
    margin: 0 .25rem !important;
  }
}

.tableHead {
  grid-template-columns: 30% 50% 20%;
  padding-top: .75rem;
  height: auto;
  & > div:last-child{
    padding-right: 0;
  }
}