@use "src/scss/mixins/_ui-indicators.scss" as ui_indicators;

.container {
  margin: 0.8rem 0;
  border-radius: 0.5rem;
  color: #000;
  font-family: var(--title-font);
  font-size: 13px !important;

  & label {
    white-space: nowrap;
  }

  .titleContainer {
    cursor: pointer;
    background-color: #fff;
    border-radius: 0.5rem;
  }

  .header {
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 0 1rem;
    height: 3.1rem;
    font-weight: 400;
    display: grid;
    grid-template-columns: 1fr 37% 3fr 2fr 3fr 3fr 3fr 2fr;
    white-space: nowrap;

    .numberCol,
    .nameCol,
    .countCol,
    .addIntervalCol,
    .intervalCol:not(:last-child) {
      padding-right: 1rem;
    }
  }

  .intervalCol {
    text-align: center;
  }

  .numberCol,
  .nameCol,
  .countCol,
  .intervalCol {
    text-align: center;
  }

  &.active {
    border: 1px solid #d8d8d8;

    .field {
      .fieldDescription {
        display: block;
      }
    }

    .progressBarWrapper {
      display: none;
    }

    .colorLabel {
      opacity: 0;
    }
  }
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4fb2ed;
  border-radius: 50%;
  width: 1.625rem;
  height: 1.625rem;
  margin-right: 1.625rem;
  cursor: pointer;

  & path {
    fill: #fff;
  }
}

.checkbox {
  margin-right: 0.5rem;
}

.titleContainer {
  &.active {
    position: sticky;
    z-index: 14;
  }
}

.header {
  position: sticky;
  z-index: 14;
}

.checkboxHolder {
  width: 1.5rem;
}

.nameMargin {
  margin-top: 0.0625rem;
  max-width: 33rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerAmount {
  min-width: 6.5rem;
}

.percents {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4fb2ed;
  border-radius: 0.7rem;
  padding: 0.2rem;
  color: #fff;
  min-width: 3.21rem;
  margin: 0 1rem;
}

.addBtn {
  display: none;
  margin-right: 1rem;
}

$indicatorSize: 8px;

.withIndicator {
  position: relative;
}

.withIndicator:before {
  @include ui_indicators.getIndicatorAsContent($indicatorSize);
  position: absolute;
  left: -1rem;
  top: 50%;
  transform: translateY(-50%);
}

.actionsCol {
  flex-basis: 50px;
  height: 10px;
}

.loader {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
