.row {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 4.6rem;
}

.container {
  margin-bottom: 0.8rem;
  border-radius: 1rem;
  height: 4.6rem;

  &:not(.open) {
    .row:hover {
      .count {
        color: #fff;
      }
    }
  }
}

.name {
  font-family: var(--title-font);
  font-size: 1.12rem !important;
}

.count {
  width: 14%;
  color: var(--color-font-secondary3);
  margin-left: auto;
  font-size: 1.12rem !important;
  font-family: var(--title-font);
}
