@use "src/scss/dimensions.scss" as dimensions;

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.5%;
  height: 13.75rem;
  column-gap: 2rem;
  background-image: linear-gradient(90deg, rgba(244, 244, 244, 0.2) 63%, rgba(244, 244, 244, 1) 100%)
}

.logoSubtitle {
  display: flex;
  flex-direction: column;
}

@include dimensions.breakpoint-from(640px) {
  .footer {
    padding: 4rem 7.5%;
  }
}