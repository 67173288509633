.tableRow {
  background: #f4f4f4;
  border-radius: 0.5rem;
  font: normal normal 400 1rem var(--title-font);
  color: #000;
  margin-bottom: 0.6rem;
  border: 1px solid #f4f4f4;
  position: relative;

  &.active {
    background: #fff;
    border: 1px solid #4fb2ed;
  }
}
.content {
  padding: 0 1.3rem 1.3rem;
  border-bottom: none !important;
}
.headerMaterial {
  display: flex;
  justify-content: space-between;
  font: normal normal 500 1rem var(--main-font);
  color: #000;
  padding: 0.5rem 0rem;

  .headerItem {
    flex: 1;
    padding: 0 0.5rem;
    white-space: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 11%;
  }
  .name {
    flex-basis: 41%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 0;
  }
  .name1 {
    padding-left: 1.2rem;
  }
  .checkBlock {
    flex-basis: 15%;
    padding-right: 0;
  }
}

.contentJustification {
  padding: 1rem;
}

.blueLabel {
  background-color: #e6f4fc;
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-primary);
    color: #fff;
  }
}

.blueLabelNonActive {
  background-color: #fff;
}

.hideBlockTitle {
  padding-left: 1.3rem;
  font-size: 1rem;
}

.hideMaterialBlock {
  display: flex;
  margin-top: 0.5rem;

  .materialItem {
    padding: 0.9rem 2.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .flex_1 {
    flex: 1;
    text-align: left;
  }

  .flex_4 {
    flex: 2;
    padding-left: 1.3rem;
  }

  .empty {
    flex: 1;
  }

  .title {
    border-radius: 8px;
    margin-right: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    .arrow {
      position: absolute;
      top: 0.2rem;
      right: 0.3rem;
    }
  }
}

.checkItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
}

.righted {
  text-align: right;
  justify-content: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkFile {
  width: 2.5rem;
  position: relative;
  cursor: pointer;
}

.measure,
.centeredContent {
  justify-content: center;
  text-align: center;
}

.labels {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.ticketHeader {
  padding-left: 1.3rem;
}
