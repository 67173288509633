.value {
  margin-bottom: 0.4rem;
}

.title {
  opacity: 0.7;
}

.container {
  text-align: right;
  margin-left: auto;
}
