.workerCard {
  & span:not(.medium) {
    font-weight: 400;
  }
}

.medium {
  font-weight: 500;
}

.textWithBackground {
  padding: 0.313rem 0.688rem;
  border-radius: 0.5rem;
  background-color: var(--color-devider-line);
  max-width: fit-content;
}

.header {
  padding: 1.625rem 2.438rem 1.25rem;
  display: flex;
  border-bottom: 1px solid var(--color-border-field5);

  @media screen and (max-width: 1480px) {
    padding: 1.625rem 1.438rem 1.25rem;
  }
}

.avatar {
  width: 7rem;
  height: 7rem;
  border-radius: 0.5rem;
  object-fit: cover;
}

.avatarNone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.188rem;
  border-radius: 0.5rem;
  background-color: var(--color-border-field3);

  & img {
    width: 2.625rem;
    height: 2.625rem;
  }
}

.headerInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 1rem;
}

.title {
  font-family: var(--title-font);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.dotsIfLong {
  width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerInfoItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & span,
  & .switcher {
    width: 55%;
  }
}

.section {
  padding: 1.125rem 2.438rem 0;

  @media screen and (max-width: 1480px) {
    padding: 1.125rem 1.438rem 0;
  }
}

.subtitle {
  font-size: 1rem;
  margin-bottom: 0.875rem;
}

.mainInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mainInfoItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 45%;
}

.phoneInput {
  height: auto;
  font-weight: 400;
  border: none;
  border-radius: 0;
  padding: 0;
  color: black;
}

.bigWidth {
  width: 100%;
}

.mainInfoTitle {
  margin-bottom: 0.563rem;
}

.itemDate {
  max-width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pBottom {
  padding-bottom: 6.25rem;
}

.attachHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.938rem;

  & h3 {
    margin-bottom: 0;
  }
}

.downloadButton {
  cursor: pointer;
  color: var(--color-font-primary);
}

.footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 0.813rem 0;
  border-top: 1px solid var(--color-border-field20);

  & button {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.email {
  text-overflow: ellipsis;
  overflow: hidden;
}

.calendarIcon {
  margin-left: 1rem;
}
