.container {
  display: grid;
  grid-template-columns: 8% 51% 16% 14% 7% 4%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  align-items: center;

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.field1 {
  display: flex;
  justify-content: center;
}

.center {
  text-align: center;
}

.dot {
  background: var(--color-background-field8);
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 10rem;
}

.money {
  text-align: right;
}