.container {
  font-family: Montserrat;

  .input {
    border: 1px solid #c3c3c3;
    height: 2.5625rem;
    border-radius: 12px;
    padding: 0.7rem 1.125rem;
    font-size: 1rem;
    color: #000;
    font-weight: 400;
    opacity: 0.5;

    &::placeholder {
      color: #000;
    }
  }

  .inputError {
    border-color: #ff0558;
  }

  .errorMessage {
    font-size: 11px;
    margin: 0.1rem 0;
    color: #ff0558;
  }
}