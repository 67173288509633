.checkboxLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
  font: normal normal 1.125rem/1.375rem var(--title-font);
}

.checkbox {
  border: 1px solid var(--color-border-field18);
  border-radius: 0.357rem;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &.disabled {
    opacity: .6;
    cursor: default;
  }
}

.text {
  margin: 0;
}

.hidden {
  display: none;
}

.leftAlign {
  margin-right: 1.75rem;
}

.rightAlign {
  margin-left: 1.75rem;
}