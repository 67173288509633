.container {
  position: sticky;

  width: 65%;
  bottom: 0;
  left: 35%;
  height: 0;

  z-index: 96;
}

.content {
  display: flex;
  position: absolute;
  cursor: default;

  width: 100%;
  bottom: 0;
  left: 0;
  height: calc(100vh - 2.25rem);

  flex-direction: column;

  backdrop-filter: blur(2px);
}

.message {
  position: absolute;
  top: 66%;
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 1.125rem;
  padding: 0 20%;
}
