.confirmationModal {
  max-width: 40rem;
  text-align: center;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text {
    margin: 0.75rem 0 0;
    font: normal normal 1rem/1.125rem var(--title-font);
  }

  .content, .actionBlock {
    padding: 1rem;
  }

  .actionBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    
    button {
      margin: 0 0.5rem;
    }
  }

  &.secondary {
    width: 36.875rem;

    .actionBlock {
      border-top: 1px solid var(--color-border-field6);
    }
  }
}