body.osla {
  header {
    background: #5EA2F4;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }

  .header__main {
    padding: 11px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header__logo {
    display: flex;
  }

  .menu-cnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 82%;
  }

  .header__menu {
    margin: 0 auto;
  }

  .header__menu ul {
    display: flex;
    align-items: center;
  }

  .header__menu ul li {
    margin: 0 16px;
  }

  .header__menu ul li:first-child {
    display: none;
  }

  .header__menu ul li a {
    font-family: 'Gotham Pro';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
  }

  .header__menu ul li a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.5);
    width: 0;
    height: 1px;
    transition: 0.3s;
  }

  .header__menu ul li a:hover {
    color: rgba(255, 255, 255, 0.5);
  }

  .header__menu ul li a:hover:after,
  .header__menu ul li.active a:after {
    width: 100%;
  }

  .header__contact {
    display: flex;
    align-items: center;
  }

  .header__phone {
    font-family: 'Gotham Pro';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    transition: 0.3s;
  }

  .header__phone:hover {
    color: rgba(255, 255, 255, 0.5);

  }

  .come-in {
    padding: 8px 16px;
    width: 89px;
    height: 41px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 52px;
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    font-family: 'Gotham Pro';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .come-in:hover {
    background: #FFFFFF;
    color: #5EA2F4;
  }

  .phone-for__planshet {
    display: none;
  }

  .open-menu {
    display: none;
    width: 26px;
    height: 18px;
    position: relative;
  }

  .open-menu span {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #FFFFFF;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .close-menu {
    display: none;
  }

  .body_fix {
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
  }

  .body_fix .open-menu.close-menu {
    z-index: 9999999;
  }

  @media (max-width: 1199px) {
    .header__menu ul li {
      margin: 0 10px;
    }

    .header__menu ul li a {
      font-size: 16px;
    }

    .header__phone {
      font-size: 16px;
    }

    .menu-cnt {
      width: 79%;
    }
  }

  @media (max-width: 991px) {
    .menu-width {
      height: 0 !important;
      -webkit-transition: all 0.5s ease 0s !important;
      -o-transition: all 0.5s ease 0s !important;
      transition: all 0.5s ease 0s !important;
    }

    .transition-menu {
      height: 100% !important;
      -webkit-transition: all 0.5s ease 0s !important;
      -o-transition: all 0.5s ease 0s !important;
      transition: all 0.5s ease 0s !important;
    }

    .open-menu span:nth-child(2), .open-menu span:nth-child(3) {
      top: 8px;
      right: 0;
    }

    .open-menu span:last-child {
      top: 16px;
      right: 0;
    }

    .open-menu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 999;
    }

    .close-menu span {
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      right: auto;
    }

    .close-menu span:first-child, .close-menu span:last-child {
      display: none;
    }

    .close-menu span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .close-menu span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    .menu-cnt {
      margin: 0;
      height: 0;
      width: 100%;
      position: fixed;
      z-index: 99999;
      top: 63px;
      left: 0;
      overflow-x: scroll;
      background: linear-gradient(0deg, rgba(48, 123, 211, 0.5) 0%, rgba(95, 163, 245, 0) 100%), #5EA2F4;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .header__menu ul {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .header__menu ul li {
      margin: 0 0 25px;
    }

    .header__menu ul li a {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
    }

    .header__contact {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 70px;
    }

    .header__phone {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
    }

    .come-in {
      padding: 8px 16px;
      width: 128px;
      height: 50px;
      margin-left: 0;
      margin-top: 30px;
    }

    .phone-for__planshet {
      margin-left: auto;
      margin-right: 35px;
      display: flex;
    }

    .phone-for__planshet .header__phone {
      font-size: 18px;
      line-height: 24px;
    }

    .header__menu ul li:first-child {
      display: flex;
    }
  }

  @media (max-width: 570px) {
    .phone-for__planshet {
      display: none;
    }
  }

}