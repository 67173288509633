.rangeElement {
  display: flex;
  flex-direction: column;
  row-gap: 0.375rem;
  padding: 0 0.25rem;
}

.label {
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.indicator {
  position: relative;
  background-color: white;
  width: 1.6875rem;
  height: 1.6875rem;
  border-radius: 50%;
  box-shadow: 0 0.15rem 0.5rem #cbcbcb;
  z-index: 2;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}

.line {
  z-index: 0;
  position: relative;
  cursor: pointer;
  &:after {
    content: " ";
    display: block;
    background-image: linear-gradient(90deg, #00ff6c 1.6%, #fa890e 53.2%, #ff0027 98.6%);
    transform: translateY(-50%);
    width: 100%;
    height: 0.625rem;
    border-radius: 0.5rem;
    position: absolute;
    top: 50%;
  }
}

.legend {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 1rem);
  margin: 0 0.25rem;
}

.priority {
  width: 0.25rem;
  height: 0.6875rem;
  border-radius: 0.125rem;
}

.priorityLow {
  outline: 1px dashed #54d74c;
}

.priorityMedium {
  outline: 1px solid #ee7e0e;
}

.priorityHigh {
  background-color: #ff0127;
  outline: 1px solid #ff0127;
}

.disabled,
.disabled:active {
  cursor: default;
}

//select

.option {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.75rem;
  padding-left: 0.75rem;
  height: 2rem;

  .indicator {
    width: 0.875rem;
    height: 0.875rem;
  }
}

.option.value {
  padding-left: 0;
  .indicator {
    box-shadow: none;
  }
}
