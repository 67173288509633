.row {
  grid-template-columns: 52% 14% 16% 12% 6%;

  &.withActions {
    grid-template-columns: 47% 14% 16% 12% 6% 5%;
  }
}

.files {
  color: #000;
}
