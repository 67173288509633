.forContractorField {
  display: flex;
  gap: 0 0.5rem;
  padding: 0;
  margin-left: 0.5rem;

  color: var(--base-text-primary, #000);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */
}