.table {
    margin-top: 1.2rem;
}

.container {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: -2rem;
    margin-right: -1.5rem;
    padding-left: 2rem;
    padding-right: 1.5rem;
}
