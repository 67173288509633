.container {
  border-radius: 1.156rem;
  height: 2.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

$buttonMinWidth: 3rem;

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: .725rem 0;
  border-radius: 0;
  min-width: $buttonMinWidth;
}

.doubleButtonWidth{
  min-width: calc(2 * $buttonMinWidth);
}

.doubleButton {
  min-width: calc(2 * $buttonMinWidth);
}

.primary {
  background-color: var(--color-background-primary) !important;
}

.default {
  background-color: white !important;
}