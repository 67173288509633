.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 0 2.75rem;
}

.cols2 {
  grid-column: 1 / 3;
}
