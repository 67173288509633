.activeWriteLink {
  button {
    color: #fff;
    background-color: #4FB2ED;
  }
}

.toWriteOff {
  padding-left: 2rem;
  padding-right: 2rem;
}

.measure {
  margin-left: 2rem;
}

.rightSide {
  display: flex;
  align-items: center;
  font: normal normal 400 1.18rem var(--title-font);
  margin-left: 3rem;
  margin-right: auto;

  .line {
    margin-right: 1.3rem;
  }

  .count {
    font-weight: 500;
    margin-left: 1rem;

    &:not(:last-child) {
      margin-right: 0.6rem;
    }
  }

}