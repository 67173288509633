.tableSide {
  height: auto;
  min-height: calc(100vh - 16.5rem);

  & :global(.fixed) {
    position: fixed;
    top: 3.7rem;
    width: 1180px;
  }
}
