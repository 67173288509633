.body {
  height: calc(100% - 8rem);
  max-height: calc(100% - 8rem);
  overflow-y: auto;
  padding-top: .5rem;
}

.eventDate {
  display: block;
  margin-bottom: .5rem;
}

.taskAvatars {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: .875rem;
  position: relative;
  cursor: pointer;
  margin-left: 1rem;
}

.emptyPlaceholder{
  min-height: auto;
  margin-top: 5rem;
}

.taskEvent{
  cursor: pointer;
}

.taskDeadline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: .5rem;
  & *:last-child{
    margin-right: -1.5rem;
  }
}

.eventBubble {
  width: 80%;
}