.btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 34px;
  border-radius: 0.5rem;
  border: 1px solid #4fb1eb;
  color: #4fb1eb;
  padding: 0 1rem;
  margin-left: auto;

  &:hover {
    background-color: #4fb1eb;
    color: #fff;
    & path {
      fill: #fff;
    }
  }
}
