.itemBlock {
  font: normal normal 1.125rem/1.375rem var(--title-font);
  background-color: var(--color-background-primary);
  color: var(--color-font-hover);
  border-radius: 0.5rem;
  padding: 1.125rem 0.75rem 0.75rem;
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}

.amount {
  font-weight: 500;
  flex-shrink: 0;
  font-size: 1.188rem;
  line-height: 1.438rem;
}

.icon {
  margin-right: 0.75rem;
  flex-shrink: 0;

  path {
    fill: #fff;
  }
}

.itemName {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  margin-bottom: 0.25rem;
}

.expenditure {
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.125rem;

  .itemName {
    margin-bottom: 0;
  }

  .price .value, .price .title {
    font-size: 1rem;
    line-height: 1.125rem;
  }
}