.tableHeader {
  font-weight: 500;
  display: grid;
  grid-template-columns: 6% 28% 16% 8% 14% 14% 14%;
  background-color: #fff;
  z-index: 3;
  border-bottom: 1px solid #4fb2ed;
  margin-bottom: 0.3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.88rem;
  height: 3.88rem;

  &.sticky {
    position: sticky;
    top: 2rem;
    z-index: 2;
  }

  &.withEar {
    &::before {
      content: "";
      position: absolute;
      background-color: #fff;
      width: 2rem;
      height: calc(100% + 2rem);
      top: -2rem;
      left: -2rem;
    }
  }
}

.sticky {
  position: sticky;
  top: 3.5rem;
}

.headerCell {
  white-space: nowrap;
  display: flex;
  align-items: center;
  min-height: 1.875rem;
}

.centered {
  text-align: center;
  justify-content: center;
}

.righted {
  text-align: right;
  justify-content: flex-end;
  padding-right: 3rem;
}

.small {
  padding-top: 0;
  height: 2.5rem;
}
