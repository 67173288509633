.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  height: 100%;
  justify-content: center;
}

.message {
  font: normal normal 300 1.125rem/1.375rem var(--title-font);
  margin: 0 0 2rem;
}