.weeksLine {
  position: relative;
}

.weekCaptionBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
  position: absolute;
  top: 0;
  height: 2.25rem;
  background-color: #f4f4f4;
  border-bottom: 1px solid rgba(177, 177, 177, 1);
}

.weekInterval {
  color: #4fb1eb;
}

.critical {
  color: red;
}

.currentLine {
  position: absolute;
  width: 0.1875rem;
  min-height: calc(100vh - 5.62rem);
  top: 2.81rem;
  background-color: #707070;
  z-index: 0;
}

.currentWeekDay {
  position: absolute;
  height: 100%;
  width: 1.6875rem;
  top: 2rem;
  z-index: 1;
  transform: translateX(12.5%);
  span {
    background: #4fb2ed;
    border-radius: 100%;
    font-weight: 600;
    font-size: 0.9375rem;
    color: white;
    width: 1.6875rem;
    height: 1.6875rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &::before {
    content: " ";
    position: absolute;
    top: 1.6rem;
    left: 0.7535rem;
    width: 0.1875rem;
    height: 100vh;
    background-color: #707070;
    z-index: -1;
    pointer-events: none;
  }
}

.hasCheckpoint {
  cursor: pointer;
}

.hasCheckpoint::before {
  content: " ";
  position: absolute;
  top: calc(50% - 0.625rem);
  left: 1.75rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;
  background: var(--color-indicator-frostbite);
}
