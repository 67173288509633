.addButton {
  border: none;
  min-width: auto;
  padding: 0;
  display: flex;
  align-items: center;

  & > div {
    display: flex;
  align-items: center;
  }

  &:hover {
    background-color: transparent;
  }

  &:active {
    background-color: transparent;
  }
}

.text {
  margin-right: 0.5rem;
}