.container {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
}

.content {
  flex: 1;
  padding-bottom: 1rem;
}

.headline {
  padding-bottom: 1rem;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 20;
}

.taskName {
  font-size: 1.375rem;
  font-weight: 800;
}

.tabs {
  width: fit-content;
  margin: 0 auto;

  & > div {
    padding: 0 1.1875rem;
    width: auto;
  }
}

.footer {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.65625rem;
  padding: 1.125rem 0 0.7125rem;
  border-top: 1px solid #bcbcbc;
  position: sticky;
  width: 100%;
  bottom: 0;
}

.button {
  border-radius: 0.25rem !important;
  font-size: 1rem;
  min-width: 12.25rem;
  justify-content: center;
}

.cancelButton {
  border-color: black;
}

.createButton,
.editButton {
  column-gap: 1.585rem;
}

.createButton {
  justify-content: flex-start;
}

.editButton {
  justify-content: center;
  flex: 1;
  margin: 0 2.28rem;
}

.back {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin: 1rem 0;
}

////
.statusBar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.status {
  font-size: 0.875rem;
  color: #fff;
  height: 28px;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 0 12px;
  font-weight: 600;
  border-radius: 20px;
  background: #4fb1eb;
}

.creatorName {
  color: #696969;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

// design v2

.modal {
  width: 46.5rem;
}
