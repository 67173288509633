.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  row-gap: .2rem;
}

.title {
  font-family: var(--title-font);
  font-size: .875rem;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  column-gap: 1.5625rem;
}

.entry {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  row-gap: .1125rem;
}

.subtitle {
  color: rgba(0, 0, 0, .7);
  font-family: var(--title-font);
  font-size: .6875rem;
}

.value {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  column-gap: .9375rem;
}

.value > span:first-child {
  font-family: var(--title-font);
  font-size: 1.25rem;
}

.value > span:last-child {
  font-family: var(--title-font);
  font-size: .875rem;
}