.root {
  margin-left: auto;
  border-radius: 10px;
  border: 1px solid #ffc402;
  background: #fff;
  position: relative;
  height: 2rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}

.title {
  top: 0;
  right: -2px;
  position: absolute;
  font-weight: 400;
  font-size: 0.625rem;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 15.5px;
  background: #ffc402;
  height: 18px;
  transform: translateY(-60%) translateX(10px);
}
