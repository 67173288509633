.container {
  position: relative;
  height: 1.5rem;
  display: flex;
  align-items: center;
}

.absoluteContainer {
  border-radius: 1rem;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  &:empty {
    display: none;
  }
}

.count {
  background: var(--color-background-primary);
  color: white;
  font: normal normal 400 0.87rem var(--title-font);
  min-width: 1.57rem;
  padding: 0.125rem 0.375rem;
  text-align: center;
}

.leftCount {
  background-color: var(--color-status-on-created);
}
