.container {
  border-radius: 0.5rem;
  background-color: #fff;
  position: relative;
  height: 1px;

  .content {
    height: 100%;
    overflow: hidden;
    animation: fading 0.3s linear;
  }
}

.btn {
  position: absolute;
  left: -1.08rem;
  top: 0.5rem;
  width: 1.1rem;
  height: 4.7rem;
  background-color: #efefef;
  border-radius: 5px;
}

.activeBtn {
  border-radius: 5px 0 0 5px;
  background-color: #4fb2ed;

  & svg {
    transform: scaleX(-1);

    & path {
      fill: #fff;
    }
  }
}

.open {
  box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
  height: auto;
  margin-bottom: 1rem;
}

@keyframes fading {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
