@use 'sass:color';

.container {
  margin-top: 1rem;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 8.2889% 8.2889% 9.4731% 9.4731%;

  &.removable {
    grid-template-columns: 1fr 8.2889% 8.2889% 9.4731% 9.4731% 3rem;
  }
}

.field {
  width: 100%;
  display: flex;
  gap: 0.375rem;
  align-items: center;
  justify-content: flex-start;
}

.fieldTitle {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #696969;
}

.inputContainer {
  width: 100%;

  .input {
    &::placeholder {
      color: #A4A4A4;
    }

    text-align: left;

    &.error {
      border-color: red;
    }
  }
}

.inputIcon {
  color: #A4A4A4;
  padding-right: 0.5rem;
}

.selectContainer {
  width: 100%;

  .select {
    & ::placeholder {
      color: #A4A4A4;
    }

    &.error {
      border-color: red;
    }
  }
}

.name {
  display: flex;
  justify-content: flex-end;
  gap: 0.375rem;
  padding-left: 1.25rem;

  .type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 10.5rem;
    color: #A4A4A4;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
  }
}

.dashedLine {
  width: 1.375rem;
  height: 1px;
  border: none;
  border-bottom: 3px dashed #9AD9FF;
  margin: auto 0;
}

.container:last-child {
  .dashedLine {
    border: none;
  }
}

.placeholder {
  color: #A4A4A4;
}

.removeButton {
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  border-radius: 1.875rem;
  background: #F4F4F4;
  padding: 0.5625rem 0.75rem;
  transition: background-color .2s ease;


  &:hover {
    background-color: color.adjust(#F4F4F4, $lightness: -3%);
  }

  .removeIcon {
    fill: #A4A4A4;
    cursor: pointer;
  }
}