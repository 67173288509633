.controls {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 98;
  height: auto;
  padding: 1.88rem 4.88rem;
  column-gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
}

.datePicker {
  height: 2.813rem;
  margin-left: 0.5rem;
  & > div:first-child {
    width: max-content;
  }
}

.viewTabsContainer {
  margin-left: auto;
}

.viewTabs > div {
  @media screen and (min-width: 1440px) {
    /* width: auto; */
    padding: 0.3125rem 0.75rem;
    width: min-content;
    user-select: none;
  }
}

.controlIcon {
  height: 2rem;
  width: 1.5rem;
}

.checkIcon > path {
  fill: none;
}

.approvePlansContainer {
  column-gap: 0.25rem;
}

.approvePlansCount {
  position: static;
}
