.blockSum {
  width: 90%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 5%;
  margin-right: 5%;
  border-top: #e1e8ee 1px solid;
  font: normal normal 400 1rem var(--title-font);

}

.block1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.938rem;
  color: #707070;
  padding: 0.25rem 0;

  .value1 {
    color: black;
  }
}

.block2 {
  @extend .block1;
  padding: 0.35rem 0;
  font-size: 1rem;
  color: black;

  .value2{
  color: #00b4f2;
}

}