.weekBar {
  display: flex;
  flex-direction: column;
  text-align: center;
  font: normal normal 1rem/1.25rem var(--title-font);
  border-radius: .5rem;
  padding: 0 1.5rem;
}

.active {
  background-color: var(--color-background-primary);

  &, .date {
    color: var(--color-font-hover);
  }
}

.date {
  color: var(--color-font-primary);
}