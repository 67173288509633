.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: .6875rem .70625rem .7125rem .9375rem;
  border-radius: .5rem;
  row-gap: .55rem;
  border: 1px solid transparent;
}

.clickable {
  cursor: pointer;
}

.containerOpen, .container:hover {
  background-color: #F7F7F7;
  border: 1px solid var(--color-border-field16)
}

.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: .5375rem;
}

.headline {
  width: inherit;
  display: flex;
  align-items: center;
  column-gap: 1rem;

  & span:first-child {
    margin-right: auto;
  }
}

.progress {
  width: 100%;
  height: .375rem;
  border-radius: .375rem;
  outline: 1px solid #EAEAEA;
}


.body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  flex-wrap: nowrap;

  & > *:not(hr) {
    flex-grow: 1;
  }

  & > hr {
    flex-grow: .5;
  }
}

.body hr {
  margin: 0 auto;
  position: relative;
  height: 100%;
  border: none;

  &:after {
    content: '';
    width: 2px;
    border-radius: 2px;
    height: 70%;
    align-self: flex-end;
    transform: translate(50%, 2%);
    background-color: #C7C7C7;
    position: absolute;
    bottom: 0;
  }
}

.chip {
  font-size: .75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .125rem .625rem .25rem;
  border-radius: .625rem;
  color: white;
  white-space: nowrap;
}

.percents {
  display: flex;
  align-items: center;
  column-gap: .3475rem;
  white-space: nowrap;

  & span:nth-child(2) {
    color: #808080
  }
}
