.container {
  width: 23.6rem;
  height: 4.5rem;
  vertical-align: middle;
  list-style-type: none;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  background-color: var(--color-hawkes-blue);
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  font: normal normal 1rem/1.25rem var(--title-font);

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  &:not(:nth-child(3n)) {
    margin-right: 60px;
  }

  &:not(.isApproved) {
    background-color: var(--color-background-field10);
  }

  .chainLine {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -4.5rem;
    width: 4.5rem;
    height: 4px;
    background-color: var(--color-chain-line);
  }

  @media (max-width: 1350px) {
    &:not(:nth-child(2n)) {
      margin-right: 4.5rem;
    }
  }
}

.info{
  padding: 6px 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  align-self: center;
}

.addIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  cursor: pointer;
}

.name {
  width: 100%;
  font-size: 1rem;
  font-weight: normal;
  vertical-align: middle;
  text-align: left;
}

.type {
  margin-top: 0.188rem;
  font-size: 1rem;
  font-weight: normal;
  color: #6C6C6C;

  span {
    display: block;
  }
}

.date {
  margin-top: 0.571rem;
  color: var(--color-font-secondary7);
}

.noRole {
  color: #696969;
}

.blueArea {
  background-color: var(--color-background-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0.5rem 0 0 0.5rem;
  width: 1.63rem;

  .positionCount {
    position: absolute;
    top: 0;
    color: #fff;
  }
}

.delete {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 990px) {
  .elem {
    width: auto;
  }
}

.contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #696969;
  font: normal normal 0.813rem/1rem var(--title-font);
}

.email {
  margin-right: 0.5rem;
}

.receivedPopover{
  width: 1.25rem;
  height: 1.25rem;
  margin: auto 0.4rem;
}

.receivedToApprovePopover{
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  font-size: .75rem;
  padding: .53rem .78rem;

  & span:last-child {
    font-weight: 500;
  }
}