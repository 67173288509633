.table {
  border-collapse: separate;
  border-spacing: 0 0.3rem;
  width: 100%;

  td, th {
    padding: 0 1rem;
  }

  tr td:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  tr td:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
  }
}

.table__header {
  font-family: var(--title-font);
  font-size: 1rem;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  white-space: nowrap;
  border-bottom: 1px solid var(--color-border-primary);
  border-radius: 0;
  padding-bottom: 0.625rem;
  margin-bottom: 1.375rem;

  .crossIcon {
    margin-left: 0.5rem;
    padding: 2px;
    width: 1.9rem;
  }

  &:first-child {
    padding-left: 0 !important;
  }
}

.table__row {
  background-color: #f4f4f4;
  border-radius: 10px;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  height: 3.875rem;
  margin: 5px;
  font-family: var(--title-font);

  & td:first-child {
    text-align: center;
  }
  & :nth-child(8) {
    text-align: center;
    width: 10%;
  }

  &:hover {
    background-color: #4FB2ED;
    color: #fff;

    & :global(.table_person_cercle) {
      background-color: #fff;

      & path {
        fill: #000;
      }
    }
  }
}

.table__titleBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;

  .title {
    margin-right: 0.5rem;
  }
}

.table__titleBlock_centre {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  .title {
    margin-right: 0.5rem;
  }
}

.table__actions {
  width: fit-content;
  background-color: #fff;
  border-radius: 10rem;
  padding: 9px 16px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
  margin-right: 1rem;
}

.table__actions_icon {
  cursor: pointer;
}

.actionPopover {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 30px 30px 20px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #4FB2ED;
  border-radius: 5px;
}

.actionPopover__text {
  text-align: center;
  font-size: 16px;
}

.actionPopover__buttons {
  display: flex;
  justify-content: center;
  gap: 9px;
}

.moreButton {
  margin-top: 2rem;
}

.addLine {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  & h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }

  & button {
    margin-left: 30px;
  }

  & svg {
    margin-left: 12px;
  }
}

.delete {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 3rem;
  border-radius: 1.25rem;
  background-color: #fff;
  margin-left: auto;
}


.alert {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -19px;
  margin-right: -19px;
  border-top: 1px solid #D3D3D3;
  padding-top: 16px;

  & button {
    display: flex;
    width: 195px;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #000000;

    &:first-child {
      margin-right: 32px;
      background: #EB4F8B;
      color: #fff;
      border: none;
    }
  }
}

.plusIcon {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}