.container {
  display: grid;
  grid-template-columns: 5% 27% 13% 10% 13% 8% 11% 13%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  align-items: center;

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.field1 {
  display: flex;
  justify-content: center;
}

.field2 {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
}

.center {
  text-align: center;
}

.field6 {
  text-align: right;
  white-space: nowrap;
  padding-right: 4rem;
}

.dot {
  background: var(--color-background-field8);
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 10rem;
}

@media screen and (max-width: 1640px) {
  .field6 {
    padding-right: 2rem;
  }
}

.filesCount {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.filesRow {
  padding: 0.5rem 1.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  margin-top: -1rem;
}

.spinner {
  margin-right: auto;
}
//
.tableHead {
  display: grid;
  grid-template-columns: 40% 45% 10% 5%;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  height: 2.2rem;
}
.tableHead {
  height: auto;
  font-size: 13px;
  border-bottom: 1px solid #4fb1eb;
  padding-bottom: 0.3rem;
}

.tableRow {
  display: grid;
  grid-template-columns: 40% 45% 10% 5%;
  padding: 0.5rem 1rem;
}

.delete {
  cursor: pointer;
}

.files {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  width: 3rem;
  border-radius: 0.9rem;
  cursor: pointer;

  & svg {
    height: 1.4rem;
  }
}
