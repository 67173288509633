.container {
    margin-bottom: 0.8rem;

    & b {
      margin-bottom: 1rem;
    }
}

.approvals {
  display: flex;
  flex-direction: column;
  row-gap: .9375rem;
}