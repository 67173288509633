@use "../../../../_ks6a_vars.scss" as vars;

$monthWidth: 21.6%;

.month {
  min-height: inherit;
  position: relative;
  background-color: white;
  min-width: $monthWidth;
  max-width: $monthWidth;
  height: 100%;
  margin-top: vars.$headerHeight;
}

.monthHeader, .monthRow, .monthTotal{
  & > div {
    outline: vars.$cellBorder;
    display: flex;
    padding: 0.5rem;
  }
}

.monthHeader {
  min-width: $monthWidth;
  font-weight: 500;
  font-size: .875rem;
  position: sticky;
  top: 0;
  background-color: white;
  height: vars.$headerHeight;
  display: grid;
  grid-template-rows: 2.375rem 1fr;
  grid-template-columns: vars.$monthColumns;
  z-index: 10;
  & > div {
    justify-content: center;
    align-items: flex-start;
  }
  &::after {
    content: " ";
    display: block;
    background-color: #f4f4f4;
    height: 1.625rem;
    grid-column: 1/4;
    border-top: vars.$cellBorder;
    border-bottom: vars.$cellBorder;
  }
}

.monthName {
  grid-column: 1 / 4;
  grid-row: 1;
  font-weight: 700;
}

.monthCount {
  grid-column: 1;
  grid-row: 2;
}

.monthCost {
  grid-column: 2;
  grid-row: 2;
}

.monthCostFact {
  grid-column: 3;
  grid-row: 2;
}

.monthRow {
  display: grid;
  grid-template-columns: vars.$monthColumns;
  grid-template-rows: vars.$baseRowHeight;
  & > div {
    align-items: center;
  }
}

.monthRowCount {
  grid-column: 1;
  justify-content: center;
}

.monthRowCost {
  grid-column: 2;
  justify-content: flex-end;
}

.monthRowCostFact {
  grid-column: 3;
  justify-content: flex-end;
}

.monthTotal {
  display: grid;
  grid-template-columns: vars.$monthColumns;
  grid-template-rows: vars.$totalRows;
  border-top: vars.$primaryBorder;
  position: sticky;
  bottom: 0;
  background-color: white;
  height: 100%;
  & > div {
    align-items: center;
  }
}

.monthTotalCount,
.monthTotalCost,
.monthTotalCostFact {
  grid-row: 1;
}
.monthOtherCount,
.monthOtherCost,
.monthOtherCostFact {
  grid-row: 2;
}
.monthSumCount,
.monthSumCost,
.monthSumCostFact {
  grid-row: 3;
}

.monthTotalCount,
.monthOtherCount,
.monthSumCount {
  grid-column: 1;
}
.monthTotalCost,
.monthOtherCost,
.monthSumCost {
  grid-column: 2;
}
.monthTotalCostFact,
.monthOtherCostFact,
.monthSumCostFact {
  grid-column: 3;
}

.monthTotalCount,
.monthOtherCount,
.monthSumCount {
  justify-content: center;
}

.monthTotalCost,
.monthOtherCost,
.monthSumCost,
.monthTotalCostFact,
.monthOtherCostFact,
.monthSumCostFact {
  justify-content: flex-end;
}
