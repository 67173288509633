.addRemark {
  margin-right: 2rem;
}

.popup {
  width: 19rem;
  display: flex;
  flex-direction: column;
  gap: 0.875rem 0;
}

.createPopup, .popup {
  padding: 0.625rem 1rem;
}

.ticketRemarks {
  cursor: pointer;
}

.remarksCell {
  display: flex;
  align-items: center;
  width: 7.5rem;
}

.popupRow {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: .8125rem;
}

.count {
  background: var(--color-background-primary);
  color: white;
  font: normal normal 400 0.87rem var(--title-font);
  min-width: 1.57rem;
  padding: 0.05rem 0;
  text-align: center;
  border-radius: 1rem;
  margin-right: 1.18rem;
}

.inWorkCount {
  background-color: var(--color-status-on-created);
}

.popupRowIcon {
  margin-right: 1.5rem;
}

.popupRowEnd {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.popupTitle {
  font-size: .75rem;
  color: var(--color-font-primary);
  margin: 0;
  padding-bottom: .25rem;
}

.remarksIcon {
  margin-right: 0.25rem;
}

.remarksCountContainer {
  top: -1rem;
  right: -1rem;
}