.productTable {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  margin-bottom: 15px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 0.8rem;
    font-weight: 500;
    padding-left: 44px;

    .nameCol {
      text-align: center;
    }
  }

  .checkboxCol {
    width: 3rem;
  }

  .countCol {
    width: 8rem;
    margin: 0 0.5rem;
  }

  .measureCol,
  .projectCountCol {
    width: 7rem;
  }

  .nameCol {
    width: 28rem;
  }

  .projectCountCol,
  .measureCol,
  .countCol {
    text-align: center;
  }
}
