
.planBg {
  --borderColor: #dedede;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 0;
  background-image: radial-gradient(circle at 0 0, var(--borderColor), var(--borderColor) 5px, transparent 5px),
    radial-gradient(circle at 0 100%, var(--borderColor), var(--borderColor) 5px, transparent 5px),
    radial-gradient(circle at 100% 100%, var(--borderColor), var(--borderColor) 5px, transparent 5px),
    radial-gradient(circle at 100% 0, var(--borderColor), var(--borderColor) 5px, transparent 5px),
    url("../../../../../../images/ticket-background.svg");
  flex: 1;
  // max-width: calc(100% - 0.88rem);
  overflow: hidden;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  border: 1px solid var(--borderColor);
}

.planBg.hasShift {
  --borderColor: red;
}

.intervalWrapper:hover .planBg.canAcceptArrow {
  --borderColor: #7061ea;
}

$braceColor: #4fb2ed;

.planBrace {
  position: absolute;
  height: 0.25rem;
  width: calc(100% - 1rem);
  background-color: $braceColor;
  top: 0;
  cursor: pointer;
}

.planBrace:after,
.planBrace:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
}

.planBrace:after {
  right: -0.25rem;
  border-style: solid;
  border-width: 0.25rem 0 0 0.25rem;
  border-color: transparent transparent transparent $braceColor;
}

.planBrace:before {
  left: -0.25rem;
  border-style: solid;
  border-width: 0 0 0.25rem 0.25rem;
  border-color: transparent transparent $braceColor transparent;
}

.planBrace:hover {
  z-index: 100;
}

.spacer {
  width: 100%;
  height: 100%;
}
