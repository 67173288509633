.modal {
  width: 40.5rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 1.125rem 8.625rem;
  border-top: 1px solid #D3D3D3;
}

.row, .sectionTitle {
  display: flex;
  align-items: center;
  padding: 0.875rem 1.25rem;
}

.header {
  display: flex;
  align-items: center;
  padding: 0 1.25rem .35rem;
  font-weight: 500;
  border-bottom: 1px solid var(--color-border-primary);
}

.sectionTitle {
  background: #F8F8F8;
}

.sectionCount {
  font-weight: 600;
  margin-left: 0.25rem;
}

.approver {
  display: flex;
  align-items: center;
}

.userNameCol {
  width: 25%;
}

.iconCol {
  margin-left: auto;
}

.calendar {
  padding: 0;
  border: none;
  cursor: default;
}