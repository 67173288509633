$emptyColor: #FF7499;
$filledColor: var(--color-border-primary);

.datesPlate {
  border-radius: .3125rem;
  padding: .125rem .775rem .125rem .1875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: .35rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: .75rem;
}

.actions hr {
  height: 1rem;
  width: 1px;
  margin: 0;
  border: none;
  border-radius: .125rem;
  background-color: #A8A8A8;
}

.icon {
  opacity: .2;
  pointer-events: none;
}

.iconActive {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

.calendarInput {
  padding: .1875rem .4rem .25rem .3125rem !important;
  height: auto !important;
  min-width: 8.375rem;
}

.calendarInputIcon {
  margin-left: auto;
}

.calendarInputEmpty {
  border: 1px solid $emptyColor;
}

.calendarInputFilled {
  border: 1px solid $filledColor;
}

.modalPortal {
  z-index: 99999;
}

.infoContent {
  padding: 1.3rem .9rem .5rem 1.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1.2rem;
  flex-wrap: nowrap;
}

.infoContentElement {
  display: flex;
  flex-direction: column;
  row-gap: .25rem;
  position: relative;
  &:last-child{
    padding-left: .75rem;
    border-left: 1px solid var(--color-border-field16);
  }
}

.infoContentElementTitle {
  font-size: 1.1875rem;
  font-family: var(--title-font);
}

.infoContentElementSubtitle {
  font-size: .8125rem;
  color: var(--color-font-secondary1);
}

.popoverAnchor {
  display: flex !important;
}