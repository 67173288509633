.container {
  max-width: 80vw;
  max-height: 80vh;
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    will-change: transform;
    max-width: 80vw;
    max-height: 80vh;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
