@import "src/scss/mixins/sliceTextByLines";

.item {
  position: relative;
  display: grid;
  grid-template-columns: 45% 23% 15% 16%;
  align-items: center;
  padding: .3125rem 0 .3125rem 1.226rem;
  margin-bottom: 0.375rem;
  border: 1px solid transparent;
  width: calc(100% - 2.526rem);

  &.filled {
    border-radius: 0.938rem;
    padding: 0.875rem 0 0.875rem 1rem;
    background-color: var(--color-background-field6);
  }

  &.active {
    background-color: rgb(226, 250, 250);
    border-color: #40cbcb;
  }
}

.title {
  font-size: 1rem;
  padding-right: 1.25rem;
  @include slice-text-by-lines(2);
}

.count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  padding: 0 1.5rem 0 .5rem;
  column-gap: 0.875rem;
  & > span{
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.countInput {
  width: 60%;
  flex-shrink: 0;
  & input {
    background-color: white;
  }
}

.buttonActive {
  background-color: #40cbcb;
}

.textCount {
  margin-right: 0.25rem;
}

.centered{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.justification {
  color: #4FB2ED;
  background-color: #e6f4fc;
  border-radius: 50px;
  padding: 0.2rem 0.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 12rem;
  display: flex;
  align-items: center;
}

.titleContainer{
  display: flex;
  flex-direction: column;
  row-gap: .3125rem;
}