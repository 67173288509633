.row {
  grid-template-columns: 5% 34% 10% 8% 4% 16% 10% 13%;
  z-index: 1 !important;
  & > *:nth-child(n + 3) {
    padding-left: 0.6875rem;
    padding-right: 0.6875rem;
  }
}

.rowWithoutApproving {
  grid-template-columns: 5% 54% 11% 8% 10% 12%;
}

.centered {
  text-align: center;
  justify-content: center;
  padding-right: 2rem;
}
