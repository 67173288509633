.groupAmounts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
  margin-bottom: 1rem;

  .title {
    color: var(--base-text-primary, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 125% */
    align-self: stretch;
  }

  .amountsContainer {
    display: flex;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    margin-top: 0.63rem;

    .amountItem {
      display: flex;
      width: 10.625rem;
      padding: 0.375rem 0.75rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;

      border-radius: 0.5rem;
      background: var(--gray-gray-1, #FAFAFA);

      &.isEditing {
        background-color: #fff;

        .inputInner {
          align-self: stretch;
          padding: 0.5rem 0.625rem 0.5rem 0.75rem;
          border-radius: 0.375rem;
          border: 1px solid var(--blue-blue-accent, #4FB1EB);
          background: var(--base-white, #FFF);
          color: var(--base-text-secondary, #696969);
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.25rem; /* 125% */
        }
      }

      .title {
        color: var(--base-text-secondary, #696969);
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5rem; /* 150% */
        align-self: stretch;
      }

      .value {
        color: var(--blue-blue-accent, #4FB1EB);
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5rem; /* 150% */
        align-self: stretch;
      }
    }
  }
}