.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 0.6875rem 2rem;

  border-radius: 0.75rem;
  background: var(--gray-gray-2, #F4F4F4);

  margin-top: 0.5rem;

  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  .titleContainer {
    flex-grow: 1;
  }

  .title {
    color: var(--base-text-primary, #000);
    font-family: Montserrat, sans-serif;
    font-style: normal;
    line-height: normal;
  }


  .divider {
    width: 0.125rem;
    height: 2.5rem;

    border-radius: 0.0625rem;
    background: #C9C9C9;

    margin: 0 1.34375rem;
  }

  &:hover {
    background: var(--blue-blue-accent, #4FB1EB);

    .title {
      color: #fff;
    }

    .mark {
      background-color: #fff;
      border-color: #fff;
    }

    .divider {
      background-color: #fff;
    }

    .budget {
      color: #fff;
    }

    .budgetTitle {
      color: #fff;
    }

    .checkbox {
      label:global(.checkbox) {
        border-color: #fff;
      }
    }

    .actionBlock {
      display: flex;
      align-items: center;
    }
  }

  .info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2.5rem;
  }

  .budget {
    display: flex;
    width: 10.6rem;
    flex-direction: column;
    align-items: flex-start;

    &.lessWidth {
      width: 10rem;
    }
  }
}

.checkbox {
  margin-right: 1rem;

  :global(.checkbox) {
    margin-left: 0;
  }
}

.confirmSelect {
  margin-left: 1rem;
}

.actions {
  display: flex;
}

.actionsMargin {
  margin-right: 1.625rem;
}

.actionBlock {
  display: none;
}


.amountBlock {
  align-items: flex-start;
}


.inputContainer {
  margin-right: 5rem;
}