.button {
  height: 2.5rem;
  padding: 0 1.5rem;
  white-space: nowrap;
  border-radius: 10px;
  background: #4fb2ed 0 0 no-repeat padding-box;
  color: #fff;
  font: normal normal 1.125rem/1.375rem var(--title-font);
  display: flex;
  align-items: center;
  gap: 13px;
  position: relative;
  &:disabled {
    cursor: default;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingText {
  opacity: 0;
}

.buttonQuaternary {
  border: none;
  background-color: #f5f5f5;
  color: #000000;
}

.buttonTertiary {
  border: 1px solid;
  background: #fff;
  color: #000000;

  &:active {
    border-color: #000;
    background: #e5e5e5;
  }

  &:disabled {
    opacity: 0.6;
  }
}

.buttonSecondary {
  border: 1px solid #4fb2ed;
  background: #fff;
  color: #000000;

  &:hover {
    border-color: #4fb2ed;
  }

  &:active {
    border-color: #000;
    background: #e5e5e5;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.buttonPrimary {
  border-radius: 10px;
  background: #4fb2ed;
  color: #fff;

  &:hover {
    background: #6bc0f2;
  }

  &:active {
    background: #5aa0ef;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.buttonPrimaryEmpty {
  border: 1px solid #4fb2ed;
  background: none;
  color: #000;

  &:disabled {
    background: grey;
  }
}

.buttonPrimaryBig {
  &:hover {
    background: #6bc0f2;
  }

  &:active {
    background: #5aa0ef;
  }

  &:disabled {
    background: grey;
  }
}

.buttonError {
  background: #eb4f7f;
  color: #fff;

  &:hover {
    background: #db4c78;
  }

  &:active {
    background: #c6466d;
  }
}

.buttonDanger {
  background: var(--color-danger);
  color: #fff;

  &:hover {
    background: var(--color-danger);
  }

  &:active {
    background: #c6466d;
  }
}

.big {
  height: 2.5rem;
  font-size: 1.12rem;
}

.medium {
  height: 2.5rem;
  padding: 0.571rem 2.857rem;
}

.small {
  font-size: 0.813rem;
  padding: 0.625rem 1.188rem;
  height: 1.875rem;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.buttonLoading {
  opacity: 0.7;
}
