.root {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  padding-right: 3rem;

  svg {
    height: 1.375rem;
  }
}

.divider {
  border-radius: 1.5px;
  opacity: 0.5;
  background: #4fb1eb;
  width: 2px;
  height: 1.375rem;
}

.deadline {
  border-radius: 14px;
  background: rgba(0, 0, 0, 0.1);
  height: 1.75rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 0 1rem;
}
