.container {
  margin-top: 1rem;

  .blockTop {
    width: 100%;
    background-color: #f4f4f4;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;

    .boxInput {
      display: flex;
      align-items: center;
      width: 50%;

      input {
        margin-left: 1rem;
        width: 100%;
        background-color: white;
      }
    }

    /* .boxBtn {
      cursor: pointer;
      width: 3.375rem;
      height: 2.5rem;
      border-radius: 5rem;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    } */
  }
}

.actions > div {
  background-color: #fff;
  transform: scale(1.2);
}
