.arrowButtonContainer {
  height: 3.25rem;
  position: sticky;
  top: 50%;
  left: 100%;
  transform: translate(calc(50% + 1px), 50%);
  width: 1.75rem;
  /* Выше расценок, даже :hover */
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2px;
  cursor: pointer;
  &.expandOnly {
    .arrowRight {
      border-radius: 0.75rem;
    }
  }
  &.collapseOnly {
    .arrowLeft {
      border-radius: 0.75rem;
    }
  }
}

.arrowButton {
  background-color: #4fb1eb;
  height: 3.25rem;
  width: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrowLeft {
  border-radius: 0.75rem 0 0 0.75rem;
}

.arrowRight {
  border-radius: 0 0.75rem 0.75rem 0;
}

.barier {
  width: 2px;
  background-color: #fff;
  position: absolute;
  height: 100%;
  /* Выше расценок, даже :hover */
  z-index: 3;
  pointer-events: none;
}

.rigthFirst {
  right: calc(15rem - 4px);
}

.rigthSecond {
  right: calc(22rem - 4px);
}

.rigthDays {
  right: calc(7rem - 4px);
}

.icon {
  z-index: 2;
}
