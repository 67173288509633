.list {
  display: flex;
  padding-top: 1.375rem;
  flex-wrap: wrap;
  align-items: flex-start;
}

.paysHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;

  & span {
    display: block;
    flex-basis: 11.875rem;
    margin-right: 1.06rem;
  }
}

.container {
  position: relative;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  padding: 0.625rem;
  margin-right: 0.75rem;
  width: 15.6rem;
  margin-bottom: 1.375rem;
  height: 18.5rem;

  &:last-child {
    margin-right: 0;
  }
}

.headline {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.313rem;

  .text {
    font: normal normal 400 1.125rem/1.25rem var(--title-font);
  }
}

.calendar {
  margin-left: 0.5rem;
  font: normal normal 500 1.125rem/1.25rem var(--title-font);

  &.disabled {
    border: none;
    padding: 0.7rem;
    margin: 0;
    cursor: default;
  }
}

.isPaid,
.isWaiting {
  padding: 3px 10px 4px 7px;
  border-radius: 0.5rem;
  background: #9870d0;
  color: azure;
  width: fit-content;
  font: normal normal 0.75rem/1rem var(--title-font);
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
}

.isWaiting {
  background: #fda4bc;
}

.upd {
  display: flex;
}

.terms,
.upd {
  margin-bottom: 0.75rem;
}

.terms,
.amount,
.textUpd {
  font: normal normal 400 1rem/1.125rem var(--title-font);
}

.amount {
  margin-bottom: 1rem;
}

.moreTypes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2.8rem;
}

.more {
  color: #c6c6c6;
  font: normal normal 400 2.3rem/2.8rem var(--title-font);
  text-align: center;
}

.btn {
  width: 100%;
  text-align: center;
  background: #4fb2ed;
  color: #fff;
  border-radius: 4px;
  height: 2rem;
  line-height: 2rem;
}

.hide {
  display: block;
  margin-left: 2.375rem;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-decoration-line: underline;
  color: #4fb2ed;
}

.fileLink {
  display: flex;
  align-items: center;
  height: 2rem;
}

.pay {
  margin-top: 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  position: absolute;
  bottom: 1rem;

  & > label {
    margin-right: 0.6rem;
  }
}

.disabledPay {
  pointer-events: none;
  opacity: 0.7;
  display: flex;
}

.selectWidth {
  width: 13.25rem;
}

.isPaidExtra {
  padding: 3px 10px 4px 7px;
  border-radius: 8px;
  background: #9870d0;
  color: azure;
  font-size: 0.75rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.textUpd {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
