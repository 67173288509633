.modal {
  position: relative;
  transform: translate3d(0, 0, 0);
  width: 70rem;
  z-index: 9999999998;
}

.header {
  padding: 1.5rem 1.5rem 0 !important;
  border-radius: 15px;
  background-color: var(--gray-gray-1, #FAFAFA);
}

.modalTitle {
  width: 100%;
  padding-right: 1rem;
}

.total {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 4.5rem;
  width: 100%;
  padding: 1.25rem 0;
  border-top: 1px solid var(--color-border-field20);
  background-color: #fff;
  border-radius: 0;

  &.bottom {
    bottom: 0;
    border-bottom-left-radius: 0.938rem;
    border-bottom-right-radius: 0.938rem;
  }

  & span:first-child {
    display: block;
    margin-right: 0.5rem;
  }
}

.container {
  padding: 1rem 1rem 0.75rem;
}

.paymentTerms {
  width: 100%;
  margin-top: 1rem;
}
