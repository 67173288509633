.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.templateContent {
  overflow: hidden;

  @media (max-width: 1440px) {
    padding-bottom: 0.4rem;
  }
}