.container {
  width: 25rem;
  text-align: center;
  transition: 0.3s ease-out 0s;
  position: relative;
  margin-right: 1rem;

  &:active,
  &:hover,
  &:target,
  &:focus-visible,
  &:focus-within,
  &:focus {
    text-align: left;
  }

  .searchInput {
    transition: 0.3s ease-out 0s;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    font-family: var(--title-font);
    font-size: 1rem;
    height: 2.75rem;
    border-radius: 50px;
    transition: background-color 0.25s ease-in-out;
    width: 100%;
    color: #000;
    padding: 1.2rem;
    -webkit-appearance: none;
    border: 1px solid #F5F5F5;

    &:active,
    &:hover,
    &:target,
    &:focus-visible,
    &:focus-within,
    &:focus {
      background: #fff 0% 0% no-repeat padding-box;
      border: 1px solid #00B4F2;
    }
  }

  .searchIcon {
    position: absolute;
    top: 0;
    right: 1.3rem;
    height: 2.5rem;
    width: 1.3rem;
  }
}