.icon {
  cursor: pointer;
  display: block;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popover {
  padding: 4px;
  gap: 2px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  background-color: #fff;
}

.menuitem {
  cursor: pointer;
  display: flex;
  padding: 9px 12px;
  align-items: center;
  line-height: 1.25;
  border-radius: 6px;
  white-space: nowrap;
  justify-content: space-between;

  &:hover {
    color: #fff;
    background-color: #4fb1eb;
  }
}

.popoverOverlay {
  padding-top: 1rem;
  background-color: transparent;
}

.marker {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: var(--color-indicator-frostbite);
}

.indicator {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: var(--color-indicator-frostbite);
  position: absolute;
  top: -0.125rem;
  right: -0.25rem;
}

.iconWrapper {
  position: relative;
}
