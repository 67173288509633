.lineContainer {
  position: sticky;
  top: 0;
  z-index: 97;
  margin-left: 35%;
}

.bgGradient {
  position: absolute;
  top: 2.25rem;
  left: 0;
  margin-left: 35%;
  pointer-events: none;
  height: inherit;
  min-height: 100vh;
}
