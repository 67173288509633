.header {
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid #ebebeb;
  padding: 1.25rem;
  cursor: pointer;

  & svg {
    margin-right: 0.5rem;
  }

  &:last-child {
    border-bottom: 1px solid #ebebeb;
  }
}

.expanded {
  & svg {
    transform: scaleY(-1);
  }
}

.title {
  font-weight: 600;
}

.add {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4fb1eb;
  font-weight: 500;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  padding-bottom: 2rem;
}
