.head {
  top: auto;
  margin-bottom: 0;
}

.rowContainer {
  height: 3rem;
  background-color: var(--color-background-field17);
  margin-bottom: 0;
  border-radius: initial;

  &.whiteBackground {
    background-color: #fff;
  }
}

.header {
  height: inherit;
}
