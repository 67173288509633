.container {
  padding: 1.6rem 2rem;
}

.comparison {
  margin-top: 3rem;
  margin-left: 1.62rem;
  position: relative;
}

.ordersComparisonModal {
  width: 95%;
}

.scrollX {
  overflow-x: scroll;
}

.noScrollY {
  overflow-y: hidden;
}

.comparisonRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1.875rem;
  & > button {
    margin-left: 1.875rem;
  }
}

.productTable, .comparisonRow{
  font-size: 1rem;
  font-family: var(--title-font);
}
