.row {
  display: grid;
  grid-template-columns: 73fr 15fr 29fr 29fr 4fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.removeIcon{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
}

.disabled {
  pointer-events: none;

  & .removeIcon {
    opacity: 0;
  }
}

.alert {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    margin-top: 10px;
    margin-bottom: 10px;
  }


}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -19px;
  margin-right: -19px;
  border-top: 1px solid #D3D3D3;
  padding-top: 16px;

  & button {
    display: flex;
    width: 195px;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #000000;

    &:first-child {
      margin-right: 32px;
      background: #EB4F8B;
      color: #fff;
      border: none;
    }
  }
}