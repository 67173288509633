@import "../../../../../../scss/dimensions";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  border-radius: 1rem;
  flex-direction: column;
  align-items: center;

  .button {
    width: 100%;
    padding: 0;
    border: 0;
    border-radius: 1rem;
    background-color: var(--color-background-main);
    border-color: var(--color-border-primary);
  }

  .title {
    height: 2.37rem;
    width: 15.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 1rem;
    background-color: var(--color-background-primary);
    color: var(--color-font-hover);
    padding: 0 1rem;
  }
}

.subTitle {
  font: normal normal normal 1rem/1.25rem var(--main-font);
  opacity: 0.6;
  margin-bottom: 0.4rem;

  @include breakpoint-to($laptop) {
    font: normal normal normal 0.8rem/1rem var(--main-font);
  }
}

.btn {
  width: 100% !important;
  height: 2.375rem;
  display: flex;
  justify-content: center;
  font-size: 1rem !important;
  flex-shrink: 0;
  margin-bottom: 0.75rem;
}

.number {
  text-align: center;
  font: normal normal 300 1.125rem/1.375rem var(--title-font);
}

.hidden {
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
}

.border {
  width: 65%;
  height: 6px;
  background-color: var(--color-border-primary);
  position: absolute;
  border-radius: 1rem;
  left: 19%;
  bottom: -0.75rem;
}
