.container {
  width: 72rem;

  .block1 {
    padding: 1.5rem;
    padding-top: 0;
  }

  .block1Title {
    display: flex;
    justify-content: space-between;

    span {
      font: normal normal 400 1.375rem var(--main-font);
    }
  }

  .block1btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.tableHeaderClass {
  font: normal normal 500 1rem var(--main-font);
  color: black;
}

.tableRowClass {
  font: normal normal 400 1rem var(--title-font);
  color: black;

  & td:first-child {
    padding-left: 1rem;
  }

  & td:last-child {
    padding-right: 1rem;
  }
}

.errorName {
  margin: 0.5rem 0;
}

.download {
  text-decoration: none;
}

.closeIcon {
  cursor: pointer;
}

.sendBtn {
  padding: 0.375rem 1.25rem;
  line-height: 1rem;
  height: 2.5rem;
  font-size: 1.12rem;
  font-weight: 400;
  background-color: #4fb2ed;
  color: #ffffff;
  margin-right: 1rem;

  &:hover {
    background-color: #4fb2ed;
    color: #ffffff;
  }
}

.tableTextBold {
  font-size: 1rem;
  color: #000;
  font-weight: 500;
}

.list {
  max-height: 100%;
  overflow-y: auto;
  min-height: 37.5vh;
  max-height: 60vh;

  & thead {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #fff;
  }
}
