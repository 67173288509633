@use 'sass:color';

.container {
  padding: 1.125rem;
  background-color: #FAFAFA;
  border: 1px solid #EBEBEB;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .title {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: #696969;
  }
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.actionsDescription {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-right: 1rem;
  color: #696969;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  flex-shrink: 0;
}

.addButton {
  display: flex;
  align-items: center;
  background-color: #EBEBEB;
  border-radius: 0.375rem;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  padding: 0.3125rem 0.875rem 0.3125rem 0.625rem;
  transition: background-color .2s ease;

  &:hover {
    background-color: color.adjust(#EBEBEB, $lightness: -5%);
  }
}

.closeOrSaveButton {
  margin-left: 2.5rem;
  display: flex;
  align-items: center;
  width: 4.375rem;
  height: 2.125rem;

  button {
    border: none;
    background-color: #4FB1EB;
    transition: background-color .2s ease;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      background-color: color.adjust(#4FB1EB, $lightness: -5%);
    }

    &.save {
      border-radius: 1.875rem 0 0 1.875rem;
      justify-content: flex-end;
    }

    &.close {
      border-radius: 0 1.875rem 1.875rem 0;
      justify-content: flex-start;
    }

    .spinnerContainer {
      width: 1.2rem;
      height: 1.2rem;

      .spinner {
        border-width: 0.2rem;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.divider {
  height: 1px;
  width: 94.3161%;
  margin-left: auto;
  background: #EBEBEB;
}

.resourcesRows {
  margin-top: 0.75rem;
  position: relative;

  &:before {
    content: '';

    position: absolute;
    top: -1rem;
    left: 1.25rem;
    bottom: 1.2rem;

    width: 1.37rem;
    border-radius: 0 0 0 0.5rem;
    border: none;
    border-left: 3px dashed #9AD9FF;
    border-bottom: 3px dashed #9AD9FF;
    background-clip: padding-box;
  }
}
