.tableWrapper {
  & thead {
    & th {
      border-bottom: 1px solid var(--color-background-primary);
      padding-bottom: 0.5rem !important;
    }
  }
}

.tableRowClass {
  background-color: #ffffff;
  color: #000;
  font: normal normal 400 1rem var(--title-font);
  height: 3.875rem;

  & td:nth-child(1) {
    text-align: center;
  }

  & td {
    border-top: none !important;
    border-bottom: 1px solid #e1e8ee;
  }
}

.tableHeaderClass {
  font: normal normal 500 1rem var(--main-font);
}

.labelBox {
  display: flex;
  margin: 0.5rem 0;
  flex-wrap: wrap;

  .blueLabel {
    color: #4fb2ed;
    background-color: #e6f4fc;
    border-radius: 50px;
    padding: 0.2rem 0.6rem;
    margin-right: 1rem;
    white-space: nowrap;
    cursor: pointer;
  }

  .greyLabel {
    color: #808080;
    background-color: #ededed;
    border-radius: 50px;
    padding: 0.2rem 0.6rem;
    white-space: nowrap;
    height: min-content;
    cursor: pointer;

    svg {
      margin-right: 0.2rem;
    }
  }
}

.lastRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .iconBox {
    cursor: pointer;
    width: 1.35rem;
  }
}

.input {
  height: 2.18rem;
  width: 7.8rem;
  border: none;
  background-color: #efefef;
  border-radius: 0.5rem !important;
  color: var(--color-font-main);
  padding-left: 1rem;
}

.settingsIcon {
  cursor: pointer;
}

.tableTextBold {
  font-size: 1rem;
  color: #000;
  font-weight: 500;
}

.innerTableContainer {
  margin: 0 4.88rem;
}
