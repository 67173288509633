.approve {
  padding: 0.5rem 1rem 1rem;
}

.chooseChapter {
  padding: 1rem 1rem 0.5rem;
  border-bottom: 1px solid #C7C7C7;
}

.approveBlock {
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid var(--color-border-primary);
  width: 100%;
}

.actionBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.button1 {
  margin-right: 1rem;
}