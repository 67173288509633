.wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.monthSlider {
  position: relative;
  flex-grow: 1;
  height: 100%;
  line-height: 2.81rem;
}

.monthSlider__inner {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(12, 1fr);
  font-size: 1rem;
  padding: 0 0.5rem;
  height: 100%;

  & > div {
    text-align: center;
  }
}

.monthSlider__draggable {
  position: absolute;
  height: 100%;
  border: 2px solid #5bb6ec;
  background-color: rgba(255, 255, 255, 0.48);
  border-radius: 3px;
  cursor: move;
  transition: width 0.5s;
}

.monthSlider__left {
  position: absolute;
  height: calc(100% + 2px);
  width: 10px;
  background: #5bb6ec;
  top: -1px;
  left: -1px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.monthSlider__right {
  position: absolute;
  height: calc(100% + 2px);
  width: 10px;
  background: #5bb6ec;
  top: -1px;
  right: -2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.monthSlider__stick {
  position: absolute;
  height: 16px;
  top: 50%;
  left: 50%;
  width: 6px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
}

.arrow {
  height: inherit;
}

.month {
  text-align: center;
}
