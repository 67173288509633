.fileBlock {
  width: 30rem;
  padding: 0.286rem 0.286rem 0.286rem 0.643rem;
  border: 1px dashed #b9b9b9;
  border-radius: 0.357rem;

  &.isClose .headerText::before {
    transform: rotate(180deg);
  }

  .header,
  .headerText {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
  }

  .header {
    justify-content: space-between;
    margin-bottom: 0.714rem;
  }

  .headerText {
    font: normal normal 1.125rem/1.375rem var(--main-font);
    margin: 0;
    cursor: pointer;

    &::before {
      content: "";
      margin-right: 0.5rem;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--color-border-field10);
    }
  }

  .hiddenInput {
    display: none;
  }

  .button {
    padding-left: 3.571rem;
    padding-right: 2.286rem;
  }

  .buttonIcon {
    position: absolute;
    left: 0.857rem;
  }

  .empty {
    color: var(--color-font-secondary6);
    text-align: center;
  }
}
