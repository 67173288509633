.container {
  min-width: min-content;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  .content {
    padding: 0 1.8rem;
    overflow-y: scroll;
    overflow-x: visible;
    // height: calc(100% - 78px);
    flex: 1;
  }

  .fieldCalendar {
    width: 100%;
  }

  .fieldRow {
    display: flex;

    .fieldBlock {
      width: 50%;
    }
  }

  .fieldValue {
    color: #707070;
    opacity: 0.8;
    padding: 0;
    width: 100%;
    font: normal normal normal 0.9em var(--main-font);
  }

  .fieldTitle {
    font: normal normal bold 1rem var(--main-font);
    color: #000000;
    margin: 1.5rem 0 0.5rem 0;
    white-space: nowrap;
    display: flex;
    align-items: center;
    // font: 0.9em var(--main-font);
    // font-weight: bold;
  }

  .plusIconBlock {
    display: flex;
    align-items: center;
  }

  .titleBlock {
    padding: 1rem 1.8rem 0;

    .title {
      font: normal normal bold 1.3em var(--main-font);
      color: #000000;
    }

    .subTitle {
      color: #707070;
      padding: 0.5rem 0;
      opacity: 0.8;
      font: normal normal normal 0.9em var(--main-font);
    }
  }

  .blockTitle {
    font: normal normal bold 14px var(--main-font);
    color: #000000;
  }
}

.sliderClassName {
  width: 34rem;

  & > div {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
}

.actionBlock {
  border-top: 1px solid #bcbcbc;
  padding: 10px 31px;
  // position: absolute;
  width: 100%;
  bottom: 0;
  text-align: right;
  background-color: #fff;
  border-radius: 0 0 0 20px;
  display: flex;
  justify-content: center;
}

.icon {
  width: 1.5rem;
  cursor: pointer;
  padding: 1rem 0;
  margin-right: 1rem;
}

.multipleFieldRow {
  display: flex;
  align-items: flex-start;
  column-gap: 1rem;

  & > * {
    flex-grow: 1;
  }
}

.rightCalendar {
  transform: translateX(70%) !important;
}

.closureDatesOptions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 1rem;

  padding: 1rem 0.275rem 0.5rem;

  & > div {
    padding: 0.275rem 0.5rem;
  }

  &:before {
    content: "Выберите дату";
    color: #707070;
    font-size: 0.625rem;
    position: absolute;
    top: 0;
    left: 0.75rem;
  }
}

.dinamicsContainer {
  display: flex;
  justify-content: space-between;
  margin-right: 5rem;
  padding-top: 1rem;

  div {
    p {
      margin-top: 0.2rem;
    }
  }
}

.dinamics {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 0 1.8rem 0.5rem;
  margin-top: 1rem;

  span {
    font-weight: 600;
    color: #696969;
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
  }

  .fieldTitle {
    margin-bottom: 0.875rem;
  }
}

.containerPlan {
  display: flex;
  justify-content: flex-start;

  .fieldTitle {
    margin-top: 1.5rem;
  }

  .leftSide {
    padding-right: 1rem;
    width: 52%;
  }

  .rightSide {
    // width: 100%;
    width: 48%;
    padding-top: 1.375rem;

    .planText {
      margin: 1.25rem 0 0 0;
      font-weight: 500;
    }
  }

  span {
    font-weight: 600;
    color: #696969;
    margin-top: 1.5rem;
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
    width: 10%;
  }
}

.bigFieldTitle {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 1rem;
}
