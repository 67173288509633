.root {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  width: 27rem;
}

.meta {
  font-size: 13px;
  opacity: 0.5;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.metaRight {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.filesCount {
  position: absolute;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
}

.text {
  position: relative;
  padding-right: 1.5rem;
  padding-left: 1.25rem;
  padding-top: 0.375rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  border: 1px solid #b2b2b2;

  /* &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 1rem;
    height: 1rem;
    border: 1px solid #b2b2b2;
    background-color: #fff;
    transform: rotate(45deg);
    left: -0.5rem;
    top: 0.5rem;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 0.7rem;
    height: 1.5rem;
    background-color: #fff;
    left: 0.05rem;
    top: 0.275rem;
  } */
}

.mine {
  flex-direction: row-reverse;

  .text {
    background: #e8f7ff;
    border: 1px solid #4fb1eb;
  }
}

.inputWrapper {
  background: #e9e9e9;
  padding: 0.75rem;
  width: 45.5rem;
  position: absolute;
  bottom: 4.25rem;
  left: 0;
  padding-right: 1.5rem;
  padding-left: 1rem;

  textarea {
    background-color: #fff;
  }

  .send {
    position: absolute;
    top: 50%;
    transform: translateY(-30%);
    right: 2rem;
  }

  .clip {
    position: absolute;
    right: 0;
    top: 1rem;
    right: 3.25rem;
    width: 0.625rem;

    .attachedCount {
      font-size: 0.75rem;
      height: 0.875rem;
      display: flex;
      align-items: center;
      background-color: #4fb2ed;
      border-radius: 0.5rem;
      padding: 0 0.25rem;
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
      transform: translateX(100%);
    }

    svg {
      width: 0.625rem;
    }
  }
}

.list {
  overflow-y: auto;
  margin-right: -3.5rem;
  padding-right: 3.5rem;
  scroll-behavior: auto !important;
  display: flex;
  flex-direction: column-reverse;
  padding-top: 1rem;
  /* scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  } */
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  img {
    width: 5.5rem;
  }
}

.actions {
  div {
    background-color: transparent;
  }
}

.textarea {
  padding-right: 1.5rem;
}
