.breakBubble {
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #707070;
  padding: 0 0.625rem;
  border-radius: 0.625rem;
  transform: translate(0, 0.35rem);
  cursor: default;
  user-select: none;
}

.breakBubbleHighlighted {
  border-color: #7061ea;
}
