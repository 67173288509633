.content {
    width: 35rem;
    border: none;

    & b {
        font-weight: 700;
    }

    & :global(.modal-body) {
        padding: 1.188rem;
    }
}

.modal {
    & header {
        padding: 1.188rem !important;
    }
}


.field {
    margin-top: 0.5rem;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}