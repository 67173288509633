.viewers {
  padding: 1rem 3.714rem;
  margin-bottom: -1.5rem;
  font: normal normal 1.125rem/1.375rem var(--title-font);

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 1.286rem;
  }

  .headerText {
    margin: 0 1rem 0 0;
    font: normal normal 400 1.125rem/1.375rem var(--title-font);
  }

  .elements {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}