.modal {
  width: 60rem;
}

.head {
  border-bottom: 1px solid #d8d8d8;
  padding: 1rem 1.4rem 0.8rem;
  position: sticky;
  z-index: 2;
  background-color: #fff;
  top: 0;
}

.headControls {
  display: flex;
  align-items: center;
}

.inputPair {
  margin-right: 2rem;
  display: flex;
  align-items: center;

  & label {
    margin-right: 1rem;
  }
}

.input {
  width: 14rem;
}

.bottom {
  display: flex;
  column-gap: 1rem;

  & > button {
    width: 14rem !important;
    justify-content: center;
  }
}

.spinner {
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  display: block;
}

.pendingText {
  text-align: center;
  margin-top: 5rem;
}

.list {
  padding: 0.5rem;
  padding-bottom: 1rem;
}

.loading {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fading 0.5s linear;
}

.firstLine {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.constrControlBtn {
  margin-left: auto;
  margin-right: 1rem;
}

@keyframes fading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
