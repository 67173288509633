.grid {
  display: grid;
  gap: 2rem;
}

.row {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.select {
  margin-bottom: 1.5rem;
}

.label {
  font-weight: 500 !important;
  color: var(--color-font-main) !important;
}
