.title {
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 30px;
  cursor: pointer;

  & img {
    margin-left: 2rem;
  }
}

.firstline {
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  & img {
    margin-left: 2rem;
    cursor: pointer;
  }
}

.nameInput {
  width: 409px;
  height: 100%;
  border-radius: 12px;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  color: #000000;
  background: #EFEFEF;
}

.form {
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  grid-template-columns: 41fr 21fr 17fr 17fr 17fr 43fr;
  column-gap: 1rem;

  & input {
    width: 100% !important;
    height: 2.64rem;
    padding: 0.7rem 1rem;
    border: 1px solid var(--color-border-field18);
    border-radius: 0.5rem;
  }
}