.tables {
  border-collapse: separate;
  border-spacing: 0 0.3rem;
  width: 100%;

  td {
    padding: 0 1rem;
  }

  th {
    padding: 0 1rem;
  }

  tr th {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  tr td:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  tr td:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  thead {
    position: sticky;
    top: 2.7rem;
    z-index: 2;
    background-color: #fff;
    font-family: var(--title-font);

    & th {
      border-radius: 0 !important;
      padding-top: 1rem;
      border-bottom: 1px solid #4fb2ed;
    }
  }
}

.tableRowClass {
  background-color: #f4f4f4;
  color: #000;
  font-size: 13px;
  height: 3.875rem;
  font-family: var(--title-font);
  font-weight: 400;

  .cellNumber {
    color: #000;
  }

  &:hover {
    background-color: #4fb2ed;
    color: #fff;

    .icon svg > path,
    .link svg > path {
      fill: #fff;
    }

    .icon,
    .link,
    .cellNumber {
      color: #fff;
    }
  }
}

.titleBlockSum {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2rem;
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tableHeaderClass {
  font-family: var(--title-font);
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  color: #000;
  white-space: nowrap;

  .title {
    margin-right: 0.5rem;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #000;

  span {
    border-bottom: 1px solid #000;
    margin-right: 0.8rem;
    color: #000;
  }

  svg {
    margin-left: 0.2rem;
    cursor: pointer;
  }
}

.MoreButton {
  margin-top: 2rem;
}

.stickyTabs {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.overFlowLine {
  background: #fff;
  width: 90%;
  height: 1.88rem;
  position: absolute;
  left: 51%;
  transform: translateX(-50%);
  top: 3.7rem;
  z-index: 10;
}

.link {
  font-family: var(--title-font);
  font-weight: 400;
  font-size: 1rem;
  padding: 0.36rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-font-main);

  svg > path {
    fill: var(--color-font6);
  }
}

///////

.files {
  color: var(--color-font6) !important;
}
