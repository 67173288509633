.container {
    display: flex;
    align-items: center;
    height: 2rem;
    border-radius: 1rem;
    padding-right: 1rem;
    min-width: 8rem;
}

.to_pay {
    background: #bef7da;

    .marker {
        background-color: #6fc79b;
    }
}

.todo {
    background: #ccecff;

    .marker {
        background-color: #4eb1ea;
    }
}

.paid {
    background: #c5f7be;

    .marker {
        background-color: #008f47;
    }
}

.marker {
    width: 1rem;
    height: 1rem;
    background-color: #6fc79b;
    border-radius: 50%;
    margin: 0 0.675rem;
}
