$supplyColor: #ef9d1c;
$supplyBubbleColor: #fff2de;
$paymentColor: #9870d0;
$paymentBubbleColor: #f4effc;
$taskColor: #73c0e3;
$taskBubbleColor: #e5f7ff;

.eventItem {
  position: relative;
  border-radius: 0.5rem;
  padding: 0.6875rem 0.75rem 1rem;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 1.2rem;

  & a {
    text-decoration: underline;
  }
}

.eventStatus {
  position: absolute;
  top: 0;
  right: 0.6rem;
  transform: translateY(-50%);
  color: white;
  border-radius: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.3rem 0.74rem 0.3rem 0.3125rem;
  font-family: var(--title-font);
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 0.8125rem;
  letter-spacing: 0;
}

.statusIcon {
  width: 1rem;
  height: 1rem;
}

.taskStatus {
  background-color: $taskColor;
}

.notPayedPayment {
  background-image: repeating-linear-gradient(135deg, #aa80e6, #aa80e6 10px, #9870d0 10px, #9870d0 20px);
}

.payedPayment {
  background-color: $paymentColor;
}

.payedSupplies {
  background-color: $supplyColor;
}

.notPayedSupplies {
  background-image: repeating-linear-gradient(135deg, #f6ad40, #f6ad40 10px, #ef9d1c 10px, #ef9d1c 20px);
}

.eventItemName {
  font-family: var(--title-font);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: 0;
}

.supplyContainer {
  border-color: $supplyColor;

  .eventItemName {
    color: $supplyColor;
  }

  .eventEntryBubble {
    background-color: $supplyBubbleColor;
  }
}

.paymentContainer {
  border-color: $paymentColor;

  .eventItemName {
    color: $paymentColor;
  }

  .eventEntryBubble {
    background-color: $paymentBubbleColor;
  }
}

.taskContainer {
  border-color: $taskColor;

  .eventItemName {
    color: $taskColor;
  }

  .eventEntryBubble {
    background-color: $taskBubbleColor;
  }
}


$eventDataRowGap: 0.5rem;

.eventData {
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: $eventDataRowGap;
}

.eventEntry {
  position: relative;
  height: auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}

.eventEntryLabel {
  flex-basis: 15%;
}

.onlyLabel {
  flex-basis: 100%;
}

.eventEntryValue {
  flex-basis: 85%;
  display: grid;
  grid-template-columns: 40% .1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  word-break: break-all;
  padding-right: 1rem;

  & > span:first-child {
    padding-left: .5rem;
    width: auto;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.withoutBubble {
  grid-template-columns: 100%;
}

@media (screen and max-width: 1440px) {
  .eventEntryValue {
    padding-right: 0;
  }
}

.eventEntryDivider {
  background-color: #bfbfbf;
  width: 1px;
  height: calc(100% + $eventDataRowGap);
  margin: 0 1.625rem;
}

.eventEntryBubble {
  position: relative;
  border-radius: 1rem;
  padding: 0.34rem 2rem 0.34rem 0.97rem;
  width: 100%;
  justify-self: flex-end;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.eventEntryBubble:not(.withoutArrow) {
  &::after {
    content: " ";
    display: block;
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5.54px 0 5.54px 6px;
    border-color: transparent transparent transparent #070707;
  }
}

.isButton {
  cursor: pointer;
  text-decoration: underline;
}