.nav {
  margin-bottom: 0 !important;
}

.sticky {
  position: sticky;
  z-index: 3;
  background-color: #fff;
  top: 2.8rem;
}

.row {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 42% 15% 13% 15% 15%;
  &:hover {

    .remarkStatus {
      background-color: white;
      color: black;
    }
  
    .date svg {
      filter: saturate(0);
    }
  }
}

.date {
  display: flex;
  align-items: center;
  column-gap: 0.875rem;

  & svg {
    width: 1.063rem;
    height: 1.063rem;
  }
}

.headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.813rem;
}
