a {
  color: #000;

  &:hover {
    color: #000;
    text-decoration: none;
  }
}

button {
  background: none;
  border: none;
  outline: none;
}

[type="email"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
[type="area"],
textarea,
input {
  border: 1px solid var(--color-border-field18);
  border-radius: var(--input-border-radius);
  background-color: var(--input-background-color);
  transition: var(--input-transition);
  height: var(--input-height);
  width: var(--input-width);
  font-size: var(--input-fonst-size);
  color: var(--input-color);
  padding: var(--input-padding);
  -webkit-appearance: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

svg {
  vertical-align: middle;
}

label {
  display: block;
  margin-bottom: 0.35rem;
}

.ant-message {
  z-index: 999999999999;

  .anticon .anticon-check-circle {
    display: none;
  }
}

.ant-message-notice-content {
  padding: 1rem 2rem;
  font-weight: 600;
  font-size: 1.125rem;

  & span {
    display: block;
  }
}

#root {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
