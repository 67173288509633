.addFile {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  width: 6rem;
}

.disabled {
  opacity: 0.6;
  cursor: default;

  &:active {
    background: inherit;
    border-color: var(--color-background-primary);
  }
}

.iconContainer {
  width: 5.5rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--color-border-primary);
  background-color: #efefef;
}

.text {
  font: normal normal 1rem/1.25rem var(--main-font);
  color: #707070;
  text-align: center;
}

.button {
  width: 90%;
  font: normal normal 1rem/1.25rem var(--main-font);
}

.buttonIcon {
  position: absolute;
  left: 1.5rem;
}
