.audit {
  display: flex;
  flex-grow: 1;
  width: inherit;
}

.audit__listSide {
  width: 32vw;
  padding-right: 0.875rem;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.161);
  flex-shrink: 0;
  margin-top: -1.88rem;
  padding-top: 1.88rem;
  padding-bottom: 22px;
  min-height: 100%;
}

.audit__tableSide {
  padding-left: 0.625rem;
  width: calc(100% - 31.6vw);
  min-height: 100%;

  & :global(.spinnerGlobal) {
    height: 62vh;
    margin-top: calc(4.1rem + 28px);
  }
}

.sticky {
  position: sticky;
  z-index: 2;
  background-color: #fff;
  top: 1rem;

  &:before {
    content: "";
    position: absolute;
    height: 1rem;
    background-color: #fff;
    width: 100%;
    top: -1rem;
  }
}

@media screen and (max-width: 1700px) {
  .audit__listSide {
    width: 30vw;
  }

  .audit__tableSide {
    width: calc(100% - 29.6vw);
  }
}

@media screen and (max-width: 1441px) {
  .audit__listSide {
    width: 28vw;
  }

  .audit__tableSide {
    width: calc(100% - 28.6vw);
  }

  .sticky {
    & thead {
      font-size: 0.9rem !important;
    }
  }
}
