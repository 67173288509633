.sharedBrace {
  // над фоном отрезка
  z-index: 1;
  width: calc(100% - 0.22rem);
  height: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-width: 0.125rem;
  border-radius: 0;
  border-style: solid;
  border-right: none;
  border-top: none;
  pointer-events: none;
}

.sharedCircle {
  position: absolute;
  top: 0;
  left: -0.0625rem;
  transform: translateX(-50%) translateY(-50%);
  height: 1rem !important;
  width: 1rem !important;
  z-index: 1;
}
