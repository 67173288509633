.container {
  position: relative;
  height: 1px;
  width: 100%;
  border-radius: 0.5rem;
}

.btn {
  position: absolute;
  left: -1.08rem;
  top: 0.5rem;
  width: 1.1rem;
  height: 4.7rem;
  background-color: #efefef;
  border-radius: 5px;
}

.activeBtn {
  border-radius: 5px 0 0 5px;
  background-color: var(--color-background-primary);

  & svg {
    transform: scaleX(-1);

    & path {
      fill: #fff;
    }
  }
}

.content {
  height: 100%;
  overflow: hidden;

  & > div > div {
    padding-bottom: 1rem !important;
  }
}

.open {
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  height: auto;
  margin-bottom: 0.8rem;
  min-height: 5.5rem;
}

.container:not(.open){
  margin-left: -.5rem;
}