.container {
  margin-top: 1rem;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 8.2889% 8.2889% 9.4731% 9.4731%;
  position: relative;

  &.hasRemovableResources {
    grid-template-columns: 1fr 8.2889% 8.2889% 9.4731% 9.4731% 3rem;
  }
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  align-items: flex-start;
}

.fieldTitle {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #696969;
}

.inputContainer {
  width: 100%;
  position: relative;
  z-index: 2;

  .input {
    &::placeholder {
      color: #A4A4A4;
    }

    &.error {
      border-color: red;
    }

    text-align: left;
  }
}

.inputIcon {
  color: #A4A4A4;
  padding-right: 0.5rem;
}

.selectContainer {
  width: 100%;

  .select {
    & ::placeholder {
      color: #A4A4A4;
    }

    &.error {
      border-color: red;
    }
  }
}

.placeholder {
  color: #A4A4A4;
}