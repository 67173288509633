.cancelOrderModal {
  width: 47.5rem;

  .body {
    margin: 2.25rem 1.5rem;
  }

  .button {
    margin-left: 1rem;
  }

  .footer {
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--color-border-field19);
    padding: 0.625rem 1.5rem;
  }
}