@import "src/scss/mixins/sliceTextByLines";

.row {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  min-height: 5.44rem;
  height: auto !important;
  cursor: pointer;
  display: grid;
  grid-template-columns: 41.5% 13% 12% 10% 15.5% 8%;
}

.inGroupRow {
  grid-template-columns: 41.5% 13% 12% 10% 15.5% 8%;
  padding-left: 0;
  padding-right: 0;
}

.inGroup {
  background-color: transparent !important;
  border-radius: 0;
  border-bottom: 1px solid #aaa;

  &:last-child {
    border-bottom: none;
  }
}

.rowContainer {
  min-height: 5.44rem;
  height: auto !important;
}

.info {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.3rem 0;
  height: 100%;
}

.name {
  white-space: pre-line;
  padding-right: 1rem;
  @include slice-text-by-lines(2);
}

.labels {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  flex-wrap: wrap;
  row-gap: 0.25rem;
}

.blueLabel {
  background-color: #e6f4fc;
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-primary);
    color: #fff;
  }
}

.blueLabelNonActive {
  background-color: #fff;
}

.doneRow {
  justify-content: space-between;
}

.checkFile {
  width: 2.5rem;
  position: relative;
  cursor: pointer;
}

.rowItem {
  padding: 0 0.6rem;
  position: relative;
}

.divider {
  height: 2rem;
  width: 2px;
  background: #cecece;
  align-self: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.righted {
  margin-left: auto;
}

.replacement {
  display: flex;
  &:hover {
    & path {
      stroke: #fff;
    }
  }
}

.secondCheck {
  transform: translateX(-70%);
  margin-right: -1rem;
}

.greenLabel {
  background-color: #8fe532 !important;
  color: #fff !important;
}
