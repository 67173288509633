.flexRow {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 1rem 0;
  z-index: 6;

  .showBlockUserSwitch {
    margin-right: 2rem;
  }
}

.tables {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  width: 100%;
  margin-top: 0.5rem;

  tr th {
    border-radius: 10px 0 0 10px;
  }

  tr td:last-child {
    border-radius: 0 10px 10px 0;
  }

  tr td:first-child {
    border-radius: 10px 0 0 10px;
  }

  td {
    padding: 0 1rem;
    font-size: 13px;
  }

  th {
    padding: 0 1rem;
    font-weight: 500;
  }

  thead {
    position: sticky;
    top: 2rem;
    z-index: 2;
    background-color: #fff;

    & th {
      padding-top: 1rem;
      border-radius: 0 !important;
      border-bottom: 1px solid #4fb2ed;
    }
  }
}

.tableRowClass {
  background-color: #f4f4f4;
  color: #000;
  border-radius: 10px;
  font-size: 1.125rem;
  height: 4rem;
  font-family: var(--title-font);
  font-weight: 400;

  & td:nth-child(5) {
    opacity: 0;
  }

  & td:nth-child(4) {
    padding-right: 0;
  }

  //& td:last-child{
  //  padding: 0;
  //}
  &:hover {
    background-color: #4fb2ed;
    color: #fff;

    .block svg > path {
      fill: #fff;
    }

    td:nth-child(5) {
      opacity: 1;
    }
  }
}

.btnChange {
  width: 5rem;
  height: 2.5rem;
  background: #fff;
  border-radius: 20px;
  display: flex;
  // align-items: baseline;
  justify-content: space-evenly;
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boxButtonPaginator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.addButtonClass {
  display: flex;
  align-items: center;
}

.plusIcon {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.buttonPaginator {
  background-color: #ffffff;
  border: 1px solid #4fb2ed;
  color: #000;
  width: 17.5rem;
  height: 2.25rem;
  border-radius: 20px;
  font-size: 1rem;
  text-transform: uppercase;

  &:hover {
    box-shadow: 0 0 10px #cfcfcf;
  }
}

.controls {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 0;
}

.table__titleBlock {
  display: flex;
  align-items: center;
}

.alert {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -19px;
  margin-right: -19px;
  border-top: 1px solid #d3d3d3;
  padding-top: 16px;

  & button {
    display: flex;
    width: 195px;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #000000;

    &:first-child {
      margin-right: 32px;
      background: #eb4f8b;
      color: #fff;
      border: none;
    }
  }
}

@media (min-width: 1649px) {
  .tableRowClass {
    height: 3.875rem !important;
  }
}

@media (max-width: 1449px) {
  .tables {
    thead {
      & th {
        padding-top: 1.075rem !important;
      }
    }
  }
}
