.circle {
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #ededed;
}

.tooltipText {
  padding: 0.5rem 1rem;
  text-align: center;
  color: #4fb1eb;
}

.visibilityMark {
  margin-left: 1.625rem;
}

.allHidden {
  path {
    fill: #000;
  }
}

.partHidden {
  path {
    fill: #a4a4a4;
  }
}
