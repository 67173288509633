body.osla {
  .opportun__main {
    background: linear-gradient(0deg, rgba(48, 123, 211, 0.5) 0%, rgba(95, 163, 245, 0) 100%), #5EA2F4;
  }

  .opportun__flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 122px 0 82px;
  }

  .opportun__info {
    max-width: 910px;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .opportun__info p {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    margin-top: 24px;
    margin-bottom: 80px;

  }

  .opportun__photo {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .opportun-mob__pic {
    display: none;
  }

  .planning__main {
    padding: 90px 0;
  }

  .planning__flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .planning__box {
    width: 24%;
  }

  .planning__box span {
    display: block;
    margin-top: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }

  .planning__box p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-top: 12px;
  }

  .production-planning, .supply-resources, .execution-works, .formation-documentation, .additional-features {
    padding: 120px 0 60px;
  }

  .sites-block-plan {
    background: #DFECFF;
  }

  .sites-block__title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }

  .sites-block__title h2 {
    max-width: 752px;
    width: 100%;
  }

  .menu-sites-blocks {
    max-width: 780px;
    width: 100%;
  }

  .menu-sites-blocks ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-sites-blocks ul li {
    cursor: pointer;
    padding: 9px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    height: 40px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 32px;
    font-family: 'Gotham Pro';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 6px;
    white-space: nowrap;
  }

  .menu-sites-blocks ul .active-href {
    background: #5EA2F4;
    color: #FFFFFF;
  }

  .reviews-min-block-js {
    transition: 0.8s;
  }

  .sites-block__flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 80px;
    transition: 0.8s;
    position: relative;
    z-index: 1;
  }

  .sites-block__info {
    max-width: 624px;
    width: 100%;
    margin-top: 4px;
  }

  .sites-block__text ul li {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #424345;
    position: relative;
    padding-left: 27px;
    margin-top: 16px;
  }

  .sites-block__text ul li:before {
    content: url("../img/ptich.png");
    position: absolute;
    top: 0;
    left: 0;
  }

  .sites-block__hert {
    margin-top: 40px;
    counter-reset: my-sec-counter;
  }

  .sites-block__hert ul li {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #424345;
    padding: 12px 12px 12px 35px;
    margin-top: 6px;
    position: relative;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 8px;

  }

  .sites-block__hert ul li:before {
    left: 16px;
    top: 12px;
    position: absolute;
    counter-increment: my-sec-counter;
    content: counter(my-sec-counter) ".";
    z-index: 99;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #424345;
  }

  .sites-block__photo {
    position: relative;
    display: flex;
    max-width: 752px;
    width: 100%;
    transition: 0.7s;

  }

  .sites-block__photo .sites-pic-mob {
    display: none;
  }

  .sites-before-after:before {
    content: "";
    background-image: url("../img/slaq.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -18px;
    left: -60px;
    max-width: 225px;
    width: 100%;
    height: 178px;
  }

  .sites-before-after:after {
    content: "";
    background-image: url("../img/stars111.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -70px;
    right: -117px;
    max-width: 178px;
    width: 100%;
    height: 228px;
  }

  .sites-block-silver {
    background: #EFF4FB;
  }

  .sites-block-silver .sites-block__hert ul li {
    background: #DFECFF;
  }

  .sites-block-silver .menu-sites-blocks ul li {
    background: #DFECFF;
  }

  .sites-block-silver .menu-sites-blocks ul .active-href {
    background: #5EA2F4;
  }

  .supply-resources:after, .formation-documentation:after {
    content: "";
    position: absolute;
    bottom: 82px;
    right: 62px;
    background-image: url("../img/stars2.png");
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 192px;
    width: 100%;
    height: 180px;
  }

  .execution-works:after {
    content: "";
    position: absolute;
    top: 122px;
    right: 135px;
    background-image: url("../img/star3.png");
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 219px;
    width: 100%;
    height: 231px;
  }

  .additional-features:after {
    content: "";
    position: absolute;
    bottom: 94px;
    left: 83px;
    background-image: url("../img/star4.png");
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 192px;
    width: 100%;
    height: 184px;
  }

  @media (max-width: 1800px) {
    .sites-before-after:after {
      right: -70px;
    }
  }
  @media (max-width: 1600px) {
    .opportun__photo {
      max-width: 748px;
    }
    .opportun__info {
      max-width: 645px;
    }
    .opportun__info p {
      margin-bottom: 45px;
    }
    .opportun__flex {
      padding: 100px 0 70px;
    }
    .sites-before-after:after {
      right: -32px;
      max-width: 120px;
      height: 156px;
    }
    .sites-block__info {
      max-width: 550px;
    }
    .sites-block__photo {
      max-width: 700px;
    }
    .sites-before-after:before {
      bottom: -19px;
      left: -42px;
      max-width: 190px;
      height: 154px;
    }
    .execution-works:after {
      top: 158px;
      right: 3px;
      max-width: 150px;
      height: 171px;
    }
  }
  @media (max-width: 1440px) {
    .opportun__photo {
      max-width: 660px;
    }
    .opportun__flex {
      padding: 70px 0 65px;
    }
    .opportun__info p {
      font-size: 22px;
    }
    .sites-before-after:before {
      display: none;
    }
    .sites-block__photo {
      max-width: 620px;
    }
    .sites-block__text ul li {
      font-size: 16px;
      line-height: 22px;
      margin-top: 12px;
    }
    .sites-block__hert ul li {
      font-size: 16px;
      line-height: 22px;
    }
  }
  @media (max-width: 1300px) {
    .opportun__info p {
      font-size: 20px;
      line-height: 24px;
    }
    .opportun__info {
      max-width: 520px;
    }
    .planning__box span {
      margin-top: 18px;
      font-size: 16px;
      line-height: 22px;
    }
    .sites-block__info {
      max-width: 460px;
    }
    .production-planning, .supply-resources, .execution-works, .formation-documentation, .additional-features {
      padding: 80px 0 40px;
    }
    .sites-block__flex {
      margin-top: 30px;
      margin-bottom: 50px;
    }
    .menu-sites-blocks ul li {
      padding: 8px 10px;
      font-size: 15px;
      line-height: 22px;
      margin: 0 0 0 8px;
      height: 36px;
    }
    .supply-resources:after, .formation-documentation:after {
      bottom: 27px;
      right: 4px;
      max-width: 151px;
      height: 141px;
    }
  }
  @media (max-width: 1200px) {
    .opportun__photo {
      max-width: 560px;
    }
    .opportun__flex {
      padding: 55px 0 55px;
    }
    .opportun__info p {
      font-size: 18px;
      line-height: 24px;
    }
    .opportun__info {
      max-width: 474px;
    }
    .planning__flex {
      flex-wrap: wrap;
    }
    .planning__box {
      width: 48%;
      margin-top: 30px;
    }
    .planning__main {
      padding: 40px 0 60px;
    }
    .sites-before-after:after {
      display: none;
    }
    .sites-block__info {
      max-width: 420px;
    }
    .sites-block__photo {
      max-width: 480px;
    }
    .additional-features:after {
      display: none;
    }
    .supply-resources:after, .formation-documentation:after {
      display: none;
    }
    .execution-works:after {
      display: none;
    }
    .sites-block__hert {
      margin-top: 20px;
    }
  }
  @media (max-width: 991px) {
    .opportun__info p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
      margin-top: 12px;
    }
    .opportun__photo {
      max-width: 500px;
    }
    .opportun__photo img {
      object-position: 71px;
    }
    .sites-block__title {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .menu-sites-blocks {
      margin-top: 16px;
    }
    .production-planning, .supply-resources, .execution-works, .formation-documentation, .additional-features {
      padding: 60px 0 30px;
    }
    .sites-block__flex {
      margin-top: 30px;
      margin-bottom: 30px;
      flex-direction: column;
      align-items: center;
    }
    .sites-block__info {
      max-width: 660px;
    }
    .sites-block__photo {
      max-width: 680px;
      margin-top: 30px;
    }
    .sites-block-silver .sites-block__flex {
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 760px) {
    .planning__box {
      width: 100%;
      text-align: center;
    }
    .opportun__photo {
      display: none;
    }
    .opportun-mob__pic {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .opportun-mob__pic img {
      width: 100%;
    }
    .opportun__flex {
      padding: 35px 0 15px;
    }
    .opportun__info {
      position: unset;
    }
    .opportun__info .button {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
    .planning__box p {
      max-width: 360px;
      width: 100%;
      margin: 12px auto 0;
    }
    .menu-sites-blocks {
      margin-top: 16px;
      overflow-x: scroll;
      display: flex;
      justify-content: center;
      padding-bottom: 15px;
    }
    .menu-sites-blocks::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .menu-sites-blocks::-webkit-scrollbar-thumb {
      width: 0;
      height: 0;
    }
    .planning__main {
      padding: 20px 0 40px;
    }
    .planning__box span {
      margin-top: 5px;
    }
    .sites-block__flex {
      margin-top: 15px;
    }
    .additional-features .menu-sites-blocks ul {
      justify-content: flex-start;
    }
  }
  @media (max-width: 570px) {
    .sites-block__hert {
      margin-top: 20px;
    }
    .menu-sites-blocks ul {
      justify-content: flex-start;
    }
    .opportun__flex {
      padding: 30px 0 15px;
    }

  }
  @media (max-width: 425px) {
    .sites-block__photo {
      justify-content: center;
    }
    .sites-block__photo .sites-pic-dec {
      display: none;
    }
    .sites-block__photo .sites-pic-mob {
      display: block;
    }
  }
}
