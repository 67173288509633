.container {
  position: fixed;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  transition: all 1s ease;
  z-index: 10;

  &.active {
    top: 40%;
  }

  .content {
    border-radius: 0.9rem;
    background-color: #fff;
    min-width: 44rem;
    position: relative;

    .cancelButton {
      width: 2.5rem;
      height: 4.5rem;
      background-color: #fff;
      position: fixed;
      top: 0.8rem;
      right: -2.5rem;
      border-radius: 0 0.7rem 0.7rem 0;
      background-color: #4FB2ED;
      border: 1px solid #fff;
      z-index: -1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 0.7rem;
      cursor: pointer;
    }
  }
}

.shadow {
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}