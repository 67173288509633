.inputContainer {
  display: flex;
  align-items: center;
  height: var(--input-height);
  border: 1px solid var(--color-border-field18);
  border-radius: 0.5rem;
  padding: 0.7rem 2.5rem 0.7rem 1.5rem;
  font: 1rem var(--main-font);
  position: relative;
  background: #fff;
  padding-left: 0.75rem;

  &:not([aria-disabled="true"]) {
    cursor: pointer;
  }
}

.time {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  border-radius: 0.5rem;
}

.buttons {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  button {
    border-radius: 0.5rem;
    padding: 0 10px;
    margin: 0;
    &:hover {
      background-color: #d6f0ff;
    }
  }
}

.timeSelectContainer {
  display: flex;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: absolute;
  height: 25rem;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-border-field18);
  top: 100%;
  left: 0;
  overflow-y: hidden;
  z-index: 10;
}

.timeList {
  margin: 0;
  list-style: none;
  overflow-y: auto;
  padding: 0 0.5rem;
  li {
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: #d6f0ff;
    }
  }
}

.disabled {
  pointer-events: none;
}

.timeV2 {
  border-radius: 0.25rem;
  border: 1px solid #4fb1eb;
  background: #d1eeff;
  display: flex;
  align-items: center;
  height: 2rem;
  width: 3.5625rem;
  gap: 0.5rem;

  & > span {
    display: inline-block;
    min-width: 1.375rem;
    text-align: center;
  }
}

.timeBlock {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.inputContainerV2 {
  padding-left: 0.25rem;
  padding-right: 0.875rem;
}

.buttonsV2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  button {
    padding: 0;
    display: block;
    height: 0.625rem;
    width: 0.75rem;

    img {
      display: block;
    }
  }
}

.timeIcon {
  margin-left: auto;
}
