.row {
  min-height: 3rem;
  display: grid;
  grid-template-columns: 8fr 4fr 3fr 2fr;
  grid-template-columns: 49% 19% 19% 10%;
  width: 100%;
  grid-template-rows: 1fr;
  column-gap: 1%;
  align-items: center;
  padding: 0.5rem 0.625rem 0.5rem 1.5rem;

  & svg {
    cursor: pointer;
    flex-shrink: 0;
  }
}

.lightblueBg {
  background-color: var(--color-background-field17);
}

.icon > svg {
  cursor: pointer;
  margin-top: -0.25rem;
}

.input {
  font-size: 1rem;
  background-color: #daebfd;
}

.rankDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-border-primary);
  color: white;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  flex-grow: 0;
  justify-self: center;
}

.centered {
  display: flex;
  justify-self: center;
}

.controls {
  display: flex;
  justify-content: space-around;
  gap: 0.5rem;
}

.singleControl {
  width: 100%;
  display: flex;
  justify-content: end;
}
