.approversAndViewers {
  width: 100%;

  .approvers, .viewers {
    padding-left: 0;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}