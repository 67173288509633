.noteItem {
  width: 674px;
  background-color: #f4f4f4;
  border-radius: 8px;
  position: relative;
  margin-bottom: 7px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;

  .readCheck {
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    background-color: #4fb2ed;
    border-radius: 50%;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
  }

  .displayNone {
    display: none;
  }

  &:hover {
    background-color: #f2f2f2;
  }
}

.innerNoteItem {
  .dateFlexAndCloseBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 602px;

    .objectFlexAndDate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 548px;

      .flexObjectText {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 454px;
        margin-left: 9px;

        .objectCircle {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #d8ebfd;
        }

        .messageInner {
          font-style: 14px;
          color: #000;
          font-weight: 400;
          flex-basis: 377px;
          width: 377px;
          display: flex;
          flex-wrap: wrap;
        }
      }

      .dateReceived {
        font-style: 14px;
        color: #707070;
        font-weight: 400;
      }
    }

    .closeNotyBtn {
      width: 14px;
      height: 14px;
      cursor: pointer;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
