.pair {
  width: 100%;
  display: grid;
  grid-template-columns: 45fr 50fr;
  margin-bottom: 1.25rem;

  b {
    font-weight: 400;
  }

  &.centered {
    align-items: center;
  }
}

.calendarPopup {
  transform: translateX(33%) !important;
}

.element {
  width: 100%;
  margin-bottom: 1.25rem;
}

.executorsTable {
  margin-left: -2rem;
  width: calc(100% + 4rem);
}

.addExecutorBtn {
  margin-left: -0.675rem;
}

.field {
  margin-bottom: 1rem;
  min-width: 13.5rem;

  b {
    font-weight: 400;
  }
}

.plannedCompletion {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputBudget {
  min-width: 15.5rem;
}

.closure {
  margin-bottom: 1.25rem;

  button {
    padding-left: 0;
  }
}
