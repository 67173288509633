.err {
  border-color: red !important;
}

.errorText {
  color: red;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

.percents {
  display: flex;
  align-items: center;
}

.inputPercents {
  width: 3rem;
  height: 2rem;
  margin-right: 0.5rem;

  & input {
    padding: 0.1rem !important;
    text-align: center;
  }
}
