.sliderModal {
  width: 58rem;
}

.container {
  overflow: auto;
  height: 100vh;

  .content {
    padding: 1.625rem 2.3rem;

    .titleBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font: normal normal 400 1.25rem var(--title-font);
      }
    }

    .checkboxField {
      width: 4rem;
      display: flex;
      justify-content: center;
    }

    .primaryField {
      width: 10rem;
    }

    .headerTable {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: 500;
    }

    .tableRow {
      display: flex;
      align-items: center;
      height: 3.625rem;
      background-color: var(--color-background-field6);
      border-radius: 0.8rem;
      margin-bottom: 0.5rem;
      cursor: pointer;

      &:hover {
        background-color: var(--color-background-primary);
        color: var(--color-font-hover);

        .checkbox {
          border-color: var(--color-background-main);

          path {
            stroke: var(--color-background-main);
          }
        }
      }
    }
  }
}

.headerTable, .tableRow {
  & > * {
    width: 25%;
  }
}

.nowrapText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}