.tables {
  width: 100%;
  border-bottom: 1px solid var(--color-border-field4);

  td {
    padding: 0 1rem;
  }

  th {
    padding: 1rem;
  }

  & th:nth-child(2) {
    text-align: left;
  }
}

.fontOfCurrency {
  font-family: "Montserrat", sans-serif;
}

.tableRowClass {
  height: 3rem;
  font: normal normal 1rem var(--title-font);
  text-align: center;

  &:nth-child(2) {
    text-align: left;
  }
}

.tableRowClass,
.tableHeaderClass {
  border-bottom: 1px solid var(--color-border-field4);
}

.tableHeaderClass {
  font: normal normal 500 1rem var(--title-font);
  color: #454545;
  white-space: nowrap;
  text-align: center;
}

.input {
  height: 31px;
}

.files {
  border-left: 1px solid rgb(185, 185, 185);
  padding-left: 1rem;
}

.comment {
  position: relative;
  cursor: pointer;

  .commentCount {
    position: absolute;
    top: -8px;
    left: 14px;
    border-radius: 12px;
    background-color: #4fb2ed;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 500;
    width: 1.5625rem;
    height: 1.0625rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.rightRowItem {
  width: 100%;
  text-align: right;
}
