.input {
  border: 1px solid #c4c4c4;
}

label {
  font: normal normal 400 1rem var(--main-font);
  color: #666666;
  margin-bottom: 0.35rem;
}

.selectContainer {
  position: relative;
  color: black;
  border: none;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5625rem;
  background-color: var(--color-background-main);
  border-radius: 2rem;
  font: 1rem AvenirNextCyr;
  cursor: pointer;

  &.isOpen {
    border-radius: 1rem 1rem 0 0;
    z-index: 10000;
    position: relative;
    border-bottom: none;
  }

  &.disabled {
    color: #a0a0a0;
    cursor: default;
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    padding: 0.625rem 0 0.625rem 1.6rem;
  }

  .arrow {
    transition: all 0.2s ease;
    width: 1rem;
    margin-left: 0.25rem;
  }

  .arrowReverse {
    transform: rotate(-180deg);
  }

  .isOpen {
    display: block;
  }
}

.optionsBlock {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 9999;
  margin: 0.0625rem 0;
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
}

.option {
  padding: 0.625rem 0 0.625rem 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.043);
  &:last-child {
    border-radius: 0 0 1rem 1rem;
  }
}

.withDescription {
  width: auto;

  .option {
    display: flex;
    align-items: flex-start;
    white-space: normal;
    text-overflow: inherit;
  }

  .name {
    width: 20%;
  }

  .description {
    width: 80%;
  }
}

.blue {
  border-color: var(--color-border-primary);
}

.small {
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.iconContainer {
  padding: 0 0.875rem;
  border-left: 1px solid rgb(201, 201, 201);
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
}

.loading {
  .title,
  .iconContainer {
    opacity: 0.4;
  }
}
