.bar {
  position: relative;
  padding: 0.375rem 0.6rem 0.35rem 2.25rem;
  height: 2rem;
  background-color: var(--color-background-field10);
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.3rem;
  white-space: nowrap;

  :global(.bp4-popover2-target) {
    display: flex;
    width: 20px;
    height: 20px;
  }
}

.indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1.625rem;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    height: 0.72rem;
    margin-top: 0.05rem;
  }
}

.indicatorApproved {
  background-color: #a0e84d;
}

.indicatorWaiting {
  background-color: var(--color-background-field12);
}

.name,
.approvedAt {
  font-size: 1rem;
}

.approvedAt {
  color: #626262;
  display: inline-block;
  margin-left: auto;
  font-size: 0.875rem;
}

.portal {
  z-index: 99999;
}

.sendToApprovePopover {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  font-size: 0.75rem;
  padding: 0.53rem 0.78rem;

  & span:last-child {
    font-weight: 500;
  }
}
