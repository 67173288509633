.handler {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.withOpenIndicators {
  .header {
    flex-direction: column;
  }
}

.header {
  position: sticky;
  top: 0;
  display: flex;
  background: #fff;
  padding: 1.5rem 2.5rem;
  z-index: 98;
}