.container {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: 5% 14% 50% 8% 8% 10% 5%;

  & > div {
    padding-right: 0.5rem;

    &:last-child {
      padding-right: 0;
    }
  }
}

.actions {
  display: flex;
  align-items: flex-end;

  & > div {
    margin-bottom: 0.36rem;
    height: 2.125rem;
    background-color: #4fb1eb;

    & :global(.actionsDelete) {
      & path {
        stroke: #fff;
      }
    }

    & path {
      fill: #fff;
    }
  }
}

.oneAction {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.36rem;
  height: 2.125rem;
  width: fit-content;
  margin-right: auto;
}

.name {
  position: relative;
}

.add {
  background-color: #fff;
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
  cursor: pointer;
}

.rouble {
  color: #a4a4a4;
  padding-right: 0.5rem;
}

.popover {
  padding: 0.5rem;
  color: #4fb1eb;
}

.field3 input {
  background-color: #fff;
}