.container {
  position: relative;
  width: min-content;
}

.item {
  position: relative;
  padding: 0.7rem 0.75rem;

  font-family: var(--title-font);
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--color-font4);

  &:empty {
    display: none;
  }

  p {
    margin-bottom: 0.35rem;
  }
}

.textPrimary {
  font-family: var(--title-font);
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  text-align: right;
  color: var(--color-font-main);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-family: var(--main-font);
  column-gap: 0.5rem;
  padding: 0.7rem 1rem 0.2rem 1rem;
}

.intervalDates {
  color: #6c6c6c;
  white-space: nowrap;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: var(--main-font);
  font-size: 0.9375rem;
  border-top: 1px solid #d8d8d8;
}

.cardButton {
  color: rgba(79, 177, 235, 1);
  padding: 0.5rem 1rem;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.statuses {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 50%));
  grid-auto-rows: 1fr;
  grid-gap: 0.5625rem 1rem;
}

.statusBlock {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 0.5rem;
}

.status {
  width: 0.8125rem;
  min-width: 0.8125rem;
  height: 0.8125rem;
  min-height: 0.8125rem;
  border-radius: 0.25rem;
  border-style: solid;
  border: 0.0625rem solid #f5f5f5;
}

.indicators {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.statusName {
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  margin-right: 0.5rem;
  white-space: nowrap;
}

.statusPercentage {
  font-size: 1.125rem;
}

.backBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  cursor: pointer;
}

.main,
.workersContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 2.5rem;
  row-gap: 1rem;
}

.links {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 0.5rem;
}

.link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.arrow {
  width: 0.35rem;
}

.arrowLeft {
  transform: rotate(180deg);
}

.fieldsBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.fieldBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  .value {
    color: #808080;
  }
}

.workersContainer {
  align-items: flex-start;
}

.workers {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.worker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.6875rem;
  color: rgba(112, 112, 112, 1);
  background-color: #dddddd;
  padding: 0.53rem 0.6rem;
  border-radius: 0.5rem;
}

.check {
  position: absolute;
  top: -0.4rem;
  left: -1.2rem;
}

.planLabel {
  font-weight: 900;
}

.doned {
  background-image: repeating-linear-gradient(135deg, #d5c9df, #d5c9df 1.5px, transparent 1.5px, transparent 2.8px);
  background-color: white;
}

.confirmed {
  background-image: repeating-linear-gradient(135deg, #4fb1eb, #4fb1eb 1.2px, transparent 1.2px, transparent 4.46px);
  background-color: white !important;
}

.popoverContainer {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.statusCount {
  white-space: nowrap;
}

.remarks {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:before {
    content: " ";
    position: absolute;
    height: 1px;
    top: 0;
    left: 0.75rem;
    right: 0.75rem;
    background-color: var(--color-border-field3);
  }

  & span:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.4375rem;
  }
}

.shift {
  padding: 0.5625rem 0.5rem 0.6875rem;
  color: #eb4f8b;
  width: 100%;
  text-align: center;
  border-top: 1px solid #ebebeb;
  cursor: pointer;
  user-select: none;
}
