.modal {
  width: 40rem;
}

.header {
  padding: 1.5rem 1rem 1rem 2rem;
  border-bottom: 1px solid #d8d8d8;
  position: sticky;
  z-index: 2;
  background-color: #fff;
  top: 0;

  & h2 {
    font-size: 1.375rem;
  }
}

.subtitle {
  color: #707070;
  margin-bottom: 0.625rem;
}

.headline {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.625rem;
}

.content {
  padding: 0.625rem 1rem 0 2rem;
}

.doc {
  font-size: 0.875rem;
  display: grid;
  align-items: center;
  height: 3rem;
  padding: 0 2rem;
  grid-template-columns: 62.5% 20.5% 10.5% 6.5%;
  width: 100%;
  white-space: nowrap;

  &:nth-child(2n + 2) {
    background: #eff7fc;
  }
}

.docsContainer {
  padding: 0rem 1rem 0rem 2rem;
}

.docsRow {
  & label {
    font-family: var(--title-font) !important;
    color: #000;
    font-weight: 500 !important;
  }
}

.addButton {
  padding-left: 0;
}

.docsHead {
  font-size: 1rem;
  font-weight: 500;
  margin-top: -0.675rem;
}

.download {
  cursor: pointer;

  & svg {
    height: 1.5rem;
  }
}

.actionCell {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.range {
  width: 16rem;
}

.heightHolder {
  height: 4rem;
}

.row {
  margin-bottom: 0.675rem;
}

.hideInput {
  visibility: hidden;
}

.error {
  font-size: 0.687rem;
  color: #ff0558;
}

.axes {
  padding: 0 1rem 1rem 2rem;
}
