.row {
  grid-template-columns: 25% 20% 20% 35%;

  &:hover {
    & span.progress {
      color: white;
    }

    & div.progress {
      background-color: white;
    }

    & .currencyIcon {
      background-color: #fff;

      & path {
        fill: #000;
      }
    }

    & .currency {
      background-color: white;
      color: black;
    }

    & .buttonGroup {
      background-color: white;
    }

    & .actionButton {
      color: black;

      svg path {
        fill: #a8a8a8;
      }
    }

    & .iconButton {
      svg {
        fill: white;
      }
    }
  }
}

.actionsContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 1.1875rem;
}

div.progress {
  background-color: #00c3f2;
}

.actionButton {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  svg {
    fill: rgba(255, 255, 255, 0.7);
    width: 1.5rem;
    height: 1.5rem;
  }
}

.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: #a8a8a8;
  }
}

.currencyIcon {
  background: #4fb2ed;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  & path {
    fill: #fff;
  }
}

.textSize {
  font-size: 1rem;
}

.buttonGroup {
  height: 2.5rem;
  width: auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 2rem;
  transition: 0.1s all ease-in-out;
  background-color: #4fb2ed;
}

.buttonGroup > .actionButton {
  &:first-child {
    padding-left: 1rem;
  }

  &:last-child {
    padding-right: 1rem;
  }
}

.sharingWrapper {
  position: relative;
}

.head {
  top: 5.8rem !important;
  padding-top: 0 !important;
  height: auto !important;
}

.newHeader {
  display: grid;
  grid-template-columns: calc(34% - 0.5rem) calc(28% - 1rem) calc(28% - 0.5rem) 10%;
  column-gap: 1rem;
}

@media screen and (max-width: 1700px) {
  .newHeader {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
