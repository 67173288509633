.progressTabs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 1rem;
  border-bottom: 2px solid var(--color-border-field2);

  .tab {
    height: 2.625rem;
    width: 22.5rem;
    border: 1px solid var(--color-border-field16);
    font: normal normal 500 1.12rem var(--title-font);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0 1rem;
    cursor: pointer;

    &.active {
      background-color: var(--color-hawkes-blue);
      border: 1px solid transparent;
    }
  }
  .arrow {
    padding: 0 2.6rem;
  }
}