.title {
  font: normal normal 400 1.125rem/1.375rem var(--title-font);
  cursor: pointer;
  border-bottom: 1px solid var(--color-border-primary);
  color: var(--color-font-main);
}

.modal {
  min-width: 35rem;
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: var(--modal-shadow);
  border-radius: 15px;
  outline: 0;
  padding-bottom: 3.813rem;
  margin: 0 auto;
}

.header, .body {
  padding: 0 1.188rem;
}

.header {
  padding: 1.188rem 1.188rem 0;
}

.field {
  margin-bottom: 1rem;

  img {
    margin-right: 0.5rem;
  }
}

.body {
  border-bottom: 1px solid var(--color-border-field6);
}

.footer {
  position: absolute;
  bottom: 0.75rem;
  right: 1.188rem;
}