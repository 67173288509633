.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.125rem;
  position: relative;
}

.selectContainer {
  display: flex;
  align-items: center;
}

.select {
  margin-left: 1.875rem;
  width: 18rem;
}

.transferApprove {
  position: absolute;
  right: 19rem;
  top: 0;
  width: 28rem;
}