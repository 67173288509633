.backButton {
  padding: 0 2rem;
}

.buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;
  margin-top: 1rem;
  border-top: 1px solid var(--color-border-field10);

  & button {
    min-width: 12.25rem;
    display: flex;
    justify-content: center;

    &.mr {
      margin-right: 0.625rem;
    }
  }
}
