$border-radius: 0.375rem;

:global(.bp4-popover2) {
  border-radius: $border-radius;
  box-shadow: none;
  background-color: #fff;

  :global(.bp4-popover2-content) {
    border-radius: $border-radius !important;
    &::after,
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1rem;
      position: absolute;
      z-index: -1;
    }
    &::before {
      top: -1rem;
    }
  }

  :global(.bp4-popover2-arrow) {
    position: relative;

    &::before {
      box-shadow: none !important;
    }

    & path {
      fill-opacity: 1 !important;
    }
  }
  :global(.bp4-popover2-arrow-border) {
    fill: inherit;
  }
}

.portal {
  z-index: 99;
}

.popover {
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
}

@mixin popoverBorderStyle($color) {
  border-color: $color;
  fill: $color;
}

.borderPrimary {
  @include popoverBorderStyle(var(--color-background-primary));
}

.borderDefault {
  @include popoverBorderStyle(rgb(222, 222, 222));
}
