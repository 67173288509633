.container {
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  height: 2.813rem;
}

.yearDisplay {
  width: 6rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--input-height);
  border: 1px solid var(--color-border-field18);
  background-color: var(--color-background-main);
  border-radius: 0.5rem;
  padding: 0.6875rem 1.125rem;
  cursor: default;
  white-space: nowrap;
}

@media screen and (max-width: 1441px) {
  .container {
    padding: 0 0.3rem;
  }
  .yearDisplay {
    margin-right: 0.5rem;
  }
}
