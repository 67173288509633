.container {
  width: 22rem;
}

.header {
  padding: 0.75rem;
  border-bottom: 1px solid #4fb1eb;
  font-weight: 500;
  color: #696969;
}

.spinner {
  padding: 1.5rem;
}

.empty {
  text-align: left;
  color: #4fb1eb;
  font-weight: 500;
  padding: 0.75rem;
  cursor: pointer;
}

.row {
  border-bottom: 1px solid #ebebeb;
  display: grid;
  padding: 0.5rem 0.75rem;
  grid-template-columns: 75% 25%;
  white-space: nowrap;

  & > div {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.count {
  text-align: right;
}

.footer {
  color: #696969;
  font-weight: 500;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
}

.table {
  max-height: 400px;
  overflow-y: auto;
}
