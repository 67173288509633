.addSectionButton {
  color: var(--base-text-primary, #000);
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.25rem; /* 125% */

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  margin-right: 1rem;
  white-space: nowrap;
}