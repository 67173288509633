.root {
  overflow: hidden;
  position: relative;
  border: 1px solid #4fb1eb;
  border-radius: 1rem;
  padding: 0.75rem;
}

.expand {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  cursor: pointer;

  &:hover {
    .control {
      transition: 0.3s;
      opacity: 0.1;
    }
  }

  .control {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    padding: 0.25rem 1.5rem;
    border-radius: 1rem;
    background-color: var(--color-background-primary);

    &:hover {
      transition: 0.3s;
      opacity: 1 !important;
    }

    path {
      stroke: #fff;
    }
  }
}

.openControl {
  background: transparent;
  svg {
    transform: scaleY(-1);
  }
}

.more {
  display: inline-block;
  color: var(--color-background-primary);
  cursor: pointer;
  font-weight: 500;
}
