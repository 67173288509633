.container {
  position: relative;
  margin-top: -2rem;
}

.headerContainer {
  padding: 0 1.25rem;
}

.head {
  grid-template-columns: 10% 62% 28%;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;

  @media screen and (max-width: 1680px) {
    grid-template-columns: 10% 60% 30%;
  }

  @media screen and (max-width: 1440px) {
    grid-template-columns: 7% 60% 33%;
  }
}

.rowContainer {
  padding: 0 0.7rem;
  padding-bottom: 5rem;
}

.rowItemContainer {
  height: 6.875rem;
  padding: 1.25rem 0.7rem;
}

.row {
  display: flex;
  font-size: 1rem;
  padding: 0 !important;
  height: 100%;
}

.id {
  width: 10%;

  @media screen and (max-width: 1440px) {
    width: 7%;
  }
}

.medium {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0.9rem;
}

.name {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bold {
  font-weight: 500;
}

.right {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.date {
  width: 81%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1680px) {
    width: 88%;
  }

  @media screen and (max-width: 1440px) {
    width: 100%;
  }

  & svg {
    width: 1.063rem;
    height: 1.063rem;
  }
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.625rem;
  height: 1.438rem;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
}

.created {
  background-color: var(--color-status-on-created);
}

.review {
  background-color: var(--color-status-on-check);
}

.accepted {
  background-color: var(--color-status-received);
}

.divider {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  background: #fff;
  height: 50px;
  border-top: 1px solid var(--color-border-field10);
}
