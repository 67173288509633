.slider {
  height: 3rem;
  width: 100%;
  justify-content: space-evenly;
}

.head {
  padding: 0.9375rem 0 1.25rem;
  position: sticky;
  z-index: 3;
  background-color: #fff;
  top: 2.8rem;
  padding-bottom: 0;

  &::before {
    content: "";
    background-color: #fff;
    height: 100%;
    position: absolute;
    width: 3rem;
    left: -3rem;
  }
}

.flex {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.spinner {
  height: 90vh;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
}

.navbar {
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: calc(var(--input-height) + 2rem);
}
