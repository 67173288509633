.container {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.75rem;
  background: #f4f4f4;
  position: relative;
  cursor: pointer;

  & b {
    font-size: 0.875rem;
  }
}

.name {
  margin-bottom: 1.25rem;
  font-weight: 400;
}
