.container {
    position: relative;
    height: 1px;
    width: calc(100% + 1rem);
    border-radius: 0.5rem;
    margin-left: -1rem;
    margin-right: -1rem;
}

.btn {
    position: absolute;
    left: -1.08rem;
    top: 0.5rem;
    width: 1.1rem;
    height: 4.7rem;
    background-color: #efefef;
    border-radius: 5px;
}

.activeBtn {
    border-radius: 5px 0 0 5px;
    background-color: #4FB2ED;
    & svg {
        transform: scaleX(-1);

        & path {
            fill: #fff;
        }
    }
}

.content {
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-evenly;
    opacity: 0;
}

.open {
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    height: auto;
    margin-bottom: 0.8rem;

    & .content {
        opacity: 1;
    }
}
