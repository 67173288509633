.container {
  padding: 1.875rem 1.125rem 0 2.5rem;
  position: relative;

  .title {
    max-width: 14rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .value {
    padding: 0 0.8rem;
    flex-shrink: 0;
  }

  .buttons {
    display: flex;
    gap: 16px;
  }
}

.chatsBlock {
  margin-bottom: 1rem;
}

.executor {
  display: flex;
  align-items: center;
}

.executorButton {
  margin-left: 1rem;
}

.boxTitle {
  font: normal normal 400 1.125/1.375rem var(--title-font);
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.rightSide {
  display: flex;
  gap: 0.5rem;
  position: absolute;
  top: 1.875rem;
  right: 1.125rem;
}

.calendar {
  border: none;
}

.mainInfo,
.calendar {
  font: normal normal 500 1.25rem/1.5rem var(--title-font);
}

.mainInfoBlock,
.mainInfo {
  display: flex;
  align-items: center;
}

.dateWrapper {
  padding: 0.7rem 0;
  display: flex;
  align-items: center;

  .emptyBox {
    background-color: var(--color-background-field5);
    border-radius: 0.625rem;
    width: 8rem;
    height: 2.43rem;
  }

  .calendarIcon {
    margin-left: 1rem;
  }
}

.titleRow {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.calendarIcon {
  margin-right: 1rem;
}

.dateWithBackground {
  background-color: var(--color-background-field5);
  border-radius: 6px;
  padding: 0.571rem 1rem 0.571rem 0.714rem;
}

.boxValueName {
  border-bottom: 1px solid var(--color-border-primary);
  min-width: max-content;
  max-width: max-content;
  margin-right: 1rem;
}

.downloadButton {
  position: absolute;
  right: 1.125rem;
  top: 8.5rem;
  z-index: 2;
}

.takenAtTitle {
  margin-right: 1rem;
}

//

.root {
  margin: 1rem 0 2rem;
}
