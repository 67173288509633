.container {
  font-family: var(--main-font);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--input-height);
  border: 1px solid var(--color-border-field18);
  border-radius: 0.5rem;
  font: 13px var(--main-font);
  cursor: pointer;
  position: relative;
  background: #fff;

  &:hover {
    border: 1px solid #4fb2ed;
  }

  .input {
    border: none;
    height: 100%;
    padding: 0 1rem;

    &::placeholder {
      color: #777777;
    }
  }
  .actionBlock {
    border-left: 1px solid #4fb2ed;
    height: 100%;
    width: 3rem;

    .arrow {
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        padding-top: 0.1rem;
      }
      &:last-child {
        padding-bottom: 0.1rem;
      }
    }
  }
}

.label {
  font-weight: 400;
  margin-bottom: 0.3rem;
}
