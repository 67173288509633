.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  border-radius: 0.875rem;
  border: 1px solid var(--gray-gray-4, #C3C3C3);
  background: var(--base-white, #FFF);

  &.approveForm {
    position: relative;
    height: 100%;
  }
}

.spinner {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.addButtonContainer {
  width: 100%;
  padding: 0.75rem;
}


.addButton {
  width: calc(100% - 1.5rem);
  height: 4rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9f1ff;
  border-radius: 0.5rem;
  color: #4fb1eb;
  font-weight: 500;
  background: #eff7fc;
  transition: background-color .2s ease, color .2s ease;
  margin: 0.75rem;

  & .icon {
    path {
      transition: fill .2s ease;
    }
  }

  &:hover {
    color: #fff;
    background-color: #4fb1eb;

    & .icon {

      path:first-child {
        fill: #fff;
      }

      path:last-child {
        fill: #4fb1eb;
      }
    }
  }
}

.expenditureFormWrapper {
  padding: 0.75rem 0.75rem 0;

  &:last-of-type {
    padding-bottom: 0.75rem;
  }
}

