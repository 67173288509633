.container {
    display: grid;
    align-items: center;
    height: calc(2.5rem + 0.375rem + 0.375rem);
    padding: 0.3rem 1rem;
    grid-template-columns: 5% 60% 5% 10% 15% 5%;
    cursor: pointer;
    font-weight: 400;
    top: 3rem;
    background-color: #fff;
    z-index: 3;
    position: sticky;

    &:hover {
        & .add {
            display: flex;
        }
    }
}

.name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 3rem;
    font-weight: 500;
}

.add {
   display: none;
}

.addVisible {
    display: flex;
}