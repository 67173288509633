.modalContainer {
  min-width: 47.8125rem;
  max-width: 47.8125rem;
  overflow: hidden;

  &.approveActionView {
    min-width: 37.5rem;
    max-width: 37.5rem;
  }
}

.modalHeader {
  // TODO: избавиться от important
  padding: 1.5rem 1.5rem 1rem !important;
}

.body {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
}

.header {
  font-size: 1.125rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: flex-start;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    line-height: 1.5rem;
  }

  .info {
    display: flex;

    .dateInfo {
      color: #A4A4A4;
      font-size: 1rem;
    }

    .dates {
      color: #EB4F8B;
      font-weight: 500;
      margin-left: 0.5rem;
    }
  }
}

.materials {
  border: 1px solid #C3C3C3;
  border-radius: 12px;
  background-color: #FAFAFA;
  margin: 0 1.5rem;

  .materialsHeader {
    font-size: 1.125rem;
    padding: 1rem 1.5rem;
    font-weight: 600;
    border-bottom: 1px solid #EBEBEB;
  }

  .materialsItemsWrapper {
    max-height: 17.25rem;
    overflow-y: auto;
  }

  .materialsItem {
    padding: 0.75rem 1.5rem;

    &:not(:first-child) {
      border-top: 1px solid #EBEBEB;
    }

    .materialItemName {
      font-size: 1.125rem;
      display: block;
    }

    .materialInfo {
      display: flex;
      justify-content: flex-start;

      margin-top: 0.5rem;

      .materialInfoItem {
        .materialInfoItemName {
          font-weight: 600;
          color: #A4A4A4;
        }

        .materialInfoItemCount {
          color: #EB4F8B;
          margin-left: 0.5rem;
        }

        &:not(:first-child) {
          margin-left: 1.25rem;
        }
      }
    }
  }
}

.relatedPlans {
  border: 1px solid #C3C3C3;
  border-radius: 12px;
  background-color: #FAFAFA;
  margin: 0.75rem 1.5rem 0;

  .relatedPlansHeader {
    cursor: pointer;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: 600;
      font-size: 1.125rem;
    }

    .arrow {
      transition: transform .2s ease;
      transform: rotate(180deg);
      &.opened {
        transform: rotate(0);
      }
    }
  }

  .relatedPlansItems {
    height: 0;
    overflow-y: auto;

    &.opened {
      height: auto;
      max-height: 17.25rem;
    }

    .relatedPlansItem {
      padding: 0.75rem 1.5rem;
      font-size: 1.125rem;
      border-top: 1px solid #EBEBEB;

      .relatedPlansItemName {
        line-height: 1.5rem;
      }
      .relatedPlansItemInfo {
        margin-top: 0.5rem;
        font-size: 1rem;
        color: #A4A4A4;
        font-weight: 600;

       .date {
         font-weight: 500;
         color: #696969;
       }
      }
    }
  }
}

.shiftDays {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1.5rem;
  padding-left: 10rem;
  font-weight: 600;
}

.input {
  width: 3.75rem;
}

.controls {
  position: relative;
  margin-top: 1.5rem;

  &.double {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
  }
}

.controlsWrapper {
  padding-top: 0;
}

.spinner {
  position: relative;
  margin: 10rem 0;

  &.smallMargin {
    margin: 1.05rem 0;
  }
}

.shiftsList {
  max-height: 35rem;
  overflow-y: auto;

  .shiftsListItem {
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    line-height: 1.5rem;

    border-top: 1px solid #EBEBEB;

    &.selectable {
      cursor: pointer;
    }

    &:hover {
      background-color: #FAFAFA;
    }

    .breadcrumbs {
      span {
        font-weight: 600;
        line-height: 1.5rem;
        color: #A4A4A4;

        &:last-child {
          font-weight: 500;
        }
      }
    }

    .name {
      margin-top: 0.5rem;
    }

    .info {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.4rem;

      .infoItem {
        width: auto;
        display: flex;
        justify-content: flex-start;

        span {
          line-height: 1.25rem;

          &.accent {
            color: #EB4F8B;
            text-decoration: underline;
          }

          &:first-child {
            font-size: 1rem;
            font-weight: 600;
            color: #A4A4A4;
          }
        }

        .iconLink {
          margin-left: 0.5rem;
          fill: #EB4F8B;

          &.deactivated {
            fill: black;
          }
        }
      }
    }
  }
}

.back {
  width: 2.375rem;
  min-width: 2.375rem;
  height: 2.375rem;
  min-height: 2.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4FB1EB;
  border-radius: 0.625rem;
  margin-right: 1rem;
  cursor: pointer;
}


.centerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 2.1875rem 0 2rem;
  font-size: 1rem;

  span {
    margin-top: 0.75rem;
    font-weight: 400;
    line-height: 1.21875rem;

    &.attention {
      font-weight: 600;
      font-size: 1rem;

      & + span {
        margin-top: 0.2rem;
      }
    }
  }
}

.bigButton {
  font-weight: 500;
  font-size: 1.25rem;
}

.loadMore {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #EBEBEB;
  padding: 1rem 0;
}


.warningIcon {
  fill: #C3C3C3;

  &.critical {
    fill: #EB4F8B;
  }
}

