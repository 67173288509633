.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.parent {
  background-color: #fff !important;
}

.name {
  grid-template-columns: 100%;
}