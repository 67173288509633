.weeksSlider {
  background-color: #F4F4F4;
  border-radius: 0.5rem;
  padding: 0.25rem;
}

.weeksSlider, .weeks {
  display: flex;
  align-items: center;
}

.weeks {
  padding: 0 .5rem;
}

.weekBar {
  flex-shrink: 0;
}