.header {
  padding: 2rem 1.5rem 1.25rem;
  border-bottom: 1px solid #bfbfbf;
}

.title,
.subtitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.title {
  font: normal normal 500 1.375rem/1.5rem var(--title-font);
  margin: 0 0 0.625rem;
}

.subtitle {
  opacity: 0.5;
  margin: 0;
}

.modalChildren {
  min-height: 100%;
}

.notifications {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.813rem 0;
  padding: 0 2.625rem 1.25rem;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  max-height: calc(100% - 13rem);
  min-height: calc(100% - 13rem);
}

.switches,
.notificationsHeadline {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.switches {
  width: 100%;
  & > span {
    flex-basis: 80%;
  }
}

.switch {
  flex-basis: 20%;
  justify-content: flex-end;
}

.footer {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.65625rem;
  padding: 1.125rem 0 0.7125rem;
  border-top: 1px solid #bcbcbc;
  position: sticky;
  width: 100%;
  bottom: 0;
}

.groupLabel {
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.325rem 2.625rem;
  width: calc(100% + 5.25rem);
  margin-left: -2.625rem;
  &:not(&:first-of-type) {
    padding: 0.7315rem 2.625rem;
  }
}

.tabs {
  width: min-content;
  margin: 0.75rem auto;
}
