.row {
  display: grid;
  width: 100%;
  align-items: center;
  padding: 0 0.75rem 0 1rem;
  grid-template-rows: 1fr;
  font-weight: 400;
  cursor: pointer;
  height: 3rem;
  grid-template-columns: 8% 78% 15%;
  padding-right: 1rem;

  &:nth-child(2n + 2) {
    background: #eff7fc;
  }
}

.hover {
  grid-template-columns: 7% 10% 68% 15%;
  padding-right: 0;
  &:hover {
    background-color: #4fb1eb;
    color: #fff;

    & :global(.checkbox) {
      border-color: #fff;
    }

    & path {
      stroke: #fff;
    }

    & .delete {
      display: block;
    }
  }
}

.selected {
  background-color: #4fb1eb !important;
  color: #fff;

  & :global(.checkbox) {
    border-color: #fff;
  }

  & path {
    stroke: #fff;
  }

  & .delete {
    display: block;
  }
}

.materialRow {
  grid-template-columns: 7% 51% 18% 24%;
}

.planMaterialRow {
  grid-template-columns: 7% 51% 18% 24%;
}

.expenditureRow {
  grid-template-columns: 7% 51% 18% 24%;
  cursor: default;
}

.selected {
  background-color: #4fb1eb !important;
  color: #fff;

  & :global(.checkbox) {
    border-color: #fff;
  }

  & path {
    stroke: #fff;
  }
}

.input {
  width: 4.5rem;
  font-size: 13px;
  background-color: #fff;
  padding: 0.5rem;
  height: var(--input-height);
}

.name {
  padding-top: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.063rem;
}

.measure {
  text-align: center;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number {
  padding-top: 1px;
  text-align: center;
}
