.row {
  display: grid;
  grid-template-columns: 80% 20%;
}

.subRow {
  background-color: #fff !important;
}

.parent {
  background-color: #fff !important;
}

.right {
  text-align: right;
  display: flex;
  justify-content: end;
}

.toAcceptance {
  opacity: 0.65;
}

.count {
  font-weight: 600;
  margin-right: 0.5rem;
}
