.headline {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 6;
}

.table {
  padding-top: 1.5rem;
}

.rowContainer {
  &:hover .changeItems {
    opacity: 1;
  }
}

.rowHeader {
  display: flex;
  justify-content: space-between;
}

.changeItems {
  display: flex;
  background-color: #fff;
  border-radius: 1.25rem;
  padding: 0.75rem 1.125rem;
  opacity: 0;

  & svg:not(:last-child) {
    margin-right: 1rem;
  }
}
