.info {
  cursor: pointer;
  position: relative;
  width: 1.625rem;
  height: 1.625rem;
  /* border-radius: 50%; */
  /* background-color: white; */
  /* color: black; */
  font-weight: 500;
  width: 1.625rem;
  height: 1.625rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
