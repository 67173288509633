.flexInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
  &:nth-child(5){
    grid-row-start: 1;
  }
}

.border {
  border: 1px solid var(--color-border-field7);
}

.inputForm {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flexAction {
  display: flex;
  justify-content: space-between;
  padding: 20px 25px 0 20px;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 35px;
}

.text {
  font-size: 1.125rem;
  font-weight: 400;
  color: black;
}


.goodIcon {
  position: absolute !important;
  right: 15px !important;
  top: 54%;
  color: green;
  width: 20px;
  height: 20px;
}

.badIcon {
  position: absolute !important;
  right: 15px !important;
  top: 54%;
  color: #4fb2ed;
  width: 20px;
  height: 20px;
}

.cleaveInput {
  height: 2.5625rem;
  outline: none;
  padding: 0.6875rem 1.125rem;
  text-transform: capitalize;
  border-radius: 12px;
  border: 1px solid #c3c3c3;

  &:focus {
    opacity: 1;
    border: 1px solid #4fb2ed;
  }
}

.input {
  height: 2.5625rem;
  outline: none;
  padding: 0.6875rem 1.125rem;
  font-size: 1rem;
  border-radius: 0.75rem;
  border: 1px solid var(--color-border-field7);

  &:focus {
    opacity: 1;
    border: 1px solid var(--color-font-primary);
  }
}

.error {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
  position: absolute;
  bottom: -1.25rem;
}