@import "src/scss/dimensions";

.container {
  position: relative;
  padding-bottom: 1.88rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.slider {
  height: 3rem;
  width: 100%;
  justify-content: space-evenly;
}