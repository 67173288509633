.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
  margin-bottom: .25rem;
}

.line {
  display: inline-block;
  flex: 1;
  border: none;
  height: 1px;
  background-color: #dcdcdc;
}
