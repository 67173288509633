.container {
  display: flex;
  border: 1px solid var(--color-background-field3);
  border-radius: 0.5rem;
  margin-bottom: 0.68rem;
  font-family: var(--title-font);

  .item {
    width: 50%; 
    padding: 0.5rem 0.75rem;
    color: #000;
    &:first-child {
      border-right: 1px solid var(--color-background-field3);//#00F241 
    }
    .itemTitle {
      color: var(--color-font-primary);
      margin-bottom: 1.18rem;
    }    
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
    .itemValue {
      margin-bottom: 0.5rem;
    }
    .background {
      background-color: var(--color-background-field3);
      border-radius: 2rem;
      // padding: 0 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 5.75rem;
      width: max-content;
      padding: 0 0.5rem;
      height: 2rem;
    }
  }
}