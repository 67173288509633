.header {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2.5rem;
}

.chatContainer {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
}

.fileBlock {
  width: 24.25rem;
}

.switcher {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 30%;

  & span {
    font-size: 1.125rem;
  }
}
