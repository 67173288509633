.modal {
  width: 68rem;
  overflow: hidden;
  position: relative;
}

.header {
  //background-color: #fafafa;
}

.subHeader {
  //background-color: #fafafa;
  border-bottom: 1px solid #c3c3c3;
  padding: 1rem;
  padding-top: 0;
  color: #696969;
  display: flex;
  justify-content: space-between;
  width: 100%;

  & span {
    font-weight: 400;
    color: #4fb1eb;
  }
}

.btns {
  gap: 1rem;
  display: flex;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #c3c3c3;
}

.thead {
  display: grid;
  grid-template-columns: 5% 5% 50% 15% 25%;
}

.table {
  max-height: 20rem;
  overflow: auto;
}

.parentRow {
  border-bottom: 1px solid var(--gray-gray-3, #ebebeb);
  background: var(--gray-gray-1, #fafafa);
  padding: 0.5rem 1.5rem;

  & span {
    color: #696969;
  }
}
