.sticky {
  &::before {
    border-bottom: 1px solid #c3c3c3;
    height: calc(100% + 1rem + 1px);
  }
}

.extraControl {
  padding-right: 1rem;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  visibility: hidden;
}

.popover {
  padding: 0.5rem 1rem;
  color: #4fb1eb;
}
