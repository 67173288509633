.avatar {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: 50%;
  background: #BEE6FF;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal 0.875rem var(--title-font);
    color: #000 !important;
  }
}