.row {
    display: grid;
    grid-template-columns: 80% 20%;
    & * {
        font: normal normal 400 1.12rem var(--title-font) !important;
    }
    & svg {
        margin-left: auto;
    }

    &:hover {
        & .progress {
            & span {
                color: #fff;
            }
            
            & :global(.progressBar) {
                background-color: #fff !important;
            }
        }
    }
}

.progress {
    justify-content: flex-end;
}