.tabsContainer {
  display: flex;
  max-width: 100%;
  margin-top: 0.75rem;
  margin-bottom: 0.625rem;
}

.tabs {
  & div {
    width: 15rem;
  }
}