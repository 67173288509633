.rowContainer {
  background-color: #fff;
  height: 2.2rem;
}

.row {
  display: grid;
  grid-template-columns: 7% 44% 12% 20% 12% 5%;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  height: 2.2rem;
}

.status {
  padding: 0;

  & img {
    margin-right: 0;
  }
}

.statusPortal {
  z-index: 9999;
}

.head {
  height: auto;
  font-size: 13px;
  border-bottom: 1px solid #4fb1eb;
  padding-bottom: 0.3rem;
}

.centered {
  text-align: center;
}

.files {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  width: 3rem;
  border-radius: 0.9rem;
  cursor: pointer;

  & svg {
    height: 1.4rem;
  }
}

.delete {
  cursor: pointer;
}

.title {
  font-size: 13px;
  padding: 0 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #d8d8d8;
}
