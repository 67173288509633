.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 2.5rem;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 2.5rem;
}
