.thirdRow {
  display: grid;
  grid-template-columns: min-content auto auto;
  gap: 0.5rem;
}

.nums {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: auto;
  font-size: 0.875rem;
  padding-left: 0.5rem;
}

.date {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.calendar {
  img {
    width: 1rem;
    height: 1rem;
    display: block;
  }
}

.calendarSelect {
  padding: 0 !important;

  flex-direction: row-reverse;

  height: 1.25rem;
  gap: 0.25rem;
  border: none;
  & span/* :not(:global(.bp4-popover2-target)) */ {
    margin-right: 0;
  }

  svg {
    width: 0.75rem;
    height: 0.75rem;

    & path {
      fill: #fff;
    }
  }
}

.calendarIcon {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4fb1eb;
}
