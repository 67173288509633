.head {
  grid-template-columns: 2.8% 30% 15% 9.9% 9.9% 10.6% 12.3% 4.5% 5%;

  position: sticky;
  z-index: 2;
  background-color: #fff;
  padding-right: 0.75rem;
  padding-top: 0;
  height: 2rem;
  top: 3rem !important;
  z-index: 11 !important;
}
