.createRequisitionModal {
  width: 514px;

  .body {
    padding: 1rem 2rem 0;
  }

  .button:not(:last-child) {
    margin-right: 1rem;
  }

  .text {
    margin: 0;
  }

  .footer {
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--color-border-field6);
    margin-top: 1rem;
    padding: 0.5rem 2rem;
  }
}