.list {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  & > span {
    margin-bottom: 0.5rem;
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.listItem {
  flex-shrink: 0;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--color-background-primary);
  //width: 15.625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  & > span {
    display: block;
    opacity: 0.5;
    font-weight: 500;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.arrow {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.7;
}

.listWrapper {
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 1.5rem;
  scrollbar-color: transparent transparent !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  //
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.container {
  width: 100%;
  position: relative;
}

.left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateX(-70%);
}

.right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(70%);
}

.rightObserver {
  margin-left: -1.5rem;
}
