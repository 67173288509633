.popup {
  & > div {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-bottom: 1px solid #e3e3e3;
    font-weight: 400;

    &:last-child {
      border-bottom: none;
    }
  }
}

.container {
  width: 200px;
  min-height: 200px;
  position: relative;
  border-radius: 1rem;
  padding: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
}

.controls {
  position: absolute;
  cursor: pointer;
  right: 1rem;
  top: 0.4rem;
}

.btn {
  width: 70%;
  justify-content: center;
  margin-top: 0.3rem;
}

.input {
  width: 70%;
}

.inputInner {
  font-size: 0.8rem !important;
  background-color: #fff;
  padding: 0.3rem 0.525rem !important;
  height: auto !important;
}

.name {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.icon {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  width: 80%;
  height: 120px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 1rem;

  & > * {
    max-width: 100%;
  }
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rename {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customExtension {
  width: 102px;
  height: 120px;
  border-radius: 0.8rem;
  background-color: red;
  font-size: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
}

.count {
  background: #ebebeb;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  height: 1.7rem;
  border-radius: 0.8rem;
  padding: 0 1rem;
  white-space: nowrap;
  font-weight: 400;
  font-size: 0.875rem;
}
