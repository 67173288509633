.thead {
    border-radius: 0.5rem;
    padding: 0 1.5rem;
    display: grid;
    align-items: center;
    grid-template-columns: 5% 50% 10% 12%;
    background: #f2f2f2;
    font-weight: 500;
    height: 3rem;
    position: sticky;
    top: 10rem;
    z-index: 2;
}

.row {
    padding: 0 1.5rem;
    display: grid;
    align-items: center;
    grid-template-columns: 5% 50% 10% 12% 11% 6% 6%;
    min-height: 3.75rem;
}

.container {
    border-bottom: 1px solid #d6d6d6;

    &:last-child {
        border-bottom: none;
    }
}

.filesRow {
    display: flex;
    align-items: center;
    padding: 0.8rem 1.5rem 0;
    padding-left: calc(1.5rem + 5%);
    column-gap: 0.5rem;
    flex-wrap: wrap;

    & > * {
        margin-bottom: 0.8rem;
    }
}

.right {
    text-align: right;
    justify-content: end;
}
