.form {
  margin-top: 20px;
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  grid-template-columns: 63fr 17fr 17fr 17fr 43fr;
  column-gap: 1rem;

  &.kz {
    grid-template-columns: 88fr 30fr 43fr;
  }
}

.bankLine {
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  grid-template-columns: 783fr 172fr 303fr 288fr 32px;
  column-gap: 1rem;
}

.reqsTitle {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  padding: 25px 0 5px;
  font-weight: 400;
  margin-bottom: 20px;

  & svg {
    cursor: pointer;
    margin-left: 23px;
  }
}

.delete {
  cursor: pointer;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}

.submit {
  margin-left: auto;
  margin-bottom: 20px;
}

.addButtonClass {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 0 !important;
  z-index: 10;
}
.plusIcon {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.tables {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  width: 100%;

  tr th {
    border-radius: 10px 0 0 10px;
  }
  tr td:last-child {
    border-radius: 0 10px 10px 0;
  }

  tr td:first-child {
    border-radius: 10px 0 0 10px;
  }
  td {
    padding: 0 1rem;
  }

  th {
    padding: 0 1rem;
  }
}

.tableRowClass {
  background-color: #f4f4f4;
  color: #000;
  border-radius: 10px;
  font-size: 1.125rem;
  height: 3.875rem;
  font-family: var(--title-font);
  font-weight: 500;

  & td:nth-child(4) {
    opacity: 0;
  }
  &:hover {
    background-color: #4fb2ed;
    color: #fff;

    .boxBtn {
      display: flex;
    }
    .block2 svg > path {
      fill: #fff;
    }
    td:nth-child(4) {
      opacity: 1;
    }
  }
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.block1 {
  width: 70%;
  min-width: 20rem;
}

.block2 {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 15rem;
  max-width: 17rem;
  margin: 0 2rem;
  justify-content: space-between;
}

.boxButtonPaginator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.btnChange {
  width: 5rem;
  height: 2.5rem;
  background: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.blockRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.boxValueBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .boxBtn {
    display: none;
  }
}

.addButtonClass {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 1rem 0;
}

.plusIcon {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.buttonPaginator {
  background-color: #ffffff;
  border: 1px solid #4fb2ed;
  color: #000;
  width: 17.5rem;
  height: 2.25rem;
  border-radius: 20px;
  font-size: 1rem;
  text-transform: uppercase;

  &:hover {
    box-shadow: 0 0 10px #cfcfcf;
  }
}

.add {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;

  & button {
    margin-left: 32px;
  }
}

.container {
  padding-top: 1.5rem;
}
