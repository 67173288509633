.confirmSections {
  position: relative;
}

.sections {
  margin-bottom: 1.25rem;

  &:not(:first-child) {
    margin-top: 2.5rem;
  }
}

.title {
  font: normal normal 1.125rem/1.375rem var(--title-font);
  margin-bottom: 1.125rem;
}

.divider {
  position: absolute;
  width: 100vw;
  height: 1px;
  background-color: #4FB2ED;
  left: -4rem;
}

.count {
  font-weight: bold;
}