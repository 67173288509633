.parentRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  background: #fafafa;
  padding: 0.5rem 1.25rem;
  display: grid;
  gap: 5%;
  grid-template-columns: 80% 15%;
}

.parentName {
  color: #696969;
}

.right {
  display: flex;
  justify-content: end;
}
