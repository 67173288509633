.fulfillmentIsland {
  width: 100%;
  grid-column: 1;
  grid-row: 4 / 9;
}

.date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
}

.body {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: .9375rem;
  overflow-y: auto;
  min-height: calc(100% - 3.5rem);
  height: calc(100% - 3.5rem);
  max-height: calc(100% - 3.5rem);
}

.fulfillmentLegend {
  padding-top: 1rem;
  padding-bottom: 1rem;
  bottom: 0;
  width: 100%;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: 1fr;
  position: absolute;
}