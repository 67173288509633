.wrapper {
  display: contents;
}

.tree {
  position: sticky;
  left: 0;
  z-index: 97;
  min-height: 100vh;
  /* Синхронизировано с .treeHeader */
  width: 35%;
  background-color: white;
  border-right: 2px solid #9ad9ff;

  /* Синхронизировано с .treeHeader */
  &.w7 {
    width: calc(35% + 7rem);
  }
  /* Синхронизировано с .treeHeader */
  &.w22 {
    width: calc(35% + 22rem);
  }
}

.branches {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.intervalsBranch {
  position: absolute;
  top: 0;
  // Один уровень с подсветкой строки
  z-index: 1;
  &:hover {
    // Выше остальных строк
    z-index: 2;
  }
}

.branch {
  position: relative;
  width: 100%;
  background-color: #fff;
  border-radius: 0.625rem;
  height: 3rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  box-sizing: border-box;
  padding: 0.765rem 0 0.765rem 1rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  z-index: 1;
}

.branchElement {
  &.bgLight .branch {
    background-color: #eff7fc;
  }
}

.runningLine {
  position: absolute;
  margin-top: 2.25rem;
  top: 0;
  left: 0;
  width: 100%;
  transform-origin: 0 0;
  height: 3rem;
  background-color: rgba(216, 241, 255, 0.5);
  pointer-events: none;
  // выше фона графика
  z-index: 1;
}

.activeBranch,
.branchElement:hover {
  z-index: 2;

  .branch {
    align-items: flex-start;
    height: auto;
    min-height: 3rem;
  }

  .branchName {
    white-space: normal;
  }
}

.branchChild {
  padding-left: 2rem;
}

.branchExpenditure {
  padding-left: 3.25rem;
}

.branchExpenditureChild {
  padding-left: 4.625rem;
  cursor: default !important;
}

.branchExpenditureChildWithNumber {
  padding-left: 2.75rem;
}

.branchNameWrapper {
  display: flex;
  align-items: center;
  align-self: center;
  flex-grow: 1;
  overflow: hidden;

  &.wDays {
    width: calc(100% - 11.5rem);
  }
  &.wPeriod {
    width: calc(100% - 26.5rem);
  }
}

.branchName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.5rem;
}

.activeBranch .branch,
.branch:hover {
  background-color: #4fb2ed !important;
  color: #fff !important;
  border-radius: 0.625rem 0 0 0.625rem;

  .count {
    background-color: #fff;
    color: #4fb2ed;
  }

  .branchCheckbox {
    border-color: white;
  }

  .branchCheckbox.branchCheckboxFalse:before,
  .branchCheckbox.branchCheckboxFalse:after {
    background-color: white;
  }

  .bubbleCurrency,
  .bubbleInfo,
  .bubbleAddPlan {
    display: flex;
  }
}

.branchCheckbox {
  min-width: 0.875rem;
  width: 0.875rem;
  height: 0.875rem;
  border: 1px solid #d1d1d1;
  border-radius: 0.25rem;
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
}

.branchCheckbox.branchCheckboxTrue::after {
  content: "";
  position: absolute;
  width: 0.0875rem;
  height: 0.5rem;
  background-color: #7c7c7c;
  top: 0.125rem;
  left: calc(50% - 0.04375rem);
}

.branchCheckbox.branchCheckboxFalse:before {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 0.0875rem;
  background-color: #7c7c7c;
  top: calc(50% - 0.04375rem);
  left: 0.125rem;
}

.branchChildActive {
  color: #4fb2ed;
}

.count {
  background: #4fb2ed;
  min-width: 2.25rem;
  padding: 0 0.375rem;
  height: 1.375rem;
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: 0.69rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubbleCurrency,
.bubbleInfo,
.bubbleAddPlan {
  display: none;
}

.bubbleAddPlan {
  cursor: pointer;
}

.bubbleAddPlanPopover {
  padding: 0.5rem 1rem;
  color: #4fb1eb;
}

.branchBubbles {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.625rem;

  & > div:not(:last-child) {
    margin-right: 0.75rem;
  }
}

.branchNumber {
  position: relative;

  min-width: 1.75rem;
  margin-right: 0.25rem;
  flex-shrink: 0;

  font-size: 0.8125rem;
  text-align: center;
  align-self: flex-start;
}

.days {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
}

.period {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
}

.flexDaysPeriods {
  display: flex;
}
