.header {
  padding: 2rem 1.5rem 1.25rem;
  border-bottom: 1px solid #bfbfbf;
}

.title {
  font: normal normal 500 1.375rem/1.5rem var(--title-font);
  margin: 0 0 0.625rem;
}

.subtitle {
  opacity: 0.5;
  margin: 0;
}

.body {
  padding: 1.25rem 1.5rem;
}
