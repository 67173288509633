.btn {
  border-radius: 0.25125rem;

  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & svg {
    transform: scale(0.8);
  }
}

.expanded {
  background: #a26dd0;
  position: relative;

  & path {
    fill: #fff;
  }

  &::after {
    content: "";
    position: absolute;
    width: 0.25rem;
    height: 1000vh;
    background: #eae2f6;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
