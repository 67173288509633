@import "src/scss/mixins/sliceTextByLines";

.header {
  display: flex;
  padding: 0.6rem 0;
  height: 5.44rem;
  cursor: pointer;

  &.active:not(:last-child) {
    border-bottom: 1px solid #c7c7c7;
  }
}

.notProcess {
  display: none;
}

.buttonOpen {
  position: absolute;
  top: 1.3rem;
  width: 1.62rem;
  height: 3rem;
  left: -1.62rem;
  background: #e0e0e0;
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & path {
    fill: #fff;
  }

  &.process {
    background-color: #4fb2ed;
  }
  &.approved {
    background-color: #8fe532;
  }

  .count {
    font: normal normal 500 0.88rem var(--title-font);
    background-color: #fff;
    border-radius: 1rem;
    padding: 0 0.2rem;
    margin-bottom: 0.2rem;
  }
}

.rowItem {
  flex-basis: 12%;
  width: 12%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 0 0.6rem;

  &.checkBlock {
    display: flex;
    flex-basis: 20%;
    width: 20%;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }

  .formBlock {
    display: flex;
  }

  &.doneRow {
    justify-content: space-between;
  }
}

.nameBlock {
  position: relative;
  width: 41%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 41%;
  padding-left: 0;
  padding-right: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.withoutLastBlock {
    flex-basis: 50%;
  }

  .nameContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.3rem 0;
    height: 100%;
  }

  .name {
    white-space: pre-wrap;
    padding-right: 1rem;
    @include slice-text-by-lines(2);
  }

  & :global(.ant-tooltip-content) {
    word-wrap: break-word;
    white-space: normal;
  }
}

.content {
  padding: 0 1.3rem 1.3rem;
}

.blueLabel {
  background-color: #e6f4fc;
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-primary);
    color: #fff;
  }
}

.blueLabelNonActive {
  background-color: #fff;
}

.righted {
  text-align: right;
  justify-content: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkFile {
  width: 2.5rem;
  position: relative;
  cursor: pointer;
}

.labels {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.divider {
  height: 2rem;
  width: 2px;
  background: #cecece;
  align-self: center;
}

.inGroup {
  .nameBlock {
    flex-basis: 42%;
  }
}

.replacement {
  display: flex;
  &:hover {
    & path {
      stroke: #fff;
    }
  }
}

.secondCheck {
  transform: translateX(-70%);
  margin-right: -1rem;
}
