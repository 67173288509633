.content {
  background-color: #E9E9E9;
  padding: .375rem .5rem;
  position: relative;
}

.islandHeading {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 200;
  font-family: var(--title-font);

  & b {
    font-weight: 400;
  }
}

.islands {
  position: relative;
  padding: .875rem 0;
  width: 100%;
  height: calc(100% - 13rem);
  display: grid;
  grid-template: repeat(8, 5.5rem) / minmax(auto, 35%) minmax(auto, 30%) minmax(auto, calc(35% - 2rem));
  grid-gap: 1rem 1.125rem;
}