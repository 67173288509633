.sliderBlock {
  display: flex;
  //height: 2.69rem;
  background: #EFEFEF;
  border-radius: 0.5rem;
  padding: 0 0.1rem;
  width: min-content;
  margin-bottom: 1rem;
}

.sliderItem {
  padding: 0.5rem 2.6rem 0.5rem 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.25rem;
  font-weight: 400;
  //font-family: var(--main-font);
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  height: 2.3rem;

  .icon {
    position: absolute;
    left: 0.625rem;
  }

  &.active {
    background-color: var(--color-background-primary);
    color: #fff;
  }
}