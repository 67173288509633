.container {
  position: relative;
  z-index: 13;
  margin-bottom: 1rem;
  top: 0;
}

.content {
  align-items: center;
  background-color: #fff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.circleTitle {
  text-align: left !important;
}

.all {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  min-width: 8rem;
}

.allCount {
  font-size: 1.5rem;
}

.wrapper {
  cursor: pointer;
  height: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;

  & > * {
    padding: 0;
  }
}

.wrapperActive {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
