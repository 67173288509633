.content {
    padding-bottom: 0.3rem;

    .headerMaterial {
        display: flex;
        font: normal normal 500 1rem var(--main-font);
        color: #000;
        padding: 0.5rem 0rem;
        background-color: #f4f4f4;

        .headerItemBig {
            flex: 1;
            margin-left: 4rem;
            padding: 0 0.5rem;
        }

        .headerItem1 {
            width: 3rem;
        }

        .headerItem {
            width: 10rem;
            padding: 0 0.5rem;
            white-space: nowrap;
            text-align: center;
        }
    }
}

.box {
    display: flex;
    align-items: center;
}

@media (min-width: 1920px) {
    .count {
        padding-right: 2rem !important;
    }
}
