.all {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.allCount {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 0.3rem;
}

.allSubtitle {
    font-size: 0.875rem;
    pointer-events: none;
}

.blue {
    color: #5ec5eb;
}

.circleTitle {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 0.3rem;
    text-align: left;
}

.circleDescription {
    font-weight: 400;
    font-size: 0.875rem !important;
    line-height: 1.15 !important;
}

.fileTypes {
    opacity: 0.7;
}

.loading {
    opacity: 0 !important;
}
