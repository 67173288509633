.miniModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 12;
    backdrop-filter: blur(5px);
    opacity: 0;
    pointer-events: none;
}

.miniModalContent {
    background-color: #fff;
    width: 590px; 
    border-radius: 21px;
}

.miniModal.active {
    opacity:1;
    pointer-events: all;
}   

.miniModalContent.activeContent {
    // transform: scale(1);
}