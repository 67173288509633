.container {
  display: flex;
  align-items: center;
  height: 2rem;
  border-radius: 1rem;
  padding-right: 1rem;
  min-width: 8rem;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 0 0.675rem;
}

@mixin statusPillColor($color) {
  background: $color;

  .marker {
    background-color: darken($color, 35%);
  }
}

.created{
  @include statusPillColor(#FFE9D6);
}

.review{
  @include statusPillColor(#DEDEDE);
}

.accepted{
  @include statusPillColor(#E5D9EF);
}