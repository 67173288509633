.setsRow {
  grid-template-columns: 50% 14.5% 20% 8% 1%;
}

.docsRow {
  grid-template-columns: 62% 11% 10% 10% 10%;
}

@media screen and (max-width: 1441px) {
  .tabs {
    min-width: 11.5rem !important;

    & > div {
      & > div {
        width: 11rem !important;
      }
    }
  }
}
