.container {
  display: flex;
  align-items: center;
  background-color: #F4F4F4;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
}

@media screen and (max-width: 1441px) {
  .container {
    padding: 0 0.3rem;
  }
}