.tabs {
    margin-bottom: 1rem;
    width: 100%;

    & > * {
        flex-basis: 50%;
    }
}

.title {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.pair {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1.25rem;

    &.centered {
        align-items: center;
    }
}

.pass {
    display: flex;
    align-items: center;

    & b {
        flex-basis: 30%;
    }
}

.info {
    display: flex;
    flex-direction: column;

    & b {
        margin-bottom: 0.75rem;
    }
}

.doubleInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    width: 80%;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        width: 1rem;
        height: 1rem;
        left: -1.5rem;
        top: 0;
        background-image: url("./arrowUp.svg");
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "date label" "worker worker";
    row-gap: 1rem;
    margin-bottom: 1rem;

    & > div:nth-child(3) {
        grid-area: worker;
        margin-right: 0;
    }
}

.accept {
    text-align: right;
    font-weight: 500;
}

.moment {
    color: #707070;
    font-weight: 500;
}

.hr {
    width: 100%;
    height: 1px;
    background: #DBDBDB;
    margin-bottom: 1rem;
}

.mb {
    margin-bottom: 1.25rem;
}

.row2 {
    width: 100%;
    display: grid;
    grid-template-columns: 45fr 55fr;
    margin-bottom: 1.8rem;
    column-gap: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.date {
    font-weight: 500;
}

.time {
    font-weight: 500;
}

.label {
    font-weight: 500;
    color: #000;
    margin-right: 1rem;
    white-space: nowrap;
}

.cell {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #707070;
    font-weight: 500;
    max-width: 11rem;
}

@media screen and (max-width: 1441px) {
    .doubleInfo {
        width: 100%;
        &:before{
            left: -1.25rem;
        }
    }
}