.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  --stroke-color-datecell: #4fb1eb;
  --bg-color-datecell: #fff;

  & path {
    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(8) {
      fill: var(--bg-color-datecell) !important;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(7),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(12) {
      stroke: var(--stroke-color-datecell) !important;
    }
  }
}

.icon {
  margin-left: 0.625rem;
}
