.listRow {
  font-family: var(--main-font), sans-serif;
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 4px 0;
  min-height: 31px;
  border-bottom: 1px solid transparent;
  margin-left: -22px;
  margin-right: -14px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;

  &:nth-child(2n + 2) {
    background-color: #eff7fc;
  }
  &:hover,
  &:global(.hover) {
    background: #4fb2ed;
    color: #fff !important;

    & path {
      fill: #fff;
    }

    .listRow__title {
      &.active {
        color: #fff !important;
      }
    }

    & :global(.extraControl) {
      visibility: visible;
    }
  }
}

.loader {
  height: 62vh;
  display: flex;
  align-items: center;
}

.listRow__count {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  min-width: 2.1rem;
  height: 22px;
  border-radius: 20px;
  color: #fff;
  background: #c3c3c3;
  user-select: none;
  position: relative;
  z-index: 1;
  margin-right: 0.7rem;
  &.active {
    background: #4fb2ed;
  }
}

.listRow__icon {
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expenditureNumber {
  display: inline-block;
  width: auto;
  height: 15px;
  text-align: center;
  font-size: 0.8125rem;
  margin-left: -0.25rem;
  margin-right: 0.25rem;
}

.listRow__titleWrapper {
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 466px;
  height: 21px;
  display: flex;
  align-items: center;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    .listRow__title {
      left: 0;
      z-index: 1;
    }
  }
}

.listRow__title {
  padding-left: 22px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  &.active {
    color: #4fb2ed;
  }
}

.positive {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 2px;
    height: 1.375rem;
    border-radius: 3px;
    background-color: green;
    top: 2px;
    left: 12px;
  }
}

.negative {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 2px;
    height: 1.375rem;
    border-radius: 3px;
    background-color: red;
    top: 2px;
    left: 12px;
  }
}

.numberHolder {
  width: 1.25rem;
}

.borderTop {
  position: relative;
  margin-top: 1px;

  &::before {
    height: 1px;
    width: 100%;
    top: 0;
    background-color: #c3c3c3;
    position: absolute;
    content: "";
    left: 0;
  }
}
//v
.spinner {
  width: 1rem;
  height: 1rem;
}
