.btn {
  display: flex;
  height: 1.875rem;
  align-items: center;
  background: #d3efff;
  padding: 0 0.675rem;
  border-radius: 0.9rem;
  min-width: 2.75rem;
  justify-content: center;
  transition: color 0s !important;

  & svg {
    height: 0.875rem;
  }
}

.text {
  margin-left: 0.375rem;
  color: #000;
}

.empty {
  background-color: transparent;
}
