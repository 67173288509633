@import "customTableAnt";

:root {
  --main-color: AvenirNextCyr;
  --title-font: Montserrat;

  --input-height: 2.625rem;

  // background
  --color-background-main: #fff;
  --color-hawkes-blue: #ddf0fc;
  --color-background-primary: #4fb2ed;
  --color-background-field1: #daeefa;
  --color-background-field2: #bfe7ff;
  --color-background-field3: #f4effc;
  --color-background-field4: #f5f5f5;
  --color-background-field5: #efefef;
  --color-background-field6: #f4f4f4;
  --color-background-field7: #9ad9ff;
  --color-background-field8: #dbdbdb;
  --color-background-field9: #fafafa;
  --color-background-field10: #f2f2f2;
  --color-background-field11: #e0e0e0;
  --color-background-field12: #4fb1eb;
  --color-background-field13: #fff2de;
  --color-background-field14: #e6f4fc;
  --color-background-field15: #d0f2ff;
  --color-background-field16: #7061ea;
  --color-background-field17: #eff7fc;

  //divider
  --color-devider-main: #a3a3a3;
  --color-devider-line: #d9f1ff;
  --color-devider-field1: #bfbfbf;

  //border
  --color-border-primary: #4fb2ed;
  --color-chain-line: #a6d1ed;
  --color-ordinary-line: #d1d1d1;
  --color-border-secondary: #bfe7ff;
  --color-border-field1: #d0d0d0;
  --color-border-field2: #a8d0e8;
  --color-border-field3: #ebebeb;
  --color-border-field4: #c7c7c7;
  --color-border-field5: #9ad9ff;
  --color-border-field6: #d3d3d3;
  --color-border-field7: #c3c3c3;
  --color-border-field8: #d6d6d6;
  --color-border-field9: #8472e6;
  --color-border-field10: #d8d8d8;
  --color-border-field11: #8fe52b;
  --color-border-field12: #f2f2f2;
  --color-border-field14: #9870d0;
  --color-border-field15: #ef9d1c;
  --color-border-field16: #cecece;
  --color-border-field17: #e2e2e2;
  --color-border-field18: #a0a0a0;
  --color-border-field19: #bfbfbf;
  --color-border-field20: #bcbcbc;

  //font
  --color-font-main: #000;
  --color-font-hover: #fff;
  --color-font-primary: #4fb2ed;
  --color-font-secondary: #bababa;
  --color-font-secondary1: #707070;
  --color-font-secondary2: #666666;
  --color-font-secondary3: #878787;
  --color-font-secondary4: #606060;
  --color-font-secondary5: #333333;
  --color-font-secondary6: #bebebe;
  --color-font-secondary7: #727272;
  --color-font: #a4a4a4;
  --color-font2: #4a4a4a;
  --color-font3: #7f7f7f;
  --color-font4: #696969;
  --color-font5: #b4b4b4;
  --color-font6: #868686;

  //status
  --color-status-alarm: #ff0558;
  --color-status-active: #4fb2ed;
  --color-status-disabled: #a0a0a0;
  --color-status-approved: #47d6d1;
  --color-status-received: #a26dd0;
  --color-status-on-check: #b2b2b2;
  --color-status-on-created: #ef8932;

  --color-danger: #eb4f8b;
  --color-positive: #51ec51;
  --color-negative: #ec5151;

  //shadows
  --modal-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);

  //indicators
  --color-indicator-frostbite: #EA33AD
}
