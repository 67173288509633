.sectionInfo {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0.6875rem 1rem 0.6875rem 1.875rem;
  height: 100%;
  width: 100%;
  gap: 1rem;
}

.title {
  font-family: normal normal normal 1.12rem var(--title-font);
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.budget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 11.25rem;
}

.amount {
  color: var(--base-text-primary, #000);
  text-align: right;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
}

.description {
  color: var(--base-text-secondary, #696969);
  text-align: right;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
}

.budgetWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.amountWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.wide {
    width: 11.25rem;
  }
}

.divider {
  height: 100%;
  min-width: 1px;
  width: 1px;
  background-color: #C3C3C3;
  display: flex;
}