$containerBorderRadius: 1rem;

.selectedBox1 {
  width: 12.375rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.selectedBox2 {
  @extend .selectedBox1;
  position: absolute;
  z-index: 2;
  bottom: -2.6rem;
  border-radius: 0 0 10px 10px;
  background-color: #FFFFFF;
  cursor: pointer;
  box-shadow: var(--shadow-primary);
}

.selectedBlock {
  background-color: #FFFFFF;
  font: normal 500 normal 1rem/1.125rem var(--title-font);
  color: #606060;
  border-radius: $containerBorderRadius;
  margin-right: 1rem;
  position: relative;

  &.bordered {
    border: 1px solid #C9C9C9;

    .selectedBox2 {
      @extend .selectedBox1;
      box-sizing: content-box;
      width: 100%;
      position: absolute;
      z-index: 2;
      left: -1px;
      bottom: -2.6rem;
      border-radius: 0 0 10px 10px;
      background-color: #FFFFFF;
      cursor: pointer;
      box-shadow: var(--shadow-primary);
      border: 1px solid #C9C9C9;
    }
  }
}

.iconStatus {
  border-right: 1px solid #C9C9C9;
}

.arrow {
  transition: all 0.2s ease;
  width: 1rem;
  position: absolute;
  right: 1rem;
}

.arrowReverse {
  transform: rotate(-180deg);
}


.div1 {
  width: 1.1rem;
}

.selectedBlockBorder {
  border-radius: 15px 15px 0 0;
}

.approve, .cancel {
  width: 83%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 0.813rem;
}

.arrowContainer {
  align-self: stretch;
  display: flex;
  align-items: center;
  width: 17%;
  position: relative;
}

.iconStatus {
  padding-right: 0.5rem;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .5);
  border-radius: $containerBorderRadius;
}