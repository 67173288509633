.header {
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4fb2ed;
  font-weight: 500;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
  padding-left: 1.5rem;
}

.content {
  padding: 1rem 0;
}

.row {
  padding: 0.25rem 1.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.5rem;
  min-height: 2.5rem;

  &:nth-child(odd) {
    background-color: rgb(196, 236, 254);
  }
}
