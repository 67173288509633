.selectContainer {
  margin-left: auto;
  height: 2.813rem;
  min-width: 15rem;
}

.selectContent {
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 0.5rem;

  & > div {
    position: relative;
    border-left: none !important;

    &:before {
      content: "";
      position: absolute;
      width: 1px;
      background-color: #707070;
      height: 1.3rem;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.selectOptions {
  background-color: #f5f5f5 !important;
  border-top-color: transparent;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  margin: 0;
  box-shadow: 0 3px 6px rgb(0 0 0 / 4%);
}

.selectOption {
  background-color: #f5f5f5 !important;
  box-shadow: none !important;
  border-top: 1px solid #bebebe;

  &:last-child {
    border-radius: 0 0 0.625rem 0.625rem;
  }
}

.selectItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-right: 0.625rem;

  span {
    font-size: 1.125rem;
  }
}

.downloadIcon {
  width: 1.75rem;
  margin-left: auto;
}

.downloadOption {
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 0.9rem;
}
