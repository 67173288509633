.templateNarrow{
  padding: 1.88rem 1rem;
}

.headerNarrow{
  padding: 1.88rem 3.88rem 1rem;
}

.container {
  display: flex;
}

.content {
  width: calc(100% - 4.4rem);
  background: #fff;
  padding: 1.88rem 4.88rem;
  overflow-y: scroll;
  height: calc(100vh - 3.7rem);
  overscroll-behavior-y: none;
}