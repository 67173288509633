.rowContainer {
  height: 6.25rem;
}

.row {
  height: inherit;
  grid-template-columns: 3% 2% 34% 10% 8% 8% 13% 9% 13%;

  & > div {
    position: relative;

    &:nth-child(n + 4) {
      padding-left: 0.6875rem;
      padding-right: 0.6875rem;
    }
  }
}

.noApprovals {
  grid-template-columns: 3% 2% 54% 10% 8% 9% 14%;
}

.roundLabel {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50px;

  &.redLabel {
    background-color: var(--color-indicator-frostbite);
  }
}

.nameCell {
  position: relative;
  display: flex;
  align-items: center;
  height: inherit;
}

.title {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.divider {
  height: 3rem;
  width: 2px;
  background: #cecece;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  flex-shrink: 0;
}

.matching {
  margin-left: auto;
  margin-right: 2rem;
  width: 2.5rem;

  & button {
    width: 2rem !important;
    height: 2rem !important;
  }
}

.centered {
  text-align: center;
  padding-right: 2rem;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
}

.expendituresInGroupCount {
  color: var(--color-font-primary);
  background-color: white;
  border-radius: 0.25rem;
  white-space: nowrap;
  padding: 0.3125rem 0.82rem 0.375rem 0.6875rem;
}

.titleContainer {
  width: calc(100% - 6rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 0.72rem;
  padding: 0.72rem 0 0.56rem;
  height: inherit;
  overflow: hidden;
}

.blueLabel {
  font-size: 0.875rem;
  background-color: white;
  color: var(--color-background-primary);
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-primary);
    color: #fff;
  }
}
