$tooltipColor: #c1e7fd;

.tooltip {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  width: max-content;
  text-align: center;
  display: block;
  padding: 0.5rem 0.625rem;
  box-sizing: border-box;
  background-color: $tooltipColor;
  border-radius: 0.3rem;
  cursor: pointer;
}

.popoverContainer {
  z-index: 999;
  position: absolute;
  height: inherit;
  width: inherit;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.portal {
  cursor: pointer;
}

.portal :global(.bp4-popover2 .bp4-popover2-arrow-fill) {
  fill: $tooltipColor;
}
