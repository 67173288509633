.content {
  padding: 1.5rem 1.5rem 3rem;
  flex-grow: 3;
}

.header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 2rem 0.5rem 1.5rem;
  border-bottom: 1px solid #cdcdcd;
  min-height: 2.5rem;
  position: sticky;
  top: 5rem;
  background-color: #fff;
  z-index: 2;
}

.back {
  padding: 0.5rem 1.5rem;
}

.btnModule {
  margin-left: auto;
}

.instructionName {
  color: var(--color-background-primary);
  cursor: pointer;
}

.sectionName {
  cursor: pointer;
}

.videoWrapper {
  border: 2px solid #4fb1eb;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  background-color: #000;
}

.slideTitle {
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.videoRow {
  display: flex;
  gap: 1rem;
  max-width: 100%;
  overflow: auto;
}

.videoSlide {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  video {
    display: block;
  }
}

.videoTitle {
  font-weight: 600;
  font-size: 1.125rem;
  margin-top: 0.5rem;
}
