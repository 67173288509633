.settingsIcon {
  cursor: pointer;
}

.content {
  padding: 1rem;
  min-width: 300px;
}

.title {
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 500;
  width: 100%;
  display: block;
}

.form {
  display: grid;
  gap: 1rem;
}

.switcher {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.popover {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}
