.modal {
  width: 33.75rem;
  //min-height: 18rem;
  max-height: 50rem;
  overflow: visible;
}

.title {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  z-index: 99999999999;
  border-radius: 1rem 1rem 0 0;
  div {
    margin: auto;
  }
}
