.taskFiles {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.filesHead {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  & span {
    color: #696969;
    font-family: "AvenirNextCyr";
  }

  b {
    font-weight: 600;
  }

  & div {
    color: #4fb2ed;
    cursor: pointer;
  }
}

.empty {
  padding: 1.25rem 0;
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: center;
  width: 100%;
}
