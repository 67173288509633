.container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.4);
  padding: 8px 16px;
  gap: 20px;
}

.control {
  cursor: pointer;
  color: #fff;
}

.disabled {
  pointer-events: none;
  color: #a4a4a4;
}
