.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border-radius: 100%;
}

.right {
  right: 1rem;
  position: fixed;
  top: calc(50% + 6.95rem);
}

.left {
  position: sticky;
  left: 100%;
  top: calc(50% + 2.25rem);
  transform: translateX(3.5rem);
}

.icon {
  height: 0.75rem;
}

.iconLeft {
  transform: scaleX(-1) translateX(0.055rem);
}
