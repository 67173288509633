.popupBlock {
  :global(.bp3-popover2-arrow) {
    width: 30px;
    height: 30px;
    z-index: 1;

    :global(.bp3-popover2-arrow-border) {
      fill: #bfbfbf;
    }

    :global(.bp3-popover2-arrow-fill) {
      fill: white;
    }
  }
}

.commentPopup {
  padding: 12px 9px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #bfbfbf;
  border-radius: 5px;
  width: 17.3125rem;
  max-height: 25.8125rem;
  position: relative;
}

.commentPopup__text {
  font-size: 0.875rem;
  color: #000;
  font-weight: normal;
}

.commentPopup__buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 9px;
  width: 100%;
  background-color: #fff;
  padding-top: 0.625rem;
}

.commentPopup__button {
  flex-grow: 1;
  justify-content: center;
}

.commentField, .addComments {
  margin-top: 1rem;
  height: 5rem;
}

.commentField {
  position: relative;
  cursor: pointer;

  .closeIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    overflow: hidden;
  }

  .textArea {
    padding-right: 15px;
    font-size: 0.875rem;
    color: #000;
    font-weight: normal;
    min-height: auto;
    height: 100%;
  }
}

.addComments {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 0.5rem;
  padding: 1.375rem 0 0.375rem 0;

  .addCommentsText {
    font-size: 0.875rem;
    color: #707070;
    font-weight: normal;
    margin-top: 0.1rem;
  }
}

.positionMessage {
  position: relative;
  cursor: pointer;

  .addIcon {
    position: absolute;
    top: -14px;
    left: 19px;
  }
}

.listFixedComments {
  position: relative;
  max-height: 14.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.2rem;
}

.comment {
  padding: .375rem .375rem 1rem .375rem;
  margin-bottom: 1.359375rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #000;
  font-size: 0.75rem;
  font-weight: normal;
  background-color: #E3F5FF;

  .forRelative {
    position: relative;
  }

  .additionalData {
    position: absolute;
    top: .3rem;
    left: -.375rem;
    right: -.375rem;
    height: 1.5rem;
    background-color: #4FB2ED;
    border-radius: .75rem;
    color: #fff;
    font-size: .75rem;
    padding: .28125rem .325rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .messageSender {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: .1875rem;
      flex-basis: 80%;
    }

    .dataAndTime {
      display: flex;
      align-items: center;
      border-left: 1px solid #fff;
      padding-left: .52875rem;
      flex-basis: 20%;

      .sendTime {
        padding-left: .52875rem;
      }
    }
  }
}

.commentText {
  word-break: break-word;
}
