.card {
  padding: 0.469rem 0.656rem;
  display: flex;
  flex-direction: column;
  gap: 0.688rem 0;
}

.card, .label {
  font-size: 0.875rem !important;
}

.label, .bold {
  font-weight: 500 !important;
}

.block {
  display: flex;
  justify-content: space-between;
}

.blockName {
  width: 9rem;
}

.supply {
  display: flex;
  justify-content: space-between;
}

.calendar {
  margin-right: 0.25rem;
}

.selectSpace{
  min-height: 2.6rem;
}