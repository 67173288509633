.modal {
  width: 50rem;
}

.container {
  padding: 1rem 2rem;
}

.header {
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font: normal normal 1rem/1.25rem var(--title-font);
}

.scroll {
  max-height: 20rem;
  overflow-y: auto;

  &.loading {
    min-height: 20rem;
  }
}