.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.75rem;
}

.row {
  display: flex;
  justify-content: space-between;
  min-height: 2.625rem;
  width: 100%;
  gap: 1rem;
}

.leftSide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
  flex-grow: 0;
  overflow: hidden;
}

.back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.backTitle {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  line-height: normal;
  margin: 0 0 0 1rem;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rightSide {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2.5rem;
  flex-shrink: 0;
}

.modeSelect {
  flex-shrink: 0;
  width: 17.3rem;
  height: 2.625rem;

  color: var(--base-text-primary, #000);
  font-family: Montserrat, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  line-height: 1.25rem; /* 111.111% */
}

.modeSelectOption {
  color: var(--base-text-primary, #000);
  font-family: Montserrat, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  line-height: 1.25rem; /* 111.111% */
}

.modeSelectIcon {
  width: 0.75rem !important;
  height: 0.75rem;
}

.uploadButton {
  color: var(--base-white, #fff);
  font-family: Montserrat, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  line-height: 1.5rem; /* 133.333% */
  flex-shrink: 0;
}

.visibilityModalButton,
.createSectionButton,
button.importDocumentationButton {
  position: static;
  top: unset;
  left: unset;
  z-index: unset;
  margin-bottom: 0;
  flex-shrink: 0;

  color: var(--base-text-primary, #000);
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.25rem; /* 125% */
}

.uploadFile {
  flex-shrink: 0;
}

.moveItemsButton {
  display: flex;
  width: 17.3rem;
  padding: 0.6875rem 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;

  border: 1px solid var(--blue-blue-accent, #4fb1eb);
  background: var(--base-white, #fff);

  color: var(--blue-blue-accent, #4fb1eb);
  font-family: Montserrat, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  line-height: 1.25rem; /* 111.111% */

  &.disabled {
    color: var(--blue-blue-hover, #9ad9ff);
    border-color: transparent;
    background: var(--blue-background-field-17, #eff7fc);
    cursor: not-allowed;
  }

  & .text {
    white-space: nowrap;
  }
}

.checkbox {
  margin-left: 2rem;
}

.checkbox,
.checkedCount {
  color: var(--base-text-secondary, #696969) !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 0.9375rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.withBorderBottom {
  border-bottom: 1px solid var(--color-border-primary);
}

.createSectionInput {
  width: 100%;
}

.checkAllButton {
}

.moveItemsSpinnerContainer {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;

  .moveItemsSpinner {
    width: 1rem;
    height: 1rem;
  }
}

.addButton {
  flex-shrink: 0;
}
