.header {
  padding-top: 1rem;
  position: sticky;
  top: 2rem;
  background-color: #fff;
  z-index: 2;
}

.navbar {
  margin-bottom: -1rem;
  position: relative;
  z-index: 3;
}
