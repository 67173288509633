.wrapper {
  padding: 0 1.563rem;

  &:last-child {
    padding-bottom: 5rem;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
}

.mb {
  margin-bottom: 0.875rem;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.calendar {
  margin: 0 0.75rem;

  @media screen and (max-width: 1680px) {
    margin: 0 0.438rem;
  }
}

.text {
  font-weight: 400;
  color: var(--color-font-secondary1);
}

.name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-left: 0.625rem;
  width: 100%;
}

.minWidth {
  min-width: 3.875rem;
}

.rightBlock {
  width: 58%;

  @media screen and (max-width: 1680px) {
    width: 52%;
  }
}

.description {
  margin-top: 1.125rem;
  margin-bottom: 1.375rem;

  & span {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.regulations {
  margin-top: 1.125rem;
  margin-bottom: 1.375rem;

  & span {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.files {
  & > span {
    display: block;
    margin-bottom: 0.688rem;
  }
}