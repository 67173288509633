.input {
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.updatedAt {
  width: 100%;
  text-align: right;
  color: #a4a4a4;
  font-weight: 500;
}

.grid {
  display: grid;
  //gap: 1.5rem;
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
  bottom: -1.25rem;
  white-space: nowrap;
}
