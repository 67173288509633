.icon {
  cursor: pointer;

  &:hover {
    & path {
      fill: #4fb1eb;
    }
  }
}

.double {
  display: flex;
  height: var(--input-height);
  position: relative;
}

.halfInput {
  & input {
    border-radius: 0;
  }
}

.firstInput {
  & input {
    border-radius: 0.5rem 0 0 0.5rem;
    padding-right: 1.5rem;
  }
}

.filledInputs {
  position: relative;
  z-index: 10;

  & input {
    border-right: none !important;
  }

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 1rem;
    background-color: var(--color-border-field18);
    top: 50%;
    right: 0;
    transform: translateX(50%);
  }
}

.secondInput {
  & input {
    border-radius: 0 0.5rem 0.5rem 0;
    padding-left: 1.5rem;
    border-left: none !important;
  }
}
