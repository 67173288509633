.form {
  padding-bottom: 7rem;

  & label {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    color: #000;
    margin-bottom: 0.5rem;
  }

  & textarea {
    border: 1px solid var(--color-border-field18);

    &:focus {
      border-color: var(--color-border-primary);
    }
  }
}

.paddings {
  padding: 0 1.25rem;
}

.field {
  margin-bottom: 1.25rem;
}

.calendarSelect {
  min-height: 2.625rem;
}
//
//.calendarOptions {
//  left: 0;
//}

.responsible {
  display: flex;
  align-items: center;
  min-height: 2.625rem;

  & svg {
    cursor: pointer;
    width: 2.125rem;
    height: 2.125rem;
  }

  & .selected {
    position: relative;
    height: 2.625rem;
    width: 100%;
    padding: 0.625rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    background-color: var(--color-background-field12);
    color: #fff;

    & svg {
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: 5%;
      top: 30%;
    }
  }
}

.cancel {
  cursor: pointer;

  & svg {
    margin-right: 0.938rem;
  }
}

.table {
  margin-top: -2.188rem;
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: var(--color-status-alarm);
  font-weight: 400;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar {
  width: 45%;
}

.files {
  margin-top: 1.875rem;
}

.deleteRemark {
  width: 100%;
  margin-top: 0.938rem;
  display: flex;
  justify-content: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    width: 13.813rem;
    border-color: var(--color-status-alarm);
    padding: 0;

    &:hover, &:active {
      border-color: var(--color-status-alarm);
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;
  margin-top: 1rem;
  border-top: 1px solid var(--color-border-field10);

  & button {
    min-width: 12.25rem;
    display: flex;
    justify-content: center;

    &.buttonCancel {
      margin-right: 0.625rem;
    }
  }
}
