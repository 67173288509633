@use "src/scss/dimensions.scss" as dimensions;

.header {
  z-index: 99;
  padding: 1rem 2.5% 0;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2.5%;
  font-family: var(--title-font);
  overflow: hidden;
  background-image: linear-gradient(to bottom, rgba(244, 244, 244, 0.5), rgba(244, 244, 244, 0.05));
  backdrop-filter: blur(.1rem);
}

.headerLinks {
  padding: 0;
  margin: 0;
  justify-content: flex-start;
  flex-grow: 1;
  column-gap: 5%;

  & li {
    display: inline-block;
    position: relative;
    width: auto;

    & a {
      color: var(--color-font-primary)
    }
  }
}

.actionButton, .headerLinks {
  display: none;
}

.actionButton {
  flex-shrink: 0;
}

.menuToggleButton {
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
  }
}

.openHeader {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 4rem 10%;
  z-index: 99;

  .actionButton {
    display: block;
    width: 100%;
    max-width: 25rem;
  }

  .headerLinks {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    width: 100%;
    max-width: 25rem;

    & li {
      padding: .75rem 0;
    }

    & li > a:after, & li:first-child > a:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #d9d9d9;
    }

    & li:first-child > a:before {
      top: 0;
    }
  }

  .logoGroup {
    align-self: center;
    transform: scale(.7);
  }

  .menuToggleButton {
    position: absolute;
    right: 2.5%;
    top: 3rem;
  }
}

.logoGroup {
  transform: scale(.7) translateX(-20%);
  font-weight: normal;
}

.desktopSignIn {
  display: none;
}


@include dimensions.breakpoint-from(640px) {
  .headerLinks {
    display: flex;
  }
}

@include dimensions.breakpoint-from(768px) {
  .actionButton{
    display: block;
  }
}

@include dimensions.breakpoint-from(1000px) {
  .desktopSignIn {
    display: flex;
  }
  .header .menuToggleButton {
    display: none;
  }
  .logoGroup {
    transform: none;
  }
  .header {
    padding: 2rem 3.75% 0;
  }
}

@include dimensions.breakpoint-from(1440px) {
  .header{
    column-gap: 5%;
    padding: 4rem 7.5% 0;
  }
}

.desktopButtons{
  display: flex;
  align-items: center;
  column-gap: 1rem;
}