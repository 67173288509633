.flex {
  display: grid;
  width: 100%;
  min-height: 100%;
  grid-template-columns: 18rem 1fr;
}

.nav {
  background: #fafafa;
  border-right: 1px solid #ebebeb;
}

.header {
  border-bottom: 1px solid #ebebeb;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1.25rem 1.25rem 1rem 1.25rem;

  & span {
    color: #4fb1eb;
  }
}

.navContent {
  padding: 0.5rem;
}

.trigger {
  cursor: pointer;
  margin-right: -0.5rem;
}

.content {
  padding: 1.25rem 2rem 4rem;

  & img {
    display: block;
    max-width: 100%;
    margin: 1rem;
    margin: 0 auto;
  }
}

.video {
  width: 100%;
  padding: 1rem;

  & video {
    width: 80%;
    margin: 0 auto;
    display: block;
  }
}

////

.progress {
  margin-top: 0.5rem;
  justify-content: space-between;
  gap: 0.5rem;
  height: 1.1rem;

  & > div {
    flex-grow: 2;
  }
}

.title {
  width: 100%;
  display: block;
}

.subtitle {
  height: calc(1.5rem + 1px);
}

.item {
  padding: 0.375rem 0.75rem 0.375rem 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  border-left: 5px solid transparent;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.active {
  background-color: #ebebeb;
  border-left: 5px solid #4fb1eb;
}

.disabled {
  pointer-events: none;
  color: #c3c3c3;
}

.bottom {
  border-top: 1px solid #ebebeb;
  display: flex;
  padding: 1rem 2rem 1.25rem 2rem;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  margin-left: -2rem;
  width: calc(100% - 18rem);
  background-color: #fff;
}

.prev {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  font-weight: 500;
}

.next {
  font-weight: 500;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  & > div {
    transform: scaleX(-1);
  }
}

.btnDisabled {
  & > div {
    background-color: #c3c3c3;
  }

  color: #c3c3c3;
  pointer-events: none;
}

///////////////////////////
///
///
.modal {
  width: 70.5rem;
}

.grid {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
}
