$emptyColor: #ff7499;
$filledColor: var(--color-border-primary);

.datesPlate {
  border-radius: 0.3125rem;
  padding: 0.125rem 0.775rem 0.125rem 0.1875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.35rem;
  margin-bottom: 0.25rem;
}

.datesPlate:nth-last-child(-n + 1) {
  margin-bottom: 0;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.75rem;
}

.actions hr {
  height: 1rem;
  width: 1px;
  margin: 0;
  border: none;
  border-radius: 0.125rem;
  background-color: #a8a8a8;
}

.icon {
  opacity: 0.2;
  pointer-events: none;
}

.iconActive {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

.calendarInput {
  padding: 0.1875rem 0.4rem 0.25rem 0.3125rem !important;
  min-width: 8.875rem;
  max-height: 2.188rem;
}

.calendarInputIcon {
  margin-left: auto;
}

.calendarInputEmpty {
  border: 1px solid $emptyColor;
}

.calendarInputFilled {
  border: 1px solid $filledColor;
}

.modalPortal {
  z-index: 99999;
}

.infoContent {
  padding: 1.3rem 0.9rem 0.5rem 1.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1.2rem;
  flex-wrap: nowrap;
}

.infoContentElement {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  position: relative;
  &:last-child {
    padding-left: 0.75rem;
    border-left: 1px solid var(--color-border-field16);
  }
}

.infoContentElementTitle {
  font-size: 1.1875rem;
  font-family: var(--title-font);
}

.infoContentElementSubtitle {
  font-size: 0.8125rem;
  color: var(--color-font-secondary1);
}

.popoverAnchor {
  display: flex !important;
}

.doubleInput > div {
  display: grid;
  grid-template-columns: 30% 70%;
}

.doubleInput label:first-child::after {
  left: 30%;
}
