.head {
  position: sticky;
  z-index: 21;
  top: 0;
  background-color: #fff;

  &::before {
    content: "";
    background-color: white;
    left: -1rem;
    right: 0;
    bottom: 100%;
    top: -1.88rem;
    position: absolute;
  }

  &::after {
    content: "";
    background-color: white;
    width: 1rem;
    top: 0;
    bottom: 0;
    left: -1rem;
    position: absolute;
    z-index: -1;
  }
}

@media screen and (max-width: 1439px) {
  .outerTemplate {
    padding-left: 3.88rem;
    padding-right: 2rem;
  }
}
