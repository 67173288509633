@use "src/scss/dimensions.scss" as dimensions;

.title {
  display: inline-block;
  text-transform: uppercase;
  margin: 0 0 .75rem 0;
  line-height: 1.9375rem;
  font-size: 1.375em;
  text-align: left;
}

.subtitle {
  font-weight: normal;
  margin: 0;
  padding: 0;
  line-height: 1.375rem;
  font-size: .875rem;
  text-align: left;
}

.container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 1.5rem 2.875rem;
  overflow: hidden;
  background-image: linear-gradient(90deg, rgba(244, 244, 244, 0.2) 63%, rgba(244, 244, 244, 1) 100%)
}

@include dimensions.breakpoint-from(640px) {
  .container {
    padding: 1.5rem 7.5%;
  }

  .title:first-child {
    font-size: 1.75em;
    margin-bottom: .75rem;
  }

  .title {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }

  .subtitle {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@include dimensions.breakpoint-from(950px) {
  .container {
    padding: 1.5rem 11.25%;
  }
}