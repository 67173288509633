.link {
  // width: 50%;
  
}
.container {
  // margin: 0.2rem;
  padding: 0.5rem 0.5rem;
  position: relative;
  white-space: nowrap;
  width: 100%;

  &:hover {
    .titlePopup {
      display: block;
    }
  }
  &.active {
    background-color: #F7F7F7;
    border: 1px solid #4FB2ED;
    border-radius: 4px;
  }
  .icon {
    width: 1.2rem;
    margin-right: 1rem;
  }
  .title {
    font: 1em var(--main-font);
    color: #000;
  }
}
.active .container{
  background-color: #F7F7F7;
  border: 1px solid #4FB2ED;
  border-radius: 4px;
}