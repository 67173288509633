.draft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.title {
  font: normal normal 300 1.125rem/1.375rem var(--title-font);
  margin: 2rem 0;
}

.button {
  margin-top: 4.25rem;
}