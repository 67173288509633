.flex {
  display: grid;
  width: 100%;
  min-height: 100%;
  grid-template-columns: 18rem 1fr;
}

.nav {
  background: #fafafa;
  border-right: 1px solid #ebebeb;
}

.header {
  border-bottom: 1px solid #ebebeb;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1.25rem 1.25rem 1rem 1.25rem;

  & span {
    color: #4fb1eb;
  }
}

.navContent {
  padding: 0.5rem;
}

.searchInput {
  background-color: transparent;
  padding-left: 2.5rem;
  background-image: url("./search.svg");
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  transition: padding 0.1s;

  &:focus {
    padding-left: 0.5rem;
    background-image: none;
  }
}

.trigger {
  cursor: pointer;
  margin-right: -0.5rem;
}

.content {
  padding: 1.25rem 2rem;

  & img {
    display: block;
    max-width: 100%;
    margin: 1rem auto;
  }
}

.video {
  width: 100%;
  padding: 1rem;

  & video {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
}

.searchWrapper {
  margin-bottom: 1rem;
}

//

.modal {
  width: 70.5rem;
}

.root {
  padding: 1.5rem;
}

.secondaryItems {
  border-top: 1px solid #ddd;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
  gap: 1rem;

  h3 {
    display: none;
  }

  & > div > div {
    display: block;
  }
}
