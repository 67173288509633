.row {
    padding-left: 1.5rem;
    padding-top: 1.88rem;
    grid-template-columns: 5% 13% 18% 15% 13% 14% 15% 7%;
    top: 2rem !important;
    z-index: 2 !important;
}

.inProduction {
    grid-template-columns: 8% 28% 15% 13% 14% 15% 7%;
}

.reload {
    cursor: pointer;
    text-decoration: underline;
}
