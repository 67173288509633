.listHeader {
  display: grid;
  grid-template-columns: 2.875% 58.6317% 11.4351% 11.4351% 11.4351% 4.0022%;

  padding: 0 1.88rem;

  border-radius: 0.5rem;
  border-top: 1px solid var(--gray-gray-3, #EBEBEB);
  border-bottom: 1px solid var(--gray-gray-3, #EBEBEB);
  background: var(--gray-gray-2, #F4F4F4);

  position: sticky;
  top: 19rem;
  z-index: 17;

  &.approveForm {
    top: -2px;
  }

  &.budget,
  &.markup {
    grid-template-columns: 2.875% 46.4675% 11.4351% 14.0652% 11.4351% 13.7221%;
  }

  &.estimatedPrice {
    grid-template-columns: 2.875% 43.4675% 11.4351% 14.0652% 14.4351% 13.7221%;
  }

  &.canConfirm {
    grid-template-columns: 2.875% 52.6317% 11.4351% 11.4351% 11.4351% 10.0022%;
  }
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem 0;

  color: var(--base-text-primary, #000);
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 1.375rem;

  &.withBorder {
    border-right: 1px solid #C3C3C3;
  }

  &.isLeft {
    justify-content: flex-start;
  }
}

.estimatedPriceColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  span {
    padding: 0.25rem 0;
  }
}

.diff {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  height: 100%;

  div {
    display: flex;
    justify-content: center;
    align-self: stretch;
    width: 50%;

    &.diffPercent {
      border-right: 1px solid #C3C3C3;
    }
  }
}