.ks2row {
  display: grid;
  grid-template-columns: 4% 15% 1fr 12% 4% 12%;
}

.openKs2Row {
  & .ks2row {
    position: sticky;
    z-index: 1;
    top: 6rem;
    background-color: #fff !important;
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

.openKs2RowInNested {
  & .ks2row {
    position: sticky;
    z-index: 1;
    top: 9rem;
    background-color: #fff !important;
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

.ksName {
  font-weight: 500;
}

.parent {
  background: #fff;

  & > div {
    background: #fff;
  }
}

.head {
  height: 3rem;
}

.headRow {
  height: 3rem;
  display: grid;
  grid-template-columns: 4% 45% 14.4% 8% 8% 8% 15.5%;
}

.nav {
  position: sticky;
  z-index: 2;
  top: 2.8rem;
  background-color: #fff;
}

.number {
  & > div {
    text-overflow: unset !important;
  }
}
