.header {
    padding: 1.375rem 1.5rem 1rem 1.5rem;
    border-bottom: 1px solid #d8d8d8;

    & * {
        margin-bottom: 0;
    }

    & p {
        margin: 0.56rem 0;
        color: #707070;
    }

    & h3 {
        font-size: 1rem;
        font-weight: 400;
    }
}

.content {
    padding: 1.25rem 1.5rem 3rem;
}

.modal {
    width: 34rem;
}

.table {
    padding-top: 1rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

.files {
    padding-top: 1rem;
}
