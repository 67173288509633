.title {
  font-size: 1rem;
  line-height: 1.125rem;
  margin-bottom: 0.5rem;
}

.list {
  display: flex;
  flex-direction: column;
  max-height: 16.25rem;
  overflow-y: auto;
  gap: 0.5rem 0;
  padding-right: 0.203rem;
}