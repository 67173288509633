.switchBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.text {
  cursor: pointer;
  margin-right: 1rem;

  &.s {
    font-size: 14px;
  }

  &.m {
    font-size: 16px;
  }
}

.textRight {
  cursor: pointer;
  margin-left: 1rem;

  &.s {
    font-size: 14px;
  }

  &.m {
    font-size: 16px;
  }
}
