.requisition {
  padding: 1rem 2rem;
  background-color: var(--color-background-field9);
  min-height: 100%;
}

.alignRightButton {
  margin-left: auto;
}

.footer {
  display: flex;
  padding: 1.65rem 2.53rem;
  gap: 0 0.5rem;
}

.buttons {
  display: flex;
  gap: 0 0.5rem;
}

.completeButton {
  align-self: flex-end;
}
