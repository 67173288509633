.title{
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  position: relative;
  white-space: nowrap;

  .icon{
    padding-right: 1rem;
  }
}

.titleContainer {
  display: flex;
  width: 100%;
}

.hiddenBlock{
  width: 100%;
}

.hiddenBlock-enter {
  max-height: 0;
}
.hiddenBlock-enter-active {
  max-height: 100vh;
}
.hiddenBlock-exit {
  max-height: 100vh;
}
.hiddenBlock-exit-active {
  max-height: 0;
}
