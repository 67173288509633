.chain{
  display: flex;
  flex-direction: column;
}

.chainLine {
  margin: 0 0 0 .8125rem;
  height: 1rem;
  width: 0;
}

.nextConfirmed{
  border: 1px solid #A0E84D;
}

.nextWaiting{
  border: 1px dashed var(--color-background-field12);
}