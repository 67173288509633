.modal {
  width: 35rem;
  padding-bottom: 3.813rem;
}

.body {
  padding: 0 1rem;
  border-bottom: 1px solid var(--color-border-field6);

  .item {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    img {
      margin-right: 0.5rem;
    }
  }
}

.link {
  &, &:hover {
    color: #000;
  }
}