.btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    background: #4FB1EB;
    color: #fff;
    height: 1.5625rem;
    border-radius: 0.78rem;
    padding: 0 0.625rem;
    display: flex;
    align-items: center;
    text-decoration: underline;
}