.row {
  display: grid;
  grid-template-columns: 25% 20% 20% 35%;
}

.actionsContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 1.1875rem;
}

.buttonGroup {
  height: 2.5rem;
  width: auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 2rem;
  transition: 0.1s all ease-in-out;
  background-color: #4fb2ed;
}

.settingsBtn {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rowContainer:hover {
  & .buttonGroup {
    background-color: #fff;

    & path {
      fill: #4fb2ed;
    }
  }

  & .progress {
    color: #fff !important;
  }
}
