.expenditures {
  display: flex;
  flex-direction: column;
  gap: 0.75rem 0;
  padding: 0 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 38.5rem;
  margin: 0 0.75rem;
}

.footer {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  border-top: 1px solid #D3D3D3;
  margin-top: 0.5rem;
  padding: 0.5rem 0;
}