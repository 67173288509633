.container {
    background: #fff !important;
    cursor: pointer;
    position: sticky;
    z-index: 3;
    top: 5rem;
    font-size: 13px;

    &:not(:hover) {
        border-radius: 0 !important;
    }

    &:hover {
        background: #DEF0FF !important;
        & .hidden {
            display: block;
        }
    }
}

.grid {
    display: grid;
    padding-left: 1rem;
    grid-template-columns: 5% 35% 10fr 10fr 5fr 10fr;
}

.name {
    font-size: 1rem;
}

.sections {
   width: 12rem;
}

.zIndexIncreased {
    z-index: 6;
}

.hidden {
    display: none;
}

.mark {
    width: 4px;
    height: 12px;
    margin-right: 0.75rem;
    border-radius: 2px;
}

.expired {
    background: #FF0558;
}

.expiring {
    background: #FF8900;
}