.remarks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 1.5rem;
}

.remark {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 0.5rem;

  width: 100%;
  padding: 0.75rem 0 1rem;

  font-family: var(--title-font);
  font-size: 1rem;
  line-height: 1.25rem;

  &:not(:first-child) {
    border-top: 1px solid var(--color-border-field17);
  }

  &:last-child {
    border-bottom: none;
  }
}

.resolveStatus {
  display: flex;
  color: #ef9d1c;
  align-items: center;

  &.isOnReview {
    color: #a4a4a4;
  }
}

.resolve,
.descr,
.responsible {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.375rem;
}

.resolveDate {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
  flex-wrap: nowrap;
}

.bold {
  font-weight: 600;
}

.iconCalendar {
  flex-shrink: 0;
}

.point {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: var(--color-border-field15);
  margin-right: 4px;

  &.isOnReview {
    background-color: #a4a4a4;
  }
}

.descr {
  grid-column-start: 1;
  grid-column-end: 3;
}

.file {
  cursor: pointer;
  & p {
    white-space: nowrap;
  }
}
