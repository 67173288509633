.container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
}

.colorPopupPortal {
  // Выше модального окна
  z-index: 111;
}

.shadow {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  // Ниже кнопки закрытия модального окна
  z-index: -1;
}

.closeButtonBlock {
  cursor: pointer;
  position: absolute;
  // Ниже модального окна
  z-index: -1;
  top: 1rem;
  right: -2.3rem;
  background-color: var(--color-background-primary);
  border-radius: 21px;
  width: 4rem;
  height: 4.5rem;
  padding: 0.9rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 1.56rem;
  left: 50%;
  max-width: 42.4rem;
  padding: 1.5rem;
  border-radius: 1.2rem;
  background: #fff;
  cursor: default;
}

.modalHeading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  /* font-weight: 600; */
  font-size: 1.25rem;
}

.preview {
  position: relative;
  // Для отображения попапа выше остальных элементов модального окна
  z-index: 1;
  width: 100%;
  height: 5rem;
  padding: 0.5rem 0;
  border-top: 1px dashed #ececec;
  border-bottom: 1px dashed #ececec;
}

.interval {
  position: relative;
  top: 1rem;
  left: 3rem;
  transform: scale(2) !important;
}

.intervalFill {
  position: absolute;
  left: 0;
  height: 100%;
}

.controlsGrid {
  grid-template-columns: 1rem minmax(auto, 14rem) 1fr 1fr;
  column-gap: 2rem;
}

.row {
  background: none;
  &:hover {
    background: #e8f7ff;
    .statusTitleInput {
      border: 1px solid var(--color-border-field18);
    }
    .statusRange {
      opacity: 1;
    }
  }
}

.statusIndex {
  font: normal normal 500 1.125rem var(--title-font);
}

.statusColor,
.statusRange {
  -webkit-appearance: auto;
}

.statusRange {
  opacity: 0.5;
  /* transition: opacity 0.3s; */
}

.statusColor {
  width: 1px;
  height: 1px;
  opacity: 0;
  pointer-events: none;
}

.statusColorDisplay {
  width: 3rem;
  height: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  outline: 1px solid #e0e0e0;
  &:hover {
    outline: 1px solid var(--color-background-primary);
  }
}

.statusTitleInput[class] {
  border: 1px solid transparent;
  transition: border 0s;
  min-width: 100%;
  background-color: var(--input-background-color);
  &:focus {
    border: 1px solid var(--color-background-primary);
  }
}

.statusTitle {
  max-width: 90%;
}

.modalButtons {
  display: flex;
  justify-content: space-evenly;
  column-gap: 2rem;
  margin-top: 1rem;
}

.colorPopupTarget {
  width: min-content;
}

.colorPopup {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 1rem;
  width: 18rem;
  height: auto;
}

.colorPopupRow {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}

.colorPopupPalette {
  display: grid;
  grid-auto-rows: 1fr;
  gap: 1rem;
}

.colorPopupPaletteItem {
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  box-shadow: 0px 0.2rem 0.4rem #00000029;
  cursor: pointer;
}

.colorPopupPalettePlus {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background-primary);
  &::after {
    content: "+";
    color: white;
    font-weight: 600;
  }
}

.statusColorLabel {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  cursor: pointer;
}
