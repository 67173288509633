.trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.avatar {
  transform: translateX(-55%);
}

.bordered {
  border: 1px solid #fff;
}

.count {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 0.3rem;
}

.dots {
  transform: translateX(-0.5rem);
}

.popup {
  padding: 16px;
  overflow-y: auto;
  max-height: 11rem;
  width: 19.25rem;
  padding: 0.4rem;
}

.worker {
  background: #e6f7ff;
  border-radius: 0.5rem;
  padding: 0.7rem 0.9rem;
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
}

.texts {
  font-size: 0.875rem;
  margin-left: 1rem;
}
