.wrapper {
  width: 100%;
  position: relative;
}

.container {
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.text {
  font-size: 18px;
  font-weight: 400;
}

.formContainer {
  margin-top: 1.875rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(4, 1fr);
  grid-auto-columns: 1fr;
  column-gap: 10%;
}

.innerData {
  margin-top: 17px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

@media (max-width: 2501px) {
  .container {
    max-width: 1550px !important;
  }
}

@media (max-width: 1920px) {
  .container {
    max-width: 1200px !important;
  }
}

@media (max-width: 1599px) {
  .container {
    max-width: 1000px !important;
  }
}
