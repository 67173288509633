.blockStyles {
  display: flex;
  align-items: center;

  &.column {
    flex-direction: column;
    justify-content: center;

    .labelStyles {
      padding: 0;
      margin-bottom: 0.5rem;
    }
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  .labelStyles {
    max-width: 6.2rem;
    padding-right: 1rem;
    color: #000;
    font: normal normal normal 1rem var(--title-font);
  }

  .containerStyles {
    height: 0.25rem;
    width: 6.2rem;
    background-color: #e6e6e6;
    border-radius: 50px;

    .fillerStyles {
      height: 100%;
      background-color: #00c3f2;
      border-radius: inherit;
    }
  }
}
