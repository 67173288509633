.content {
  padding: 1rem;
  width: 43rem;
}

.input {
  width: 100%;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1.25rem 0 0;
  align-items: center;
}

.switch {
  display: flex;
  height: 2rem;
  align-items: center;
  margin-bottom: 1rem;
}

.bottom {
  width: 100%;
  gap: 1rem;
  display: flex;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #c3c3c3;
}

.currency {
  color: #a4a4a4;
  padding-right: 0.5rem;
  background-color: #fff;
}

.spinner {
  height: 30rem;
  width: 43rem;
}
