.container {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.title {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.add {
  margin-bottom: 0.5rem;
  padding-left: 0;
}
