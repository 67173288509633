.row {
    display: grid;
    align-items: center;
    height: calc(2.5rem + 0.375rem + 0.375rem);
    padding: 0 1rem;
    grid-template-columns: 60% 1fr 1fr 5%;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0.3rem;
    background: #F4F4F4;
}

.cell {
    display: flex;
    justify-content: center;
}

.actions {
    background-color: transparent;
}

.input {
    background-color: #fff;
}