.subtitle {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.75rem;
}

.mb0 {
    margin-bottom: 0;
}

.value {
    margin-top: 1rem;
}

.flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.period {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-bottom: 1.5rem;
}

.back {
    margin-bottom: 1.5rem;
}

.sum {
    font-weight: 400;

    & strong {
        margin-left: 0.5rem;
    }
}
