@import "../../../../../../../scss/dimensions";

.header, .row {
  padding: 0 0.5rem;

  & > * {
    flex-shrink: 0;
  }

  & > *:not(:last-child) {
    padding-right: 1rem;

    @include breakpoint-to($laptop) {
      padding-right: 0.5rem;
    }
  }
}

.header {
  font: normal normal 500 1rem/1.25rem var(--main-font);
  border-bottom: 1px solid var(--color-border-primary);
  height: 52px;

  &, & > * {
    display: flex;
    align-items: center;
  }

  .dayColumn {
    justify-content: center;
  }
}

.roundColumn, .numberColumn {
  width: 2%;
}

.nameColumn {
  width: 24%;
}

.dayColumn {
  width: 10.3%;
  height: 100%;
  cursor: default;
}

.round {
  display: block;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--color-border-primary);
  background-color: var(--color-background-main);
  border-radius: 50%;

  &.blueLabel {
    background-color: var(--color-border-primary);
  }
}

.activeHeaderDay {
  border-radius: 2rem;
  background: var(--color-background-primary);
  color: var(--color-font-hover);
  width: min-content;
  padding: 0.3rem 0.25rem;
  margin: 0 auto;
}

.moreButton {
  margin-top: 1rem;
}

.numericDay {
  margin-left: 0.25rem;
  opacity: .5;
}

.postInfo {
  opacity: 0.5;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.875rem;
  border-radius: 0.5rem;
  background-color: var(--color-background-field6);
  border: 1px solid rgba(0,0,0,0);
  font: normal normal 1rem/1.25rem var(--title-font);
  margin-top: 0.5rem;
  cursor: pointer;
}

.emptyPlaceholder{
  margin-top: 2rem;
}