.container {
  border: 1px solid #4fb2ed;
  margin-bottom: 1.25rem;
  padding: 1rem 1.3rem;
  border-radius: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.date {
  position: absolute;
  top: 0;
  right: 0;
  background: #4fb1eb;
  border-radius: 0.675rem;
  color: #fff;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.75rem;
  transform: translateX(-1rem) translateY(-50%);
}

.pair {
  width: 100%;
  display: grid;
  grid-template-columns: 47% 51%;
  column-gap: 2%;
  white-space: nowrap;
}

.key {
  font-weight: 600;
}

.reason {
  margin-top: 0.75rem;
  width: 100%;
}

.reasonKey {
  margin-right: 0.5rem;
}
