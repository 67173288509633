@import "src/scss/mixins/sliceTextByLines";

.item {
  margin-bottom: 0.5rem;
  background-color: #d9d9d9;
  border-radius: 0.5rem;
  padding: 0.5rem;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: auto auto;
  cursor: pointer;
}

.icon {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;

  svg {
    display: block;
  }
}

.info {
  p {
    font-size: 0.9375rem;
    max-width: 97%;
    @include slice-text-by-lines(2);
    margin-bottom: 0.25rem;
    font-weight: 500;
  }

  span {
    font-size: 0.8125rem;
    font-weight: 600;
    color: #898989;
  }
}

.active {
  background-color: var(--color-background-primary);

  .info {
    & p,
    & span {
      color: #fff;
    }
  }
}
