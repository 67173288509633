.forkIcon {
  width: 1.5rem;
  height: 1.5rem;
  transform: rotate(-90deg);
  cursor: pointer;
}

.title {
  text-align: left;
  margin-bottom: 0.5rem;
}

.popover {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  input {
    border: none;
    font-size: 1rem;
    background: darken(white, 5%) !important;
    font-weight: 300;
    color: #4a4a4a;
    margin-bottom: 0.5rem;
    &:focus {
      background-color: darken(white, 10%) !important;
    }
  }
}

.btn {
  text-align: center;
  display: flex;
  justify-content: center;
}
