.container {
  display: flex;
  align-items: center;
  margin-right: auto;
  .breadcrumbsTitle {
    font-family: var(--title-font);
    font-size: 1.25em;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 1.25rem;
    // height: 100%;
  }
  .breadcrumbsItem {
    font-family: var(--title-font);
    font-weight: 400;
    font-size: 1rem;
    padding: 0.36rem 1rem;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .selectClassName {
    max-width: 30rem;
  }
  .optionsBlockClassName {
    padding: 0.4rem 0;
    min-width: 10rem;
  }
  .breadcrumbsTitleSmall {
    font-family: var(--title-font);
    font-size: 1.125rem;
    font-weight: 400;
    margin-right: 1.25rem;
    // text-transform: uppercase;
    // height: 100%;
  }
}
.selectClassNameNew {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.moduleTitle {
  pointer-events: none;
}
