.back {
  z-index: 3;
  position: sticky;
  background-color: #fff;
  //padding-top: 1.5rem;
  top: -3rem;
}

.header {
  top: -2rem !important;
  position: sticky;
  z-index: 2;
}

.th {
  grid-template-columns: 25% 20% 20% 35% !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  gap: 0 !important ;
}
