.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem 1.25rem;
  border-radius: 0.5rem;
  text-align: center;
}

.in_work {
  background: #fff2de;
  color: #ef9d1c;
}

.solidification {
  background: #eae2f6;
  color: #a26dd0;
}

.completed {
  color: #47d6d1;
  background: #e3fffe;
}
