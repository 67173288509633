.ant-table-thead > tr > th {
  border-right: 1px solid var(--color-border-field7);
  text-align: center;
  font-family: var(--title-font), sans-serif;
  background: var(--color-background-field10);

  &:nth-child(3n + 3) {
    border-right: 1px solid var(--color-border-field7);
  }
}

.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr:hover > td {
  &:nth-child(-n + 3) {
    background: #e6f7ff;
  }
}

.ant-table-tbody > tr > td {
  text-align: center;
  border-bottom: 0;
  &:nth-child(-n + 3) {
    background: #f4f9ff;
  }
}

.ant-table-thead > tr:nth-child(2) th {
  border-top: 1px solid var(--color-border-field7);
  border-bottom: 1px solid var(--color-background-field12);
}

.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body {
  overflow: auto !important;
}

tbody.ant-table-tbody > tr > td {
  padding: 6px 8px 5px 8px !important;
}

.ant-table {
  line-height: 1.3;
}

.ant-table-small table {
  width: 752px !important;
}

.ant-table-measure-row {
  display: none !important;
}

.ant-table-placeholder {
  display: none !important;
}

.bp4-popover2-arrow {
  &:after {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-20%) translateY(-20%);
  }
}

.ant-table-content {
  &::-webkit-scrollbar {
    display: none;
  }
}
