.pair {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.25rem;
    
  @media (max-width: 1440px) {
    grid-template-columns: 0.9fr 1fr;
  }

  &.centered {
    align-items: center;
  }
}

.info {
  display: flex;
  flex-direction: column;

  & b {
    margin-bottom: 0.75rem;
    white-space: nowrap;
  }
}
