.table {
  width: 100%;
  margin-right: 0;
}

.table__titleBlock {
  display: flex;
  align-items: center;
}

.table__row {
  margin-bottom: 12px;
  padding: 18px 37px;
  background: #f4f4f4;
  border-radius: 12px;
}

.table {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  width: 100%;

  td {
    padding: 0 1rem;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    padding: 0 1rem;
  }

  tr td:last-child {
    border-radius: 0 10px 10px 0;
  }

  tr td:first-child {
    border-radius: 10px 0 0 10px;
  }

  thead {
    position: sticky;
    top: -2rem;
    z-index: 2;
    background-color: #fff;

    & th {
      padding-top: 0.95rem;
      border-radius: 0 !important;
      border-bottom: 1px solid #4fb2ed;
    }
  }
}

.table__header {
  font-family: var(--main-font);
  font-size: 1rem;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  white-space: nowrap;

  .crossIcon {
    margin-left: 0.5rem;
    padding: 2px;
    width: 1.9rem;
  }
}

.table__row {
  background-color: #f4f4f4;
  border-radius: 10px;
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  height: 4rem;
  font-family: var(--title-font);

  &:hover {
    background-color: #4fb2ed;
    color: #fff;
  }
}

.table__titleBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.table__actions {
  width: fit-content;
  background-color: #fff;
  border-radius: 10rem;
  padding: 9px 16px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.table__actions_icon {
  cursor: pointer;
}

.actionPopover {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 30px 30px 20px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #4fb2ed;
  border-radius: 5px;
}

.actionPopover__text {
  text-align: center;
  font-size: 16px;
}

.actionPopover__buttons {
  display: flex;
  justify-content: center;
  gap: 9px;
}

.moreButton {
  margin-top: 2rem;
}

.name {
  margin-top: 35px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}

.contactLink {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  & svg {
    margin-right: 12px;
  }
}

.btns {
  height: 40px;
}

.wrapper {
  margin-top: calc(-1rem + 1px);
}

@media (min-width: 1649px) {
  .table__row {
    height: 3.875rem !important;
  }
}

@media (max-width: 1449px) {
  .table {
    thead {
      & th {
        padding-top: 1rem !important;
      }
    }
  }
}
