@import "src/scss/dimensions";

.workDay {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.25rem;

  @include breakpoint-from($laptop) {
    padding: 0.5rem;
  }
}

.selectContainer, .whiteBlock {
  width: 100%;

  &:not(:last-child) {
    margin-right: 0.25rem;
  }
}

.select {
  padding: 0.5rem;

  .title {
    overflow: auto;
  }

  @include breakpoint-to($laptop) {
    padding: 0.25rem;
  }
}

.whiteBlock {
  height: 2.5625rem;
  border-radius: 0.5rem;
  background-color: var(--color-background-main);
}

.title {
  font-weight: 500;
}

.time {
  font-size: 0.8rem;
}

.blue {
  background-color: var(--color-hawkes-blue);
}