.multipleButtonWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.multipleButtonWrapper > *:first-child,
.multipleButtonWrapper > *:first-child button {
  border-radius: var(--input-border-radius) 0 0 var(--input-border-radius) !important;
}

.multipleButtonWrapper > *:last-child,
.multipleButtonWrapper > *:last-child button {
  border-radius: 0 var(--input-border-radius) var(--input-border-radius) 0 !important;
}

.multipleButtonWrapper > *:only-child,
.multipleButtonWrapper > *:only-child button {
  border-radius: var(--input-border-radius) !important;
}

.multipleButtonWrapper > *:not(:last-child):not(:first-child),
.multipleButtonWrapper > *:not(:last-child):not(:first-child) button {
  border-radius: 0 !important;
}

.separateWithBorder.multipleButtonWrapper > *:nth-child(n + 1) {
  border-left: 1px solid var(--color-border-field1);
}
