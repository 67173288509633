@import "src/scss/dimensions";

.container {
  height: 100%;
}

.header {
  margin-bottom: 1.4rem;
  display: flex;
  align-items: center;
}

.addButton {
  margin-left: auto;
}

.slider {
  margin: 0 1rem;
}

.slider, .addButton, .tabs {
  height: 2.813rem;
}

.selectContainer {
  margin-left: auto;
  height: 2.5rem;
  min-width: 18.125rem;
}

.selectContent {
  height: 100%;
  border: 1px solid var(--color-border-primary);
  border-radius: 0.625rem;
  border-bottom: 1px solid var(--color-border-primary) !important;
}

.selectOptions {
  border: 1px solid var(--color-border-primary);
  border-top-color: transparent;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  margin: 0;
}

.selectOption {
  &:last-child {
    border-radius: 0 0 0.625rem 0.625rem;
  }
}

.selectItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-right: .625rem;

  span {
    font-size: 1.125rem;
  }
}

.hiddenInput {
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
}

@include breakpoint-to($laptop) {
  .addButton {
    font-size: 1rem;
  }
}