.comment {
  position: relative;
  cursor: pointer;
}

.commentCount {
  position: absolute;
  top: -8px;
  left: 14px;
  border-radius: 12px;
  background-color: #4FB2ED;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 500;
  width: 1.5625rem;
  height: 1.0625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}