.sliderClassName {
  width: 40rem;
}
.header {
  padding: 2.05rem 2.75rem 1.9rem;

  .title {
    font-size: 1.37rem;
    font-weight: 700;
    color: #000;
  }
  .inputSearchClassName {
    margin-right: 1rem;
  }
  .searchBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
}
.tableWrapper {
  overflow: auto;
  height: calc(100vh - 14rem);
  border-bottom: 1px solid #BCBCBC;

  table { 
    width: 100%;
  }

  td {
    border: none;
  }
  thead tr {
    background-color: #fff;
    z-index: 9;
    position: sticky;
    top: 0;
    outline: 1px solid #9AD9FF;

    th {
      padding-bottom: 0.5rem;
      text-align: left;

      &:first-child {
        padding-left: 1.7rem;
      }
    }
  }
  tbody tr:first-child td {
    padding-top: 1.13rem;
  }
  tbody tr td {
    &:first-child {
      padding-left: 1.7rem;
    }
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}
.cell {
  font: normal normal 400 1rem var(--title-font);
}