.workDay {
  display: flex;
  align-items: center;
  position: relative;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.numericDay {
  opacity: 0.5;
  margin-left: 0.125rem;
}

.weekend {
  color: #8BD4FF;

  .numericDay {
    opacity: 1;
  }
}

.editIcon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  position: absolute;
  right: 0.25rem;
  top: 0;
}
