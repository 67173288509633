.headline {
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.5rem;

  & h1 {
    margin: 0 33px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
}

.cancel {
  margin-left: auto;
  margin-right: 14px;
}

.btns {
  margin-left: auto;
  display: flex;
  min-width: 19rem;
  justify-content: flex-end;

  & button {
    animation: fade .4s linear;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}