.controls {
  position: sticky;
  top: -0.88rem;
  z-index: 2;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &:before {
    position: absolute;
    content: "";
    background-color: #fff;
    width: 100%;
    height: 1rem;
    top: -1rem;
  }
}

.sharedBlock {
  margin: 1.75rem 0 0;

  p {
    font-weight: 400;
  }

  strong {
    font-weight: 700;
  }
}

.statusOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 7.5rem;
  margin-right: 0.625rem;

  span {
    font-size: 1rem;
    width: min-content;
  }

  svg {
    min-width: 1.4rem;
    min-height: 1.4rem;
    width: 1.4rem;
    height: 1.4rem;
  }
}

.alert {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 1rem 0;

  & svg {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid #d3d3d3;
  padding-top: 1rem;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    padding: 0.5rem 2.5rem;
    border: 1px solid #000000;
  }

  .accept {
    background-color: #00dec5;
    color: white;
    border-color: transparent;
  }

  .decline {
    background-color: #eb70b7;
    color: white;
    border-color: transparent;
  }
}

.back {
  z-index: 3;
  position: sticky;
  background-color: #fff;
  padding-top: 1.5rem;
  top: 2rem;
}

.total {
  display: flex;
  gap: 1rem;
  flex-shrink: 0;
}

.divider {
  height: 1.5rem;
  width: 2px;
  background-color: #ebebeb;
}

.progress {
  flex-shrink: 0;

  & span {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
  }
}

.budget {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
}
