.btn {
    background: #4fb1eb;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 1.2rem;
    color: #fff;
    font-weight: 400;
    cursor: pointer;

    & svg {
        margin-right: 1rem;
    }
}

.container {
    z-index: 3;
    position: fixed;
    bottom: 2rem;
    right: 4rem;
}

.input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    visibility: hidden;
    width: 1px;
}
