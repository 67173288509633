.headline {
  display: flex;
  align-items: center;
  column-gap: .625rem;
  margin-top: 1rem;
  padding: 0 0.25rem 0 0.9rem;

  & > *:first-child {
    flex-grow: 1;
  }
}

.addBtn {
  margin-top: 1.725rem;
}

.modalContent {
  padding: 0;
}

.withoutBottomBorder {
  border-bottom: none;
}

.positions {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
}