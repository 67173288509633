.container {
  display: flex;
  flex-direction: column;

  .block_title {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    h2 {
      padding-left: 2.3rem;
      font: normal normal normal 1rem/1.375rem var(--main-font);
      color: black;
      margin: 0;
    }

    .block_arrow {
      margin-right: 1.3rem;
      cursor: pointer;
    }

    .arrow {
      transition: all 0.2s ease;
      width: 1rem;
      margin-left: 1rem;
      cursor: pointer;
      color: #707070;
    }

    .arrowReverse {
      transform: rotate(-180deg);
    }
  }

  .block_circle {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 0.65rem;
    padding-bottom: 0.35rem !important;

    @media screen and (max-width: 1440px) {
      padding-bottom: 0rem !important;
    }

    .divider {
      width: 1px;
      background-color: #C9C9C9;
      height: 3.5rem;
    }

    .item {
      height: 100%;
      display: flex;
      width: 16rem;
      justify-content: space-between;
      align-items: center;

      .circleTitle {
        display: flex;
        font-size: 1.25rem;
        margin-bottom: 0.393rem;
      }

      .circleDescription {
        font-size: 1rem;
      }
    }
  }
}

.button {
  border-radius: 10px;
  background-color: #4FB2ED;
  padding: 0.5rem 1.2rem 0.5rem 0.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--main-font);
  color: #fff;

  .arrowLeft {
    height: 0.8rem;
    margin-right: 1rem;
  }
}

.backTitle{
  display: flex;
  align-items: center;
}
