.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.625rem;
  margin: 1.25rem 0 0.94rem;
  & > span {
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.editIcon {
  cursor: pointer;
}

.blueBg {
  background-color: #eff7fc;
}

.materialRow {
  padding: 0 2rem;
  margin-left: -2rem;
  width: calc(100% + 4rem);
  min-height: 3rem;
}

.materialRowHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
  & > span {
    margin: 0.94rem 0 1rem;
    flex-basis: 80%;
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
}

.materialRowBody {
  margin: 0 0 1rem;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.6rem;
  width: 80%;
}

.doc {
  display: flex;
  align-items: center;
  & > span {
    cursor: pointer;
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 400;
    font-size: 0.6875rem;
    line-height: 0.8125rem;
  }
}

.docIcon {
  width: 1rem;
  height: 1rem;
}

.removeButton {
  max-height: 100%;
  padding: 0.625rem 0.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: white;
  transform: rotate(90deg);
  box-shadow: 0.15rem 0 0.3rem rgba(96, 96, 96, 0.1);
  margin-right: 0.5rem;
}

.removeButtonIcon {
  width: 0.75rem;
  height: 0.75rem;
}

.plusIcon {
  transform: rotate(45deg);
}

.splitLine {
  height: 1px;
  width: calc(100% + 4rem);
  margin: 0.5rem 0 0.5rem -2rem;
  background-color: #d8d8d8;
}

.statusPopup {
  z-index: 102;
}
