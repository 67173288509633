.tableHeader {
  font-weight: 500;
  display: grid;
  grid-template-columns: 8% 26% 16% 8% 14% 14% 14%;
  position: sticky;
  background-color: #fff;
  top: 2rem !important;
  z-index: 2 !important;
  border-bottom: 1px solid #4fb2ed;
  padding-top: 1.88rem;
  padding-left: 1.5rem;
}

.incomeHeader {
  grid-template-columns: 6% 28% 12% 9% 20% 14% 7% 4% !important;
}
