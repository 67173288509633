.info > svg {
  position: relative;
  width: 1.625rem;
  height: 1.625rem;
}

.info {
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  background-color: white;
  color: black;
  font-weight: 500;
  width: 1.625rem;
  height: 1.625rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.infoPopoverTitle {
  font-size: 1rem;
  color: #727272;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.infoPopoverContainer {
  font-weight: 400;
  transition: 0.1s all ease-in-out;
  color: black;
  background-color: white;
  border-radius: 0.375rem;
  padding: 0.5rem 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.infoPopoverHeading,
.infoPopoverItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  padding: 0.25rem 0;
  font-size: 0.875rem;
  width: 100%;
}

.infoPopoverHeading {
  justify-content: space-between;
}

.infoPopoverItem {
  justify-content: flex-end;
  & > span {
    text-align: right;
  }
}

.infoPopoverItemMarker {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.25rem;
  margin-right: auto;
}

.square {
  width: 0.625rem;
  height: 0.625rem;
}

.legend {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-auto-rows: 1fr;
  padding: 0.25rem 0;
  font-size: 0.875rem;
  grid-row-gap: 0.5rem;
  grid-column-gap: 1rem;
  width: 100%;
  & > span {
    text-align: right;
  }
}
