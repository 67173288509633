.vCenteredFlex {
  display: flex;
  align-items: center;
}

.rank,
.stake {
  @extend .vCenteredFlex;
}

.head {
  display: grid;
  grid-template-columns: 30% 35% 20%;
  padding-top: 0;
  padding-left: 2%;
  padding-right: 2%;
  height: 2.5rem;
  width: 100%;
}

.headDisplaying {
  grid-template-columns: 35% 35% 25%;
}

.row {
  height: 3rem;
  display: grid;
  grid-template-columns: 30% 35% 23% 12%;
  &.display {
    grid-template-columns: 35% 35% 25%;
  }
  grid-template-rows: 1fr;
  align-items: center;
  white-space: nowrap;
  padding: 0.375rem 2% 0.375rem 2%;
  background-color: white;
  border-top: 1px solid transparent;

  &:hover {
    background-color: var(--color-background-primary);

    color: white;

    & .icon > svg {
      filter: invert(100%);
    }
  }
}

.lightblueBg {
  background-color: var(--color-background-field17);
}

.rank {
  padding-right: 1rem;

  & > div {
    width: 100%;
  }
}

.rankSelect {
  color: black;
  width: 100%;
  height: 2.1875rem;

  & > div {
    padding: 0.6875rem 0.5rem;
  }
}

.selectOptions {
  & > div {
    padding: 0.25rem;
    border-radius: 0.25rem;
  }
}

.stake {
  column-gap: 0.75rem;

  & > div {
    width: 100%;
  }
}

.typeSelect {
  color: black;
  width: 100%;
  height: 2.1875rem;
}

.stakeInput {
  width: 100%;
}

.icon {
  flex-shrink: 0;
  margin-left: 0.5rem;
  display: flex;
  column-gap: 0.5rem;
  justify-content: end;

  & svg {
    flex-shrink: 0;
    cursor: pointer;
  }
}

.selectInput {
  height: inherit;
}

.stakeDisplay {
  width: 100%;
  text-align: right;
  padding-left: 1rem;
}

.rightedHeader {
  padding-right: 0;
}
