.root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  pointer-events: none;
  top: 0;
  left: 0;
  padding-left: 100px;
  padding-top: 50px;
}

.hidden {
  visibility: hidden;
  pointer-events: none;
}

.handle {
  width: 35%;
  height: 6px;
  margin-bottom: 1rem;
  cursor: grab;
  background: #d9d9d9;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}

.close {
  cursor: pointer;
  position: absolute !important;
  top: 11px;
  left: -2.3rem;
  background-color: var(--color-background-primary);
  border-radius: 21px;
  width: 4rem;
  height: 4.5rem;
  padding: 0.9rem;
  display: flex;
  align-items: center;
  z-index: 1 !important;

  & button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal {
  pointer-events: all;
  width: 29.125rem;
  background-color: #fff;
  border-radius: 1rem;
  //border: 2px solid #4fb1eb;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  position: relative;
  padding-bottom: 4rem;

  &:before {
    content: "";
    position: absolute !important;
    top: 11px;
    left: 0;
    height: 4.5rem;
    width: 50px;
    z-index: 3;
    background-color: #fff;
  }

  & > * {
    position: relative;
    z-index: 4;
  }
}

.videoWrapper {
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1.5rem;

  & video {
    width: 100%;
    display: block;
  }
}

.controls {
  position: absolute;
  width: 100%;

  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  border-top: 1px solid #4fb1eb;

  button {
    font-size: 0.875rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.title {
  font-weight: 500;
  margin-bottom: 0.5rem;
  opacity: 0.5;
}

//md

.content {
  height: 10rem;
  overflow: auto;

  h1 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  img {
    max-width: 100%;
  }

  p h6,
  :global(.markdownClosed) h6 {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  &:global(.markdownClosed) :global(.bg-blue),
  &:global(.markdownClosed) :global(.bg-orange),
  &:global(.markdownClosed) :global(.bg-light) {
    background: transparent !important;
    border: none !important;
    &:before {
      display: none !important;
    }
  }

  &:global(.markdownClosed) li {
    list-style: none !important;
  }

  & :global(.bg-blue) {
    background-color: #eef7fd;
    padding: 0.5rem;
    padding-left: calc(0.5rem + 7px);
    border-radius: 0.5rem;
    border: 1px solid #e2f4fe;
    position: relative;
    overflow: hidden;

    &:before {
      position: absolute;
      height: 100%;
      width: 4px;
      content: "";
      left: 0;
      top: 0;
      background-color: #4fb1eb;
    }

    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & :global(.bg-orange) {
    background-color: #fff3de;
    padding: 0.5rem;
    padding-left: calc(0.5rem + 7px);
    border-radius: 0.5rem;
    border: 1px solid #f7b55392;
    position: relative;
    overflow: hidden;

    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &:before {
      position: absolute;
      height: 100%;
      width: 4px;
      content: "";
      left: 0;
      top: 0;
      background-color: #ef9c1c;
    }
  }

  & :global(.bg-light) {
    background-color: #fafafa;
    padding: 0.5rem;
    padding-left: calc(0.5rem + 7px);
    border-radius: 0.5rem;
    border: 1px solid #eeeeee;
    position: relative;
    overflow: hidden;

    ul {
      padding-left: 1rem;
    }

    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &:before {
      position: absolute;
      height: 100%;
      width: 4px;
      content: "";
      left: 0;
      top: 0;
      background-color: #4eb1eb;
    }
  }

  & :global(.ol-blue) {
    padding-left: 0;
    list-style: none;
    counter-reset: my-sec-counter;

    & li {
      padding-left: 1rem;
      position: relative;

      &:before {
        color: #4eb1eb;
        counter-increment: my-sec-counter;
        content: counter(my-sec-counter);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  & :global(.ul-circles) {
    padding-left: 0;
    list-style: none;

    & li {
      padding-left: 1rem;
      position: relative;

      &:before {
        background-color: #4eb1eb;
        content: "";
        position: absolute;
        left: 2px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: calc(1.3rem / 2);
        transform: translateY(-50%);
      }
    }
  }
}
