.container {
  width: 100%;
}

.containerOpened {
  position: relative;
  z-index: 10;
}

.input {
  height: var(--input-height);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  border: 1px solid var(--color-border-field18);
  justify-content: space-between;
  cursor: pointer;
  background-color: #fff;

  &:hover {
    border: 1px solid #4fb1eb;
  }
}

.inputOpened {
  border-radius: 0.5rem 0.5rem 0 0;
  border: 1px solid #4fb1eb;
}

.content {
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  background-color: #fff;
  border: 1px solid #4fb1eb;
  border-top: none;
  border-radius: 0 0 0.5rem 0.5rem;
  max-height: 15rem;
  overflow-y: auto;
}
