.files {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.head {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & span {
    font-weight: 500;
  }

  & div {
    color: #4fb2ed;
    cursor: pointer;
  }
}
