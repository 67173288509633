.container {
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 1.875rem;
  padding: 0 0.75rem 0 0.25rem;
  background: #e4faf8;
  border-radius: 0.9rem;
  cursor: pointer;
  gap: 0.5rem;
}

.fail {
  background: #ffe8f5;
}

.transparent {
  background: transparent;
}

.popover {
  font-size: 0.938rem;
  padding: 0.5rem 0.8rem;
}
