.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.clip {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  min-width: auto;
}

.count {
  border: 1px solid #8fe532;
  background-color: #fff;
}

.expired {
  border-color: red;
}
