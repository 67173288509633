.add {
  font: normal normal 1.125rem/1.375rem var(--title-font);
}

.row {
  display: grid;
  gap: 1rem;
  grid-template-columns: 40% 1fr;
}

.input {
  width: 100%;
}
