.header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;

  &::before {
    content: "";
    position: absolute;
    width: 2rem;
    left: -2rem;
    height: 100%;
    background-color: #fff;
    top: 0;
  }
}

.remarksButton {
  margin-left: auto;
}

.switchGroupBy {
  margin-left: auto;
}
