.modal {
  position: relative;
  width: 70%;
}

.modalHeader {
  padding: 1.2375rem 1.3rem .78125rem;
  column-gap: 1rem;
}

.head {
  padding: 0 1.3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.1375rem;
}

.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2.3375rem;
  flex-wrap: nowrap;

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: .625rem;
  }

  & > div > span:last-child {
    color: var(--color-font-primary)
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableHead {
  margin-left: -1.3rem;
  width: calc(100% + .8rem);
  grid-template-columns: 6% 43% 12% 12% 17% 10%;
  padding-top: 0;
  padding-right: .4rem;
  height: 2rem;
}

.costTableHead {
  grid-template-columns: 6% 43% 23% 23% 5%;
}

$modalBodyHeight: 25rem;
$modalBodyPadding: 0 1.3rem .7685rem 1rem;

.body {
  padding: $modalBodyPadding;
  overflow-y: auto;
  height: $modalBodyHeight;
}

.placeholder {
  margin-top: 3.45rem;
  height: $modalBodyHeight;
  min-height: $modalBodyHeight;
  max-height: $modalBodyHeight;
}

.addButton {
  padding-right: 0;
}

.bottomControls {
  padding: 1rem 0 .7685rem !important;
  position: absolute;
}

.bottomControlsButton {
  padding: .625rem 3.4rem 0.5625rem;
}

.percentAlert {
  position: absolute;
  top: 50%;
  right: 28%;
  transform: translate(100%, -50%);
  color: var(--color-font4)
}