.container {
  & svg {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }

  &.done {
    & svg path {
      fill: #4BE07E;
    }
  }
}

.count {
  font-weight: 400;
  min-width: 3.438rem;
}