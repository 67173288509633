@mixin getIndicatorAsContent($indicatorSize, $color: var(--color-indicator-frostbite)){
  content: '';
  display: block;
  background-color: $color;
  border-radius: 50%;
  width: $indicatorSize;
  min-width: $indicatorSize;
  max-width: $indicatorSize;
  height: $indicatorSize;
  min-height: $indicatorSize;
  max-height: $indicatorSize;
}