.myOrdersStatus {
  display: flex;
  align-items: center;
  background: #f2f2f2;
  padding: 0 10px;
  height: 2rem;
  max-width: 19.7rem;
  border-radius: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;

  & span {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.myOrdersStatus:not(span) {
  color: #000;
}

.status {
  min-width: 1.125rem;
  max-width: 1.125rem;
  height: 1.125rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 0.75rem;
}

.in {
  background-image: url("./status-in.svg");
}

.out {
  background-image: url("./status-out.svg");
}

.end {
  background-image: url("./status-end.svg");
}
