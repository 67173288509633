.root {
  border-top: 3px solid #bae5ff;
  position: relative;
  padding: 1rem 1.5rem;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #fff;

  .rootClosed {
    padding-top: 0;
    padding-bottom: 1rem;
  }

  .expandable {
    height: 15rem;
    transition: 0.3s;
    box-sizing: border-box;

    &.closed {
      height: 0rem;
      transition: 0.3s;
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
    }
  }

  &.closed {
    height: 0rem;
    transition: 0.3s;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }
}

.expand {
  height: 24px;
  width: 94px;
  position: absolute;
  background-color: #bae5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: #4fb2ed;

    path {
      stroke: #fff;
    }
  }

  &.closed {
    svg {
      transform: scaleY(-1);
    }
  }
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.select {
  width: 8.5rem;
}

.videoWrapper {
  border: 2px solid #4fb1eb;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  height: 11rem;

  & video {
    position: relative;
    z-index: 2;
    display: block;
    object-fit: cover;
    height: 100%;
  }
}

.slideTitle {
  text-align: center;
  margin-bottom: 0.5rem;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: #4fb1eb;
    cursor: pointer;
  }
}

.gallery {
  position: relative;
  padding: 1rem;
}

.left {
  position: absolute;
  left: 0;
  top: 45%;
  z-index: 5;
  transform: (translateX(-60%));
}

.right {
  position: absolute;
  right: 0;
  top: 45%;
  z-index: 5;
  transform: (translateX(60%));
}

.inWork {
  position: absolute;
  text-align: center;
  white-space: nowrap;

  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  transform: translateX(-50%) translateY(-50%);

  svg {
    display: block;
    flex-shrink: 0;
  }

  span {
    opacity: 0.5;
  }
}

.flex {
  display: grid;
  grid-template-columns: calc(100% / 3 - 16px / 3) calc(100% / 3 - 16px / 3) calc(100% / 3 - 16px / 3);
  gap: 16px;
}

.gallery {
  padding-top: 0;
}
