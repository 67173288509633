.container {
  flex-basis: 19%;
  flex-grow: 1;
  border-width: 1px;
  border-style: dashed;
  height: 7.3rem;
  border-radius: 1rem;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  font-weight: 500;

  &.fullWidth {
    flex-basis: unset;
    flex-grow: 1;
  }

  & > div {
    width: 100%;
    height: 100%;
  }

  &:not(:last-child) {
    margin-right: 0.75rem;
  }
}

.active {
  border-style: solid;
}

.disabled {
  pointer-events: none;
}