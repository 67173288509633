.headline {
  display: flex;
  width: calc(68vw - 4.2rem - 26px - 0.6rem);
  align-items: center;
  position: relative;
  padding-right: 1.2rem;
  margin-bottom: 1.063rem;
}

.measureBtn {
  margin-left: 1rem;
  height: 2.8rem;
  width: 150px;
  border: 0.35rem solid #f4f4f4;
  border-radius: 10px;
  background: #4fb2ed;
  border-radius: 0.75rem;
  color: #fff;
  margin-right: 1rem;
  white-space: nowrap;
}

.mlAuto {
  margin-left: auto;
}

.highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #f5f5f5;
  width: 3rem;
  height: 3rem;
  border-radius: 8px;
  margin-right: -3px;
}

.counter {
  color: #fff;
  background: #ff0093;
  border-radius: 50%;
  position: absolute;
  right: -0.525rem;
  top: -0.525rem;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.9375rem;
}

.dropdown {
  position: relative;
}

.dropdownContainer {
  left: auto !important;
  right: 0;
}

.downloadPlaceholder {
  color: #4fb1eb;
}

.downloadSelect {
  width: 13rem;
  margin-left: auto;
}

.menu {
  background-color: #fff;
  border: 1px solid #4fb2ed;
  border-radius: 0 0 5px 5px;
  width: 17.75rem;

  & p {
    color: #6a6a6a;
    margin-left: 11px;
    margin-top: 13px;
    margin-bottom: 0;
  }
}

.btns {
  border-top: 1px solid #d8d8d8;
  padding: 1rem 11px;
  display: flex;
  justify-content: space-between;

  & button {
    font-size: 0.9rem;
  }
}

.checks {
  padding: 1.05rem 11px;

  & label {
    display: flex;
    align-items: center;

    &:first-child {
      margin-bottom: 11px;
    }

    & :global(.ant-checkbox-wrapper) {
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }
}

@media screen and (max-width: 1800px) {
  .downloadSelect {
    width: 9rem;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 1441px) {
  .headline {
    padding-right: 2rem;
    width: calc(72vw - 4.2rem - 26px - 0.6rem);
  }

  .highlight {
    margin-right: 0;
  }
}

.settingsIcon {
  margin: 0 1rem;
  cursor: pointer;
}

.tooltipSettings {
  color: #4fb2ed;
  padding: 0.5rem 1rem;
}
