.root {
  width: 19.125rem;
  background-color: #ededed;
  height: 100%;
}

.head {
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  height: 6.8rem;
  padding-left: 2rem;
  padding-right: 2rem;

  h1 {
    font-size: 1.375rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
}

.progressTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.progress {
  width: 100%;

  & > div {
    width: 100% !important;
  }

  span {
    display: none;
  }
}

.nav {
  height: calc(100vh - 6.8rem);
  overflow: auto;
  padding: 0.5rem;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
