.row {
  background-color: #f2f2f2 !important;
  pointer-events: none;
}

.container {
  position: relative;
}

.btn {
  position: absolute;
  top: 0;
  z-index: 2;
  left: 3.5rem;
  transform: translateY(-50%);
  cursor: pointer;
}
