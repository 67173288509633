.emptyTitle {
  color: var(--base-text-primary, #000);
  font-family: Montserrat, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  line-height: normal;
}

.emptyButton {
  font-family: Montserrat, sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
}