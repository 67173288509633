.row {
  display: grid;
  grid-template-columns: 6% 43% 23% 23% 5%;
  padding: 0 .4rem !important;

  & > *:first-child {
    padding-left: .4rem;
  }

  & > * {
    font-size: 1rem !important;
    font-family: var(--main-font) !important;
  }
}

.input input {
  background-color: white;
}

.selectCell {
}

.doubleInput input{
  border-radius: 0;
}