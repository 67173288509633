.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:nth-child(1) {
    color: #4FB2ED;
  }
}

.table {
  & :global(.ant-table-thead) {
    transition: .5s;
  }

  & td {
    padding: 5px 8px 5px 8px !important;
  }
  & tr {
    height: 31px;
    transition: 0s !important;
    &:global(.hover),
    &:hover {
      & td {
        transition: 0s !important;
        background-color: #d8f1ff !important;
      }
    }
  }
}

.hideTitle {
  & thead {
    display: none !important;
  }
}

.sticky {
  position: sticky;
  z-index: 2;
  top: 30px;
}


