.root {
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
  overflow: hidden;
  height: 2.325rem;
  padding: 0 0.5rem;
  font-size: 0.875rem;
  white-space: nowrap;

  &.withPercents {
    padding-right: 0;
  }
}

.percents {
  background-color: #4fb1eb;
  margin-left: 0.5rem;
  height: 2.125rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  flex-shrink: 0;
  min-width: 2.5rem;
}

.name {
  font-weight: 600;
  font-size: 0.875rem;
  margin-right: auto;
}

.red {
  color: red;
}
