.row {
    grid-template-columns: 8% 10% 24% 15% 15% 10% 17%;
    padding-left: 1.5rem;

    &:hover {
        & .link {
            color: #fff;

            & svg {
                & path {
                    fill: #fff;
                }
            }
        }
    }
}
.navIcon span {
    border-bottom: none !important;
  }
  
  .icon {
    display: flex;
    align-items: center;
  
    svg {
      margin-left: 0.5rem;
    }
  }
  
  .block {
    display: flex;
    align-items: center;
    width: 100%;
  
    .container {
      display: flex;
      align-items: center;
      margin-left: 1rem;
  
      & div {
        margin-right: 0 !important;
      }
  
      .breadcrumbsTitle {
        font-family: var(--title-font);
        font-size: 1rem;
        padding-right: 0.5rem;
      }
  
      .selectClassName {
        height: 100%;
        max-width: 20rem;
        margin-left: auto;
        padding-left: 0;
      }
  
      .optionsBlockClassName {
        max-height: 20rem;
        width: 9rem;
        padding: 0.36rem 1rem;
        justify-content: space-between;
        min-width: 8rem;
      }
    }
  }
  
  .breadcrumbsItem {
    font-family: var(--title-font);
    font-weight: 400;
    font-size: 1rem;
    padding: 0.36rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-font-main);
  
    svg > path {
      fill: var(--color-font6);
    }
  }