.container {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  & :global(.monthSlider) {
    height: 2.813rem;
  }
}

.timeSlider {
  margin-left: 1rem;
}

.parameter {
  margin-left: auto;
  white-space: nowrap;
  align-self: center;
  display: flex;
}

.approve {
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1441px) {
  .tab {
    width: 11rem !important;
  }

  .tabs {
    min-width: 11.5rem !important;
  }

  .timeSlider {
    margin-left: 2rem;
  }

  .parameter {
    & span {
      margin-right: 0.5rem;
    }
  }
}
