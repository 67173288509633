.addButton {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.permissionsContainer{
  grid-row-end: 5;
  grid-column-start: 1;
}