.content {
    padding: 1rem;
    padding-top: 0.5rem;

    & strong {
        margin-right: 0.5rem;
    }
}

.row {
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 2fr;
    margin: 1.5rem 0;
}

.modal {
    width: 50rem;
}

.subtitle {
    margin-bottom: 0;
}

.expenditures {
    margin-bottom: 1.5rem;
}

.fileInput {
    visibility: hidden;
    position: absolute;
}

.approveRow {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    cursor: pointer;

    & span {
        margin-left: 0.5rem;
    }

    & label {
        margin-left: 0 !important;
    }
}

.disabled {
    cursor: auto;
    opacity: 0.6;
    pointer-events: none;
}

.fileRow {
    height: 2.5rem;
}

.upload {
    & svg {
        height: 60%;
    }
}
