.name {
  font: normal normal 1rem/1.125rem var(--title-font);
}

.container {
  margin: 0.8rem 0;
  border-radius: 0.5rem;
  color: #000;
  font-family: var(--title-font);
  border: 1px solid #d8d8d8;
}

.header {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  margin-bottom: 0.175rem;
  height: 3rem;
  font-weight: 500;
  position: sticky;
  z-index: 17;

  & > * {
    text-align: center;
    flex-shrink: 0;

    &:first-child {
      display: flex;
      justify-content: center;
    }
  }

  & > *:not(:last-child) {
    padding-right: 1rem;
    position: relative;
  }
}

.colorLabel {
  opacity: 0;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4FB2ED;
  border-radius: 50%;
  width: 1.625rem;
  height: 1.625rem;
  margin-right: 1.625rem;
  cursor: pointer;

  & path {
    fill: #fff;
  }
}

.checkbox {
  margin-right: 1rem;
}

.budget {
  justify-content: flex-end;
}

.titleContainer {
  padding-left: 1rem;
  padding-right: 3rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  height: 3.625rem;
  column-gap: 1.875rem;

  &:hover {
    .block {
      .boxValue {
        display: block;
      }

      .boxValueObject {
        color: #878787;
      }
    }

    .arrowRight path {
      fill: #fff;
    }

    .colorLabel {
      opacity: 0;
    }
  }

  .name {
    flex: 1;
    font-family: normal normal normal 1.12rem var(--title-font);
    padding: 0 1rem;
    display: flex;
    align-items: center;
  }

  .divider {
    content: "";
    height: 2rem;
    width: 1px;
    background-color: #c2c2c2;
  }

  .progressBarWrapper {
    padding: 0 1rem;
  }

  .arrowRight {
    padding-left: 2rem;
  }
}

.numberCol, .priceCol, .withDiscountAmount, .budgetCol {
  text-align: left;
}

.numberCol, .checkboxCol {
  width: 3%;
}

.nameCol {
  width: 34%;
}

.priceCol, .countCol {
  width: 16%;
}


.completedCol {
  width: 15%;
}

.acceptedCol {
  width: 26%;
}

.actionsCol {
  margin-left: -0.625rem;
}

.draft {
  .nameCol {
    width: 32%;
  }

  .countCol {
    width: 11%;
  }

  .priceCol {
    width: 6%;
  }

  .completedCol {
    width: 22.5%;
  }

  .acceptedCol {
    width: 11%;
  }

  .actionsCol {
    width: 14.5%;
    margin-left: 0;
  }
}

.addButton {
  display: flex;
  align-items: center;
  height: auto;

  .text {
    margin-right: 1.5rem;
  }
}

.diffCol {
  display: grid !important;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(5, 1fr);
}

.diffHeading{
  grid-row: 1;
  grid-column: 1 / 6;
}

.diffPercent{
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;
}

.diffPrice{
  grid-column-start: 3;
  grid-column-end: 6;
  grid-row: 2;
}

.diffPrice {
  border-left: 1px solid #C3C3C3;
}

.pricingModeBudget, .pricingModeEstimatedPrice {
  display: grid;
  height: 3rem;
  grid-template-columns: 3% 42% 12% 12% 18% 14%;
  padding-top: 0;
  padding-bottom: 0;

  & > * {
    width: 100%;
    text-align: center;
    height: inherit;
    margin: auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.pricingModeEstimatedPrice {
  grid-template-columns: 3% 42% 10% 10% 21% 14%;
}

.pricingModeDivider {
  position: absolute;
  width: 1px;
  height: 3rem;
  background: #C3C3C3;
  right: 0;
  top: 0;
}