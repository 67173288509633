.buttonArrow {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover:not(:disabled) {
    border-color: var(--color-border-primary);
    background-color: var(--color-background-main);
    z-index: 1;
  }
  &:disabled{
    cursor: default;
  }
}

.left {
  padding-left: 0.5rem;
  padding-right: 0.75rem;
}

.right {
  padding-left: 0.75rem;
  padding-right: 0.5rem;
}