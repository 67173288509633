.visibilityModal {
  display: flex;
  align-items: center;
  height: auto;
  margin-bottom: 1.5rem;
  position: absolute;
  top: 3.25rem;
  left: 22rem;
  z-index: 9999;

  .text {
    margin-right: 1rem;
  }
}

.modal {
  width: 37rem;
  min-height: 12.625rem;
  position: relative;

  .modalText {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    margin-bottom: 4rem;
    text-align: center;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-top: 1px solid #ebebeb;
  width: 100%;
  position: absolute;
  bottom: 0;

  .btn {
    display: flex;
    justify-content: center;
  }
}
