.controls {
  position: sticky;
  top: 100%;
  width: 100%;

  @media (min-width: 1900px) and (min-height: 1080px) {
    position: static;
    padding-top: 1rem;
  }
}

.eventsRow {
  width: 100%;
  height: 3rem;
  border-top: 1px solid #B2D5EA;
  border-bottom: 1px solid #B2D5EA;
  background-color: white;
  margin-top: 2px;
  position: relative;
  cursor: pointer;
}

.eventDays {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  &>.eventDay {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 2;
    height: 100%;

    &>.eventDayInner {
      width: 100%;
      flex-grow: 1;
    }
  }
}

.frame {
  position: absolute;
  width: calc(100% / 12);
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #00b4f2;
  cursor: pointer;
  pointer-events: none;

  &:active {
    cursor: grabbing;
  }
}

.blocksContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.block {
  display: flex;
  flex-direction: column;
  width: 25%;
  font-size: 1.25rem;
  box-sizing: border-box;

  &:not(:last-child) {
    border-right: #B2D5EA 1px solid;
  }
}

.weekRange {
  font-size: 1rem;
  padding: 0.5rem;
  margin-left: 3%
}

.blockName {
  padding: 0.5rem;
  text-align: center;
}

.blockSum {
  font-size: 1rem;
  width: 90%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-left: 5%;
  margin-right: 5%;
  border-top: #e1e8ee 1px solid;
}

.monthsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #B2D5EA;

  &>div {
    width: calc(100% / 12);
    text-align: center;
    padding: 0.5rem;
    font-size: 1rem;
    cursor: pointer;

    &:not(:last-child) {
      border-right: #B2D5EA 1px solid;
    }
  }
}