.wrapper {
  position: fixed;
  background-color: #fff;
  z-index: 22;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.template {
  padding: 1rem 2rem;
  background-color: var(--color-background-field9) !important;
}

.paper {
  padding: 1.5rem 3rem;
  min-height: calc(100vh - 6.2rem);
}

.head {
  display: flex;
  align-items: center;

  & h1 {
    font-size: 1.375rem;
    font-weight: 700;
    margin-bottom: 0;
    margin-left: 2rem;
    margin-right: 4rem;
  }
}

.subtitle {
  display: flex;
  align-items: center;
  margin-top: 0.675rem;
}

.filesBtn {
  margin-left: auto;
  margin-right: 1.25rem;
}

.periodTitle {
  font-weight: 500;
  margin-right: 1.125rem;
  font-size: 1.125rem;
}

.date {
  font-size: 1.125rem;
  font-weight: 500;
}

.filesRow {
  display: flex;
  column-gap: 0.5rem;
  padding-top: 1rem;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 0.8rem;
  }
}

.spinner {
  height: calc(100vh - 6rem);
  display: flex;
  align-items: center;
}

.headline {
  background-color: #fff;
  position: sticky;
  z-index: 3;
  top: -1.5rem;
  padding-top: 1.5rem;
  margin-top: -1.5rem;
  padding-bottom: 1.5rem;
}
