.container {
  width: 70rem;
  margin: 0 auto;
}

.content {
  padding: 1.25rem 1rem 1rem 2.5rem;
}

.title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}