.container {
  position: relative;
  border-radius: 12px;
  border: 1px solid var(--color-status-green-approved, #47d6d1);
  padding: 1rem;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.type {
  border-radius: 10px;
  background: var(--color-status-green-approved, #47d6d1);
  position: absolute;
  left: 10px;
  top: 0;
  transform: translateY(-50%);
  color: var(--base-white, #fff);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 0 12px;
}

.date {
  border-radius: 10px;
  background: var(--color-status-green-approved, #47d6d1);
  position: absolute;
  right: 10px;
  top: 0;
  transform: translateY(-50%);
  color: var(--base-white, #fff);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.authorRow {
  display: grid;
  width: 100%;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 50% 50%;

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.descr {
  color: var(--base-text-primary, #000);
  font-size: 1rem;
  font-weight: 600;
}

.deadline {
  display: flex;
  align-items: center;
  gap: 8px;
}

.old {
  opacity: 0.5;
}

.worker {
  & > div {
    width: fit-content;
  }
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.25rem;
  margin-top: 0.5rem;
}

.root {
  padding-top: 2rem;
}

.rightedPerson {
  margin-left: auto;
  margin-right: 1rem;
}
