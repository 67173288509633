.container {
  position: relative;
  flex-shrink: 0;

  .button {
    cursor: pointer;
    position: relative;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background: #f0f0f0;
      border-radius: 0.3rem;
    }

    .redMark {
      position: absolute;
      background: var(--color-status-alarm);
      border-radius: 50px;
      width: 0.5rem;
      height: 0.5rem;
      top: 0.3rem;
      right: 0.2rem;
    }
  }

  .modal {
    position: absolute;
    top: 110%;
    left: 0;
    z-index: 2;
    padding: 0.81rem 0.88rem 1.1rem;
    background: #fff;
    border: 1px solid #4fb2ed;
    text-align: left;
    border-radius: 0 0 0.5rem 0.5rem;

    .title {
      color: #a5a5a5;
      margin-bottom: 0.8rem;
    }

    .actionBlock {
      padding-top: 1rem;
      display: flex;
    }
  }

  .modalLast {
    @extend .modal;
    left: auto;
    right: 1px;
  }
}

@media (max-width: 1649px) {
  .button {
    padding: 0.4rem !important;
  }
}
