.table {
  position: relative;
  margin-left: -1.8rem;
  margin-right: -1.8rem;
}

.marginTop {
  margin-top: 1.5rem;
}

.spinner {
  margin-top: 1rem;
}

.bordered {
  border-bottom: 2px solid #4fb1eb;
  padding-bottom: 1rem;
  margin-top: 0.75rem;
}

.minHeight {
  min-height: calc((100vh - 528.812px) - 2.2rem);
}

.wrapper {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.subtitle {
  margin-top: 1rem;
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
  height: 32px;
}

.header {
  border-top: 1px solid #d8d8d8;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: red;
}

.addButton {
  display: inline-flex;
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.title {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.expandableHeadline {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem;
  align-items: center;
  min-height: calc(2rem + 2px);
}

.subTitleButton {
  margin-left: auto;
  font: normal normal 400 1rem var(--main-font);
  color: #4fb1eb;
  cursor: pointer;

  &.disabled {
    color: #c3c3c3;
  }
}
