.headline {
  padding: 1.5rem 1.5rem 1rem;
  border-bottom: 1px solid #bfbfbf;
}

.fixedHeadline {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.title {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.scrollableContainer {
  min-height: 100%;
  height: auto;
}

.justification {
  margin-left: auto;
  margin-right: auto;
  min-width: 10rem;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #4fb2ed;
  background: #e6f4fc;
  height: 1.875rem;
  border-radius: 0.9rem;
  width: fit-content;
  padding: 0 0.5rem;
  margin-top: 0.75rem;
}

.ml0 {
  margin-left: 0;
}

.content {
  padding: 1.5rem 2rem;

  @media (max-width: 1440px) {
    padding: 1.5rem 1.5rem;
  }

  & b {
    display: block;
    font-weight: 500;
    margin-right: 0.6rem;
  }
}

.subheader {
  margin-top: 0.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status {
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
  height: 2rem;
  padding: 0 1.3rem 0 0.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;

  &.green {
    background: #6fc79b;
  }

  &.blue {
    background: #4fb1eb;
  }

  &.gray {
    background: #bababa;
  }

  &.orange {
    background: #eb7f00;
  }

  &.violet {
    background: #d5c9df;
    color: #000;
  }

  &.red {
    background: #e8527a;
  }

  & svg {
    margin-right: 0.5rem;
  }
}
