:root {
  // input
  --input-border-radius: 0.5rem;
  --input-background-color: transparent;
  --input-transition: background-color 0.25s, border-color 0.25s ease-in-out;
  --input-height: 2.14286rem;
  --input-width: 100%;
  --input-fonst-size: 1rem;
  --input-color: #4a4a4a;
  --input-padding: 0.5rem;
  --placeholder-fsz: 1rem;
}
