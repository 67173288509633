.body {
  padding: 0 1rem 1.188rem 1rem;
  border-bottom: 1px solid var(--color-border-field6);
  width: 35rem;
  font-weight: 400;
  min-height: 20rem;

  & b {
    font-weight: 500;
  }

  .item {
    margin-top: 0.5rem;
  }
}

.loader {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer {
  padding: 0.786rem 2.357rem;
}

.btns {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.infoItem {
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #4fb2ed;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
}

.infoWrapper {
  max-height: 25rem;
  overflow-y: auto;
}