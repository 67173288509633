.container {
  color: #000;
  background-color: #E6F4FC;
  border-radius: 0.5rem;
  padding: 0.2rem 0.6rem;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 400;
  width: max-content;

  &.blueFont {
    color: var(--color-font-primary);
  }
}