.modal {
  position: relative;
  transform: translate3d(0, 0, 0);
  width: 60rem;
  min-height: 37.5rem;
}

.content {
  padding: 1rem 0 4.5rem;
  overflow: auto;
  max-height: 30.625rem;
}

.head {
  font-size: 1rem;
  margin-top: -2rem;
  margin-bottom: 0;
  grid-template-columns: 45% 23% 15% 16%;
  padding-left: 1.226rem;
  width: calc(100% - 1.3rem);

  & > div:nth-child(2) {
    padding-left: 0.5rem;
  }
}

.subtitle {
  font-size: 0.875rem;
  margin: 0.938rem 0;
}

.total {
  width: 100%;
  position: fixed;
  background-color: #fff;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--color-border-field20);
  border-bottom-left-radius: 0.938rem;
  border-bottom-right-radius: 0.938rem;
  display: flex;
  padding: 1.07rem 24.5% 5.625rem;
  justify-content: space-between;
  column-gap: .5rem;
}

.totalText {
  font-size: 1rem;
  white-space: nowrap;
}

.totalAmount {
  font-size: 1rem;
  white-space: nowrap;
}

.bottomControls {
  width: 100%;

  & button {
    flex-basis: 25%;
  }
}


.expenditureRow {
  position: relative;
}

.expenditureRow:not(:last-child):after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -.3125rem;
  background-color: #DBDBDB;
}

.materialsAndServicesRow {
  padding: .625rem 0;
  background-color: #F4F4F4;
  border-radius: .5rem;
  margin-left: 1.226rem;
  width: calc(100% - 3.752rem);

  & > *:first-child {
    padding-left: 1.226rem;
  }
}

.tabs {
  margin-left: 1.226rem;
}
