.filesRow {
    display: none;
    column-gap: 0.5rem;
    flex-wrap: wrap;

    & > * {
        margin-bottom: 0.8rem;
    }
}

.headRow {
    display: grid;
    height: 3.875rem;
    grid-template-columns: 1fr 12% 2% 12% 6%;
}

.headRowKs3 {
    display: grid;
    height: 3.875rem;
    grid-template-columns: 12% 1fr 6% 6% 4%;
}

.divider {
    width: 3px;
    background: #cecece;
    margin: auto;
    height: 2.7rem;
}

.rowContainer {
    min-height: 3.875rem;
    height: auto !important;
}

.row {
    min-height: 3.875rem;
    height: auto !important;
}

.fileCell {
    display: none;
}

.lastIndicator {
    padding-right: 2rem;
}

.expandCell {
    opacity: 0;
}

.rowOpen {
    & .fileCell {
        display: flex;
    }

    & .expandCell {
        opacity: 1;
    }

    & .headRow {
        grid-template-columns: 1fr 12% 2% 12% 6% 6%;
    }

    & .filesRow {
        display: flex;
    }

    & .row {
        position: sticky;
        top: 6.3rem;
        z-index: 2;
        background-color: #fff;
        border-radius: 0.5rem;
    }

    & * {
        --stroke-color-datecell: #4fb1eb !important;
        --bg-color-datecell: #fff !important;
    }
}

.headRowKs3,
.headRow {
    &:hover {
        & * {
            --stroke-color-datecell: #fff;
            --bg-color-datecell: #4fb1eb;
        }
    }
}

.ks3title {
    font-weight: 500;
}
