@use "../../../../_ks6a_vars.scss" as vars;

.yearTotal {
  min-height: inherit;
  background-color: white;
  position: sticky;
  right: 0;
  z-index: 20;
  border-left: vars.$primaryBorder;
  min-width: 15%;
}

.yearTotalHeader, .yearTotalRow, .yearTotalFooter{
  & > div{
    outline: vars.$cellBorder;
    display: flex;
    padding: 0.5rem;
  }
}

.yearTotalHeader {
  font-weight: 500;
  font-size: .875rem;
  position: sticky;
  top: 0;
  background-color: white;
  height: vars.$headerHeight;
  display: grid;
  left: auto;
  grid-template-columns: vars.$yearTotalColumns;
  grid-template-rows: 2.375rem 1fr;
  z-index: 10;
  & > div {
    justify-content: center;
    align-items: flex-start;
  }
  &::after {
    content: " ";
    display: block;
    background-color: #f4f4f4;
    height: 1.625rem;
    grid-column: 1/3;
    border-top: vars.$cellBorder;
    border-bottom: vars.$cellBorder;
  }
}

.yearTotalLabel {
  grid-column: 1 / 3;
  grid-row: 1;
  font-weight: 700;
}

.yearTotalCount {
  grid-column: 1;
  grid-row: 2;
}

.yearTotalCost {
  grid-column: 2;
  grid-row: 2;
}

.yearTotalRow {
  display: grid;
  grid-template-columns: vars.$yearTotalColumns;
  grid-template-rows: vars.$baseRowHeight;
  & > div {
    align-items: center;
  }
}

.yearTotalRowCount {
  grid-column: 1;
}

.yearTotalRowCost {
  grid-column: 2;
}

.yearTotalFooter {
  display: grid;
  grid-template-columns: vars.$yearTotalColumns;
  grid-template-rows: vars.$totalRows;
  border-top: vars.$primaryBorder;
  position: sticky;
  bottom: 0;
  background-color: white;
  & > div {
    align-items: center;
  }
}

.count{
  justify-content: center;
}

.cost{
  justify-content: flex-end;
  padding-right: 1.5rem;
}
