* {
  font-family: "Montserrat", sans-serif;
}

.eventsListEmptyPlaceholder {
  margin-top: 1.5rem;
}

.container {
  background-color: #fff;
}

.container {
  margin-top: 13rem;
}

// ==========

.eventsWithDataRange {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectedBlock {
  display: flex;
  align-items: center;
}

.selectedItem {
  padding: 8.35px 12.68px 9.65px 9.5px;
  background-color: #4fb2ed;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-bottom: 9.5px;
  margin-right: 9.5px;

  .selectedItemText {
    font-size: 1rem;
    font-weight: 400;
    margin-right: 27px;
  }
}

.fixedHeader {
  width: 100%;
  padding: 0 1.813rem 0 1.75rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111111;
}

.positionTypesEvents {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 10rem;
  left: 0;
  z-index: 1;
  background: white;
}

#eventsSelect div:nth-child(1) {
  pointer-events: none !important;
}

.calendarRange {
  height: 2.625rem;
  font-size: 0.75rem;
  // margin-top: 0.75rem;
  flex-basis: 70%;
}

.headerContent {
  width: 100%;
  padding-top: 25px;
  margin-bottom: 15.5px;
  background-color: #fff;

  .boxTopTitle {
    font-size: 1.375rem;
    font-weight: 500;
    color: #000;
  }

  .boxTopValue {
    color: #000;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 1.3125rem 0 1rem 0;
  }

  .dataAndObjectFilters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;

    .boxTopDateCount {
      font-size: 1rem;
      color: #4fb2ed;
      font-weight: 400;
      margin-left: 0.5rem;
    }

    .selectCode {
      max-width: 78%;
      flex-basis: 82%;
      height: 2.625rem;

      @media (max-width: 1600px) {
        max-width: 76%;
      }

      @media (max-width: 1440px) {
        max-width: 75%;
      }
    }

    .crossIcon {
      width: 2.625rem;
      height: 2.625rem;
      background-color: #ebebeb;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.boxEventScroll {
  padding: 0 1rem 0 0;
  flex: 1;
  overflow-y: scroll;
}

.selectedBlock {
  display: flex;
  align-items: center;
}

.selectedItem {
  padding: 8.35px 12.68px 9.65px 9.5px;
  background-color: #4fb2ed;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-bottom: 9.5px;
  margin-right: 9.5px;

  .selectedItemText {
    font-size: 1rem;
    font-weight: 400;
    margin-right: 27px;
  }
}

.boxTop {
  padding: 25px 29px 0 29px;
  margin-bottom: 15.5px;

  .newEvents {
    display: flex;
    align-items: center;

    .newEventsText {
      font-size: 1rem;
      font-weight: 400;
      color: #000;
      margin-right: 16px;
    }
  }
}

.filterFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectOfTypes {
  width: 100%;
}

.boxEvent {
  flex: 1;
  margin: 0 1.75rem;
  margin-top: 10px;
}

.supplyerFlex {
  display: flex;
  font: normal normal 400 1.125rem var(--title-font);
  margin-bottom: 17px;
  margin-top: 17px;
}

.objectFlex {
  display: flex;
  font: normal normal 400 1.125rem var(--title-font);
}

.mountFlex {
  display: flex;
  font: normal normal 400 1.125rem var(--title-font);
}

.updFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boxEventPay {
  display: flex;
}

.boxEventSup {
  display: flex;
  border-left: 1px solid #bfbfbf;
  margin-right: 24px;
}

.sliderEventValue {
  width: 50%;
  border-right: 1px solid #bfbfbf;
  padding-right: 20px;
}

.sliderEvents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sliderDayTitle {
  font-size: 1.2rem;
  margin-top: 0.75rem;
  margin-left: 0.5rem;
  margin-bottom: 1rem;

  &:first-child {
    margin-top: 1.4rem;
  }

  @media (max-width: 1480px) {
    margin-left: 0;
  }
}

.typesTitle {
  font: normal normal 400 1.125rem var(--title-font);
  margin: 20px 0 16px 0;
}

.sliderEventInfoRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  height: 2rem;
  font: normal normal 400 1.125rem var(--title-font);
}

.wrapperTypes {
  width: 100%;
  overflow-y: auto;
  height: 100vh;
}

.typesContainer {
  .storageOfTypes {
    display: flex;
    flex-wrap: wrap;
  }
}

// media styles

@media (max-width: 1600px) {
}

.scrollEventsPosition {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  // top: 20px;
  left: 0;
  z-index: 1;
  background: white;
}

div.filterFlex {
  div.selectCode {
    width: 44% !important;
    height: 42px;
  }
}

@media (max-width: 1700px) {
  div.container {
    padding: 0 29px;
  }

  div.dataAndObjectFilters {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div.crossIcon {
      width: 2.7rem !important;
      height: 2.5rem !important;
      background-color: #ebebeb;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

@media (max-width: 1500px) {
  div.supplyerFlex,
  div.objectFlex,
  div.mountFlex {
    font: normal normal 400 1rem var(--title-font);
  }

  div.sliderEventInfoRow {
    font: normal normal 400 1rem var(--title-font);
  }

  div.selectCode {
    flex-basis: 77% !important;
  }

  div.calendarRange {
    flex-basis: 66%;
  }
}

@media (min-width: 2000px) and (max-width: 2400px) {
  div.container {
    max-width: 550px;
  }

  div.dataAndObjectFilters {
    display: flex;
    justify-content: space-between;

    div.crossIcon {
      width: 2.6rem !important;
      height: 2.6rem !important;
      background-color: #ebebeb;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  div.boxEventSup {
    display: flex;
    border-left: 1px solid #bfbfbf;
    margin-right: 50px;
  }

  .scrollEventsPosition {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 190px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .positionTypesEvents {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 340px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .container,
  .container2 {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
  }

  .container2 {
    margin-top: 0.75rem;
  }

  .fixedHeader {
    width: 100%;
    padding: 0 1.813rem 0 1.75rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111111;
    background-color: #fff;
    border-top-left-radius: 1rem;
  }
}

@media (min-width: 2401px) and (max-width: 2700px) {
  .scrollEventsPosition {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 190px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .positionTypesEvents {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 340px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .container,
  .container2 {
    max-width: 650px;
    margin: 0 auto;
    background-color: #fff;
  }

  .container2 {
    margin-top: 0.75rem;
  }

  .fixedHeader {
    width: 100%;
    padding: 0 1.813rem 0 1.75rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111111;
    background-color: #fff;
    border-top-left-radius: 1rem;
  }

  div.boxEventSup {
    display: flex;
    border-left: 1px solid #bfbfbf;
    margin-right: 90px;
  }

  div.container {
    max-width: 650px;
  }

  div.dataAndObjectFilters {
    display: flex;
    justify-content: space-between;

    div.crossIcon {
      width: 2.7rem !important;
      height: 2.5rem !important;
      background-color: #ebebeb;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

@media (min-width: 1701px) and (max-width: 1999px) {
  .scrollEventsPosition {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 190px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .positionTypesEvents {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 340px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .container,
  .container2 {
    max-width: 498px;
    margin: 0 auto;
    background-color: #fff;
  }

  .container2 {
    margin-top: 0.75rem;
  }

  .fixedHeader {
    width: 100%;
    padding: 0 1.813rem 0 1.75rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111111;
    background-color: #fff;
    border-top-left-radius: 1rem;
  }
}

.tabs {
  display: flex;
  justify-content: center;
  width: 100%;
}

.tabsContainer {
  width: 100%;
  margin-top: 0.75rem;
}

.tab {
  flex-basis: 50%;
}

.placeholder {
  margin-top: 4rem;
}

@media screen and (max-width: 1650px) {
  .calendarPopup {
    right: 65% !important;
  }
}
