.listSide__header {
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;
  align-items: center;
  margin-right: -1rem;
}

.listSide__title {
  font-size: 1rem;
}

.sticky {
  position: sticky;
  padding-top: 10px;
  z-index: 2;
  background-color: #fff;
  top: 1rem;
  padding-top: 0;
  padding-bottom: 14px;

  & > * {
    position: relative;
  }

  &:before {
    position: absolute;
    content: "";
    width: calc(100% + 0.875rem + 1.88rem);
    height: calc(100% + 1rem);
    left: -1.88rem;
    top: -1rem;
    background-color: #fff;
  }
}

.total {
  position: sticky;
  bottom: 3.25rem;
  font-weight: 600;
  padding-left: 0 !important;
  background-color: #fff !important;
  z-index: 2;

  &:hover {
    background: #4fb2ed !important;
  }
}

.sticky {
  &::before {
    border-bottom: 1px solid #c3c3c3;
    height: calc(100% + 1rem + 1px);
  }
}

.extraControl {
  padding-right: 1rem;
}

.popover {
  padding: 0.5rem 1rem;
  color: #4fb1eb;
}

@media screen and (max-width: 1700px) {
  .sticky {
    padding-bottom: 16px;
  }
}
