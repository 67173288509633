.sectionRow {
  display: grid;
  grid-template-columns: 85% 15%;
}

.sectionExpHeader {
  display: grid;
  grid-template-columns: 6% 60% 12% 17% 3%;
  height: 3rem;
  font-weight: 500;
}

.sectionExpHeaderWrapper {
  height: 3rem;
}

.expRow {
  display: grid;
  background-color: #fff;
  grid-template-columns: 6% 60% 12% 17% 3%;
}

.expRowWrapper {
  background-color: #fff;
  border-radius: 0 !important;

  &:not(:last-child) {
    & > div {
      border-bottom: 1px solid #d6d6d6;
    }
  }
}
