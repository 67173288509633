.connectPoint {
  position: absolute;
  top: 50%;
  width: 0.75rem;
  height: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  cursor: pointer;
  /* Выше popupAnchor */
  z-index: 4;
  &:active {
    cursor: grabbing;
  }
}

.arrowAcceptElement {
  // над sharedBrace
  z-index: 2;
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
