.logo {
  margin: 1rem auto;
  display: block;
}

.simplified {
  overflow: hidden;

  &.approve_estimate {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}