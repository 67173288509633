.daysWrapper {
  position: relative;
  user-select: none;
}

.dayWrapper {
  line-height: 1.75rem;
  height: 2.25rem;
  position: absolute;
  top: 0;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(177, 177, 177, 1);
}

.weekend {
  color: #4fb2ed;

  &.critical {
    color: red;
  }
}

.today {
  .day {
    width: 1.75rem;
    height: 1.75rem;
    color: #fff;
    background-color: #4fb2ed;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.critical {
      .day {
        color: red;
      }
    }
  }
  &::after {
    content: " ";
    position: absolute;
    top: 2.25rem;
    width: 0.1875rem;
    height: 100vh;
    background-color: #707070;
    z-index: 0;
    pointer-events: none;
  }
}

.critical {
  color: red;
}

.hasCheckpoint {
  cursor: pointer;
  .day {
    width: 1.75rem;
    height: 1.75rem;
    color: #fff;
    background-color: var(--color-indicator-frostbite);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
