
.dayNameBlock {
  display: grid;
  grid-template-columns: repeat(7, 1.25fr);
  grid-auto-rows: 1fr;
  margin: 0 auto;
  max-width: 19.03125rem;
}

.dayName {
  font-weight: 400;
  color: #000;
  text-align: center;
  font-size: 1rem;
  height: 1.75rem;
  width: 2.71875rem;
  margin-bottom: 0.1875rem;
}

.dayNumber {
  cursor: pointer;
  font-weight: 400;
  font-family: var(--main-font);
  color: #707070;
  text-align: center;
  font-size: 1rem;
  height: 1.75rem;
  width: 2.71875rem;

  margin-top: 0.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    z-index: 1;
    box-shadow: inset -.5px -.5px 0 solid var(--color-background-primary);
  }
}

.dayNumber:first-child {
  background-color: #000;
}

.holidays {
  color: #4FB2ED;
}

.activeDate {
  color: #fff;
  background-color: #4FB2ED;
  height: 1.75rem;
  width: 2.71875rem;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeDateEnd {
  color: #fff;
  background-color: #4FB2ED;
  height: 1.75rem;
  width: 2.71875rem;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


.numberWithBackground {
  background-color: #ECF2FE;
}

.secondaryDate {
  opacity: .5;
}

.pseudoEnding:not(.activeDateEnd), .pseudoBeginning:not(.activeDate) {
  color: #fff;
  background-color: var(--color-background-field16);
}

.pseudoEnding, .activeDateEnd  {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pseudoBeginning, .activeDate {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pseudoBeginning:not(.singleDate) {
  border-radius:  4px 0 0 4px !important;
}

.pseudoEnding:not(.singleDate) {
  border-radius: 0 4px 4px 0 !important;
}
