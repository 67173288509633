.row {
  display: grid;
  margin: 0.625rem 0;
  column-gap: 0.625rem;
  grid-template-columns: 8fr 4fr;
  padding-top: 0.3rem;
}

.row1 {
  display: grid;
  align-items: flex-end;
  margin: 0.625rem 0;
  column-gap: 0.625rem;
  grid-template-columns: 1fr auto;
}

.fileInput {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  padding-top: 0.3rem;
}

.upload {
  margin-left: 0.5rem;
  color: #000000 !important;
  background: #ededed !important;
  border-radius: 1rem;
  width: fit-content;
  padding: 0 0.4rem;

  & svg {
    margin-right: 0.5rem;
  }

  & path {
    fill: #000000;
  }
}

.remove {
  margin-bottom: 0.5rem;
}

.fileRow {
  width: fit-content;
  white-space: nowrap;

  & svg {
    margin-left: 1.5rem;
  }
}
