.container {
  border-radius: 0.75rem;
  border: 1px solid #ebebeb;
  background: #fafafa;
  margin-top: 1rem;
}

.first {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.75rem;
  border-bottom: 1px solid #ebebeb;
}

.second {
  display: flex;
  flex-direction: column;
  //padding: 0.75rem;
}

.row {
  display: grid;
  grid-template-columns: 40% 26% 26%;
  gap: 2%;
  padding: 0 2%;
  height: 2rem;
  align-items: center;

  &:nth-child(even) {
    background-color: #f4f4f4;
  }

  & label {
    color: #696969;
    margin-bottom: 0 !important;
    font-weight: 600 !important;
  }
}

.secondMark {
  text-align: right;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 1rem;
    height: 1px;
    background-color: #a4a4a4;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-weight: 500;
  flex-shrink: 0;
}

.edit {
  color: #4fb2ed;
  font-weight: 400;
}

.tableCell {
  font-size: 1rem !important;
}

.filesPortal {
  z-index: 100000;
}

.filesPopup {
  padding: 0.5rem;
  width: 200px;
  display: grid;
  gap: 0.25rem;

  * {
    margin-bottom: 0 !important;
  }
}
