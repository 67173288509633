.progressIsland {
  width: 100%;
  grid-column: 1;
  grid-row: 1 / 4;
  padding: 1rem 0.6875rem 1.375rem 1.25rem;
  position: relative;
}

$cubeSide: 5vw;

.scene {
  width: calc(2 * $cubeSide * 0.866);
  height: calc(2 * $cubeSide);
  margin-right: auto;
  margin-left: 0;
  position: absolute;
  left: 0;
}

.cube {
  display: flex;
  width: inherit;
  height: inherit;
  justify-content: center;
  align-items: center;
  position: relative;
  transform-style: preserve-3d;
}

.cubeFace {
  position: absolute;
  width: $cubeSide;
  height: $cubeSide;
  border: 0.125rem solid #ebebeb;
  backface-visibility: hidden;
}

.cubeFaceFront {
  transform: rotate(-30deg) skewX(-30deg) scaleY(0.864) translate(50%, 50%);
}

.cubeFaceRight {
  transform: rotate(90deg) skewX(-30deg) scaleY(0.864) translate(50%, 50%);
}

.cubeFaceTop {
  transform: rotate(210deg) skew(-30deg) scaleY(0.864) translate(50%, 50%);
}

.displayNumbers {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}

.content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  column-gap: 1rem;
  flex-wrap: nowrap;
  padding: 0;
  margin-right: -0.125rem;
  height: inherit;
}

.heading {
  font-size: 1rem;
}
