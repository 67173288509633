.arrowContainer {
  flex-shrink: 0;
  border-radius: 1rem;
}

.arrow {
  transform: rotate(-180deg);
}

.openArrow {
  transform: rotate(0);
}

.background {
  background: rgba(0, 0, 0, 0.09);
}

.withBackground {
  padding: 0 0.6rem 0.125rem;
}