.paymentTerms {
  width: 29rem;
  padding: 0.75rem 1rem;
  border: #E1E8EE 1px solid;
  border-radius: 0.5rem;

  .header, .headerText {
    display: flex;
    align-items: center;
  }

  .header {
    height: 24px;
    justify-content: space-between;
  }

  .headerText {
    margin: 0;
    color: #000;
    cursor: pointer;
  }

  .addButton {
    width: 1.714rem;
    height: 1.714rem;
  }

  .headerText, .fieldsSignature {
    font: normal normal 1.125rem/1.375rem var(--title-font);
  }

  .fieldsSignatures {
    display: flex;
    margin-left: 14rem;
  }

  .fieldsSignature {
    width: 5rem;
    text-align: center;
    color: var(--color-font-secondary1);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
  }
}

.waiting {
  margin-top: 0.5rem;
  font-style: italic;
}

.termsRows {
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  font-weight: 400;

  &:not(:last-child) {
    padding-bottom: 0.5rem;
    border-bottom: #E1E8EE 1px solid;
  }
}


