@use "src/scss/mixins/_ui-indicators.scss" as ui_indicators;

@import "src/scss/dimensions";
@import "src/scss/mixins/sliceTextByLines";

.container {
  padding: 0.2rem 0.1rem;
  position: relative;
  width: 100%;
  height: 8rem;

  &:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }

  .content {
    padding: 1rem;
    height: 100%;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;

    & > * {
      // flex-shrink: 0;
    }

    & > *:not(:last-child):not(.actionsCol) {
      padding-right: 1rem;
    }

    &:hover,
    &.active {
      background-color: #f8f8f8;
    }

    &:hover .addIntervalCol {
      opacity: 1;
    }
  }
}

.numberCol {
  width: 3%;
  text-align: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  position: relative;
  top: -0.3375rem;
}

.number {
  margin-bottom: 0.25rem;
}

.infoCol {
  width: 37%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.matching {
  margin-left: auto;
}

.countCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.priceCol,
.countCol {
  width: 10%;
}

.intervalCol {
  width: 21%;
  position: relative;
}

.actionsCol {
  width: 8%;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.collapseBtn {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -8px;
  width: 5rem;
  height: 16px;
  background-color: #4fb2ed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  z-index: 2;
}

.expanded {
  height: auto;
}

.handlerDraft {
  .infoCol {
    width: 32%;
  }
  .intervalCol {
    width: 17%;
  }
}

.plan,
.progress {
  .intervalCol {
    width: 15%;
  }
}

.infoContainer {
  margin-top: 0.5rem;
}

.addIntervalCol {
  width: 3%;
  opacity: 0;
}

.addIntervalIcon {
  cursor: pointer;
}

.relatedWork {
  margin-right: -33%;
  @include slice-text-by-lines(1, 43%);

  @media screen and (max-width: 1680px) {
    width: 40%;
  }
}

.withoutDiscountIcon {
  opacity: 0.5;
}

.estimatePriceDoubleInput {
  width: 16.25rem;
}

.discount,
.estimatePriceCol {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 1rem;
}

.diffCol {
  display: flex;
}

.diffPercent,
.diffPrice {
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.diffPercent {
  width: 30%;
}

.diffPrice {
  width: 70%;
}

.pricingModeBudget {
  .infoCol {
    width: 42%;
  }

  .priceCol {
    width: 12%;
    text-align: center;
    white-space: nowrap;
  }

  .discount {
    width: 18%;
  }

  .withDiscountAmount {
    width: 13%;
    text-align: center;
  }

  .countCol {
    width: 12%;
  }
}

.pricingModeEstimatedPrice {
  .infoCol {
    width: 42%;
  }

  .countCol,
  .budgetCol {
    width: 10%;
    text-align: center;
  }
  .budgetCol {
    white-space: nowrap;
  }

  .estimatePriceCol {
    width: 21%;
  }

  .diffCol {
    width: 14%;
  }
}

$indicatorSize: 8px;

.withIndicator:before {
  @include ui_indicators.getIndicatorAsContent($indicatorSize);
  position: absolute;
  left: -1rem;
  top: calc(50% + 0.52rem);
  transform: translateY(-50%);
}

.providersList {
  padding: 0.5rem 1rem;
}

.providerItem {
  margin-top: 0.625rem;

  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }

  .status {
    color: var(--blue-blue-accent, #4fb1eb);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
  }

  .providerName {
    color: var(--base-text-secondary, #696969);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    margin-top: 0.12rem;
  }
}
