.innerLinks {
  display: flex;
  background: #EFEFEF;
  border-radius: 0.5rem;
  padding: 0 0.1rem;
  width: min-content;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.25rem;
  font-weight: 400;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  height: 2.3rem;
  width: 9.518125rem;
}

.activeLink {
  background-color: var(--color-background-primary);
  color: #fff;

  &:hover {
    color: #fff;
  }
}