.nav {
  padding-top: 1.8rem;
  margin-bottom: 0 !important;
  min-height: 3.875rem;
}

.sticky {
  position: sticky;
  z-index: 3;
  background-color: #fff;
  top: 2.8rem;
}
