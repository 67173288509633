.header {
  display: flex;
  align-items: center;
  gap: 1rem;

  h1 {
    font-size: 1.125rem;
    margin-bottom: 0;
  }
}

.table {
  width: 100%;
  margin-top: 1rem;
}
