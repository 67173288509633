.pair {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.25rem;

  &.centered {
    align-items: center;
  }
}

.pass {
  display: flex;
  align-items: center;

  & b {
    flex-basis: 30%;
  }
}

.info {
  display: flex;
  flex-direction: column;

  & b {
    margin-bottom: 0.75rem;
  }
}

////////////////////////////
.justification {
  margin-left: auto;
  margin-right: auto;
  min-width: 10rem;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #4fb2ed;
  background: #e6f4fc;
  height: 1.875rem;
  border-radius: 0.9rem;
  width: fit-content;
  padding: 0 0.5rem;
}

.hr {
  width: 100%;
  height: 1px;
  background: #dbdbdb;
  margin-bottom: 1rem;
}

.mb {
  margin-bottom: 1.25rem;
}

.navbar {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  width: 100%;

  & span {
    font-size: 1.125rem;
    margin-left: 1.8rem;
  }
}

.subtitle {
  margin-top: 0.5rem;
}

/////////////

.formInput {
  width: 90%;
}

.countsContainer {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #c3c3c3;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: -2rem;
  margin-right: -2rem;
}

.counts {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;

  & .info {
    & b {
      font-weight: 600;
      color: #696969;
      margin-bottom: 0.25rem;
    }

    & span {
      font-weight: 500;
    }
  }
}

.countsHeader {
  font-weight: 700;
  margin-bottom: 1rem;
}

@media (max-width: 1440px) {
  .countsContainer {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}
