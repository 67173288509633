.summary {
  display: flex;
  flex-grow: 1;
  width: inherit;
}

.summary__listSide {
  width: 32vw;
  padding-right: 0.875rem;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.161);
  flex-shrink: 0;
  margin-top: -26px;
  padding-top: 26px;
  padding-bottom: 22px;
}

.summary__tableSide {
  padding-left: 0.625rem;
  width: calc(100% - 31.6vw);

  & :global(.spinnerGlobal) {
    margin-top: 2rem;
  }
}

.sticky {
  position: sticky;
  z-index: 2;
  background-color: #fff;
  top: 1rem;

  & :global(.ant-table-wrapper) {
    pointer-events: none;
    scroll-behavior: auto !important;
    transition: 0s !important;
  }

  &:before {
    content: "";
    position: absolute;
    height: 1rem;
    background-color: #fff;
    width: 100%;
    top: -1rem;
  }

  & :global(.ant-table-content) {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
}

@media screen and (max-width: 1700px) {
  .summary__listSide {
    width: 30vw;
  }

  .summary__tableSide {
    width: calc(100% - 29.6vw);
  }
}

@media screen and (max-width: 1441px) {
  .summary__listSide {
    width: 28vw;
  }

  .summary__tableSide {
    width: calc(100% - 28.6vw);
  }

  .sticky {
    & thead {
      font-size: 0.9rem !important;
    }
  }
}
