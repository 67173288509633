.myModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.4);
  display: none;
  z-index: 1000;
}

.myModal.active {
  display: flex;
  justify-content: flex-end;
  max-height: 100vh;
}

.myModalContent {
  width: 696px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;

  .closeModalBtn {
    background-color: #4FB2ED;
    border-top-left-radius: 21px;
    border-bottom-left-radius: 21px;
    width: 100px;
    height: 72px;
    position: absolute;
    top: 10px;
    left: -40px;
    z-index: -1;
    cursor: pointer;
    padding-left: 13px;
    display: flex;
    align-items: center;
  }
}