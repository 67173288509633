.container {
  label {
    font: normal normal 400 1rem var(--main-font) !important;
  }

  .header {
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #C3C3C3;
    padding: 1.5rem 2.5rem;
  }

  .addButtonClass {
    border-radius: 5px;
    padding: 0 0.5rem 0 1rem;
    margin-left: 1rem;
  }

  .activeClass {
    background-color: #00b4f2;
    color: #FFFFFF;
  }

  .title {
    font: normal normal 700 1.125rem var(--title-font);
    color: #000;
    margin-bottom: 1.175rem;
  }

  .topSide {
    padding: 0rem 2.5rem;
  }

  .subTitle {
    // font: normal normal 400 0.65rem var(--title-font);
    font-size: 0.75rem;
    color: #000;
  }

  .row1 {
    display: flex;


    .rowItem {
      width: 31.5%;
      margin: 1.5rem 0.5rem 0 0;

      &:last-child {
        margin-top: 1rem;
      }
    }
  }

  .nameInput {
    border-color: #C3C3C3;
    color: #000;
  }

  .colorInput {
    color: #000;
  }


  .bottomSide {
    padding: 1.75rem 2.5rem 1rem 2.5rem;
    display: flex;
    align-items: center;
    width: auto;
    height: 150px;

    .row2 {
      display: flex;
      align-items: center;

      .rowItem {
        width: 35%;
      }

      .rowItem2 {
        font: normal normal 400 0.5rem var(--main-font);

        .checkbox {
          border-color: #C3C3C3;
        }

      }

    }
  }
}

.actionBlock {
  display: flex;
  padding: 1rem 2.5rem 1rem 2.5rem;
  justify-content: center;
  opacity: 1;
  border-top: 1px solid #c3c3c3;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
}

.action {
  opacity: 0;

}

.btnSave {
  margin-left: 1rem;
}

.dostup {
  border: 1px solid #4FB2ED;
  height: 100%;
  border-radius: 12px;
  padding: 0rem 1rem;
  font-size: 1rem;
  display: flex;
  font-family: var(--main-font);
  align-items: center;
  justify-content: space-between;

  .iconPlus {
    cursor: pointer;
  }
}

.rowItem {
  margin-top: 1rem;
}