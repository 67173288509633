.shadow {
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  margin: 0 auto;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 0.5em;

  .header {
    padding: 1rem;
    width: 20rem;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      display: flex;
      justify-content: space-between;
      margin: 0.2rem 0;
      height: 14px;
    }

    .crossIcon {
      width: 1rem;
      cursor: pointer;
    }
  }
}

.actionBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn {
    border: 1px solid #4FB2ED;
    border-radius: 0.5rem;
    background: none;
    color: #000;
    font-size: 1.13rem;
    font-family: var(--title-font);
    font-weight: 400;
    padding: 0 0.5rem;
    margin-left: 0.5rem;
  }
}
