.chapter {
  background-color: #f4f4f4;
  border-radius: 0.5rem;
  padding: 0 1.375rem;
  font-size: 0.875rem;
  line-height: 1rem;
  height: 3.625rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover, &.active {
    background-color: var(--color-background-primary);
    color: #fff;
  }
}