.sectionTitleClassName {
  padding: 0.5rem 0;
  border-bottom: 1px solid #f4f4f4;
  font-weight: 500;
  width: 100%;
}

.subSectionTitleClassName {
  padding: 0.5rem 0;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.checkbox {
  margin-right: 1rem;
}

.row {
  padding: 0.5rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subSection {
  padding-left: 1rem;
}

.section {
  padding-left: 1rem;
}

.expClassName {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 5rem 5rem;
}

.measure {
  text-align: center;
}

.sectionRow {
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 1fr 5rem 5rem;

  div {
    font-weight: 500;
  }
}

.segments {
  padding-left: 3.25rem;
  display: flex;
  padding-top: 0.25rem;
  gap: 0.25rem;
}

.segment {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(150, 150, 150);
  background-color: rgb(235, 235, 235);
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
}
