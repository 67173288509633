.editAnchorContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  // над arrowAcceptElement
  z-index: 3;
  display: flex;
}

.editAnchorMid {
  flex-grow: 1;
  cursor: ew-resize;
}

.editAnchorHead, .editAnchorTail {
  flex-grow: 0;
  width: 10px;
  cursor: col-resize;
  position: relative;

  &:hover:after {
    background-color: var(--color-background-field12);
  }
}


.editAnchorHead:after, .editAnchorTail:after {
  content: ' ';
  display: block;

  position: absolute;
  top: calc(50% - 0.5625rem);
  width: 2px;
  height: 1.125rem;

  border-radius: 2px;

  background-color: var(--color-background-field7);
}

.editAnchorHead:after {
  left: 2px;
}

.editAnchorTail:after {
  right: 2px;
}