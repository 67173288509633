.container {
  display: flex;

  .content {
    width: calc(100% - 4rem);
    background: #fff;
    overflow-x: hidden;
    height: calc(100vh - 4rem);
    overflow-y: scroll;
  }
}