@import "src/scss/mixins/sliceTextByLines";

.modal {
  width: 50rem;
}

.grid {
  display: grid;
  padding: 1rem 1.5rem 2rem;
  grid-template-columns: 50% 25% calc(25% - 1.5rem);
  column-gap: 0.75rem;
  row-gap: 2rem;
  grid-template-areas: "_ _ _" "_ _ _" "estimate estimate estimate";

  & :global(.inputContainer) {
    width: 100%;
  }
}

.name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.controls {
  display: flex;
  border-top: 1px solid #ebebeb;
  padding: 1rem 1.5rem;
  gap: 1rem;
  justify-content: flex-end;
}

.estimate {
  grid-area: estimate;
}

.choseBtn {
  color: #4fb1eb;
  gap: 0;

  & button {
    transform: scale(0.8);
  }
}

.expenditureName {
  @include slice-text-by-lines(3);
}

.estimateTitle {
  display: flex;
  align-items: center;
  height: 1.3rem;
}

.select {
  width: 100%;
}

.spinner {
  display: flex;
  justify-content: flex-start;
  padding-left: 1.5rem;
}
