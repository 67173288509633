.content {
  h1 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  img {
    max-width: 100%;
  }

  h6,
  :global(.markdownClosed) h6 {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  & :global(.markdownClosed) :global(.bg-blue),
  & :global(.markdownClosed) :global(.bg-orange),
  & :global(.markdownClosed) :global(.bg-light) {
    background: transparent;
    border: none;
    &:before {
      display: none;
    }
  }

  & :global(.markdownClosed) li {
    list-style: none;
  }

  & :global(.bg-blue) {
    background-color: #eef7fd;
    padding: 0.5rem;
    padding-left: calc(0.5rem + 7px);
    border-radius: 0.5rem;
    border: 1px solid #e2f4fe;
    position: relative;
    overflow: hidden;

    &:before {
      position: absolute;
      height: 100%;
      width: 4px;
      content: "";
      left: 0;
      top: 0;
      background-color: #4fb1eb;
    }

    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & :global(.bg-orange) {
    background-color: #fff3de;
    padding: 0.5rem;
    padding-left: calc(0.5rem + 7px);
    border-radius: 0.5rem;
    border: 1px solid #f7b55392;
    position: relative;
    overflow: hidden;

    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &:before {
      position: absolute;
      height: 100%;
      width: 4px;
      content: "";
      left: 0;
      top: 0;
      background-color: #ef9c1c;
    }
  }

  & :global(.bg-light) {
    background-color: #fafafa;
    padding: 0.5rem;
    padding-left: calc(0.5rem + 7px);
    border-radius: 0.5rem;
    border: 1px solid #eeeeee;
    position: relative;
    overflow: hidden;

    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &:before {
      position: absolute;
      height: 100%;
      width: 4px;
      content: "";
      left: 0;
      top: 0;
      background-color: #4eb1eb;
    }
  }

  & :global(.ol-blue) {
    padding-left: 0;
    list-style: none;
    counter-reset: my-sec-counter;

    & > li {
      position: relative;

      &:before {
        color: #4eb1eb;
        counter-increment: my-sec-counter;
        content: counter(my-sec-counter);
        margin-right: 0.5rem;
      }
    }

    & :global(.ul-circles) {
      padding-left: 1rem;
    }
  }

  & :global(.ul-circles) {
    padding-left: 0;
    list-style: none;

    & li {
      padding-left: 1rem;
      position: relative;

      &:before {
        background-color: #4eb1eb;
        content: "";
        position: absolute;
        left: 2px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: calc(1.3rem / 2);
        transform: translateY(-50%);
      }
    }
  }

  & :global(.offset-left)::first-letter {
    padding-left: 2rem;
  }
}
