.form {
  label {
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: black;
    font-family: var(--title-font) !important;
  }
  & > * {
    margin-bottom: 1.2rem;
  }
}

.fieldInput {
  width: auto !important;
  padding-left: 0 !important;
}

.calendarOptions {
  left: -20%;
}

textarea.fieldInput {
  height: auto;
}

.options {
  width: calc(100% + 2px) !important;
  top: 100% !important;
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
}
