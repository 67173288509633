.content {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  padding: 1rem;
}

.modal {
  width: 36rem;
  position: relative;
}

.bottom {
  display: flex;
  column-gap: 1rem;
  padding: 1rem 1.25rem;
  justify-content: end;
  border-top: 1px solid #ebebeb;
}
