.container {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0 0 20px;
  border: none;
  background: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.shadow {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}

.closeButtonBlock {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  left: -2.3rem;
  background-color: var(--color-background-primary);
  border-radius: 21px;
  width: 4rem;
  height: 4.5rem;
  padding: 0.9rem;
  display: flex;
  align-items: center;

  & button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.collapseBtn {
  top: 6.5rem;

  &.isCollapsed {
    & > * {
      transform: scaleX(-1);
    }
  }
}

.containerWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 80rem;
  height: 100%;
  z-index: 101;
  transition: all 0.5s ease;
  transform: translateX(110%);
  right: 0;

  &.active {
    transform: translateX(0%);
  }

  &.collapsed {
    transform: translateX(calc(100%));
  }
}

.childrenContainer {
  height: 100%;
}

.flex {
  display: grid;
  width: 100%;
  min-height: 100%;
  grid-template-columns: 18rem 1fr;
}

.nav {
  background: #fafafa;
  border-right: 1px solid #ebebeb;
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.content {
  max-height: 100vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.header {
  border-bottom: 1px solid #ebebeb;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1.25rem 1.25rem 1rem 1.25rem;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;

  & span {
    color: #4fb1eb;
  }
}

.nav {
  & .header {
    background: #fafafa;
  }
}

.navContent {
  padding: 0.5rem;
}
