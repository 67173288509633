.markOpacity {
  color: #a4a4a4;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popoverTarget {
  width: 100%;
}

.buttonGroup {
  height: 2.5rem;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border-radius: 2rem;
  transition: 0.1s all ease-in-out;
  background-color: #4fb2ed;
  color: #fff;
  font-size: 0.8125rem;

  &:hover {
    background-color: #fff;
    color: #000;
  }
}
