.monthsLine {
  position: relative;
  width: auto;
}

.monthCaptionBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
  position: absolute;
  top: 0;
  height: 2.25rem;
  background-color: #f4f4f4;
  border-bottom: 1px solid rgba(177, 177, 177, 1);
  border-right: 1px dashed rgb(222, 222, 222);
}

.monthInterval {
  color: #4fb1eb;
}

.weekNumber {
  position: absolute;
  top: 2.25rem;
  z-index: 1;
  transform: translateX(-50%);
  span {
    font-size: 0.9375rem;
    color: #e3e3e3;
  }
  &:not(.hasCheckpoint) {
    pointer-events: none;
  }
}

.currentWeek > span {
  color: #4fb2ed;
}

.currentWeek::after {
  content: " ";
  position: absolute;
  width: 0.1875rem;
  height: calc(100vh - 1.5rem);
  top: 1.5rem;
  left: 50%;

  transform: translateX(-50%);
  z-index: 0;

  background-color: #4fb1eb;
}

.monthName {
  &:first-letter {
    text-transform: capitalize;
  }
}

.hasCheckpoint.weekNumber {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--color-indicator-frostbite);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    color: #fff;
  }
}
