.input {
  border: 1px solid #C4C4C4;
}

label {
  font: normal normal 400 1rem var(--main-font);
  color: #666666;
  margin-bottom: 0.35rem;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.64rem;
  border: 1px solid var(--color-border-field18);
  border-radius: .5rem;
  padding: 0.7rem 1rem;
  font: 1rem AvenirNextCyr;
  cursor: pointer;
  position: relative;
  width: 28.6rem;

  input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border: none;

    &::placeholder {
      color: darken(#ececec, 30%);
    }

    &:disabled{
      color: #A0A0A0;
    }
  }

  &.isOpen {
    border-color: #4FB2ED;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 0 0;
  }

  &.disabled {
    color: #A0A0A0;
    cursor: default;
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow {
    transition: all 0.2s ease;
    width: 1rem;
  }

  .arrowReverse {
    transform: rotate(-180deg);
  }

  .optionsBlock {
    // display: none;
    width: 100.5%;
    // width: 110%;
    width: 10rem;
    top: 27px;
    left: -1px;
    border-radius: 0 0 12px 12px;
    position: absolute;
    background: #fff;
    z-index: 9999;
    transition: all 0.3s ease;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    max-height: 11.8rem;
    overflow-y: auto;
    height: 0;

    &.isOpen {
      height: auto;
      border-right: 1px solid #4FB2ED;
      border-left: 1px solid #4FB2ED;
      border-bottom: 1px solid #4FB2ED;
      border-top: 1px solid #DDDDDD;
    }

    .option {
      border-radius: 0.5rem;
      padding: 0.6rem 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        background: #4FB2ED;
        color: #fff;
      }
    }
  }

  .isOpen {
    display: block;
  }
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #FF0558;
}