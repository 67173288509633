.container {
  padding-bottom: 22px;
}

.slider {
  margin-left: 1rem;
  height: var(--input-height);
}

.switch {
  margin-left: auto;
}

.right {
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.header {
  z-index: 10;
}
