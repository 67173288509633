.row {
    grid-template-columns: 5% 13% 18% 15% 13% 14% 15% 7%;
    padding-left: 1.5rem;

    &:hover {
        & .orderLink {
            color: #fff;

            & svg {
                & path {
                    fill: #fff;
                }
            }
        }
    }
}

.inProduction {
    grid-template-columns: 8% 28% 15% 13% 14% 15% 7%;
}

.actions {
    height: 2.56rem;
    width: 5.43rem;
    background-color: #fff;
    border-radius: 10rem;
    display: flex;
    align-items: center;
}

.action {
    flex: 1;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
