.footer {
  border-top: 1px solid #ebebeb;
  position: sticky;
  bottom: 0;
  right: 0;
  padding-top: 1rem;
  background-color: #fff;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-bottom: 0.75rem;
}

.footerScroll {
  overflow-x: auto;
  width: 100%;
  pointer-events: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  //
}

.firstRow {
  display: flex;
  font-size: 14px;
  font-family: AvenirNextCyr !important;
  font-weight: 600;
  line-height: 20px;
}

.footerMonth {
  flex-shrink: 0;
  display: flex;
}

.footerCell {
  width: 116px;
  flex-shrink: 0;
  color: #000;
  height: 1.25rem;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: end;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
  font-family: AvenirNextCyr !important;
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
}

.red {
  color: #f00;
}

.secRow {
  display: flex;
  color: #696969;
  font-weight: 600;
  gap: 3.75rem;
  height: 1.5rem;
  align-items: center;
  font-size: 14px;

  & > div {
    min-width: 200px;
    display: flex;
    gap: 0.75rem;

    & > span {
      color: #000;
      font-family: AvenirNextCyr !important;
      font-weight: 600;
      line-height: 20px;
      font-size: 14px;
    }
  }
}

.legend {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60vw;
  background-color: #fff;
  height: 100%;
  transform: translateX(-100%);
  padding-right: 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-bottom: 0.75rem;
  padding-top: 1rem;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    top: -1px;
    left: 0;
    border-top: 1px solid #ebebeb;
  }
}

.legendRow {
  border-right: 1px solid #c3c3c3;
  padding-right: 1.5rem;
  display: flex;
  text-align: right;
  justify-content: end;
  align-items: center;
  gap: 0.5rem;
  height: 1.3rem;
}

.legendText {
  font-weight: 600;
  color: #696969;
}

.selectSelect {
  height: 2rem !important;
}

.select {
  width: 8rem;
  text-align: left;
}

.option {
  height: 2rem !important;
  display: flex;
  align-items: center;
  padding: 0 0.5rem !important;
}
