.wrapper {
  position: relative;
  z-index: 30;
  align-self: center;
}

.btn:not([aria-disabled=true]) {
  cursor: pointer;
}


.popup {
  left: 100%;
  width: 18rem;
  position: absolute;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: var(--modal-shadow);
  transform: translateX(-100%);
  animation: fade .1s linear;
}

.title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.switch {
  padding-top:5px;
}

.select {
  width: 9rem;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}