.contentWrapper {
  display: grid;
  grid-template-columns: 60% 40%;
  max-height: 15.1rem;
}

.months {
  overflow: auto;
  max-height: 15rem;
}

.years {
  overflow: auto;
  max-height: 15rem;
}

.month {
  height: var(--input-height);
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #4fb1eb;
  }
}

.active {
  color: #fff;
  background-color: #4fb1eb;
}
