.top {
  display: flex;
  align-items: center;
  width: 100%;
  position: sticky;
  z-index: 5;
}

.topBox1 {
  .topBox1Btn {
    min-width: 0 !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    width: 2.625rem;
    height: 2.625rem;
    background-color: #ebebeb;
    border-radius: 0.625rem;
  }
}

.topBox2 {
  margin: 0 auto 0 1.2rem;

  & :global(.monthSlider) {
    height: 2.3rem;
  }
}

.crossIcon {
  width: 2.625rem;
  height: 2.625rem;
  background-color: #ebebeb;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterBtn {
  margin-left: 1.2rem;
  width: 2.625rem;
  height: 2.625rem;
  background-color: #ebebeb;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeFilter {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background: var(--color-status-alarm);
    border-radius: 50px;
    width: 0.5rem;
    height: 0.5rem;
    top: 0.4rem;
    right: 0.4rem;
  }
}

.modal {
  width: 600px;
}

.selects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2.3rem;
  margin-bottom: 2.5rem;

  .select {
    max-width: 20rem;
  }
}

.btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #d3d3d3;

  & button {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 1441px) {
  .filterBtn {
    margin-left: auto;
  }

  .topBox2 {
    margin: 0 auto;
  }
}
