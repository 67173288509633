
.chartTooltip {
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
  background: white;
  border: 1px solid var(--color-border-field8);
  border-radius: .5rem;
  padding: .5rem 1rem;
}

.chartTooltip ul {
  padding: 0;
  margin: 0;

  & li {
    display: flex;
    justify-content: space-between;
    column-gap: .5rem;
    align-items: center;
    white-space: nowrap;
  }
}

.chartTooltipMark {
  width: .1875rem;
  height: 1rem;
  border-radius: .1875rem;
}

.chartTooltipLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: .5rem;
}

.chartTooltipLabel > span {
  font-weight: lighter;
}