.container {
  padding: 6px 10px;
  position: relative;
  margin-right: 6px;

  .menuIcon {
    background-color: #E8E8E8;
    border-radius: 7px;
    padding: 0.57rem;
    width: 48px;
    height: 48px;
    cursor: pointer;
    position: relative;
    top: 0;
    left: 0;
    box-sizing: border-box;

    &:hover {
      background-color: #4FB2ED;

      circle{
        fill: #fff;
      }
    }
    &.active {
      z-index: 100;
    }
  }

  .moduleMenuPopup {
    min-width: 10rem;
    position: absolute;
    background-color: #fff;
    width: 14.5rem;
    // height: 14rem;
    border-radius: 5px;
    display: none;
    top: 0.3rem;
    left: 0.4rem;
    z-index: 9899;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
    flex-wrap: wrap;
    padding: 3.75rem 0.5rem 1rem 0.5rem;

    &.active {
      display: flex;
      z-index: 99;
    }

    & > a {
      width: 100%;
    }
  }
}
