.viewContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  a {
    color: var(--color-background-primary);
  }
}

.calendarOptions {
  left: -25%;
}

.pair {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.partial {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.partialLabel,
.partialText {
  font-size: 1rem;
  color: black;
  font-family: var(--title-font);
  word-break: break-all;
}

.partialLabel {
  font-weight: 500;
}

.partialText,
.rememberRepeat {
  font-weight: 400;
}

.bubbles {
  display: flex;
  flex-wrap: wrap;
  gap: 0.53rem;
  margin-bottom: 0.35rem;
}

.bubble {
  border-radius: 0.8rem;
  color: white;
  background-color: var(--color-background-primary);
  padding: 0.5rem 0.66rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.4rem;
}

.rememberRepeat {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  span:first-child {
    margin-right: 0.25rem;
  }
}

.calendarSelect {
  border-color: transparent !important;
}

.range {
  flex: 1;
}

.disabled {
  cursor: default !important;
}

.date {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  gap: 0.5rem;
}

.repeat {
  width: 100%;
  display: flex;

  & > span {
    flex-basis: 50%;
  }
}
//////// v2
.root {
  padding: 1rem 3.5rem;
}

.tabs {
  width: fit-content;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.875rem;

  & > * {
    width: fit-content !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.value {
  font-weight: 500;
  font-size: 0.875rem;
}

.labeledItem label {
  font-size: 0.875rem;
}

.personsRow {
  display: flex;
  flex-wrap: wrap;
  gap: 0.675rem;
}
