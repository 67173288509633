.container{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  row-gap: .25rem;
}

.banner{
  width: 100%;
  height: 100%;
  background-image: url("./background.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 1.375rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconContainer{
  background-color: #F2F2F2;
  border-radius: 1.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.625rem;
  height: 5.625rem;
  position: relative;
  padding: 1.6875rem;
  & > svg, img {
    height: 100%;
    width: 100%;
  }
}

.title{
  text-align: center;
  word-break: break-word;
  font-size: 1rem;
  line-height: 1.22rem;
  font-family: var(--title-font);
  max-width: 75%;
  font-weight: 400;
}