.order {
  background-color: var(--color-background-field6);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  font: normal normal 400 1.12rem var(--title-font);
  position: relative;
  border: 1px solid transparent;
  margin-left: 1.62rem;

  &.active {
    background-color: var(--color-background-main);
    border: 1px solid var(--color-background-primary);
    height: auto;
  }

  .orderRequests {
    border-left: none;
    border-right: none;
  }

  .approversViewers {
    display: flex;
    padding: 0.5rem 1rem;
    min-height: 116px;
    border: none;

    header {
      margin-right: 1rem;
      flex-basis: 14rem;
      flex-shrink: 0;
      justify-content: space-between;
    }
  }

  .footer {
    display: flex;
  }
}