.toConfirmContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding-bottom: 1.25rem;

  border-bottom: 1px solid #4FB2ED;
}

.confirmedContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding-top: 1.25rem;
}

.title {
  font: normal normal 1.125rem/1.375rem var(--title-font);

  .count {
    font-weight: bold;
  }
}