.flex {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    margin-top: 1.125rem;
  }
}

.leftSide {
  display: flex;
  align-items: center;
}

.righted {
  margin-left: auto;
}

.input {
  width: 16rem;
  margin: 0 0.5rem;
}

.selectInput {
  border: 1px solid var(--gray-gray-4, #C3C3C3);
}

.doubleInput {
  min-height: 2.5rem;
  width: 16rem;

  border: 1px solid var(--gray-gray-4, #C3C3C3);
  outline: none;
  transition: border-color 0.25s ease-in-out;

  &:focus-within:not([aria-disabled]) {
    border-color: var(--color-border-primary);
    box-shadow: none;
  }

  & label {
    &:after {
      height: 100% !important;
      background-color: var(--gray-gray-4, #C3C3C3) !important;
    }
  }

  & input {
    text-align: right;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }

  & span {
    font-weight: 500;
    color: #a4a4a4;
  }
}

.delayInput {
  width: 7rem;
  margin-right: 0.5rem;

  & span {
    font-weight: 500;
    color: #a4a4a4;
  }
}

.delayInputInner {
  border-color: var(--gray-gray-4, #C3C3C3) !important;

  &:focus {
    border-color: var(--color-border-primary) !important;
  }
}

.crossIcon {
  cursor: pointer;
}
