.actions {
  background-color: #f4f4f4;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
}

.blue {
  background-color: #4fb1eb;

  & path {
    fill: #fff;
  }
}

.button {
  cursor: pointer;
  flex-shrink: 0;
  text-align: center;
  padding: 0.25rem;
}

.icon {
  width: 16px;
}

.disabled {
  pointer-events: none;
}
