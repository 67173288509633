.sendToPaymentModal {
  width: 35rem;
  font: normal normal 1.143rem/1.429rem var(--title-font);

  .body {
    padding: 1.429rem;
    border-bottom: 1px solid var(--color-border-field17);
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.857rem 1.357rem;
  }

  .cancelButton {
    margin-right: 0.893rem;
  }
}

.paymentTermsTable {
  border: 1px solid var(--color-border-field17);
  padding: 0.536rem 1.036rem;
  border-radius: 12px;
  margin-bottom: 1.214rem;

  .header, .row {
    display: flex;
    align-items: center;
  }

  .header {
    margin-bottom: 0.929rem;
  }

  .row:not(:last-child) {
    padding-bottom: 0.643rem;
    margin-bottom: 0.643rem;
    border-bottom: 1px solid var(--color-border-field17);
  }

  .nameCell {
    width: 70%;
  }

  .percentCell, .daysCell {
    width: 15%;
  }
}

.textarea {
  min-height: 4rem;
}