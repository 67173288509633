.block {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #707070;
  font-size: 0.875rem;
  flex-grow: 2;
}

.bar {
  margin-top: 0.45rem;
  background: #e5e5e5;
  width: 90%;
  max-width: 165px;
  height: 6px;
  border-radius: 3px;
}

@media screen and (max-width: 1441px) {
  .block {
    font-size: 11px;
  }
}
