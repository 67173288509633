.thanks {
  padding-top: 9rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3rem;
  min-height: calc(100vh - 13.75rem);
  height: auto;
  &:before{
    content: '';
    width: 100vw;
    min-height: calc(100vh - 13.75rem);
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    background-image:  url("../../../../../images/authorization/auth_logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50% 50%;
    opacity: .025;
  }
}

.phoneHighlight{
  color: rgb(79, 177, 235);
  font-weight: 500;
}

.thanks h1, .thanks h2{
  text-align: center;
}

.thanks h1 {
  font-size: 3rem;
}

.thanks h2 {
  font-size: 1.6rem;
  font-weight: normal;
}