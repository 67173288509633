.relationsTableHeader {
  grid-template-columns: 60% 40%;
  padding: 0 0.3rem;
  height: auto;
  margin-left: -1.25rem;
  width: calc(100% + 2.5rem);
}

.relationsTableIntervalBreak {
  justify-content: flex-end;
}

.tableEditIcon {
  height: 0.94rem;
  margin-left: 0.3rem;
  cursor: pointer;
}

.relationsContent {
  position: relative;
  margin-left: -2rem;
  width: calc(100% + 4.5rem);
  max-height: calc(100vh - 15rem);
  height: calc(100vh - 15rem);
  overflow-y: scroll;
  padding: 0 0.75rem 1.25rem;
  border-bottom: 1px solid #cbcbcb;
}

.addRelationButton {
  flex-direction: column;
  row-gap: 0.6875rem;
  margin: 0 auto;
}

.addRelationPortal {
  z-index: 9999;
}

.addRelationPopover {
  display: block !important;
  flex: 1;
  margin-top: 1.375rem;
}

.addRelationContent {
  padding: 0.5rem 0.25rem;
}

.searchBlock {
  padding: 0 0.75rem 0.5rem 0.75rem;
  & label {
    font: 1rem/1.25rem normal 400 var(--title-font) !important;
    color: #707070;
    margin-bottom: 0.5625rem;
  }
}

.searchInput input {
  text-align: center;
}

.resultsBlock {
  display: flex;
  flex-direction: column;
  row-gap: 0.1875rem;
  max-height: 18rem;
  overflow-y: scroll;
  & span {
    padding: 0.625rem 0 0.6875rem 0.875rem;
    border-radius: 0.625rem;
    cursor: pointer;
    &:hover {
      background-color: var(--color-background-primary);
      color: white;
    }
  }
}

.line {
  display: inline-block;
  width: 100%;
  border: none;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  background-color: #cecece;
}

.emptyPlaceholder span {
  text-align: center;
  font-family: var(--title-font);
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.25rem;
}
