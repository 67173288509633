.dynamicInput {
  max-width: 65%;

  input {
    font: normal normal 500 1.125rem var(--title-font);
    color: var(--color-font-main);
    background-color: var(--color-background-field5);
    border-radius: 0.5rem;
    border: none;
    max-width: 100%;
    height: 1.5rem;
  }
}

.big input {
  min-width: 36rem;
}

.normal input {
  min-width: 6.75rem;
}

.small input {
  min-width: 3.75rem;
}