.listSide__header {
  display: flex;
  justify-content: space-between;
}

.listSide__title {
  margin-top: 2.5rem;
  font-size: 1rem;
}

.sticky {
  position: sticky;
  z-index: 2;
  background-color: #fff;
  top: 1rem;
  padding-bottom: 27px;

  & > * {
    position: relative;
  }

  &:before {
    position: absolute;
    content: "";
    width: calc(100% + 0.875rem + 1.88rem);
    height: calc(100% + 1rem + 1px);
    left: -1.88rem;
    top: -1rem;
    background-color: #fff;
    border-bottom: 1px solid #c3c3c3;
  }
}
