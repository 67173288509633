.row {
    display: grid;
    grid-template-columns: 15% 51% 11% 7% 6% 7% 3%;
    cursor: pointer;
}

.download {
    width: 1.75rem;
    display: block;
    margin-left: auto;
    cursor: pointer;
}

.financial {
    width: 100%;
    text-align: right;
    font-weight: 500;
    white-space: nowrap;
}

.right {
    width: 100%;
    text-align: right;
    white-space: nowrap;
}

.ks {
    font-weight: 500;
}

.remark {
    margin-left: 0.5rem;
    font-weight: 700;
}

.subRow {
    height: 3.25rem;
    background-color: #fff;
    display: grid;
    border-radius: 0;
    border-bottom: 1px solid #c4c4c4;
    grid-template-columns: 11% 34% 21% 18.25% 6% 7%;
}

.sumHead {
    width: 100%;
    text-align: right;
}

.subRowContainer {
    height: 3.25rem;
    background-color: #fff;
    cursor: pointer;

    margin-bottom: 0.1rem;
    &:first-child {
        & > div {
            border-top: 1px solid #c4c4c4;
        }
    }

    &:last-child {
        & > div {
            border-bottom: none;
            border-radius: 0 0 0.5rem 0.5rem;
        }
    }
}

.link {
    pointer-events: none !important;
}
