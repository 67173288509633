.inputWrapper {
  width: 63%;

  .input {
    background-color: #eee;
    border: none;
    padding: var(--input-padding);

    &.active {
      background-color: #ccecff;
    }
  }
}

.sharedApprovedButton {
  margin-left: auto;
  display: block;
}

.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
}

.spinner {
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmModal {
  width: 450px;
}

.acceptableIcon {
  cursor: pointer;

  &:hover {
    path {
      fill: #4fb2ed;
    }
  }
}
