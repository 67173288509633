.container {
  width: 24rem;
  height: 8rem;
}

.headline {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.label {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
}

.change {
  cursor: pointer;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #4FB2ED;
}