.preloader {
  width: 100%;
  height: 100vh;
  background: #7bcbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    width: 8rem;
    height: auto;
    animation: spawn ease-in-out 0.4s;
  }
}

@keyframes spawn {
  0%{
    transform: scale(0.9);
  }
  80%{
    transform: scale(1.05);
  }
  100%{
    transform: scale(1);
  }
}