.container {
  height: auto;
  min-width: 60vw;

  .title {
    font: normal normal 500 1.5rem var(--title-font);
    color: #000;
  }

  .topSide {
    padding: 0 2.5rem 0;
    border-bottom: 1px solid #d3d3d3;
    position: relative;

    .block1Title {
      display: flex;
      justify-content: space-between;
    }

    .closeIcon {
      cursor: pointer;
    }
  }

  .field3 {
    margin: 1rem 0 1rem 0;

    label {
      font-size: 2rem;
    }
  }
}

.actionBlock {
  padding: 0.75rem 2.5rem 0.75rem 2rem;
  justify-content: flex-end;
  display: flex;
  opacity: 1;

  button:first-child {
    margin-right: 0.5rem;
  }

  &.action {
    opacity: 0;
  }
}
