.amounts {
  display: flex;
  margin-left: 1rem;
}

.spent {
  margin-right: 1rem;
}

.infoContainer {
  margin: 0 1.625rem;
}

.index {
  background-color: #fff;
  color: #000;
}

.progressBar {
  color: var(--color-font-hover);
}

.progressBarFiller {
  background-color: var(--color-font-hover);
}

.budgetTitle {
  width: 10.6rem;

}