.header {
  font-family: var(--title-font);
  border-bottom: 1px solid var(--color-border-field5);

  .content {
    padding: 0 1rem 0.5rem 2.5rem;
    position: relative;
  }

  .topBox {
    display: flex;
    align-items: center;
    font: normal normal 400 1.125rem/1.375rem var(--title-font);

    .topBoxName {
      width: 11rem;
      font-weight: 400;
      flex-shrink: 0;
    }

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }
}

.myOrdersStatus {
  display: flex;
  align-items: center;
  background: #F2F2F2;
  padding: 0 10px;
  height: 2rem;
  max-width: 19.7rem;
  border-radius: 15px;
  width: fit-content;
  flex-grow: 0;
  flex-shrink: 0;
}

.arrowLeft {
  margin-right: 0.5rem;
  margin-bottom: 0.1rem;
}

.myOrdersStatus:not(span) {
  color: #000;
  font-size: 1.125rem;
}

.inputContainer {
  width: 6.75rem;
  margin-left: 1.875rem;
}

.number {
  font: normal normal 1.5rem/1.875rem var(--title-font);
  color: var(--color-font-main);
}

.rowValue {
  margin: 0 1.125rem;
}

.titleContainer, .row, .mainInfo {
  display: flex;
  align-items: center;
}

.title {
  font: normal normal 500 1.125rem var(--title-font);
  margin: 0 0.688rem 0 0;
  flex-shrink: 0;
}

.mainInfo, .calendar {
  font: normal normal 500 1.125rem var(--title-font);
}

.topBlock {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.calendar {
  margin: 0.625rem;
}

.calendarDisabled {
  border: none;
  padding: 0.7rem;
  margin: 0;
}

.fileBlock {
  width: 24.25rem;
  flex-shrink: 0;
}

.downloadButton {
  position: absolute;
  right: 1rem;
  top: 0;
}

.middleCol {
  margin: 0 2rem;
}

.col {
  width: 34%;
  flex-shrink: 0;
}

.hiddenText, .myOrderStatus {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}