.container {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0 0 20px;
  border: none;
  background: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.shadow {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}
.closeButtonBlock {
  cursor: pointer;
  position: absolute;
  top: 11px;
  left: -2.3rem;
  background-color: var(--color-background-primary);
  border-radius: 21px;
  width: 4rem;
  height: 4.5rem;
  padding: 0.9rem;
  display: flex;
  align-items: center;

  & button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.containerWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  min-width: 30%;
  height: 100%;
  z-index: 101;
  transition: all 0.75s ease;
  transform: translateX(110%);
  right: 0;

  &.active {
    transform: translateX(0%);
  }
}

.childrenContainer {
  height: 100%;
}
