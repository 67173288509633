.actions {
  display: flex;
  align-items: center;
  width: 6.625rem;
  height: 2.313rem;
  background-color: #fff;
  border-radius: 1rem;
  flex-shrink: 0;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 13%)
}

.removeButton,
.checkButton {
  flex: 1;
}

.checkButton {
  background-color: var(--color-background-primary);
  border-radius: 1rem 0 0 1rem;
  height: inherit;
}

.removeButton {
  background-color: white;
  border-radius: 0 1rem 1rem 0;
  height: inherit;

  & svg {
    color: var(--color-font-secondary4);
  }
}

.once {
  border-radius: 1rem;
}