.file,
.fileBody {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

.file {
  justify-content: space-between;
  cursor: default;
}

.fileBody {
  width: 100%;

  & img {
    max-width: 21px;
  }
}

.fileName {
  margin: 0 0 0 0.714rem;
  font: normal normal 1rem/1.375rem var(--main-font);
  word-break: break-word;
}

.loading {
  opacity: 0.5;
}
