.moneyCell__text {
  font-family: AvenirNextCyr;
  display: block;
  font-size: 0.9rem;
  line-height: 20px;
  text-align: right;

  &.negative {
    color: red;
  }

  &.positive {
    color: green;
  }
}
