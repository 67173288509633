.button {
  position: relative;
  border-radius: .25rem;
  overflow: hidden;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-width: 1.5px;
  border-style: solid;
}

.text {
  font-family: var(--title-font);
  font-style: normal;
  font-weight: 600;
  font-size: .875rem;
  line-height: 1.5rem;
  margin: 0 .75rem;
  flex-grow: 1;
}

.arrow {
  position: relative;

  height: 1.5rem;
  width: 1.5rem;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: ' ';
    width: 1rem;
    height: 1rem;
    background-image: url("expandArrow.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.flippedArrow:after {
  transform: scaleY(-1);
}


.plan {
  $main-color:  #4FB1EB;

  //background-color: rgba(79, 177, 235, 0.3);
  background-color: lighten($main-color, 40%);
  border-color: $main-color;

  & .arrow {
    background-color: $main-color;
  }

  & .text {
    color: $main-color;
  }
}

.progress {
  $main-color:  #47D6D1;

  //background-color: rgba(71, 214, 209, 0.3);
  background-color: lighten($main-color, 40%);
  border-color: $main-color;

  & .arrow {
    background-color: $main-color;
  }

  & .text {
    color: $main-color;
  }
}

.accepted {
  $main-color:  #6FC79B;

  //background-color: rgba(111, 199, 155, 0.3);
  background-color: lighten($main-color, 40%);
  border-color: $main-color;

  & .arrow {
    background-color: $main-color;
  }

  & .text {
    color: $main-color;
  }
}

.remark {
  $main-color: #EF9D1C;

  //background-color: rgba(239, 157, 28, 0.3);
  background-color: lighten($main-color, 40%);
  border-color: $main-color;

  & .arrow {
    background-color: $main-color;
  }

  & .text {
    color: $main-color;
  }
}