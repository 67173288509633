.container {
  display: flex;

  .rowItem {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 0 0 11%;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    height: 3rem;

    &.nameBlock {
      flex: 0 0 41%;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-left: 0;

      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }

    &.price {
      flex-basis: 15%;
      justify-content: flex-end;
      column-gap: 2.1rem;
    }

    .inputSum {
      height: 2rem;
      width: 7rem;
      background: #ededed;
      border: none;

      &.activeSum {
        background: #ccecff;
      }
    }
    .approveButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.5rem;
      height: 2rem;
      width: 8.2rem;
      background: #4fb2ed;
      border-radius: 0.8rem;
      color: #fff;
      font-size: 1.12rem;
      font-family: var(--title-font);
      cursor: pointer;
    }
  }
}

.measure,
.centeredContent {
  justify-content: center;
}
