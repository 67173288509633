.navContent{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.flexAction{
  display: flex;
  justify-content: space-between;
  padding: 20px 25px 0 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 35px;
}

.text{
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.flexText {
  display: flex;
}