.monthBlocks {
  display: flex;
  width: 19%;

  .firstColumn {
    width: 50%;
    border-right: 1px solid #E8E8E8;
  }

  .secondColumn {
    width: 50%;
  }
}

.monthValueBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .monthValue {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E8E8E8;

    &:last-child {
      border-bottom: none;
    }
  }

  .monthValue1 {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E8E8E8;

    &:last-child {
      border-bottom: none;
    }
  }
}