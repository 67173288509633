.container {
  .content {
    border-bottom: 1px solid var(--color-devider-field1);
    padding: 2rem 2.1rem 0rem 2.1rem;
    display: flex;
    flex-direction: column;
    height: 12.5rem;

    .formRow {
      flex-basis: 40%;
    }

    .formRow2 {
      flex-basis: 60%;
      justify-self: flex-end;
    }

    .formRow,
    .formRow2 {
      display: flex;
      justify-content: space-between;

      .field {
        margin-right: 1rem;

        label {
          font: normal normal 400 1rem var(--title-font);
          color: #000;
        }
      }

      .countCol {
        @extend .field;
        width: 4.25rem;
      }

      .measureCol {
        @extend .field;
        width: 6.75rem;
      }

      .primaryField {
        @extend .field;
        width: 7.43rem;
      }
    }

    .formRow2 {
      align-items: start;
    }

    .titleRow {
      font: normal normal 400 1.12rem var(--title-font);
      color: var(--color-font-primary);
      margin-top: 0.6rem;
    }

    .input {
      border-color: var(--color-border-primary);
    }

    .select {
      border-color: var(--color-border-primary);
      width: 15rem;

      input {
        color: #000;
      }
    }

    .select2 {
      border-color: var(--color-border-primary);
      width: 9rem;
    }

    .addButton {
      padding: 0 1rem;
      color: var(--color-font-secondary5);
      font-family: var(--title-font);

      svg {
        margin-left: 1rem;
        cursor: pointer;
      }
    }
  }
}

.selectedSection {
  cursor: pointer;
  position: relative;

  .titleRow {
    display: block;
  }
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
  position: absolute;
  bottom: 1rem;
}
