.container {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: var(--color-background-field6);
  padding: 3px 9px;
  border-radius: 10px;
  width: fit-content;
  max-height: 2.688rem;
  height: var(--input-height);
}

.container > span{
  font-weight: 500;
}