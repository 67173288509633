.row {
  //grid-template-columns: 32% 11% 9% 13% 18% 7% 10%;
  grid-template-columns: 48% 8% 12% 15% 7% 10%;
}

.axis {
  display: grid;
  align-items: center;
  width: 90%;
  grid-template-columns: 44% 2% 54%;
  text-align: center;
}

.divider {
  margin: 0 auto;
  background: #ebebeb;
  width: 2px;
  height: 1.25rem;
}

.disabledFiles {
  cursor: inherit;
  background: transparent;
  color: #a5a5a5;
  & path {
    fill: #a5a5a5;
  }
}
