.row1 {
  margin-bottom: 1rem;
}

.form {
  background-color: rgb(247, 246, 246);
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
  border: 1px solid rgb(189, 189, 189);
  padding: 0.5rem;
  padding-bottom: 1rem;
}

.label {
  text-align: left;
}
