.container {
  position: relative;

  .content {
    display: flex;
    align-items: center;
    min-height: 4.25rem;
    padding: 0.5rem 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-border-field8);
  }

  .leftSide {
    display: grid;
    grid-template-columns: 0.5fr 1.9fr 1.2fr;
    align-items: center;
    width: 40%;

    .field1 {
      padding-left: 2rem;
    }

    .field2 {
      flex: 1;
      color: var(--color-font-primary);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .field3 {
      justify-self: center;
    }
  }

  .rightSide {
    height: 100%;
    width: 60%;
    padding: 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1.25fr 1fr 1fr;
    justify-items: center;

    &.withExpandedDifference {
      grid-template-columns: 1fr 1fr 1.25fr 1fr 1fr 1fr;

      & > div {
        padding-right: 0.75rem;
        width: 100%;
        text-align: right;
      }

      & > div.fieldRight7 {
        text-align: center;
        padding-right: 0;
      }
    }

    .fieldRight7 {
      text-align: center;
    }

    .fieldRight2,
    .fieldRight3,
    .fieldRight5,
    .fieldRight6,
    .fieldRight8 {
      justify-self: flex-end;
      padding-right: 0.75rem;
    }

    .fieldRight8 {
      padding-right: 1.5rem;
    }

    .fieldEmpty {
      width: 100%;
      text-align: center;
      padding: 0;
    }
  }
}

.notFieldGroup {
  display: none;
}

.fieldGroup {
  position: absolute;
  height: calc(100% - 0.9rem);
  width: 6rem;
  padding-right: 0.9rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  top: 1.3rem;
  z-index: 2;

  &.open {
    .icon {
      background-color: var(--color-background-primary);
    }

    .line {
      width: 4px;
      height: 100%;
      background-color: var(--color-devider-line);
      margin-right: 0.7rem;
      margin-bottom: 0.4rem;
    }

    &:before {
      content: "";
      background-color: #fff;
      position: absolute;
      left: 0;
      width: calc(100% - 4px - 0.7rem - 0.9rem);
      height: 1rem;
      bottom: 0;
    }
  }

  .icon {
    width: 1.65rem;
    border-radius: 0.4rem;
    padding: 0.35rem 0.3rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.materialBlock {
  margin-left: 6rem;
  padding-top: 0.5rem;

  .countLabel {
    background-color: var(--color-background-primary);
    border-radius: 0.7rem;
    color: var(--color-font-hover);
    font-family: var(--title-font);
    width: min-content;
    height: 2.3rem;
    padding: 0.5rem 1rem;
  }
}

.warning {
  color: red;
}

.isTriggerForGroup.open {
  .icon {
    background-color: #a26dd0;
  }

  .line {
    background-color: #eae2f6;
  }
}

@media screen and (max-width: 1900px) {
  .field1 {
    padding-left: 1.5rem !important;
  }
}
