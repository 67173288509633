.grid {
  display: grid;
  gap: 1rem;
  width: 400px;
  padding: 1rem 3rem;
  margin-left: auto;
  margin-right: auto;
}

.pair {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 25% calc(75% - 0.5rem);
  align-items: center;
  height: 2.5rem;
}

.label {
  font-weight: 500;
}

.oneRow {
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 3rem;
}
