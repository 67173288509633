.title {
  display: flex;
  width: 25vw;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

.list {
  margin-bottom: 1.5rem;
}

.holder {
  min-height: 4.5rem;
  display: flex;
  align-items: center;
}

.change {
  color: #4fb2ed;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
