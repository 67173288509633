.container {
  display: flex;
  align-items: center;
  height: auto;
  font-family: var(--title-font);

  .input {
    height: 2.75rem;
    width: 7.75rem;
  }

  .inputInner {
    background-color: #efefef;
    color: #000;
    border: none;
    border-radius: 12px;
    opacity: 0.5;
  }
}
