.inputFiles {
  border-radius: 0.5rem;
  border: 1px dashed var(--color-border-primary);
  font-family: var(--main-font);
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.inputFilesList {
  display: flex;
  gap: 0.625rem 0;
  padding: 1.125rem;
  cursor: default;
}

.rows {
  flex-direction: column;
}

.grid {
  flex-wrap: wrap;
}

.variantsSwitcher {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1.125rem 1.125rem 0;
}

.variantIcon {
  cursor: pointer;

  &:first-child {
    margin-right: 0.75rem;
  }
}

.filesListContainer {
  width: 100%;
}

.filesExpander {
  background-color: #8ebedb;
  border-radius: 0.5rem;
  width: 3rem;
  height: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  position: absolute;
  bottom: -0.5rem;
}

.icon {
  width: 12px;
}

.emptyListWithUploadBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;

  & :global(.fileBtn) {
    width: 159px;
    gap: 0.75rem;
  }
}
