.blockStyles {
  display: flex;
  align-items: center;
  color: #000;
  width: 12rem;
  padding: 1rem;

  .labelStyles {
    padding-right: 1rem;
    font: normal normal normal 1rem var(--title-font);
    min-width: 4.375rem;
    text-align: right;
  }

  .containerStyles {
    height: 0.25rem;
    width: 6.2rem;
    background-color: #e6e6e6;
    border-radius: 50px;
    overflow: hidden;

    .fillerStyles {
      height: 100%;
      background-color: #00c3f2;
      border-radius: inherit;
    }
  }
}
