.infoIcon {
  cursor: pointer;
  margin-left: 0.5rem;
  svg {
    width: 1.25rem;
  }
}

.popup {
  padding: 1rem 2rem;
  display: flex;
}

.head {
  display: flex;
  align-items: center;
}

.title {
  font-weight: 600;
}

.chartValue {
  text-align: left !important;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.currencySign {
  font-size: 1rem;
}

.classNameCircle {
  margin-right: 0.5rem;
}

.descriptionClassName {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  color: #000000 !important;
}

.subtitleClassName {
  color: #bebebe !important;
}

.wrapper {
  min-width: 12.5rem;
}
