.header {
  background-color: white;
  padding: 1rem 1.625rem 1rem 2.625rem;
  border-radius: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  outline: 1px solid #E9E9E9;
  & :global(.monthSlider) {
    height: 2.813rem;
  }
}

.datesRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sliderBlock {
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
}