.container {
  position: relative;
}

.wrapper {
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 34px;

  &.tableWrapper > div {
    width: 100%;
  }
}

.disabled {
  pointer-events: none;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
  margin-bottom: 20px;
}

.fullWidth {
  width: 100%;

  & > div {
    width: 100%;
    margin-bottom: 20px;
  }
}

.threeCols {
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3rem;
  row-gap: 24px;
  width: 100%;
}

.teamLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  padding-bottom: 12px;
}

.mb32 {
  margin-bottom: 32px;
}

.apprLine {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 12px;
  font-size: 1rem;
  font-weight: 400;

  & svg {
    width: 24px;
    height: 24px;
    margin-left: 24px;
    cursor: pointer;
  }
}

.approvals {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 24px;
  overflow: hidden;
}

.date {
  & > div {
    width: 100%;
  }
}

.calend {
  margin-bottom: 0.35rem;
}

.headline {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.label {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
}

.teamRow {
  padding-top: 1.5rem;

  .headline {
    width: 24.5rem;
  }
}

.teamEmployees {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem 2.5rem;
}

.teamEmployee {
  width: 25rem;
}

.tab {
  width: 11.8rem;
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
}

.allowShift {
  margin-top: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
}
