.headline {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.time {
  color: #696969;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 30% 40%;
  grid-template-areas: "_ _ _" "exp exp exp";
}

.exp {
  grid-area: exp;
}

.expandableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & span {
    font-weight: 600;
  }
}

.detailForm {
  width: 100%;
  background: #fafafa;
  border: 1px solid #ebebeb;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
  padding: 1rem 0.75rem;
}

.detailFormGrid {
  width: calc(100% - 2rem);
  display: grid;
  grid-template-columns: 40% 30% 30%;
  gap: 1rem;
}

.date {
  grid-column-start: 1;
  grid-column-end: 3;
}
