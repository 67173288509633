body.osla {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;

  margin: 0;
  padding: 65px 0 0;
  position: relative;
  background: #EFF4FB;
  overflow-y: visible;

  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  iframe,
  ol,
  ul,
  li,
  form,
  label,
  i,
  footer,
  header,
  menu,
  nav,
  a,
  table,
  thead,
  tbody,
  tfoot,
  tr,
  th,
  td,
  video,
  canvas,
  section,
    /*commonly used tags*/
  applet,
  object,
  blockquote,
  pre,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  ins,
  kbd,
  hgroup,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  dl,
  dt,
  dd,
  fieldset,
  legend,
  article,
  aside,
  details,
  embed,
  figure,
  figcaption,
  output,
  section,
  summary,
  time,
  mark,
  audio,
  center,
  ruby {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
    position: relative;
  }

  ol,
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    outline: none !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  a {
    display: inline-block;
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  input,
  button,
  select {
    display: inline-block;
    padding: 0;
    border: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  img {
    max-width: 100%;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  body * {
    font-family: 'Inter';
  }

  .container {
    max-width: 1550px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
  }

  @media (max-width: 1600px) {
    .container {
      max-width: 1370px;
    }
  }
  @media (max-width: 1440px) {
    .container {
      max-width: 1230px;
    }
  }
  @media (max-width: 1300px) {
    .container {
      max-width: 1150px;
    }
  }
  @media (max-width: 1199px) {
    .container {
      max-width: 960px;
    }
  }
  @media (max-width: 991px) {
    .container {
      max-width: 718px;
    }
  }
}