.setsRow {
  grid-template-columns: 52% 14% 20% 8% 6%;

  &.setsRowWithActions {
    grid-template-columns: 47% 14% 20% 8% 6% 5%;
  }
}

.docsRow {
  grid-template-columns: 52% 14% 16% 12% 6%;

  &.docsRowWithActions {
    grid-template-columns: 47% 14% 16% 12% 6% 5%;
  }
}
