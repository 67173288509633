.inputContainer, .doubleInput, .inputs {
  display: flex;
  align-items: center;
}

.doubleInput {
  border-radius: 0.5rem;
  width: 13.5rem;
  padding: 0.25rem 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  min-height: 2.188rem;
  justify-content: center;
  position: relative;

  &:focus-within:not([aria-disabled]) {
    box-shadow: 0 0 0 1px var(--color-border-primary) inset;
  }
}

.inputs {
  justify-content: flex-start;
}

.inputContainer:not(:only-child) {
  margin: 0;

  &:first-child {
    padding-right: .375rem;
  }

  &:last-child {
    padding-left: .375rem;
  }
}

.containerWithDivider:first-child:after {
  content: "";
  width: 1px;
  height: 80%;
  background: #8E8E8E;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: inherit;
  flex-shrink: 0;
}

.withSmallDivider:first-child:after {
  top: 0;
  height: 45%;
  transform: translateY(20%);
}

.input {
  border: none;
  border-radius: 0;
  padding: 0;
}

.label {
  font-size: 0.688rem;
  color: #909090;
}

.measure {
  color: #000;
}

.inputLabels {
  display: flex;
  gap: 0.75rem;
  width: 100%;
}

.oneInputMode {
  width: 10.125rem;
}

.firstValueMeasure {
  margin-right: 0.5rem;
}

.outline {
  outline: 1px solid var(--color-border-field8);
}