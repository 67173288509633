$circleSize: 0.875rem;


.circle {
  position: relative;
  width: $circleSize;
  height: $circleSize;
  min-height: $circleSize;
  max-height: $circleSize;
  min-width: $circleSize;
  max-width: $circleSize;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-font-secondary1);
  cursor: pointer;
}

.active {
  background-image: radial-gradient(#313131 45%, transparent 46%, transparent 100%);
  cursor: default;
}