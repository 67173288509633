.container {
  height: 3.625rem;
  background: #f4f4f4;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0 1.43rem;
  color: #000;
  font: normal normal 400 1.125rem var(--title-font);
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-primary);
    color: #fff;

    .actions {
      opacity: 1;
    }

    .count {
      span {
        display: inline;
      }
    }
  }

  .name {
    flex: 1;
  }

  .count {
    padding: 0 1.12rem;

    span {
      display: none;
    }
  }

  .actions {
    opacity: 0;
    height: 2.56rem;
    width: 3.43rem;
    background-color: #fff;
    border-radius: 10rem;
    padding: 0 1.12rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
