.container {
  display: flex;
  width: 100%;
  height: 3.625rem;
  align-items: center;
  padding: 0 0.8125rem;
  font-weight: 400;
  background: #f4f4f4;
  margin-bottom: 0.375rem;
  cursor: pointer;
  border-radius: 0.75rem;

  &:hover {
    background: #4fb2ed;
    color: #fff;

    & path {
      fill: #fff;
      stroke: #fff;
    }
  }
}

.arrow {
  margin-left: auto;
}

.name {
  flex-basis: 60%;
  width: 60%;
  overflow: hidden;
  max-height: 90%;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.measure {
  flex-basis: 5rem;
  width: 5rem;
  text-align: center;
}

.divider {
  margin-right: 0.78rem;
}
