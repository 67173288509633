.myModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  z-index: 1000;
}

.myModal.active {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.myModalContent {
  min-width: 250px;
  background-color: #fff;
  padding: 20px 0 13px 0;
  border: 1px solid #4FB2ED;
  border-radius: 8px;
  position: relative;
  top: 60px;
  right: 2rem;
  width: 684px;

  .arrowPointer {
    width: 18px;
    height: 18px;
    border-top: 1px solid #4FB2ED;
    border-left: 1px solid #4FB2ED;
    transform: rotate(45deg);
    background-color: #fff;
    position: absolute;
    top: -10px;
  }

  .closeModalBtn {
    background-color: #4FB2ED;
    border-top-left-radius: 21px;
    border-bottom-left-radius: 21px;
    width: 100px;
    height: 72px;
    position: absolute;
    top: 10px;
    left: -40px;
    z-index: -1;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 13px;
  }
}

@media (min-width: 1900px) and (max-width: 2100px) {
  div.arrowPointer {
    right: 4.5rem !important;
  }
}

@media (min-width: 1700px) and (max-width: 1899px) {
  div.arrowPointer {
    right: 4.5rem !important;
  }
}

@media (min-width: 1500px) and (max-width: 1699px) {
  div.arrowPointer {
    right: 4.75rem !important;
  }
}

@media (min-width: 1300px) and (max-width: 1499px) {
  div.arrowPointer {
    right: 4.85rem !important;
  }
}

@media (min-width: 2101px) {
  div.arrowPointer {
    right: 4.5rem !important;
  }
}

@media (max-width: 1299px) {
  div.arrowPointer {
    right: 5rem !important;
  }
}