.label {
  margin-bottom: 0.3rem;
  font-weight: 500;
}

.fieldValue {
  margin-bottom: 1rem;
}

.pair {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.25rem;

  @media (max-width: 1440px) {
    grid-template-columns: 0.9fr 1fr;
  }

  &.centered {
    align-items: center;
  }
}

.pr1 {
  padding-right: 1rem;
}

.rightCalendar {
  transform: translateX(70%) !important;
}

.closureDatesOptions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 1rem;

  padding: 1rem 0.275rem 0.5rem;

  & > div {
    padding: 0.275rem 0.5rem;
  }

  &:before {
    content: "Выберите дату";
    color: #707070;
    font-size: 0.625rem;
    position: absolute;
    top: 0;
    left: 0.75rem;
  }
}

.dinamics {
  padding-bottom: 0.5rem;
  margin-top: 1.5rem;

  span {
    font-weight: 600;
    color: #696969;
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
  }

  .fieldTitle {
    margin-bottom: 0.875rem;
  }
}

.dinamicsContainer {
  display: flex;
  justify-content: space-between;
  margin-right: 5rem;
  padding-top: 1rem;

  div {
    p {
      margin-top: 0.2rem;
    }
  }
}

.containerPlan {
  display: flex;
  justify-content: flex-start;

  .fieldTitle {
    margin-top: 1.5rem;
  }

  .leftSide {
    padding-right: 1rem;
    width: 52%;
  }

  .rightSide {
    width: 48%;

    .planText {
      margin: 1rem 0 0 0;
      font-weight: 500;
    }
  }

  span {
    font-weight: 600;
    color: #696969;
    margin-top: 1.5rem;
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
    width: 10%;
  }
}
