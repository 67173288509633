.comment {
  margin-top: 0.675rem;
  background: #eff7fc;
  border-radius: 0.5rem;
  padding: 0.75rem 0.5rem;
  font-weight: 500;
}

.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #696969;
  margin-bottom: 0.675rem;
}

.content {
  margin-bottom: 1rem;
}
