.modal {
  width: 85vw;
}

.list {
  padding: 0 1rem;
  max-height: 60vh;
  overflow-y: auto;
  padding-bottom: 0.25rem;
}

.row1 {
  margin-bottom: 1rem;
}
.row2 {
  display: flex;
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid rgb(189, 189, 189);
}

.add {
  font: normal normal 1.125rem/1.375rem var(--title-font);
}
