.comment {
  margin-bottom: 0.5rem;
  font-weight: 400;
  position: relative;

  &:hover {
    & .actions {
      display: block;
    }
  }
}

.title {
  font-weight: 500;
}

.head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  color: #696969;
}

.content {
  padding: 0.5rem 0;
  border-radius: 0.7rem;
  word-break: break-all;
  max-width: 90%;

  a {
    color: var(--color-background-primary);
  }
}

.comments {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.emptyPlaceholder {
  height: auto !important;
  min-height: auto !important;
}

.addButton {
  height: auto;
}

.list {
  overflow-y: auto;
  padding-top: 0.5rem;
  max-height: 150px;
}

.comment {
  border-radius: 8px;
  background: var(--blue-background-field-17, #eff7fc);
  padding: 8px 12px;
}

.actions {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  display: none;
}

.pending {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

.edit {
  padding-top: 0.5rem;
}

.editInput {
  padding-right: 5rem;
}
