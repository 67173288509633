@use "src/scss/dimensions.scss" as dimensions;

.container {
  height: 0;
  width: 100%;
  margin: 0 auto;
}

.containerOpen {
  height: 4.5rem;
  margin-top: .5rem;
  & .blocks {
    overflow: visible !important;
  }
}

.button {
  height: 3.5rem;
  transform: translate(-50%, -100%);
  transition: none;
}

.buttonOpen{
  transform: none;
}

.blocks {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2.8125rem;
}

.selectBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: .5rem;

  & > span {
    color: #707070;
    font-size: 1rem;
    font-family: var(--title-font);
  }
}

.select {
  width: auto;
}


@include dimensions.breakpoint-from(1100px) {
  .select{
    width: 12rem;
  }
}

@include dimensions.breakpoint-from(1300px) {
  .select{
    width: 16.75rem;
  }
}

@include dimensions.breakpoint-from(1840px) {
  .select{
    width: 23.75rem;
  }
}

.clearBtn {
  width: 1.3125rem;
  height: 1.3125rem;

  & img {
    cursor: pointer;
    width: inherit;
    height: inherit;
  }
}