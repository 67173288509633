.container {
  width: auto;
}

.row {
  position: relative;
  padding: 0.7rem 0.75rem;
}

.label {
  display: block;
  margin-bottom: 0.35rem;
}

.deleteArrow {
  padding: 0.5625rem 0.5rem 0.6875rem;
  color: #eb4f8b;
  width: 100%;
  text-align: center;
  border-top: 1px solid #ebebeb;
  cursor: pointer;
  user-select: none;
}

.inputs {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 19rem 7rem;
}
