.container {
  background-color: var(--color-background-field9);
  padding: 1rem 2rem;
  min-height: 100%;

  .content {
    position: relative;

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .checkboxContainer {
      margin-left: 2.143rem;
      margin-bottom: 1.286rem;
    }
  }

  .requests {
    border-left: none;
    border-right: none;
  }
}