.navbar {
  padding-top: 1.8rem !important;
  margin-bottom: -1rem !important;
  min-height: 3.875rem;
}

.head {
  position: sticky;
  background-color: #fff;
  z-index: 2;
  top: 2.8rem;
}
