.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 0.4rem;
}

.expanded {
  transform: scaleY(-1);
}
