@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNext-Regular-08.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNext-Medium-06.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNext-DemiBold-03.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNext-Bold-01.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Bold.eot');
  src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
  url('../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro-Bold.woff2') format('woff2'),
  url('../fonts/GothamPro-Bold.woff') format('woff'),
  url('../fonts/GothamPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro-Medium.eot');
  src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
  url('../fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro-Medium.woff2') format('woff2'),
  url('../fonts/GothamPro-Medium.woff') format('woff'),
  url('../fonts/GothamPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../fonts/GothamPro.eot');
  src: local('Gotham Pro'), local('GothamPro'),
  url('../fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro.woff2') format('woff2'),
  url('../fonts/GothamPro.woff') format('woff'),
  url('../fonts/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.eot');
  src: local('Inter Regular'), local('Inter-Regular'),
  url('../fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Regular.woff2') format('woff2'),
  url('../fonts/Inter-Regular.woff') format('woff'),
  url('../fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.eot');
  src: local('Inter Semi Bold'), local('Inter-SemiBold'),
  url('../fonts/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-SemiBold.woff2') format('woff2'),
  url('../fonts/Inter-SemiBold.woff') format('woff'),
  url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-ExtraBold.eot');
  src: local('Inter Extra Bold'), local('Inter-ExtraBold'),
  url('../fonts/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-ExtraBold.woff2') format('woff2'),
  url('../fonts/Inter-ExtraBold.woff') format('woff'),
  url('../fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Italic.eot');
  src: local('Inter Italic'), local('Inter-Italic'),
  url('../fonts/Inter-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Italic.woff2') format('woff2'),
  url('../fonts/Inter-Italic.woff') format('woff'),
  url('../fonts/Inter-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.eot');
  src: local('Inter Medium'), local('Inter-Medium'),
  url('../fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Inter-Medium.woff2') format('woff2'),
  url('../fonts/Inter-Medium.woff') format('woff'),
  url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}


:root {
  --main-font: AvenirNextCyr;
  --title-font: Montserrat;
}
