.infoBlock {
  .row {
    display: flex;
    padding: 0.8rem 0;
    font: normal normal 400 1.13rem var(--title-font);

    .titleRow {
      width: 18rem;
    }

    .valueRow {
      border-bottom: 1px solid var(--color-border-primary);
    }
  }
}

.title {
  font: normal normal 500 1.125rem/1.375rem var(--title-font);
  margin: 0 1rem 0 0;
}

.header {
  display: flex;
  align-items: center;
}