.container {
  margin-top: 0.75rem;
  font-size: 0.875rem;
}

.divider {
  padding-left: 0.875rem;
}

.grid {
  display: grid;
  //grid-template-columns: 40% 35% 25%;
  grid-template-columns: 60% 40%;
  width: 100%;
  row-gap: 0.4rem;

  & > div {
    display: grid;
  }
}

.firstCol {
  grid-template-columns: 30% 70%;
}

.secCol {
  grid-template-columns: 40% 60%;
}

.thirdCol {
  grid-template-columns: 70% 30%;
}

.value {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #696969;
  font-weight: 500;
}

.label {
  color: #a5a5a5;
  font-weight: 500;
}

.name {
  margin-top: 0.4rem;
  color: #696969;
  font-weight: 500;
}
