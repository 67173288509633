.container {
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  flex-shrink: 0;
  flex-grow: 0;
  font: normal normal 400 1rem var(--title-font);

  & > div {
    white-space: nowrap;
    position: relative;
    padding-right: 1rem;
    font-weight: normal !important;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    & svg {
      width: 1.42rem;
      height: 1.42rem;
    }
    & span {
      font-weight: 500;
    }
  }
}

.withDivider::after {
  content: "";
  display: block;
  width: 2px;
  height: 150%;
  background-color: #c7c7c7;
  border-radius: 1px;
  position: absolute;
  right: 0;
  top: -25%;
}

.markerReview {
  width: 1.22rem;
  height: 1.22rem;
  border-radius: 50%;
  background-color: #afafaf;
}
