.addClosureDateButton {
  flex-direction: column-reverse;
  row-gap: 0.6875rem;
  margin: 0 auto;
  padding-top: 0.25rem;
}

.closureDatesHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.6875rem;
}

.closureDatesHead > span {
  display: inline-flex;
  column-gap: 0.25rem;
}

.addMoreDatesBtn {
  padding-right: 0;

  & svg {
    margin-right: 0;
  }
}

.datesFields {
  border: 1px solid var(--color-border-primary);
  padding: 0.25rem 0.3125rem;
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.375rem;
  row-gap: 0.3125rem;
  margin-bottom: 0.6875rem;
}

.datesFieldsWithPercents {
  border: 1px solid var(--color-border-primary);
  padding: 0.25rem 0.3125rem;
  border-radius: 0.5rem;
  margin-bottom: 0.6875rem;
}
