.headline {
  width: calc(68vw - 4.2rem - 26px - 0.6rem);
  display: flex;
  align-items: center;
  padding-right: 1rem;
  position: sticky;
  z-index: 2;
  background-color: #fff;
  top: 0;
}

.measureBtn {
  margin-left: auto;
  height: 2.8rem;
  width: 150px;
  border: 0.35rem solid #f4f4f4;
  border-radius: 0.75rem;
  background: #4FB2ED;
  color: #fff;
  margin-right: 1rem;
}

.highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #F5F5F5;
  width: 3rem;
  height: 3rem;
  border-radius: 8px;
}

.counter {
  color: #fff;
  background: #FF0093;
  border-radius: 50%;
  position: absolute;
  right: -0.525rem;
  top: -0.525rem;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.9375rem;
}

.dropdown {
  position: relative;
}

.dropdownContainer {
  left: auto !important;
  right: 0;
}

.menu {
  background-color: #fff;
  border: 1px solid #4FB2ED;
  border-radius: 0 0 5px 5px;
  width: 17.75rem;

  & p {
    color: #6A6A6A;
    margin-left: 11px;
    margin-top: 13px;
    margin-bottom: 0;
  }
}

.btns {
  border-top: 1px solid #D8D8D8;
  padding: 1rem 11px;
  display: flex;
  justify-content: space-between;

  & button {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 48%;
    padding: 0 !important;
  }
}

.checks {
  padding: 1.05rem 11px;

  & label {
    display: flex;
    align-items: center;

    &:first-child {
      margin-bottom: 11px;
    }

    & :global(.ant-checkbox-wrapper) {
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }
}

.observer {
  height: 82px;
  width: 0;
  pointer-events: none;
  z-index: -1;
  visibility: hidden;
}

@media screen and (max-width: 1441px) {
  .headline{
    padding-right: 2rem;
    width: calc(72vw - 4.2rem - 26px - 0.6rem);
  }
}

.switcher{
  margin-left: 1rem;
}