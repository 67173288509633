@use "src/scss/mixins/_ui-indicators.scss" as ui_indicators;

.row {
  background-color: var(--color-background-field6);
  border-radius: 0.5rem;
  height: 3.875rem;
  font: normal normal 400 1rem var(--title-font);
  position: relative;
  border: 1px solid transparent;
  margin-bottom: 0.3rem;

  &.small {
    background-color: #fff;
    height: 2.5rem;
    margin-bottom: 0;

    & .header {
      height: 100%;
      border-bottom: 1px solid #ebebeb;
    }
  }

  &.font13 {
    font: normal normal 400 13px var(--title-font);
  }

  &.active {
    background-color: var(--color-background-main);
    border: 1px solid var(--color-background-primary);
    height: auto;

    &.parentOfExpenditures {
      border: 1px solid #d8d8d8 !important;
    }
  }

  &.hoverable:not(.active):hover {
    cursor: pointer;
    color: #fff;
    background-color: #4fb1eb;

    &.hoverableWhiteSvg,
    & :global(.filesClipButton) {
      color: #fff;
      & path {
        fill: #fff;
      }

      & :global(.checkbox) {
        border: 1px solid #fff;

        & svg path {
          fill: transparent;
          stroke: #fff;
        }
      }
    }
  }

  .header {
    padding-left: 1.5rem;
  }

  .buttonOpen {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.62rem;
    height: 3rem;
    left: -1.62rem;
    background: #e0e0e0;
    border-radius: 0.5rem 0 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & path {
      fill: #fff;
    }

    &.blue {
      background-color: #4fb2ed;
    }

    &.green {
      background-color: #8fe532;
    }

    .earCount {
      background-color: #fff;
      color: #000;
    }
  }
}

.earCount {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 0.6875rem;
  background-color: #4fb1eb;
  color: #fff;
  margin-bottom: 0.5rem;
}

.rowCell {
  display: flex;
  height: 100%;
  align-items: center;

  &.font13 {
    font: normal normal 400 13px var(--title-font);
  }

  & .centered {
    justify-content: center;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
  }

  & .noBreak {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 1rem;
  }

  & .righted {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
    padding-right: 3rem;
  }
}

.header {
  display: grid;
  position: relative;
  align-items: center;
  height: 3.875rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

$indicatorSize: 8px;

.withIndicator:before {
  @include ui_indicators.getIndicatorAsContent($indicatorSize);
  position: absolute;
  left: -1rem;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 1910px) {
  div.container {
    margin-bottom: 0.5rem !important;
  }
}

@media (max-width: 1909px) {
  div.container {
    margin-bottom: 0.47rem !important;
  }
}
