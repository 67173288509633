.container {
  padding-top: 1rem;
}

.parent {
  background-color: #fff !important;
}

.name {
  grid-template-columns: minmax(auto, 60%) minmax(auto, 40%);
  justify-content: space-between;
  padding-right: 0;
}

.row {
  display: grid;
  grid-template-columns: minmax(auto, 60%) minmax(auto, 40%);
  justify-content: space-between;
}

.countsCell {
  justify-content: flex-end;
}
