.container {
  display: flex;
  flex-direction: column;
  position: relative;
  font: normal normal 1rem/1.125rem var(--title-font);
}

.input {
  height: var(--input-height);
  border-radius: 0.5rem;
  padding: 0.7rem 1rem;
  background-color: #fff;

  ::placeholder {
    font-size: var(--placeholder-fsz);
  }

  &.primary {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-field18);

    &:focus,
    &:active {
      border-color: var(--color-border-primary);
    }
  }

  &.secondary {
    border: none;
    background-color: var(--color-background-field5);
    color: var(--color-font-main);

    ::placeholder {
      color: var(--color-font-main);
      opacity: 0.5;
    }
  }

  &.brandColorTransparent {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-primary);
    background-color: transparent;

    &:focus,
    &:active {
      border-color: var(--color-border-primary);
    }
  }

  &.brandColorWhite {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-primary);
    background-color: #fff;

    &:focus,
    &:active {
      border-color: var(--color-border-primary);
    }
  }

  &.price {
    text-align: right;
  }

  &.price::placeholder {
    text-align: left;
  }
}

.inputError {
  border-color: #ff0558;
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
  position: absolute;
  bottom: -1.25rem;
  white-space: nowrap;
}

.label {
  color: #666666;
  margin-bottom: 0.35rem;
}

.tertiary {
  background-color: #efefef;
  border: none;
  font: normal normal 1.5rem/1.875rem var(--title-font);
  color: var(--color-font-main);
}

.inputWrapper {
  position: relative;
}

.icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.btn {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 0.35rem;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 6px;
  background: #4fb1eb;

  &:disabled {
    background: #f4f4f4;
    cursor: auto;
  }
}

.inputWithRightBtn {
  padding-right: 3rem;
}
