@import "src/scss/dimensions";

.container {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 1rem 0.75rem;
}

.svg {
  display: block;
  transform: rotate(-90deg);
}

.svg_circle_bg {
  fill: none;
}

.svg_circle {
  fill: none;
  //will-change: stroke-dashoffset;
  //transition: stroke-dashoffset 850ms ease-in-out;
}

.container_title {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  position: relative;

  .text {
    font: normal normal normal 1rem/1.25rem var(--main-font);
    opacity: 0.6;
  }
}

.progress {
  font: normal normal 300 1.125rem/1.375rem var(--title-font);
  text-align: right;

  .title {
    font-size: 1.375rem;
  }
}

.plus {
  position: absolute;
  bottom: -1.5rem;
  font: normal normal 1.125rem/1.375rem var(--title-font);
  width: 96%;
  text-align: right;
}

.progress,
.plus {
  white-space: nowrap;
}

@include breakpoint-to($laptop) {
  .container_title .text {
    font: normal normal normal 0.8rem/1rem var(--main-font);
  }
}
