.container {
  padding: 0 2.1rem 1.87rem;

  .searchBlock {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
    margin-left: -2.1rem;
    padding: 1.87rem 1.87rem 1.25rem 2.1rem;
    width: calc(100% + 3.97rem);

    .inputSearchClassName {
      flex: 1;

      &:active,
      &:hover {
        max-width: 100%;
      }
    }
    .addButton {
      padding: 0 1rem;
      color: var(--color-font-secondary5);
      font-family: var(--title-font);
      cursor: pointer;

      svg {
        margin-left: 1rem;
        cursor: pointer;
      }
    }
  }

  .titleTable {
    display: flex;
    align-items: center;
    font: normal normal 400 1.12rem var(--title-font);
    margin-bottom: 1rem;

    .countSection {
      flex: 1;
      text-align: right;
      font-size: 1rem;
    }

    .button {
      margin-right: 1rem;
      max-width: 83%;
    }
  }
}
