.container {
  display: flex;
  justify-content: center;
}

.content {
  padding: 1rem 2rem;
  width: 52rem;
  height: 41rem;
  overflow-y: auto;

  @media screen and (max-width: 1024px) {
    width: 40.625rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 1rem;
}

.title {
  font-size: 1.375rem;
  font-weight: 800;
  margin: 0;
}

.tabBar {
  margin: 1rem 0;
}

.tab {
  width: 11.5rem;
}

.rangeContainer {
  @media screen and (max-width: 1024px) {
    max-width: 28.125rem;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;

  & > div {
    max-height: none;
  }
}

.btns {
  display: flex;
  width: 100%;
  justify-content: end;
  padding-top: 2rem;
  gap: 1rem;
}

.filesPopup {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
