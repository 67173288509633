.container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  background-color: #fff;
  z-index: 12;
  position: sticky;
  top: 0;

  &::before {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 1.88rem;
    top: -1.88rem;
  }

  &::after {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 0.5rem;
    bottom: -0.5rem;
  }

  & :global(.monthSlider) {
    height: 2.6rem;
  }
}

.months {
  margin-right: auto;
}

.add {
  margin-left: 2rem;
}
