.container {
  width: 100%;
  padding: 1.5rem 1.875rem;
  align-items: center;

  display: grid;
  grid-template-columns: 2.875% 58.6317% 11.4351% 11.4351% 11.4351% 4.0022%;

  &.budget,
  &.markup {
    grid-template-columns: 2.875% 46.4675% 11.4351% 14.0652% 11.4351% 13.7221%;
  }

  &.estimatedPrice {
    grid-template-columns: 2.875% 43.4675% 11.4351% 14.0652% 14.4351% 13.7221%;
  }

  &.canConfirm {
    grid-template-columns: 2.875% 52.6317% 11.4351% 11.4351% 11.4351% 10.0022%;
  }

  border-bottom: 1px solid #EBEBEB;

  .column {
    display: flex;
    align-items: center;

    &.isCentered {
      justify-content: center;
      text-align: center;
    }
  }

  .numberColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding-right: 1rem;

    &.withoutCheckbox {
      align-items: flex-start;
    }

    :global(.checkbox) {
      margin-left: 0;
    }
  }

  .titleColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;

    color: var(--base-text-primary, #000);
    font-family: Montserrat, sans-serif;
    font-style: normal;
    line-height: 1.375rem;
    overflow: hidden;

    .title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.38rem;

      .type {
        display: flex;
        padding: 0.25rem 0.625rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 1.5rem;
        background: #E9F4FB;

        color: var(--blue-blue-accent, #4FB1EB);
        text-align: center;
        font-family: Montserrat, sans-serif;
        font-style: normal;
        line-height: 1.125rem;
      }

      .files {
        display: flex;
        height: 1.75rem;
        padding: 0.25rem 0.625rem;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;

        border: none;
        outline: none;
        border-radius: 1.25rem;
        background: #F4F4F4;

        cursor: pointer;

        .fileIcon {
          display: flex;
          flex-shrink: 0;
          width: 1rem;
          height: 1rem;
          stroke: #808080;
        }

        .number {
          font-family: Montserrat, sans-serif;
          font-style: normal;
          line-height: 1.125rem;
          color: #808080;
          font-size: 0.8rem;
        }
      }

      .budgetInfo {
        display: flex;
        height: 1.75rem;
        padding: 0.125rem 0.625rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 1.25rem;
        background: #F4F4F4;

        color: var(--base-text-primary, #000);
        text-align: center;
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 1.125rem; /* 128.571% */
      }
    }
  }

  .buttons {
    display: flex;
    padding: 0.5625rem 0.75rem;
    align-items: center;
    gap: 0.875rem;

    border-radius: 1.875rem;
    background: var(--gray-gray-2, #F4F4F4);

    .editButton {
      padding: 0;
    }

    .editIcon,
    .close {
      fill: #A4A4A4;
      cursor: pointer;
    }
  }
}

.discount,
.estimatePriceCol {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 1rem;
}
.estimatePriceDoubleInput {
  width: 16.25rem;
}

.discount,
.estimatePriceCol {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 1rem;
}

.withoutDiscountIcon {
  opacity: 0.5;
}

.withDiscountAmount {
  width: 13%;
  text-align: center;
}

.estimatedPriceColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.diff {
  display: flex;
  justify-content: center;
  align-self: stretch;
  width: 100%;

  & .diffPercent,
  & .diffPrice {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  &.diffPercent {
    border-right: 1px solid #C3C3C3;
  }
}


.deletingSpinnerContainer {
  width: 1.1rem;
  height: 1.1rem;

  .deletingSpinner {
    border-width: 0.2rem;
    width: 100%;
    height: 100%;
  }
}

.confirmSelect {
  border: 1px solid red;
}