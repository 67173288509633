.modal {
  width: 39.688rem;
}

.title {
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 1.2rem;
  font: normal normal bold 1.375rem/1.5rem var(--title-font);
}

.count {
  margin-bottom: 1.7rem;
  font: normal normal 1.25rem/1.375rem var(--title-font);
  margin-left: 2rem;
}

.inner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  & > * {
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.scroll {
  overflow-y: scroll;
  flex-grow: 2 !important;
  max-height: calc(100vh - 2rem - 1.2rem - 1.8rem - 1.7rem - 1.6rem - 5px - 1.2rem - 4.45rem);
  padding-bottom: 1rem;
}

.btns {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #BCBCBC;
  width: 100%;
  background-color: #fff;

  & > * {
    &:first-child {
      margin-right: 1rem;
    }
  }
}


.row {
  font-size: 13px !important;
  display: grid;
  height: 3rem;
  width: 100%;
  align-items: center;
  grid-template-columns: 10fr 25fr 17fr;
  margin-bottom: 0;
  color: #000;

  &:nth-child(2n+1):not(.thead) {
    background: #EFF7FC;
  }

  &:not(.thead) {
    cursor: pointer;
  }

  & > div:first-child:not(.number) {
    padding-left: 1rem;
  }
}

.selected {
  background: #4FB2ED !important;
  color: #fff !important;

  .checkbox {
    border-color: var(--color-font-hover);

    path {
      stroke: var(--color-font-hover)
    }

  }
}

.number {
  padding-left: 3.25rem;
}

.th {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 500;
  font-size: 1rem;
}

.thead {
  height: 2.2rem;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  padding-right: 7px;
}

.labelText {
  font-size: 13px;
  margin-left: -1rem;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;

  &:hover {
    text-decoration: underline;
  }
}

.contractorInfo, .backNav {
  padding: 0 1.875rem;
}

.backNav {
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: 0.75rem;
  padding-bottom: 0.1rem;
}