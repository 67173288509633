.tabBar {
  border-radius: 0.5rem;
  padding: 0 0.1rem;
  display: flex;
  background: #efefef;
}

.tab {
  height: 2.3rem;
  display: flex;
  margin: 0.25rem;
  border-radius: 0.5rem;
  font-weight: normal;
  font-size: 1rem;
  cursor: pointer;
  white-space: nowrap;
  justify-content: center;
  width: 9.875rem;
  transition: none !important;
  align-items: center;
}

.disabledTab {
  pointer-events: none;
  color: #c3c3c3;
}

.active:not(.desktopHidden) {
  color: #fff;
  background: #4fb2ed;

  &:hover {
    color: #fff;
  }
}

.active.desktopHidden:after {
  filter: invert(100%);
}

.light {
  .tabBar {
    border-radius: 0.5rem;
    padding: 0 0.1rem;
    display: flex;
    background: white;
  }

  .tab {
    height: 2.3rem;
    display: flex;
    margin: 0.25rem;
    font-weight: normal;
    font-size: 1rem;
    cursor: pointer;
    white-space: nowrap;
    justify-content: center;
    width: 9.875rem;
    transition: none !important;
    align-items: center;
    color: rgba(0, 0, 0, 0.59);
    position: relative;
  }

  .active:not(.desktopHidden) {
    color: black;
    background: transparent;

    &:before {
      content: "";
      width: 100%;
      height: 0.25rem;
      border-radius: 0.25rem;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--color-border-primary);
      transform: translateY(25%);
    }
  }
}

.default .disabled,
.light .disabled {
  pointer-events: none;
}

.default .desktopHidden,
.light .desktopHidden {
  display: none;
}

@mixin selectTabs {
  &.container {
    position: relative;
    min-height: 2.8rem;
    z-index: 8;
    min-width: 9.875rem;
  }

  .tabBar {
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  .tab {
    text-align: left;
    justify-content: start;
    padding-left: 0.75rem;
    order: 1;
    display: none;
  }

  .open {
    box-shadow: var(--modal-shadow);

    & .tab {
      display: flex;

      &:hover {
        background-color: var(--color-border-primary);
        color: #fff;
      }

      &.desktopHidden:hover:after {
        filter: invert(0);
      }
    }

    & .active::after {
      transform: rotate(0);
    }
  }

  .active {
    position: relative;
    order: 0;
    display: flex;

    &:after {
      content: "";
      margin-left: auto;
      margin-right: 0.5rem;
      background-image: url("./arrow.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 1.2rem;
      height: 0.7rem;
      flex-shrink: 0;
      transform: rotate(180deg);
    }
  }

  .single {
    & .active {
      &:after {
        display: none;
      }
    }
  }
}

.onlySelect {
  @include selectTabs;
}

@media screen and (max-width: 1441px) {
  .default .desktopHidden,
  .light .desktopHidden {
    display: block;
  }
  .select {
    @include selectTabs;
  }
}
