@import "src/scss/mixins/sliceTextByLines";

.expenditure {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
  width: 37.5rem;
}

.actions {
  display: flex;
  align-items: center;
  width: 6.625rem;
  height: 2.313rem;
  background-color: #fff;
  border-radius: 1rem;
  flex-shrink: 0;
}

.matchingContainer {
  width: 18px;
  height: 22px;
  cursor: pointer;
}

.matching {
  padding: 0;
  margin: 0 auto;
  display: block;
}

.matchingContainer,
.confirmButton {
  flex: 1;
}

.confirmButton {
  background-color: var(--color-background-primary);
  border-radius: 0 1rem 1rem 0;
  height: inherit;
}

.cancelButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
}

.label {
  font-size: 14px;
  margin-bottom: 0.75rem;
}

.name {
  @include slice-text-by-lines(2);
  color: #707070;
  height: 2.5rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0 2.25rem;
}

.confirmed {
  border-color: #40cbcb;

  .confirmButton {
    background-color: #40cbcb;
  }
}

.writeOffTarget {
  @include slice-text-by-lines(2);
  overflow-wrap: break-word;
}
