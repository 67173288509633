.budgetResourceIsland{
  width: 100%;
  grid-column: 2 / 3;
  grid-row: 5 / 9;
  user-select: none;
}

.typeSelect{
  width: 12rem;
}

.zoomOutBtn{
  margin-left: auto;
  margin-right: 1.5rem;
}

.heading {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;

  & > *:first-child {
    margin-right: auto;
  }
}