.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2.3125rem;

  .titleInput {
    width: 55.125rem;
  }
}

.selectedCount {
  font-size: 0.875rem;
  font-weight: 400;
  width: auto;
}