.container {
  min-width: min-content;
  height: 100vh;
  display: flex;
  flex-direction: column;


  .content {
    padding: 0 2rem;

    flex: 1;

    .hiddenWrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }


  }

  .block {
    display: flex;
    flex-direction: column;
    height: 90%;
    max-width: 100%;
    overflow-y: auto;
    flex: 1;
    border: 1px dashed #D1D1D1;
    border-radius: 10px;
    justify-content: space-around;


    .blockTop {
      display: flex;
      flex-wrap: wrap;
      margin: 1rem;
      text-align: center;
      height: 50%;
      border-bottom: 1px solid #D8D8D8;

      .item {
        margin: 0.5rem;

        .value {
          font: normal normal normal 1.125rem var(--main-font);
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          background: #C5FAD3;
          border-radius: 8px;
          width: 4.1rem;
          height: 5rem;
          position: relative;
        }

        .valueActive {
          @extend .value;
          background: #FFDBDB;
          position: relative;
        }

        .docName {
          font: normal normal normal 0.8rem var(--main-font);
          padding-top: 0.5rem;
          width: 4.1rem;
          height: 2.6rem;
          overflow: hidden;
          word-wrap: break-word;
        }
      }
    }

    .blockTops {
      @extend .blockTop;
      border-bottom: none;
      display: none;
    }

    .blockBottom {
      min-height: 50%;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;

      .newText{
        position: absolute;
        top: 0;
        left: 10px;
        font: normal normal medium 1rem/1.3rem  var(--main-font);
        color: #C1C1C1;
      }


      .clipBlock {
        width: 14rem;
        text-align: center;
        margin: 0 auto;

        .clip {
          margin-bottom: 1rem;
          input[type='file'] {
            display: none;
          }

          label {
            cursor: pointer;
            margin: auto;
            width: 3.6rem;
            height: 4.5rem;
            background: #eaeaea;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .blocks {
    @extend .block;
    justify-content: center;
  }

  .fieldCalendar {
    margin-right: 1rem;
  }

  .fieldRow {
    display: flex;

    .fieldBlock {
      width: 50%;
    }
  }

  .fieldTitle {
    font: normal normal bold 1rem var(--main-font);
    color: #000000;
    margin: 1.5rem 0 0.5rem 0;
    white-space: nowrap;

  }

  .fieldTitleActive {
    margin: 1.5rem 0 0.5rem 0;
    font: normal normal normal 1rem var(--main-font);
    color: #4FB2ED;
    cursor: pointer;
  }

  .titleBlock {
    padding: 1rem 2rem 0 2rem;

    .title {
      font: normal normal bold 1.3rem var(--main-font);
      color: #000000;
    }

    .subTitle {
      color: #707070;
      padding: 0.5rem 0;
      opacity: 0.8;
      font: normal normal normal 1rem var(--main-font);
    }
  }

}

.sliderClassName {
  width: 30%;
}

.actionBlock {
  border-top: 1px solid #BCBCBC;
  padding: 10px;
  width: 100%;
  bottom: 0;
  text-align: right;
  background-color: #fff;
  border-radius: 0 0 0 20px;
  display: flex;
  justify-content: flex-end;

  input{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.icon {
  width: 1.5rem;
  cursor: pointer;
  padding: 1rem 0;
  margin-right: 1rem;
}
.icons {
  width: 35%;
  position: absolute;
  cursor: pointer;
  top:-10px;
  right: -10px;
}