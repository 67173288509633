.container {
  display: flex;
  flex-direction: column;
}

.subHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .halfBlock {
    display: flex;
    align-items: center;

    .title {
      font: normal normal normal 400 1.18rem var(--title-font);
      margin-left: 1.87rem;
    }

    .countSection {
      font: normal normal normal 400 1rem var(--title-font);
      margin-right: 3.4rem;
    }
  }
}

.measureBtn {
  margin-bottom: .8rem;
}
