.container {
    width: 17rem;
    display: flex;
    flex-direction: column; 
}

.tabbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.tab {
    line-height: 2.5rem;
    font-size: 1rem;
    cursor: pointer;
    border-bottom: 1px solid transparent;

    &:hover,
    &.activeTab {
        border-bottom: 1px solid black;
    }
}

.logo {
    margin-top: 7.125rem;
    margin-bottom: 5rem;
}