.btn {
  display: flex;
  align-items: center;
  color: #4fb1eb;
  cursor: pointer;
  font-weight: 500;

  & svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
}

.select {
  width: 100%;
  margin-bottom: 1rem;
}
