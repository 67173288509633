.container {
  overflow: hidden;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  margin-top: 0.1rem;
  @media (min-width: 2100px) {
    width: 100%;
  }
}

.count {
  min-width: 2.5rem;
  text-align: center;
  padding: 0.1rem 0.25rem;
  color: white;
  font: normal normal 500 0.713rem var(--title-font);
  border-width: 1px;
  border-style: solid;
}

.payment {
  $color: #a866ee;

  .count {
    background-color: $color;
    border-color: $color;
  }

  .title {
    border-left-color: transparent;
    background-color: #f4effc;
  }
}

.task {
  $color: #73c0e3;

  .count {
    background-color: $color;
    border-color: $color;
  }

  .title {
    border-left-color: transparent;
    background-color: #e5f7ff;
  }
}

.supply {
  $color: #ee9226;

  .count {
    background-color: $color;
    border-color: $color;
  }

  .title {
    border-left-color: transparent;
    background-color: #fff2de;
  }
}

.title {
  padding: 0.1rem;
  flex-grow: 2;
  text-align: center;
  border-radius: 0 0.25rem 0.25rem 0;
  font: normal normal 500 0.75rem var(--title-font);
}

.prepay {
  .title {
    border: 1px dashed #a866ee;
    background-color: white;
  }
}

@media screen and (max-width: 1900px) {
  .collapsed {
    transition: 0.3s;
    left: auto;
    &:hover {
      transition: 0.3s;
      position: absolute;
      left: 0;
      z-index: 2;
      width: 100%;

      & .title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-left: none;
      }
    }

    &:not(:hover) {
      & .title {
        display: none;
      }

      &.prepay {
        .count {
          border: 1px dashed #a866ee;
          background-color: white;
          color: #000;
        }
      }
    }
  }

  .title {
    padding-bottom: calc(0.1rem + 1px);
    padding-top: calc(0.15rem + 1px);
  }

  .count {
    padding-top: 0.15rem;
  }
}
