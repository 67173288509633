.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0;
  width: 15.438rem;
}

.title {
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0;
  max-width: 12.125rem;
  text-align: center;
}

.section {
  position: relative;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.938rem 0 0.75rem;
}

.counter {
  height: 2rem;
  width: 8.375rem;
  border-radius: 0.5rem;

  & input {
    padding-left: 0.5rem !important;
  }
}

.measure {
  position: absolute;
  left: 105%;
  font-size: 0.875rem;
  user-select: none;
}

.footer {
  & button {
    display: flex;
    justify-content: center;
    font-size: 0.875rem;
    width: 8.375rem;
    height: 1.875rem;
    padding: 0;

    &:first-child {
      margin-bottom: 0.375rem;
    }
  }
}