.container {
  display: flex;
  border-bottom: 1px solid #D0D0D0;
  min-height: 3.6rem;

  &:nth-last-child(1) {
    border-bottom: none;
  }

  .name {
    margin: 0  0.5rem 0 4.5rem;
    // width: 52%;
    display: flex;
    align-items: center;
    flex: 1;
  }
  .rowImg{
    width: 3rem;
    padding: 1rem 0.5rem;
  }

  .rowItem {
    width: 10rem;
    padding: 0 0.5rem;
    text-align: center;

    .rowLink {
      display: flex;
      align-items: center;
      justify-content: center;

      .bottomLine {
        border-bottom: 1px solid #000;
        margin-right: 0.8rem;
        color: #000;
      }
    }
  }

  .checkFile {
    position: relative;
    cursor: pointer;

    .mark {
      position: absolute;
      right: -0.4rem;
      top: 0.2rem;
    }
  }
}
.box{
  // min-width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.order {
  margin-top: 0.2rem;
  display: block;

  &:last-child {
    margin-bottom: 0.4rem;
  }
}