.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 1.3rem;
  font-size: 1.125rem;
  font-family: var(--title-font);
}

.item > span {
  display: inline-block;
}

.item > span:nth-child(1) {
  min-width: 20.2rem;
}
.item > span:nth-child(2) {
  font-weight: 300;
}

.underlined {
  border-bottom: 1px solid var(--color-background-primary);
}
