.modal {
  width: 43.75rem;
  min-height: 18rem;
  max-height: 50rem;
  overflow: auto;
}

.title {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  z-index: 99999999999;
  div {
    margin: auto;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  padding: 0.75rem 1.25rem;
  border-top: 1px solid #ebebeb;
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

.btn {
  width: 12rem;
  display: flex;
  justify-content: center;
  margin: 0 1rem;
}

.sector {
  border-top: 1px solid #ebebeb;
  padding: 1.5rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin-bottom: 0.25rem;
  }
}

.guaranteeObligation {
  border-top: 1px solid #ebebeb;
  padding: 1.5rem 1rem 1rem;

  h3 {
    margin-bottom: 0.25rem;
  }
}

.subTitle {
  font-size: 0.875rem;
  color: #a4a4a4;
}

.addBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eff7fc;
  border-radius: 0.5rem;
  height: 2.625rem;
  border: 1px solid #d9f1ff;
  color: #4fb1eb;
  margin-top: 1rem;
  cursor: pointer;
}

.flex {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.measure {
  color: #a4a4a4;
}

.amount {
  font-weight: 600;
}

.advancePaymentChart {
  padding: 0.5rem 1rem 1rem;
}

.warning {
  color: #eb4f8b;
}

.doubleInput {
  min-height: 2.5rem;
  box-sizing: border-box;
  width: 16rem;

  border: 1px solid var(--gray-gray-4, #c3c3c3);
  outline: none;
  transition: border-color 0.25s ease-in-out;

  &:focus-within:not([aria-disabled]) {
    border-color: var(--color-border-primary);
    box-shadow: none;
  }

  & label {
    &:after {
      height: 100% !important;
      background-color: var(--gray-gray-4, #c3c3c3) !important;
    }
  }

  & label {
    &:after {
      height: 100% !important;
      background-color: var(--gray-gray-4, #c3c3c3) !important;
    }
  }

  & input {
    text-align: right;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }

  & span {
    font-weight: 500;
    color: #a4a4a4;
  }
}
