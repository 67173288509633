.pair {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.25rem;

  &.centered {
    align-items: center;
  }
}

.date {
  display: flex;
  align-items: center;
  white-space: nowrap;

  & svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.day {
  font-weight: 500;
}

.pass {
  display: flex;
  align-items: center;

  & b {
    flex-basis: 30%;
  }
}

.info {
  display: flex;
  flex-direction: column;

  & b {
    margin-bottom: 0.75rem;
  }
}

///////////////////////////////

.filesContainer {
  padding-top: 0.5rem;
}

.filesHead {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & span {
    font-weight: 500;
  }

  & div {
    color: #4fb2ed;
    cursor: pointer;
  }
}
////////////////////////////

.hr {
  width: 100%;
  height: 1px;
  background: #dbdbdb;
  margin-bottom: 1rem;
}

.mb {
  margin-bottom: 1.25rem;
}
/////////////////////////////////////////
