.container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.25rem;
    height: 1.875rem;
    background: #DAEEFA;
    border-radius: 1rem;
    font-size: 12px !important;

    & > div {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 0.25rem;
    }
}

.count {
    flex: 0 0 40%;
}

.amount {
    flex: 0 0 calc(60% - 1px);
}

.divider {
    flex: 0 0 1px;
    height: 100%;
    width: 1px;
    background-color: #5DBCD8;
    padding: 0 !important;
}