.providersList {
  padding: 0.5rem 1rem;
}

.providerItem {
  margin-top: 0.625rem;

  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }

  .status {
    color: var(--blue-blue-accent, #4fb1eb);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
  }

  .providerName {
    color: var(--base-text-secondary, #696969);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    margin-top: 0.12rem;
  }
}

.checkDouble {
  width: 1.6rem;
  min-width: 1.6rem;
  max-width: 1.6rem;
}
