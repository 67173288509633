@import "../../../../../../scss/dimensions";

.container {
  position: relative;
  padding: 0.5rem;
  height: 100%;

  .title {
    position: absolute;
    left: 0.75rem;
    flex-shrink: 0;
    height: 2.375rem;
    width: calc(100% - 1.5rem);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    background-color: var(--color-background-primary);
    color: var(--color-font-hover);
    padding: 0 1rem;
    margin-bottom: 0.75rem;
  }

  &.canDrop {
    border: 1.5px dashed var(--color-border-primary);
  }

  .numberBlock {
    font: normal normal 300 1.125rem/1.375rem var(--title-font);
  }

  .subTitle {
    font: normal normal normal 1rem/1.25rem var(--main-font);
    opacity: 0.6;
    margin-bottom: 0.4rem;

    @include breakpoint-to($laptop) {
      font: normal normal normal 0.8rem/1rem var(--main-font);
    }
  }
}

.border {
  width: 65%;
  height: 6px;
  background-color: var(--color-border-primary);
  position: absolute;
  border-radius: 1rem;
  left: 19%;
  bottom: -0.75rem;
}