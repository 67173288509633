.sliderClassName {
  width: 54.75rem;
  z-index: 9999999999;
}

.container {
  //height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  .contentWrapper {
    padding: 1rem 1.81rem 2.31rem;
    flex: 1;
  }

  .content {
    flex: 1;
  }

  .fieldCalendar {
    width: 100%;
  }

  .fieldRow {
    display: flex;
    margin: 0 -0.38rem;

    .fieldBlock {
      flex: 1;
      margin: 0 0.38rem;
    }
  }

  .fieldValue {
    color: #707070;
    padding: 0;
    font: normal normal normal 0.9em var(--main-font);
  }

  .fieldTitle {
    font: normal normal 600 1rem var(--main-font);
    color: #000000;
    margin: 1.5rem 0 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .subTitleButton {
      font: normal normal 400 1rem var(--main-font);
      color: #4fb2ed;
      cursor: pointer;
    }
  }

  .plusIconBlock {
    display: flex;
    align-items: center;
  }

  .titleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font: normal normal bold 1.38em var(--main-font);
      color: #000000;
    }

    .dateTitle {
      color: #000;
      font: normal normal normal 1.13em var(--main-font);
    }

    .userTitle {
      color: #808080;
      font: normal normal normal 1.1em var(--main-font);
    }
  }

  .blockTitle {
    font: normal normal bold 14px var(--main-font);
    color: #000000;
  }
}

.actionBlock {
  border-top: 1px solid #bcbcbc;
  padding: 1.07rem 2.2rem;
  width: 100%;
  bottom: 0;
  text-align: right;
  background-color: #fff;
  border-radius: 0 0 0 20px;
}

.icon {
  width: 1.5rem;
  cursor: pointer;
  padding: 1rem 0;
  margin-right: 1rem;
}

.classNameSelect {
  border-color: #4fb2ed;
}

.bottomBorder {
  width: 100%;
  height: 4.8rem;
  border-top: 1px solid #bcbcbc;
}

.header {
  position: sticky;
  background-color: #fff;
  z-index: 3;
  top: 0;
  padding: 2.31rem 1.81rem;
  padding-bottom: 0;
  border-bottom: 1px solid #bcbcbc;
}
.searchBlock {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 1rem 0;
  font-size: 1.125rem;
  gap: 1rem;

  .inputClassName {
    margin-right: 1rem;
    font-size: 1rem;
    width: 100%;
  }

  .btn {
    font: normal normal 1.125rem var(--title-font);
    border-radius: 10px;
  }
}

.tabs {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
}
