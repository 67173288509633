.currency {
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  background-color: white;
  color: black;
  font-weight: 500;
  width: 1.625rem;
  height: 1.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.currencyPopoverTitle {
  font-size: 1rem;
  color: #727272;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.currencyPopoverContainer {
  font-weight: 400;
  transition: 0.1s all ease-in-out;
  color: black;
  background-color: white;
  border-radius: 0.375rem;
  padding: 0.5rem 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.currencyPopoverItem {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;
  font-size: 0.875rem;
  column-gap: 1rem;
  width: 100%;
}

.currencyPopoverItemMarker {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.25rem;
}

.square {
  width: 0.625rem;
  height: 0.625rem;
}

.legend {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-auto-rows: 1fr;
  padding: 0.25rem 0;
  font-size: 0.875rem;
  grid-row-gap: 0.5rem;
  grid-column-gap: 1rem;
  width: 100%;

  & > span {
    text-align: right;
  }
}
