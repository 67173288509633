.tableHead {
  display: grid;
  grid-template-columns: 2.5rem 45.4rem 6.25rem 8.625rem;
  gap: 1rem;
  margin-bottom: 1px;
  padding: 0.75rem 1.87rem 0.75rem 1.5rem;
  height: unset;

  color: var(--base-text-secondary, #696969);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-color: #fff;
  border-top: 1px solid var(--gray-gray-4, #c3c3c3);

  &.expanded {
    grid-template-columns: 2.5rem 35.75rem 6.25rem 8.625rem 8.75rem;
  }

  .amount {
    justify-content: flex-end;
  }

  .type {
    justify-content: center;
    padding-right: 1rem;
  }

  .quantity {
  }

  .marketPrice {
    color: var(--gray-gray-5, #a4a4a4);
    display: flex;
    justify-content: flex-end;
  }
}

.tableContent {
  margin: 0;
  background-color: #fff;

  color: var(--base-text-primary, #000);
  font-size: 1rem;
  font-style: normal;
  line-height: normal;
  font-weight: 500;

  &.withoutButtons {
    border-radius: 0 0 15px 15px;
  }
}

.expendituresContainer {
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 1px solid var(--color-border-field20);
}

.expenditureItem {
  display: grid;
  grid-template-columns: 2.5rem 45.4rem 6.25rem 8.625rem;
  gap: 1rem;
  padding: 0.75rem 1.87rem 0.75rem 1.5rem;
  border-bottom: 1px solid #ebebeb;

  &.expanded {
    grid-template-columns: 2.5rem 35.75rem 6.25rem 8.625rem 8.75rem;
  }

  &.work {
    border-top: 1px solid var(--color-border-primary);

    &:first-child {
      border-top: none;
    }
  }

  &:last-child {
    border-bottom: unset;
    margin-bottom: 0;
  }

  .quantity {
    text-align: center;
  }

  .input {
    border-radius: 0.375rem;
    border-color: var(--blue-blue-accent, #4fb1eb);
    background: var(--base-white, #fff);

    display: flex;
    width: 8.75rem;
    padding: 0.5rem 0.625rem 0.5rem 0.75rem;
    align-items: center;
    gap: 0.625rem;

    color: var(--base-text-secondary, #696969);
    text-align: right;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.itemName {
}

.bubblesWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 0.5rem;
}

.bubble {
  display: flex;
  align-items: center;
  height: 1.875rem;
  width: fit-content;
  padding: 0.25rem 0.75rem;
  background-color: #eff7fc;
  border-radius: 1rem;

  & span {
    color: var(--color-background-field12);
  }

  &.justification {
    min-width: 11.438rem;
    margin-left: 0.25rem;
  }
}

.retOwnMat {
  width: 2.125rem;
  height: 1.875rem;
  background-color: #eae2f6;
  border-radius: 1.5rem;
  color: #a26dd0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.itemCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.popover {
  padding: 0.5rem;
  color: #4fb1eb;
}

.popoverWrapper {
  margin-left: 0.5rem;
  display: flex;
}

.popoverTooltip {
  z-index: 9999999999;
}
