@import "../../../../scss/dimensions";

.requestOffers {
  display: flex;

  &:not(:last-child),
  .header,
  .offer:not(:last-child) {
    border-bottom: 1px solid var(--color-border-field4);
  }

  .rows {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .header,
  .offer {
    display: flex;
    align-items: center;
  }

  .header {
    padding: 0.625rem 2.5rem;
    font: normal normal 500 1rem/1.375rem var(--main-font);
    justify-content: flex-start;
    column-gap: 1rem;
  }

  .offer {
    font: normal normal 1rem/1.375rem var(--title-font);
    padding: 1.625rem 2.5rem;
    justify-content: flex-start;
    column-gap: 1rem;
    min-height: 6.875rem;
  }

  .verticalText {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    padding: 0.714rem 2.5rem 0.714rem 2rem;
    border-left: 1px solid var(--color-border-field4);
    font: normal normal 0.813rem/1rem var(--title-font);
    text-align: center;
  }

  .nameColumn {
    display: flex;
    align-items: center;
    width: 40%;
  }

  .sumColumn,
  .priceColumn {
    text-align: right;

    .input {
      margin-left: auto;
    }
  }

  .dateColumn,
  .countColumn,
  .measureColumn,
  .sumColumn,
  .nameColumn,
  .priceColumn,
  .differenceColumn {
    flex-shrink: 0;
  }

  .nameColumn {
    .input {
      width: 95%;
      overflow: hidden;
    }
  }

  .dateColumn,
  .sumColumn,
  .priceColumn,
  .countColumn,
  .differenceColumn {
    width: 9%;
  }

  @media screen and (max-width: 1690px) {
    .dateColumn {
      width: 10%;
    }

    .countColumn {
      width: 8%;
    }
  }

  .dateColumn {
    text-align: center;
  }

  .measureColumn {
    width: 8%;
    display: flex;
    justify-content: center;
  }

  .kitOfferIcon {
    cursor: pointer;
    flex-shrink: 0;
  }

  .componentCircle,
  .kitOfferIcon {
    margin-right: 1rem;
  }

  .componentCircle {
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 100%;
    background-color: var(--color-background-primary);
    flex-shrink: 0;
  }

  .input {
    width: 75%;
  }

  .createOfferIcon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 2.75rem;
    cursor: pointer;
  }

  .createOfferSupplyIcon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .actionsColumn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    column-gap: 2.625rem;
  }

  .delete,
  .createOfferSupplyIcon {
    cursor: pointer;
  }

  .financial {
    width: 75%;
    display: block;
  }

  &.canNotEdit .financial {
    width: 100%;
  }
}

.countColumn {
  display: flex;
  justify-content: center;
}

.differenceColumn {
  text-align: right;
}

.withCountRequestMeasure {
  .nameColumn {
    width: 30%;
  }
}

.calendar {
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  span {
    margin-right: 0.325rem;
  }
}
