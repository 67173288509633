.buttonGroup {
  height: 2rem;
  width: auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 0.875rem;
  transition: 0.1s all ease-in-out;
  background-color: #4fb2ed;
  margin-left: auto;
  gap: 0.5rem;
  margin-right: 1rem;

  svg {
    height: 1.25rem;
    max-width: 1.25rem;
  }

  path {
    fill: #fff;
  }
}

.buttonGroup > .actionButton {
  color: #fff;
  &:first-child {
    padding-left: 0.75rem;
  }

  &:last-child {
    padding-right: 0.75rem;
  }
}

.btnDivider {
  width: 1px;
  height: 70%;
  background-color: #fff;
}
//

.root {
  cursor: pointer;
  background-color: #f4f4f4;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
}

.head {
  display: grid;
  grid-template-columns: calc(34% - 0.5rem) calc(28% - 1rem) calc(28% - 0.5rem) 10%;
  gap: 1rem;
}

.name {
  font-weight: 500;
}

.body {
  display: grid;
  grid-template-columns: 16rem calc(50% - 8rem - 1.5rem) calc(50% - 8rem - 1.5rem);
  padding-top: 0.75rem;
  gap: 1.5rem;
  grid-template-areas: "img first first" "img sec third";

  &:after {
    display: none;
  }
}

.mainCell {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  border-radius: 1rem;
  padding: 1rem;
  grid-area: img;
}

.mainInfo {
  display: flex;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 0.875rem;
  align-items: baseline;
}

.days {
  span {
    font-size: 1.25rem;
  }
}

.ending {
  color: #5ec5eb;
  font-weight: 500;
}

.topRow {
  grid-area: first;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: calc(48.75%) calc(51.25%);
}

.budget {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, auto);
}

.deviationIndicator {
  top: -1rem;
  left: 1.5rem;
}

.deviation {
  height: 100%;
  align-items: center;
  padding-bottom: 0;
  padding-top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  padding-left: 2rem;
  padding-right: 0;
  &:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 2.125rem;
    background-color: #cfcfcf;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.secondRow {
  display: grid;
  grid-template-columns: min-content auto auto;
  gap: 0.5rem;
}

.thirdRow {
  display: grid;
  grid-template-columns: min-content auto auto;
  gap: 0.5rem;
}

.nums {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: auto;
  font-size: 0.875rem;
  padding-left: 0.5rem;
}

.red {
  color: red;
}

@media screen and (max-width: 1700px) {
  .root {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .body {
    grid-template-columns: 16rem calc(50% - 8rem - 1rem) calc(50% - 8rem - 1rem);
    column-gap: 1rem;
  }

  .nums {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 1500px) {
  .days span {
    font-size: 1rem;
  }
  .body {
    column-gap: 1rem;
    grid-template-columns: 14rem calc(50% - 8rem) calc(50% - 8rem);
  }

  .topRow {
    grid-area: first;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-columns: calc(48.5%) calc(51.5%);
  }
}
