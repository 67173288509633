.form {
  background-color: #f4f4f4;
  border-bottom: 1px solid #ebebeb;
  padding: 1rem;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 30% 20% 30% 5%;
  row-gap: 1rem;
  column-gap: 5%;
}

.operations > .operationButton:not(:last-child) {
  margin-bottom: 1rem;
}

.operationButton {
  width: 100%;
  justify-content: center;
}

.result {
  grid-row: 2;
  grid-column: 1/3;
  align-content: center;
  color: var(--color-font-secondary5);
}

.reason {
  grid-row: 2;
  grid-column: 3/4;
}

.actions {
  grid-row: 2;
  grid-column: -2/-1;
  align-content: center;
}

@media (max-width: 1250px) {
  .form {
    grid-template-columns: 33% 20% 33% 8%;
    column-gap: 2%;
  }
}
