.container {
  background-color: #fafafa;
  border-bottom: 1px solid #dedede;
  height: 3.7rem;
  display: flex;
  align-items: center;
  color: #000;
}

.rightMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1.4rem;
  position: relative;
  z-index: 99;
}

.left {
  display: flex;
  align-items: center;
  margin-right: auto;
}
