.sectionRow {
    display: grid;
    grid-template-columns: 20% 55% 15% 10%;
}

.sectionExpHeader {
    display: grid;
    grid-template-columns: 6% 65% 10% 25%;
    height: 3rem;
    font-weight: 500;
    background-color: #fff;
    border-radius: 0;
    border-top: 1px solid #d6d6d6;
}

.sectionExpHeaderWrapper {
    height: 3rem;
    background-color: #fff;
    border-radius: 0;
}

.expRow {
    display: grid;
    background-color: #fff;
    grid-template-columns: 6% 65% 10% 25%;
}

.expRowWrapper {
    background-color: #fff;
    border-radius: 0 !important;
    &:not(:last-child) {
        & > div {
            border-bottom: 1px solid #d6d6d6;
        }
    }
}

.actions {
    & > div {
        background-color: transparent !important;
    }
}

.parentNameWrapper {
    background-color: #fff;
    height: 3rem;
    & > div {
        background-color: #fff;
        height: 3rem;
        font-weight: 500;
    }
}

.expGroupHeader {
    width: 100%;
    display: flex;
    padding-left: 1.5rem;
    align-items: center;
    background: #f2f2f2;
    height: 3rem;
    margin-top: -0.4rem;
}

.crossCell {
    padding-left: 2rem;
}
