.datePicker {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--color-background-field6);
  padding: 3px 9px;
  border-radius: 10px;
  width: fit-content;
  max-height: 2.688rem;
}

.datePicker__text {
  font-size: 1rem;
}

.datePicker__calendar {
  width: 200px;
}

.gap {
  color: #4FB2ED;
  font-weight: 500;
}