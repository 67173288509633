.orderHeader {
  display: flex;
  align-items: flex-start;
  padding: 0 0.5rem;
  font: normal normal 400 1rem/1.25rem var(--title-font);

  .checkbox {
    width: 3rem;
    text-align: center;
    padding: 1rem;
  }

  .rightSide {
    width: 100%;
    padding: 1rem;

    .topSide {
      display: flex;
      align-items: center;
      margin-bottom: 0.857rem;
    }

    .bottomSide {
      display: flex;
      align-items: center;
    }
  }

  .buttonOpen {
    position: absolute;
    top: 1.15rem;
    width: 1.62rem;
    height: 3rem;
    left: -1.62rem;
    background: #e0e0e0;
    border-radius: 0.5rem 0 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & path {
      fill: #fff;
    }

    &.completed {
      background-color: var(--color-positive);
    }

    &.haveOffers {
      background-color: #4fb2ed;
    }

    .count {
      font: normal normal 500 0.88rem var(--title-font);
      background-color: #fff;
      border-radius: 1rem;
      padding: 0 0.2rem;
      margin-bottom: 0.2rem;
    }
  }

  .divider {
    height: 1.5rem;
    width: 3px;
    background-color: var(--color-border-field16);
  }

  .status {
    margin-left: 6.786rem;
    margin-right: 3.714rem;
    color: var(--color-font-primary);

    @media screen and (max-width: 1440px) {
      margin-left: 2.786rem;
      margin-right: 1rem;
    }
  }

  .infoBlock {
    display: flex;
    flex-wrap: wrap;
    margin: 0 2.714rem;

    @media screen and (max-width: 1680px) {
      margin: 0 0.688rem;
    }

    .name {
      margin-right: 0.5rem;
      white-space: nowrap;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .packingLists {
    max-width: 38%;
  }

  .packingList:not(:last-child) {
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
  }

  .halfOpacity {
    opacity: 0.5;
  }
}

.switcher {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: auto;

  & span {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
}
