.bar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    height: 3.5rem;
}

.withoutBack {
    position: relative;
    //margin-bottom: 2rem;
}

.rightBtn {
    margin-left: auto;
    //position: absolute;
    right: 0;
    top: 0;
}
