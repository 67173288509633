.bar {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 0.3125rem;
  height: calc(var(--input-height) + 0.3125rem);
  & > span {
    opacity: 0.7;
    margin-left: 1rem;
  }

  & > div {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

.activeBar {
  & > span {
    opacity: 1;
  }
}

.trigger {
  margin-left: 0.5rem;
}

.disabledSelect {
  opacity: 0.7;
}

.optionBlock {
  color: #000 !important;
}

.modal {
  padding: 2rem 1.5rem;
  & h2 {
    font-size: 1.375rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

.tabs {
  width: fit-content;
}

.btns {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem 0;
  display: flex;
  justify-content: space-evenly;
  border-top: 1px solid #ebebeb;
}

.input {
  margin-bottom: 0.5rem;
}

.choose {
  font-size: 1.125rem;
  font-weight: 700;
  margin: 1rem 0;
}

.subtitle {
  margin-right: 0.5rem;
}

.select {
  width: 17rem;
  border: none !important;

  & > span {
    color: #fff;
  }

  &:after {
    content: "";
    width: 1px;
    height: 1.5rem;
    position: absolute;
    top: 50%;
    right: 3rem;
    transform: translateY(-50%);
    background-color: var(--color-border-field18);
    z-index: 10001;
  }
}

.selectedCount {
  font-weight: 500;
}

.button {
  font-size: 1rem;
  min-width: 15rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addOutOfEstimateButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
  cursor: pointer;
}

.import {
  position: static;
  margin-bottom: 0;
  flex-direction: row-reverse;
  gap: 1rem;

  & > * {
    margin-left: 0;
    margin-right: 0;
  }
}
