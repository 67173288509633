.container {
  width: 69.5vw;
  height: auto;
  
  .headerContainer {
    padding: 1.4375rem 1.9375rem 0 1.9375rem;
  }

  .contentContainer {
    padding: 0rem 2.5625rem 0 2.5625rem;
  }

  .title {
    font-size: 1.375rem;
    color:#000;
    font-weight: normal;
  }

  .topSide {
    border-bottom: 1px solid #D3D3D3;
    position: relative;
  }
}
.block1Title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5625rem;
}
.closeIcon {
  cursor: pointer;
}
.row1 {
  display: flex;
  justify-content: flex-start;
  // margin-bottom: 1.5rem;
  min-height: 4rem;
}
.field4{
  width: 15%;
  margin-right: 0.5rem;
}
.actionBlock {
  padding: 0.875rem 2.5rem  0.875rem 2rem;
  justify-content: flex-end;
  display: flex;
  opacity: 1;

  button:first-child {
    margin-right: 0.5rem;
  }

  &.action {
    opacity: 0;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 1.5rem;
  min-height: 5.5rem;

  &.row2 {
    margin-bottom: 1.5rem;
  }
}
.field1 {
  width: 70%;
}

.field2 {
  width: 27.5%;
}

.secondaryField {
  width: 25%;
}
.field3 {
  width: 13%;
}

.select {
  height: 2.64rem;
    input{
    border:1px solid #c3c3c3 !important;
      &:focus {
        border:1px solid #4FB2ED !important;
      }
  }
}

.titleFont {
  color:#000;
  font-weight: normal;
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
}