.container {
  min-width: min-content;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  .content {
    padding: 0rem 1.8rem;
    overflow-y: auto;
    // height: calc(100% - 78px);
    flex: 1;

    .hiddenWrapper {
      padding-left: 2rem;
    }
  }
  .titleBlock {
    padding: 1rem 1.8rem 0;
    display: flex;

    .title {
      font: normal normal 400 1.37em var(--main-font);
      color: #000000;
      white-space: nowrap;
      padding-right: 2rem;
    }
    .justification {
      background-color: var(--color-background-field3);
      border-radius: 2rem;
      padding: 0 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: min-content;
      height: 2rem;
      white-space: nowrap;
    }
  }
  
  .subTitle {
    color: #000;
    // padding: 0.5rem 0;
    padding: 0.5rem 0rem 2.3rem 2.4rem;
    // opacity: 0.8;
    font: normal normal normal 1.12em var(--main-font);
  }
  .blockTitle {
    font: normal normal bold 14px var(--main-font);
    color: #000000;  
  }
}
.sliderClassName {
  width: 34rem;
}