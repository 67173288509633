.input {
  border: 1px solid #c4c4c4;
}
.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  padding-left: 1.25rem;
  font: 1em var(--main-font);
  cursor: pointer;
  position: relative;
  width: max-content;

  & svg {
    transition: all 0.2s ease;
  }

  &.isOpen {
    // border-color: #4FB2ED;
    // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    // border-radius: 10px 10px 0 0;
    // width: 15rem;
  }
  &.disabled {
    color: #a0a0a0;
    pointer-events: none;
  }
  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .optionsBlock {
    width: 100%;
    top: 100%;
    left: 0;
    border-radius: 0 0 10px 10px;
    position: absolute;
    background: #fff;
    z-index: 9999;
    //transition: all 0.3s ease;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    max-height: 11.8rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: 0;
    // box-sizing: ;
    box-sizing: content-box;

    &.isOpen {
      height: auto;
      border: 1px solid #4fb2ed;
      // border-right: 1px solid #4FB2ED;
      // border-left: 1px solid #4FB2ED;
      // border-bottom: 1px solid #4FB2ED;
      // border-top: 1px solid #DDDDDD;
    }

    .option {
      border-radius: 0.5rem;
      padding: 0.6rem 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        background: #4fb2ed;
        color: #fff;
      }
    }
  }
  .isOpen {
    display: block;
  }
}
