.container {
  display: flex;

  .rowItem {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 0 0 11%;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    height: 3rem;

    &.nameBlock {
      flex: 0 0 41%;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-left: 0;

      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }

    &.price {
      flex-basis: 15%;
      justify-content: flex-end;
      column-gap: 2.1rem;
      padding-right: 3rem;

      @media screen and (max-width: 1680px) {
        flex-basis: 16%;
      }

      @media screen and (max-width: 1440px) {
        flex-basis: 16.5%;
      }

      & .priceCentered {
        display: block;
        width: 50%;
        text-align: center;
      }

      :global(.bp4-popover2-target) {
        border-bottom: 1px solid black;
        cursor: pointer;
      }

      & .priceUnderlined {
        border-bottom: 1px solid black;
      }
    }

    .input {
      height: 2rem;
      width: 7rem;
      background: #ededed;
      border: none;

      &.inputActive {
        background: #ccecff;
      }
    }

    .approveButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.5rem;
      height: 2rem;
      width: 8.2rem;
      background: #4fb2ed;
      border-radius: 0.8rem;
      color: #fff;
      font-size: 1.12rem;
      font-family: var(--title-font);
      cursor: pointer;
    }
  }
}

.measure,
.centeredContent {
  justify-content: center;
}
