.diagramIntervalWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0.5rem;
  height: 32px;
  box-sizing: border-box;
  z-index: 0;

  & > svg {
    display: block;
    position: relative;
    height: 32px;
    z-index: -1;
  }
}

.ticketShape {
  height: 100%;
  mask: linear-gradient(
      to right,
      transparent,
      transparent 6px,
      black 6px,
      black calc(100% - 6px),
      transparent calc(100% - 6px),
      transparent
    ),
    url("./assets/ticket-start.svg") 0 100% / 7px 100% no-repeat,
    url("./assets/ticket-end.svg") 100% 100% / 7px 100% no-repeat;
}

.work {
  flex: 1;
  // max-width: calc(100% - 0.88rem);
  overflow: hidden;
  height: 2rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  border: 1px solid lightgray;

  //   & > span {
  //     display: block;
  //     max-width: 100%;
  //     overflow: hidden;
  //     white-space: nowrap;
  //     text-overflow: ellipsis;
  //   }
}

.planBg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 0;
}

.planBg {
  background-image: url("../../../../../images/ticket-background.svg");
}

.popupAnchor {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
