.header {
  display: grid;
  grid-template-columns: 6% 28% 16% 8% 14% 14% 14%;
  position: relative;
  align-items: center;
  height: 3.875rem;
}

.incomeHeader {
  grid-template-columns: 6% 28% 12% 9% 20% 14% 7% 4%;
}

.amountAndCount {
  padding: 0 1rem !important;
}

.status {
  max-width: 16.2rem;
}

.doubleLines {
  width: 100%;
  color: var(--color-font-secondary2);
  line-height: 1.5;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2rem;
  height: unset !important;
}

.container {

  .content {
    .tableHeader {
      font: normal normal 500 1rem var(--main-font);
      border-bottom: 1px solid var(--color-background-field7);
      display: grid;
      grid-template-columns: 8% 26% 20% 15% 15% 16%;
      padding-bottom: 0.5rem;

      .dot {
        background: var(--color-background-field8);
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 10rem;
      }

      .rowItem {
        white-space: nowrap;
        display: flex;
        align-items: center;
      }

      .fieldNumber {
        justify-content: center;
      }

      .fieldTitle {
        flex: 1;
      }
    }
  }
}

.measure {
  text-align: center;
  padding: 0 1rem !important;
}

.name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
}

.earCount {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 0.6875rem;
  background-color: #4fb1eb;
  color: #fff;
  margin-bottom: 0.5rem;
}

.action {
  cursor: pointer;
  display: flex;
  min-width: 3rem;
  justify-content: center;
  & > svg > path {
    fill: black;
  }
}

.approve {
  font-size: 13px;
  background-color: #fff;
  height: 2rem;
  border-radius: 1rem;
  padding: 0 10px;
  color: #000;

  & svg {
    margin-right: 0.86rem;
  }
}
