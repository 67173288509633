.links {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  & li {
    display: inline-block;
  }
}

.socialLink > img {
  width: 2.125rem;
  height: 2.125rem;
}