.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16.75rem;
    background-color: #EDEDED;
    height: 3rem;
    border-radius: 1.5rem;
    margin: 1.5rem auto;
    font-weight: 400;
    animation: fading .6s linear;
}

@keyframes fading {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}