.workers {
    margin-bottom: 0.8rem;

    & b {
        margin-bottom: 1rem;
    }
}

.workersList {
    display: flex;
    flex-wrap: wrap;
}

.worker {
    background: #4FB2ED;
    color: #fff;
    margin-right: 0.45rem;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    padding: 0 1.2rem;
    height: 2.375rem;
    white-space: nowrap;
    margin-bottom: 0.45rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
}

.green {
    background: #13C4B7;
}