.container {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.arrow {
  position: absolute;
  left: 0;
}

.switch {
  width: calc(100% - 1.5rem);
  display: grid;
  grid-template-columns: 1fr 34px;
  align-items: center;
  justify-content: space-between;
  margin-left: 1.5rem;
  padding: 0.5rem 0;
}

.children {
  padding-left: 1rem;
}
