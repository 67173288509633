.container {
  width: 35vw;
  height: auto;

  & label {
    font-size: 1rem !important; 
  }

  .title {
    font: normal normal 500 1.5rem var(--title-font);
    color: #000;
    margin-bottom: 2rem;
  }

  .topSide {
    padding: 1.5rem 1.5rem 0;
    border-bottom: 1px solid #D3D3D3;
    position: relative;

    .block1Title {
      display: flex;
      justify-content: space-between;
    }

    .closeIcon {
      cursor: pointer;
    }
  }

  .blockSelects {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;

  }

  .field1 {
    width: 55%;
  }

  .secondaryField {
    width: 40%;
    margin-top: 1.6rem
  }

  .value {
    margin: 1rem 0;
    font: normal normal 400 1rem/1.375rem var(--main-font);

    span {
      font-weight: 700;
    }
  }
}

.actionBlock {
  padding: 1.25rem 2.5rem 1.25rem 2rem;
  justify-content: flex-end;
  display: flex;
}

.field2 {
  width: 42%;
  margin-top: 1.725rem;
  align-self: flex-start;
}