.switchBlock {
  display: flex;
  justify-content: flex-start;
}

.text {
  cursor: pointer;
  margin-right: 1rem;
  font-family: var(--title-font);
}

.textRight {
  cursor: pointer;
  margin-left: 1rem;
}

.textInactive{
  color: var(--color-font-secondary);
}

.checkbox {
  & :global(.ant-switch) {
    &:after {
      background: #B2B2B2 !important;
    }
  }
}