.container {
  .content {
    display: flex;
    align-items: center;
    min-height: 3.7rem;
    margin: 0.5rem 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-border-field8);
  }

  .leftSide {
    display: grid;
    grid-template-columns: 0.55fr 1.85fr 1.2fr;
    align-items: center;
    width: 40%;

    .field1 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .field2 {
      padding-left: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .field3 {
      justify-self: center;
    }
  }

  &.withExpandedDifference .rightSide {
    grid-template-columns: 1fr 1fr 1.25fr 1fr 1fr 1fr;
  }

  .rightSide {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1.25fr 1fr 1fr;
    width: 60%;

    .commonField {
      text-align: center;
    }

    .field1 {
      @extend .commonField;
      width: 10%;
    }

    .secondaryField {
      @extend .commonField;
      width: 20%;
    }
  }

  .materialBlock {
    .countLabel {
      background-color: var(--color-background-primary);
      border-radius: 1rem;
      color: var(--color-font-hover);
      font-family: var(--title-font);
      width: 14.62rem;
      height: 2.3rem;
      padding: 0.5rem 1rem;
    }
  }
}

.fieldLeft1,
.fieldLeft4, .fieldLeft7 {
  justify-self: center;
}

.fieldLeft2,
.fieldLeft3,
.fieldLeft5,
.fieldLeft6 {
  justify-self: flex-end;
}


.fieldLeft8 {
  text-align: right;
}

.withExpandedDifference{
  .fieldLeft2,
  .fieldLeft3,
  .fieldLeft6,
  .fieldLeft5, .fieldLeft8 {
    padding-right: .75rem;
  }
}