.flexCol {
  display: flex;
  flex-direction: column;
}

.container {
  @extend .flexCol;
}

.tableHead {
  padding-top: 1.25rem;
  padding-right: 0.5rem;
  height: auto;
  grid-template-columns: 10fr 5fr 4fr 1fr;
  column-gap: 1.56rem;
  margin-bottom: 0;
}

.tableContainer {
  @extend .flexCol;
}

.addBtn {
  margin-right: auto;
}
