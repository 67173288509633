.popup {
  padding: 0.5rem 0.8rem;
}

.icon {
  padding: 0.2rem;
  box-sizing: content-box;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;

  & :global(.spinnerGlobal) {
    & > div {
      width: 22px;
      height: 22px;
    }
  }
}

.enabled {
  cursor: pointer;

  &:hover:not(.accepted) {
    path {
      fill: #4fb2ed;
    }
  }
}

.disabled {
  pointer-events: none;
}
