.container {
  display: flex;
  justify-content: center;
  background-color: var(--color-background-main);
  align-items: center;
  padding: 1rem;
  box-shadow: 0 0 10px #cfcfcf;
  border-radius: 0.75rem;

  .selectBlock {
    width: 14rem;

    .title {
      margin-top: 5rem;
      text-align: right;
      font-family: var(--title-font);
      white-space: nowrap;
      padding: 0.2rem 0;

      .plusIcon {
        margin-left: 1rem;
        cursor: pointer;
      }
    }
  }
  .description {
    font-family: var(--title-font);
    color: var(--color-font-primary);
    margin-top: 0.5rem;
  }
}

.outer {
  position: relative;
  width: 1rem;
  height: 5rem;
}

.outerOpened {
  width: 100%;
  height: auto;
  padding-bottom: 1rem;
}

.trigger {
  z-index: 11;
  cursor: pointer;
  position: absolute;
  left: -1rem;
  top: 0.5rem;
  width: 1rem;
  height: 5rem;
  background-color: #efefef;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transform: scaleX(-1);
  }
}

.triggerOpened {
  background-color: #4FB2ED;
  border-radius: 5px 0 0 5px;
  svg {
      transform: none;
      path {
          fill: #fff;
      }
  }
}
.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .divider {
    width: 1px;
    background-color: #C9C9C9;
    height: 3.5rem;
  }

  .item {
    display: flex;
    align-items: center;
    position: relative;

    &.canDrop1 {
      border-color:var(--color-border-primary);
    }
    &.canDrop2 {
      border-color:var(--color-border-primary);
    }
    &.canDrop3 {
      border-color:var(--color-border-field11);
    }

    .block {
      position: absolute;
      top: 10rem;
      right: 0;
      background-color: #fff;
      border-radius: 0.5rem;
      border: 1px solid var(--color-border-primary);
      display: none;
      font-family: var(--title-font);
      padding: 1rem;
      width: 21.2rem;
      white-space: break-spaces;

      &.isOpen {
        display: block;
      }

      .actionBlock {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;

        .button1 {
          margin-right: 1rem;
        }
      }
    }
  }
}

.circle {
  opacity: 0.75;
  width: 100%;
}

.active {
  opacity: 1;
}

.border {
  width: 65%;
  height: 6px;
  background-color: var(--color-border-primary);
  position: absolute;
  border-radius: 1rem;
  left: 19%;
  bottom: -0.75rem;
}