.paymentTerms {
  width: 100%;
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;

  border-radius: 0.75rem;
  border: 1px solid var(--gray-gray-3, #EBEBEB);
  background: var(--base-white, #FFF);
}

.header {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
}

.headerText {
  color: var(--base-text-primary, #000);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 125% */
}

.addWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.62rem;
}

.warning {
  color: var(--blue-blue-accent, #4FB1EB);
  text-align: right;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */
}

.content {
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  width: 65.25rem;
  justify-content: flex-start;
  flex-wrap: wrap;

  &.editing {
    justify-content: space-between;
  }
}