.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 38% 38% 24%;
  grid-template-areas: "_ _ _" "exp exp exp";
  white-space: nowrap;
}

.exp {
  grid-area: exp;
}

.history {
  width: 100%;
  overflow: hidden;
}

.headline {
  display: flex;
  align-items: baseline;
  column-gap: 0.5rem;
}
