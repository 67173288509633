@use "src/scss/dimensions" as dimensions;

$markOffset: .5rem;

.block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  padding-right: calc(3 * $markOffset);
}

.head {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  column-gap: .5rem;
  font-weight: 200;
}

.largeTitle {
  font-size: 1rem;
  white-space: nowrap;
}

@include dimensions.breakpoint-from(1900px) {
  .largeTitle{
    font-size: 1rem;
  }
}


.smallTitle {
  font-size: 1.125rem;
}

.subtitle {
  color: rgba(0, 0, 0, .6);
  font-size: .875rem;
  white-space: nowrap;
}

.mark {
  position: absolute;
  right: $markOffset;
  top: 50%;
  transform: translate(50%, -50%);
  height: 1.5rem;
  width: .4375rem;
  border-radius: .4375rem;
}