.root {
  margin-bottom: 2rem;
  h3 {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    font-weight: 700;
  }
}

.grid {
  display: grid;
  grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
  gap: 1rem;
}
