.mainInfoContainer {
  display: flex;
  width: 100%;
  padding: 0.5rem 1.5rem 1rem;
  background-color: var(--gray-gray-1, #FAFAFA);
}

.mainInfo {
  display: flex;
  column-gap: 1.125rem;
  flex-grow: 0.8;
}

.mainInfoItem {
  display: flex;
  width: 8.75rem;
  padding: 0.375rem 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;

  border-radius: 0.5rem;
  border: 1px solid var(--gray-gray-3, #EBEBEB);
  background: var(--base-white, #FFF);

  &.large {
    width: 13.75rem;
  }

  &.isEditing {
    background: none;
    padding: 0 0.75rem;
    border: none;
  }

  & .mainInfoItemTitle {
    font-weight: 600;
    color: #696969;
    font-size: 1rem;

    width: 100%;

    display: flex;
    white-space: nowrap;
  }

  & .description {
    font-size: 1rem;
    margin-top: 4px;
    font-weight: 600;
    color: var(--color-background-field12);
  }
}

.input {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;

  .inputInner {
    padding: 0.5rem 0.625rem 0.5rem 0.75rem;
    height: 2.25rem;
    width: 100%;
    border-radius: 0.375rem;
    border-color: var(--gray-gray-4, #C3C3C3);
    background: var(--base-white, #FFF);
    color: var(--base-text-primary, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */
  }
}

.selectContainer {
  width: 100%;
}

.select {
  border-radius: 0.375rem;
  border: 1px solid var(--gray-gray-4, #C3C3C3);
  outline: none;
  background: var(--base-white, #FFF);
  height: 2.25rem;
  display: flex;
  padding: 0.5rem 0.625rem 0.5rem 0.75rem;
  color: var(--base-text-primary, #000);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */
}

.infoPopover {
  padding: 0.5rem 1rem;

  .title {
    color: var(--base-text-secondary, #696969);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
  }

  .value {
    color: var(--base-text-primary, #000);
    text-align: right;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */
    display: block;
    width: 100%;
    margin-top: 0.5rem;
  }
}



.popoverWrapper {
  margin-left: 0.5rem;
  display: flex;
}

.popoverTooltip {
  z-index: 9999999999;
}

.info {
  cursor: pointer;

  &:hover {
    path {
      fill: #4FB1EB;
    }
  }
}

.switcherContainer {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;

  padding: 0.5rem 0;
  width: 14rem;
}

.switcher {
  label {
    color: var(--base-text-primary, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */
  }
}

.currency {
  color: var(--gray-gray-5, #A4A4A4);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */
}