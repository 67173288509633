.borders {
  border: 1px solid var(--color-border-field18);
  border-radius: 0.357rem;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-bottom: 0;
  cursor: pointer;
  margin-left: 0.5rem;

  &.labelRight {
    margin-left: unset;
    margin-right: 0.5rem;
  }
}

.checkbox {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  display: none;
}

.container {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
}

.indeterminateThin {
  width: 0.75rem;
  height: 2.5px;
  background-color: #696969;
  border-radius: 1.25px;
}
