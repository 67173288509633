.root {
  background-color: #ededed;
  font-size: 0.875rem;
  border-radius: 1rem;
  width: fit-content;
  display: flex;
  align-items: center;
  height: 2rem;
  padding-left: 0.25rem;
  padding-right: 0.5rem;
}

.avatar {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.25rem;

  div {
    font-weight: 500 !important;
    font-size: 0.625rem !important;
  }
}

.name {
  div {
    padding-right: 0 !important;
  }
}

.delete {
  margin-left: 0.25rem;
  padding-left: 0;
  padding-right: 0;
  width: 1rem;
  margin-right: 0.25rem;

  svg {
    height: 1rem;
    display: block;
  }
}
