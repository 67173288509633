@import "../../../../scss/dimensions";

.createKitButton {
  margin-left: 2.5rem;
  margin-bottom: 1rem;
}

.orderRequests {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-border-field4);

  .header {
    font: normal normal 500 1rem/1.375rem var(--main-font);
  }

  .header,
  .request,
  .columns {
    display: flex;
    align-items: center;
  }

  .columns {
    &:first-child {
      width: 6rem;
    }

    &:last-child {
      width: 100%;
      justify-content: flex-start;
      column-gap: 1rem;
    }
  }

  .header,
  .request {
    padding: 1rem 2.5rem;
  }

  .header,
  .request:not(:last-child) {
    border-bottom: 1px solid var(--color-border-field4);
  }

  .request {
    font: normal normal 1rem/1.375rem var(--title-font);
  }

  .kitRequest {
    border-left: 2px solid var(--color-border-primary);
  }

  .numberColumn,
  .nameColumn,
  .dateColumn,
  .countColumn,
  .checkboxColumn,
  .measureColumn,
  .matchingColumn {
    flex-shrink: 0;
  }

  .countColumn,
  .measureColumn {
    display: flex;
    justify-content: center;
  }

  .numberColumn {
    width: 45%;
  }

  .checkboxColumn {
    width: 65%;
  }

  .nameColumn {
    width: 40%;
  }

  .dateColumn,
  .sumColumn,
  .priceColumn,
  .countColumn {
    width: 9%;
  }

  .dateColumn {
    text-align: center;
  }

  .measureColumn {
    width: 8%;
    display: flex;
    justify-content: center;
  }

  .nameColumnFooter {
    display: flex;
    align-items: flex-start;
    margin-top: 0.625rem;
  }

  .nameColumn {
    .input {
      width: 95%;
    }
  }

  .brand {
    padding: 0.286rem 1rem;
    background: var(--color-background-field14);
    color: var(--color-font-primary);
    border-radius: 1rem;
    margin-right: 1rem;
  }

  .actionsColumn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    column-gap: 2.625rem;
    margin-left: auto;
  }

  .fork,
  .delete,
  .split {
    cursor: pointer;
  }

  .split img {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.dateColumnWithMatching {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
}

.withDifferedMeasures {
  .nameColumn {
    width: 30%;
  }
}
