.importButton {
  display: flex;
  align-items: center;
  height: auto;
  margin-bottom: 1.5rem;
  position: absolute;
  top: 3.25rem;
  left: 46rem;
  z-index: 9999;

  .text {
    margin-right: 1rem;
  }
}

.title {
  div {
    margin: auto;
  }
}

.modal {
  width: 32.25rem;
  height: 20.375rem;
  position: relative;
}

.err {
  width: 41.25rem;
  height: 38.625rem;
  position: relative;
}
