.header, .row {
  display: flex;
  align-items: center;

  & > * {
    padding-right: 1rem;
  }
}

.checkboxCol {
  flex: 1;
  display: flex;
  justify-content: center;
}

.nameCol {
  flex: 8;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.countCol {
  flex: 3;
}

.ordersCol {
  flex: 5;
}

.section, .row, .header {
  border-bottom: 1px solid var(--color-border-field1);
  padding: 1rem 0.5rem;
}

.section, .header {
  font-weight: 500;
}

.orders {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem 0.3rem;
}