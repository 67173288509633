.container {
  position: relative;

  .topBlock {
    display: flex;
    justify-content: space-between;
  }

  .header {
    display: flex;
    align-items: center;
  }

  .actionBlock {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
  }

  .employeesBlock {
    border-top: 1px solid var(--color-border-field4);
    margin: 0.5rem 0;
    padding: 0.5rem 0;

    .titleBlock {
      display: flex;
      width: 23rem;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      font: normal normal 400 1.12rem var(--title-font);
      color: #000;

      span {
        font-size: 1em;
      }
    }

    .listEmployees {
      display: flex;
    }
  }
}

.date {
  display: flex;
  align-items: center;
  margin-right: 3rem;
}

.dateValue {
  color: var(--color-font-main);
  margin-left: 1rem;
}

.createdDate {
  width: 38%;
}

.reportingDate {
  width: 62%;
}

.tableSpinner {
  margin-top: 2rem;
}

.downloadTimesheet {
  position: absolute;
  top: -4rem;
  right: 0;
}

.approvers {
  padding-left: 0;
  padding-right: 0;
}

.fileLoading {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .text {
    flex-shrink: 0;
  }
}

.slider {
  height: 3rem;
}