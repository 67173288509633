.simplifiedContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  padding: 2.5rem;
  align-self: stretch;
  overflow: hidden;
}

.paper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  height: 100%;
  background-color: var(--color-background-main);
  border-radius: 1rem;
  border: 1px solid var(--color-border-field3);
  overflow: hidden;
}

.header {
  width: 100%;
  padding: 2rem 2.5rem 0;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  .titlesGroup {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .title {
    font: var(--title-font);
    font-weight: 500;
    font-size: 1.25rem;
  }
}

.back {
  padding: 0 2.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}


.backTitle {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  line-height: normal;
  margin: 0 0 0 1rem;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.estimateContainer {
  height: 100%;
  overflow-y: auto;
}