.amounts {
  display: flex;
  align-items: center;
}

.divider {
  height: 2.25rem;
  width: 2px;
  background-color: #C7C7C7;
  margin: 0 1rem;
}