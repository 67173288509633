.content {
  min-width: 20rem;
  padding: 1rem;
  font-size: 0.75rem;
}

.svg {
  cursor: pointer;
}

.percents {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.value {
  font-size: 1.25rem;
  position: relative;
  padding-left: 1.25rem;
  margin-bottom: 0.5rem;

  &::before {
    content: "";
    height: 1.25rem;
    width: 3px;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: red;
  }
}
