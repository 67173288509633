.paymentTerm {
  display: flex;
  align-items: center;
  width: 49.5%;

  &.editing {
    width: unset;
  }
}

.fields {
  display: flex;
  align-items: flex-start;
}

.select {
  display: flex;
  width: 15.625rem;
  align-items: center;
}

.selectInner {
  padding: 0.6875rem 0.9375rem 0.6875rem 1rem;
  border-radius: 0.5rem;

  border: 1px solid var(--gray-gray-4, #C3C3C3);
  background: var(--base-white, #FFF);
  color: var(--base-text-primary, #000);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */
}

.selectOption {
  color: var(--base-text-primary, #000);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */
}

.input {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  width: 13rem;
  margin-left: 0.5rem;
}
.paymentTerm {
  .fields {
    .input {
      .inputInner {
        padding: 0.6875rem 2.44rem 0.6875rem 1rem;
        border-radius: 0.5rem;
        border: 1px solid var(--gray-gray-4, #C3C3C3);
        background: var(--base-white, #FFF);
        width: 100%;

        color: var(--base-text-primary, #000);
        text-align: right;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 125% */

        &:focus {
          border-color: var(--color-border-primary);
        }
      }
    }

    .doubleInput {
      min-height: 2.5rem;
      box-sizing: border-box;
      width: 13rem;
      height: 2.625rem !important;
      margin-left: 0.5rem;

      border: 1px solid var(--gray-gray-4, #c3c3c3);
      outline: none;
      transition: border-color 0.25s ease-in-out;

      &:focus-within:not([aria-disabled]) {
        border-color: var(--color-border-primary);
        box-shadow: none;
      }

      & label {
        &:after {
          height: 100% !important;
          background-color: var(--gray-gray-4, #c3c3c3) !important;
        }

        span {
          margin-right: 5px;
        }
      }

      & input {
        text-align: right;
        padding-right: 0.625rem;
        color: var(--base-text-primary, #000);
        font-size: 1rem;
        font-style: normal;
        font-weight: 500 !important;
      }

      & span {
        font-weight: 500 !important;
        color: #a4a4a4;
      }
    }
  }
}

.inputIcon {
  color: var(--gray-gray-5, #A4A4A4);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */
  letter-spacing: -0.03rem;
  margin-right: 5px;
}

.deleteButtonContainer {
  padding: 0;
  margin-left: 0.75rem;
  width: 1.25rem;
}

.paymentType {
  color: var(--base-text-secondary, #696969);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 125% */
  width: 6.875rem;
}

.percentWrapper {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-left: 0.5rem;
  width: 4rem;

  & span:first-child {
    color: var(--base-text-primary, #000);
    text-align: right;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */

    display: block;
    width: 2.5rem;
    text-align: right;
  }

  & span:last-child {
    color: var(--gray-gray-5, #A4A4A4);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */
    letter-spacing: -0.03rem;
  }
}

.daysWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1.125rem;

  color: var(--base-text-primary, #000);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */

  width: 8.375rem;

  & span:first-child {
    color: var(--gray-gray-5, #A4A4A4);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */
    letter-spacing: -0.03rem;
  }

  & span:last-child {
    color: var(--gray-gray-5, #A4A4A4);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */
    letter-spacing: -0.03rem;
  }
}