.dragging {
  width: 100%;
  background-color: #EBF8FF;
  border-radius: 0.5rem;
  border: 1px solid #F4F4F4;
  height: 8rem;
}

.dragHandler {
  width: 2rem;
  background-color: #E0E0E0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  position: absolute;
  left: -2rem;
  z-index: 2;
  border-radius: 0.5rem 0 0 0.5rem;
  transition: background-color var(--transition);
  cursor: pointer;

  &.active, &:hover {
    background-color: var(--color-background-primary);

    .dragHandlerIcon path {
      fill: #fff;
    }
  }
}

.dragHandlerIcon path {
  transition: fill var(--transition);
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.1rem;
}

.container:not(:last-child) .expenditure {
  border-bottom: 1px solid #eaeaea;
}