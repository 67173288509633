.amounts {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;

  .amountContainer {
    display: flex;
    font: normal normal 500 1.125rem/1.375rem var(--title-font);
  }

  .amountName,
  .amount,
  .amountCurrency {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    white-space: nowrap;
  }

  .amount,
  .amountCurrency {
    color: var(--color-font-primary);
  }

  .amount {
    margin-left: 1.071rem;
    margin-right: 0.5rem;
  }

  .amountRow:not(:last-child) {
    margin-bottom: 0.668rem;
  }
}
