.container {
  width: 90%;
}

.label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  white-space: nowrap;
}

.bar {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  height: 0.3rem;
  background: #ebebeb;
}

.progress {
  position: absolute;
  height: 0.3rem;
  left: 0;
  top: 0;
  background: #4fb1eb;
}

.part {
  background: #a26dd0;
}
