.advancePaymentItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;

  .title {
    color: #696969;
    font-size: 0.875rem;
    margin-right: 2rem;
  }
}

.calendarInput {
  width: 10.625rem;
  margin-right: 0;
  border-color: var(--gray-gray-4, #c3c3c3);
}

.amountInput {
  margin-left: 0.5rem;
  width: 12.5rem;

  & span {
    font-weight: 500;
    color: #a4a4a4;
  }
}

.amountInputInner {
  text-align: right;
  padding-right: 2.25rem;

  border-color: var(--gray-gray-4, #c3c3c3) !important;

  &:focus {
    border-color: var(--color-border-primary) !important;
  }
}

.doubleInput {
  min-height: 2.5rem;
  width: 16rem;
  margin-left: 0.5rem;

  border: 1px solid var(--gray-gray-4, #c3c3c3);
  outline: none;
  transition: border-color 0.25s ease-in-out;

  &:focus-within:not([aria-disabled]) {
    border-color: var(--color-border-primary);
    box-shadow: none;
  }

  & label {
    &:after {
      height: 100% !important;
      background-color: var(--gray-gray-4, #c3c3c3) !important;
    }
  }

  & input {
    text-align: right;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }

  & span {
    font-weight: 500;
    color: #a4a4a4;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.deleteIcon {
  margin-right: 0.5rem;
  cursor: pointer;
}

.addIcon {
  cursor: pointer;
}
