@import "src/scss/dimensions";

$tableLeftMargin: 2.56rem;

@mixin tableColWidth($width) {
  width: $width;
  min-width: $width;
  max-width: $width;
}

.container {
  border-bottom: 2px solid var(--color-border-field2);
  padding: 1.23rem 0 1.87rem 0;
}

.count {
  position: absolute;
  top: -0.4rem;
  left: 1.1rem;
  background-color: var(--color-background-primary);
  font: normal normal 400 0.87rem var(--title-font);
  color: var(--color-font-hover);
  border-radius: 1rem;
  min-width: 1.57rem;
  padding: 0.05rem 0;
  text-align: center;
}

.productsHeaderTableAction {
  display: flex;
  align-items: center;
  font: normal normal 400 1.12rem var(--title-font);
  padding: 0 2.56rem;
  height: 2.5rem;
  justify-content: space-between;

  .leftSide {
    display: flex;
    align-items: center;
  }

  .title {
    width: 13.5rem;
  }

  .icon {
    margin-right: 2.7rem;
    cursor: pointer;
  }

  .chooseProviderButton {
    width: min-content;
    margin-left: 2rem;
  }
}

.monitoringSwitcherContainer {
  justify-content: flex-end;
  padding-top: 1rem;
  margin-bottom: -1rem;
}

.headerTable {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  margin-top: 1rem;
  margin-left: $tableLeftMargin;
  height: 1.8rem;

  & > * {
    white-space: nowrap;
  }
}

.headerTable .title,
.productRow .title {
  width: 30%;
}

.section {
  background-color: var(--color-background-field4);
  height: 2.9rem;
  display: flex;
  align-items: center;
  padding: 0 2.56rem;
  font: normal normal 400 1.12rem var(--title-font);
  border-bottom: 1px solid var(--color-border-field1);
}

.productRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3.68rem;
  font: normal normal 400 1.12rem var(--title-font);
  margin-left: $tableLeftMargin;
  border-bottom: 1px solid var(--color-border-field1);

  .providerBlock {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem;
  }

  .addProviderButton {
    border-color: var(--color-border-primary);
  }

  .providerItem {
    background: #ddf0fc;
    width: 100%;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    column-gap: 0.5rem;

    .providerName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .crossIcon {
      cursor: pointer;
    }
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 2rem;
  }

  .actionBlock {
    display: flex;
    align-items: center;

    .action,
    .separator {
      flex: 1;
    }

    .action {
      height: 1.5rem;
      display: flex;
      justify-content: center;
    }

    .separator {
      padding-left: 0.5rem;
      flex-grow: 0.1;
    }

    .paperclipIcon {
      position: relative;
      cursor: pointer;

      .paperclipIconCount {
        @extend .count;
        top: -0.2em;
        left: 0.5rem;
      }
    }

    .crossIcon {
      cursor: pointer;
    }
  }

  .input {
    width: 7.813rem;
  }

  .inputInner {
    border: none;
    background-color: #efefef;
    border-radius: 0.5rem;
    color: var(--color-font-main);
    opacity: 1;
  }

  .calendar,
  .inputInner {
    height: 2.18rem;
  }

  .calendar {
    border: none;
    background-color: #efefef;
    width: 9rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    span {
      margin-right: 0.325rem;
    }

    img {
      margin: 0;
    }
  }

  .disabled {
    background: none;
  }
}

.emptyBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .title {
    font: normal normal 300 1.25rem var(--title-font);
    margin-top: 1.68rem;
    margin-bottom: 3rem;
  }
}

.argeementBlock {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 1.65rem 2.53rem;
}

.productsTable {
  .titleCol {
    @include tableColWidth(33%);
  }

  .matchingCol {
    @include tableColWidth(3%);
    display: flex;
    justify-content: center;
  }

  .orderCol {
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: 0.25rem;
    }
  }

  .requestCountCol,
  .dateCol {
    @include tableColWidth(10%);
  }

  .orderCol {
    @include tableColWidth(8%);
  }

  .measureCol {
    @include tableColWidth(5%);
  }

  .planFactDiffCol {
    @include tableColWidth(12%);
  }

  .providerCol {
    @include tableColWidth(15%);
  }

  .actionsCol {
    @include tableColWidth(13.2%);
  }

  .titleCol,
  .measureCol,
  .requestCountCol,
  .dateCol,
  .providerCol,
  .actionsCol,
  .checkboxCol,
  .orderCol,
  .planFactDiffCol,
  .matchingCol {
    padding-right: 1rem;
  }
}

.productsTable.isDraft {
}

.productsTable.viewModeMonitoring {
  .titleCol {
    @include tableColWidth(49%);
  }

  .requestCountCol {
    @include tableColWidth(8%);
  }

  .measureCol {
    @include tableColWidth(7%);
  }

  .actionsCol {
    @include tableColWidth(13.2%);
  }
}

.productsTable:not(.withOrders) {
  .titleCol {
    @include tableColWidth(41%);
  }

  &.viewModeMonitoring {
    .titleCol {
      @include tableColWidth(57%);
    }
  }
}

.comment {
  cursor: pointer;
  height: 100%;
}

.measureCol {
  justify-content: center;
  text-align: center;
}
