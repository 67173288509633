.container {
  width: 90%;
  display: grid;
  grid-template-columns: 39% 2% 34% 25%;
  height: 2rem;
  align-items: center;
  border-radius: 2rem;
  background: #d9f1ff;
  color: #000;
}

.value {
  text-align: center;
}

.measure {
  color: #4fb1eb;
}

.divider {
  background: #9ad9ff;
  width: 2px;
  height: 1.25rem;
  margin: 0 auto;
}
