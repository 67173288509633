.modal {
    width: 41rem;
    position: relative;
    height: 43rem;
}

.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #d3d3d3;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 0 0 1rem 1rem;
}

.subtitle {
    padding: 0 1rem 0.5rem;
    color: #707070;
}

.list {
    height: 34rem;
    overflow-y: scroll;
    padding: 1rem;
    padding-top: 0.5rem;
    border-top: 1px solid #d3d3d3;
}
