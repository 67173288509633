$borderColor: #dedede;

.work {
  flex: 1;
  overflow: hidden;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  border: 1px solid $borderColor;
  background-image: radial-gradient(circle at 0 0, $borderColor, $borderColor 5px, transparent 5px),
    radial-gradient(circle at 0 100%, $borderColor, $borderColor 5px, transparent 5px),
    radial-gradient(circle at 100% 100%, $borderColor, $borderColor 5px, transparent 5px),
    radial-gradient(circle at 100% 0, $borderColor, $borderColor 5px, transparent 5px),
    url("../../../../../../images/ticket-background.svg");
}

.intervalFill {
  position: absolute;
  height: 100%;
  left: 0;
  z-index: 0;
}

.remarksChip {
  position: absolute;
  right: 0.6rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.1875rem;

  color: white;
  background-color: var(--color-border-field15);

  border-radius: 0.625rem;
  padding: 0 0.25rem 0 0.125rem;

  font-family: var(--main-font);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
  letter-spacing: 0;
}

$braceColor: red;

.workBrace {
  position: absolute;
  height: 0.25rem;
  width: calc(100% - 1rem);
  background-color: $braceColor;
  top: 0;
}

.workBrace:after,
.workBrace:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
}

.workBrace:after {
  right: -0.25rem;
  border-style: solid;
  border-width: 0.25rem 0 0 0.25rem;
  border-color: transparent transparent transparent $braceColor;
}

.workBrace:before {
  left: -0.25rem;
  border-style: solid;
  border-width: 0 0 0.25rem 0.25rem;
  border-color: transparent transparent $braceColor transparent;
}

.workBrace:hover {
  z-index: 100;
}

.spacer {
  width: 100%;
  height: 100%;
}
