.empty {
  height: 100% !important;
  min-height: auto;
  gap: 1rem;

  & > div {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.list {
  overflow-y: auto;
  height: 14.25rem;
  display: grid;
  grid-template-columns: calc(33% - 0.25rem) calc(34% - 0.5rem) calc(33% - 0.25rem);
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px dashed #4fb1eb;
  background: #fff;
  padding: 0.5rem;
}

.item {
  border: 1px solid transparent;
  height: 13.15rem;
  position: relative;
  cursor: pointer;

  &.selectedFile {
    border-radius: 9px;
    border: 1px solid #4fb1eb;
    background: #e7f6ff;
  }
  &:hover {
    border-radius: 9px;
    background: #e7f6ff;
  }
  & :global(.iconWrapper) {
    margin-top: 0.25rem;
  }
  & > div {
    max-width: 100%;
    width: 100%;
    min-height: auto;
    padding-top: 0.75rem;
    &:hover {
      background-color: transparent;
    }
  }

  .checkbox {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    width: fit-content;
    z-index: 2;
    padding-top: 0;

    label label {
      margin-left: 0;
      background-color: #0f6cbd;
      border-color: transparent;

      path {
        stroke: #fff;
      }
    }
  }
}

.folder {
  & > div {
    padding-top: 0;
  }
}

.back {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  svg {
    width: 4rem;
    height: 4rem;
    transform: scaleX(-1);
  }
}

.emptyList {
  grid-template-columns: 1fr;
}
