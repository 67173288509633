.row {
    padding: 1.88rem 1.5rem 0;
    grid-template-columns: 8% 10% 24% 24% 10% 16% 8%;
    top: 2rem !important;
    z-index: 2 !important;
}

.inProduction {
    grid-template-columns: 8% 34% 24% 10% 16% 8%;
}
