$planColor: #bababa;
$toPayColor: #6fc79b;
$toDoColor: #4fb1eb;
$orderedColor: #af9ad0;
$acceptedColor: #6fc79b;
$issuedColor: #9edddb;
$onStockColor: #9870d0;

.resource {
  flex: 1;
  overflow: hidden;
  background-color: $planColor;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: $planColor;
}

.to_pay {
  background-color: $toPayColor;
  border-color: $toPayColor;
}

.todo {
  background-color: $toDoColor;
  border-color: $toDoColor;
}

.accepted {
  background-color: $acceptedColor;
  border-color: $acceptedColor;
}

.issued {
  background-color: $issuedColor;
  border-color: $issuedColor;
}

.on_stock {
  background-color: $onStockColor;
  border-color: $onStockColor;
}

.ordered {
  background-color: $orderedColor;
  border-color: $orderedColor;
}
