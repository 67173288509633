.radioLabel {
  cursor: pointer;
  display: flex;
  align-items: center;

  .text {
    font: normal normal 1.125rem/1.563rem var(--title-font);
    margin: 0 0 0 1.964rem;
  }

  .hidden {
    display: none;
  }
}

.radio {
  border: 1px solid var(--color-border-field18);
  border-radius: 0.357rem;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  opacity: 0.6;
}