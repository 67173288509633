.finance {
  min-height: calc(100vh - 4.2rem);
  padding: 1.88rem 0 32px 1.88rem;
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 0;

  & * {
    scrollbar-width: none !important;
  }
}

.tabs {
  margin-left: 3rem;
}

.tabsContainer {
  max-width: fit-content;
}

.templateContent {
  overflow-y: auto !important;
}
