.deliveryTerms {
  width: 29rem;
  padding: 0.75rem 1rem;
  border: #E1E8EE 1px solid;
  border-radius: 0.5rem;

  .header, .headerText {
    display: flex;
    align-items: center;
  }

  .header {
    height: 24px;
    justify-content: space-between;
  }

  .headerText {
    margin: 0;
    cursor: pointer;
    color: #000;
  }

  .priceCurrency, .headerText {
    font: normal normal 1.125rem/1.375rem var(--title-font);
  }

  .priceInputContainer {
    margin-left: 2.286rem;
    margin-right: 0.821rem;
    width: 100%;
  }

  .priceInput, .priceInputContainer {
    height: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
  }
}

.waiting {
  margin-top: 0.5rem;
  font-style: italic;
}

.checkRow {
  display: flex;
  align-items: center;
  height: 2.188rem;
}

.lined {
  border-top: #E1E8EE 1px solid;
  padding-top: 1rem;
}

.alert {
  color: #eb0000;
}
