.container {
  padding: 0.563rem 0 0.938rem;
}

.header {
  max-width: 20rem;
  padding-left: 0.813rem;

  & h1 {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
}

.section {
  padding: 0.563rem 0.375rem 0.875rem;
}

.item {
  padding: 0.563rem 1rem 0.75rem 0.625rem;
  border: 1px solid var(--color-background-field11);
  background-color: #f8f8f8;
  border-radius: 0.375rem;
  cursor: pointer;

  &:first-child {
    margin-bottom: 0.563rem;
  }

  &.active {
    border-color: #aadfff;
    background-color: #ddf2ff;
    cursor: default;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioName {
  margin-left: 1rem;
  font-weight: 400;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  & :global(.ant-upload-no-style) {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;

    & button {
      font-size: 1rem;
    }
  }
}
